import axios, {CancelToken} from "axios";
import {store} from "../redux/store";
import {DocumentsApi} from "../api/DocumentsApi";
import {IGetAccountantDocumentsRequest} from "../interfaces/requestsInterfaces/reportRequests";
import {IAccountantDocument} from "../interfaces/reportInterfaces/customerInterfaces";
import {ISendDocsToEdmRequest} from "../api/request/ISendDocsToEdmRequest";
import {Utils} from "../utils/utils";
import {useAppDispatch} from "../redux/hooks";
import {setCustomerBuhDocs} from "../redux/reportsSlice";


export function DocumentsService() {

    const documentsApi = DocumentsApi();


    const dispatch= useAppDispatch();


    /**
     * Method for obtaining accountant documents
     * @param {IGetAccountantDocumentsRequest} request - request data
     * @param {CancelToken} ct - cancellation token
     * @return {Promise<IAccountantDocument[]>} accountant documents list
     */
    const getAccountantDocuments = async (request: IGetAccountantDocumentsRequest, ct: CancelToken = axios.CancelToken.source().token): Promise<IAccountantDocument[]> => {
        try {
            const state = store.getState();
            const response = await documentsApi.getAccountantDocuments(request, state.auth.accessToken, ct);
            console.log(`DocumentService/getAccDoc ${response.status};  count${response.data.length}`)
            dispatch(setCustomerBuhDocs(response.data))

            return Array.isArray(response.data) ? response.data : [];
        }
        catch (ex: any) {
            return [];
        }
    };

    /**
     * Method for generate accountant documents
     * @param {string} request - request data
     * @param {CancelToken} ct - cancellation token
     * @return {Promise<any>} result of generating accountant documents
     */
    const generateDocuments = async (request: ISendDocsToEdmRequest, ct: CancelToken = axios.CancelToken.source().token): Promise<any> => {
        try {
            const state = store.getState();
            const response = await documentsApi.generateDocuments(request, state.auth.accessToken, ct);
            return !Utils.isEmpty(response.data) ? response.data : null;
        }
        catch (ex: any) {
            return null;
        }
    };

    /**
     * Method for obtaining accountant documents uploads
     * @param {string} clientCode - company code
     * @param {string[]} docNums - document numbers
     * @param {CancelToken} ct - cancellation token
     * @return {Promise<any>} accountant documents uploads
     */
    const getDocumentUploads = async (clientCode: string, docNums: string[], ct: CancelToken = axios.CancelToken.source().token): Promise<any> => {
        try {
            const state = store.getState();
            const response = await documentsApi.getDocumentUploads(clientCode, docNums, state.auth.accessToken, ct);
            return Array.isArray(response.data) ? response.data : [];
        }
        catch (ex: any) {
            return [];
        }
    }

    /**
     * Method for obtaining document file
     * @param {string} keyOrLink - document key or link data
     * @param {boolean} asLink - flag defining the download type (as link or as key)
     * @param {boolean} clientCode - company code
     * @param {CancelToken} ct - cancellation token
     * @return {Promise<any, any>} document file
     */
    const getDocumentFile = async (keyOrLink: string, asLink: boolean, clientCode: string, ct: CancelToken = axios.CancelToken.source().token): Promise<any> => {
        try {
            const state = store.getState();
            const response = await documentsApi.getDocumentFile(keyOrLink, asLink, clientCode, state.auth.accessToken, ct);
            return !Utils.isEmpty(response.data) ? response.data : null;
        }
        catch (ex: any) {
            return null;
        }
    }


    return {
        getAccountantDocuments,
        generateDocuments,
        getDocumentUploads,
        getDocumentFile
    };
}