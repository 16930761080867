import React from "react";
import {useTranslation} from "react-i18next";
import {ITrainVariant} from "../../../../../../interfaces/ITrainVariant";
import {TrainComponent} from "../trainComponent";
import {ScheduleIcon} from "../../../../../../icons/ScheduleIcon";
import {CircleSpinnerIcon} from "../../../../../../icons/CircleSpinnerIcon";
import {Guid} from "../../../../../../utils/utils";
import "./style.scss";


interface ITrainListComponent {
    searchPending: boolean,
    searchNotFound: boolean,
    variants: ITrainVariant[],
    selectVariantHandler: (variant: ITrainVariant, tariffIndex: number) => Promise<void>;
}


export function TrainListComponent(props: ITrainListComponent) {

    const {
        searchPending,
        searchNotFound,
        variants,
        selectVariantHandler,
    } = props;

    const {t} = useTranslation();


    return (
        <div className="trip-page-search-results">

            {
                (!searchPending && !searchNotFound && variants.length == 0) && (
                    <div className="trip-page-no-results-message">
                        <div className="trip-page-no-results-message-icon">
                            <ScheduleIcon
                                width="50px"
                                height="50px"
                                style={{
                                    "path": {
                                        fill: "rgb(100 100 100)"
                                    }
                                }}
                            />
                        </div>
                        <div className="trip-page-no-results-message-caption">
                            Список пуст
                        </div>
                        <div className="trip-page-no-results-message-content" style={{minWidth: "240px"}}>
                            Воспользуйтесь формой поиска чтобы найти поезда по заданным критериям.
                        </div>
                    </div>
                )
            }

            {
                searchPending && (
                    <div className="trip-page-no-results-message">
                        <div className="trip-page-no-results-message-icon">
                            <ScheduleIcon
                                width="50px"
                                height="50px"
                                style={{
                                    "path": {
                                        fill: "rgb(100 100 100)"
                                    }
                                }}
                            />
                            <CircleSpinnerIcon
                                width="130px"
                                height="130px"
                                style={{
                                    "svg": {
                                        position: "absolute"
                                    },
                                    "circle": {
                                        stroke: "rgb(210 210 210)",
                                        strokeWidth: 1
                                    }
                                }}
                            />
                        </div>
                        <div className="trip-page-no-results-message-caption">
                            Ищем билеты...
                        </div>
                        <div className="trip-page-no-results-message-content" style={{minWidth: "300px"}}>
                            Приозводим запрос к системе для получения наиболее подходящих для Вас результатов.
                        </div>
                    </div>
                )
            }

            {
                (!searchPending && searchNotFound && variants.length == 0) && (
                    <div className="trip-page-no-results-message">
                        <div className="trip-page-no-results-message-icon">
                            <ScheduleIcon
                                width="50px"
                                height="50px"
                                style={{
                                    "path": {
                                        fill: "rgb(100 100 100)"
                                    }
                                }
                                }
                            />
                        </div>
                        <div className="trip-page-no-results-message-caption">
                            Список пуст
                        </div>
                        <div className="trip-page-no-results-message-content">
                            Поиск не дал результатов. Измените параметры или повторите поиск.
                        </div>
                    </div>
                )
            }

            {
                variants.length > 0 && (
                    <div className="trip-page-search-results-tickets">
                        {
                            variants.map((variant, variantIndex) =>
                                <React.Fragment key={variantIndex}>
                                    <TrainComponent
                                        variantInfo={{id: Guid.newGuid(), variant}}
                                        selectVariant={selectVariantHandler}
                                    />
                                </React.Fragment>
                            )
                        }
                    </div>
                )
            }

        </div>
    );
}