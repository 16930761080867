import React from "react";
import {useTranslation} from "react-i18next";
import {ITrainVariant} from "../../../../../../../interfaces/ITrainVariant";
import {TrainIcon3} from "../../../../../../../icons/TrainIcon3";
import "./style.scss";
import {Utils} from "../../../../../../../utils/utils";


export interface ITrainTicketComponent {
    ticket: ITrainVariant,
}


export function TrainTicketComponent({ ticket }: ITrainTicketComponent) {
    const {t} = useTranslation();

    const townDepartment = ticket.cityFrom; //.replace(/ *\([^)]*\) */g, "");
    const dateDepartment = Utils.parseTrainDateTime(ticket.departureAt, ticket.departureShortOn);

    const townArrival = ticket.cityTo; //.replace(/ *\([^)]*\) */g, "");
    const dateArrival = Utils.parseTrainDateTime(ticket.arriveShortOn, ticket.arriveAtn);


    return (
        <div className="train-ticket-component">

            <div className="train-ticket-component-header">
                <div className="depart-info">
                    <div className="city-code">
                        {
                            ticket.stationFrom
                        }
                    </div>
                </div>
                <div className="train-info">
                    <div className="air-company-name">
                        {
                            ticket.travelTime
                        }
                    </div>
                </div>
                <div className="arrive-info">
                    <div className="city-code">
                        {
                            ticket.stationTo
                        }
                    </div>
                </div>
            </div>

            <div className="train-ticket-component-content">
                <div className="depart-info">
                    <div className="city-name">
                        {
                            townDepartment
                                .toLowerCase()
                                .split(' ')
                                .map(word => word.charAt(0).toUpperCase() + word.slice(1))
                                .join(' ')
                                .split('-')
                                .map(word => word.charAt(0).toUpperCase() + word.slice(1))
                                .join('-')
                        }
                    </div>
                    <div className="datetime">
                        {
                            <>
                                <span>
                                    {dateDepartment.getHours().toString().padStart(2, "0") + ":" + dateDepartment.getMinutes().toString().padStart(2, "0") + ", "}
                                </span>
                                <span>
                                    {dateDepartment.toLocaleString('default', {month: 'short'}).replace(".", "")}
                                    {" "}
                                    {dateDepartment.getDate().toString().padStart(2, "0")}
                                </span>
                            </>
                        }
                    </div>
                </div>
                <div className="train-info">
                    <div className="icon">
                        <span style={{opacity: 0.25}}></span>
                        <span style={{opacity: 0.5}}></span>
                        <span></span>
                        <div style={{margin: "-2px 2px 0 2px"}}>
                            <TrainIcon3
                                width="18px"
                                height="18px"
                                style={{
                                    "path": {
                                        fill: "#475AEF"
                                    }
                                }}
                            />
                        </div>
                        <span></span>
                        <span style={{opacity: 0.5}}></span>
                        <span style={{opacity: 0.25}}></span>
                    </div>
                    <div className="number">
                        {
                            ticket.trainNum
                        }
                    </div>
                </div>
                <div className="arrive-info">
                    <div className="city-name">
                        {
                            townArrival
                                .toLowerCase()
                                .split(' ')
                                .map(word => word.charAt(0).toUpperCase() + word.slice(1))
                                .join(' ')
                                .split('-')
                                .map(word => word.charAt(0).toUpperCase() + word.slice(1))
                                .join('-')
                        }
                    </div>
                    <div className="datetime">
                        {
                            <>
                                <span>
                                    {dateArrival.getHours().toString().padStart(2, "0") + ":" + dateArrival.getMinutes().toString().padStart(2, "0") + ", "}
                                </span>
                                <span>
                                    {dateArrival.toLocaleString('default', {month: 'short'}).replace(".", "")}
                                    {" "}
                                    {dateArrival.getDate().toString().padStart(2, "0")}
                                </span>
                            </>
                        }
                    </div>
                </div>
            </div>

        </div>
    );
}
