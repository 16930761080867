import React from "react";
import {useTranslation} from "react-i18next";
import {TripElementType} from "../../../../enums/TripElementType";
import {TripElementStatus} from "../../../../enums/TripElementStatus";
import {FlightTripItem, IPassengerInfo, TrainTripItem, Trip} from '../orderDetailsModal'
import {FlightComponent} from "../flightComponent";
import {TrainComponent} from "../trainComponent";
import {MdButton} from "../../../../components/md-button";
import {MdSelect} from "../../../../components/md-select";
import {MdCheckbox} from "../../../../components/md-checkbox";
import {CrossIcon} from "../../../../icons/CrossIcon";
import {UserIconFilled} from "../../../../icons/UserIconFilled";
import {CalendarIcon} from "../../../../icons/CalendarIcon";
import {PlusIcon} from "../../../../icons/PlusIcon";
import {Guid} from "../../../../utils/utils";
import "./style.scss"
import {LockIcon} from "../../../../icons/LockIcon";
import {MenuIcon} from "../../../../icons/MenuIcon";
import {ITripPassportDTO} from "../../../../interfaces/IOrderDTO";
import {PassportType} from "../../../../enums/PassportType";
import {NotFoundIcon} from "../../../../icons/NotFoundIcon";


export interface ITripComponentCallbacks {
    removeTrip?: () => void;
    openPassengerSelectionModal?: () => void;
    changePassengerPassport?: (paxCode: string, passportInfo: any) => void;
    removePassenger?: (paxCode: string) => void;
    openTicketSearchModal?: () => void;
    toggleIsOpenItem?: (itemId: string, state: boolean) => void;
    toggleIsSelectedItem?: (itemId: string, state: boolean) => void;
    changeItemTariff?: (itemId: string, elementType: TripElementType, tariffIndex: number) => void;
    removeItem?: (itemId: string, elementType: TripElementType) => void;
}

interface ITripComponent {
    tripDetails: Trip;
    canEdit?: boolean;
    callbacks?: ITripComponentCallbacks;
}


export function TripComponent(props: ITripComponent) {

    const {
        tripDetails,
        canEdit,
        callbacks
    } = props;

    const {t} = useTranslation();

    const passengers: IPassengerInfo[] = tripDetails.passengers;

    const items =
        tripDetails.items
        // .map((element, elementIndex) => {
        //     if(element.$type === 'FlightElementResponse') {
        //         const sr = JSON.parse(element.searchResponse) as IFlightSearchResponse;
        //         if(sr.variants.length > 0) {
        //             return new FlightTripItem(
        //                 element.id,
        //                 elementIndex,
        //                 false,
        //                 sr.variants[0],
        //                 sr.variants[0].rejsInfo[sr.variants[0].rejsInfo.length - 1].tarifs[0], // TODO: find by isSelected == true
        //                 element.searchResponse
        //             )
        //         }
        //     }
        //     else if(element.$type === 'TrainElementResponse') {
        //         const sr = JSON.parse(element.searchResponse) as ITrainSearchResponse;
        //         if(sr.variants.length > 0) {
        //             return new TrainTripItem(
        //                 element.id,
        //                 elementIndex,
        //                 false,
        //                 sr.variants[0],
        //                 sr.variants[0].categoryPrice[0], // TODO: find by isSelected == true
        //                 element.searchResponse
        //             )
        //         }
        //     }
        //     return null;
        // })
        // .filter((e: FlightTripItem | TrainTripItem | null): e is Exclude<typeof e, null> => e !== null)


    return (
        <div className="trip-builder-form"
             style={{
                padding: "10px 5px",
            }}
        >

            <div className="trip-builder-form-content"
                 style={{
                     maxHeight: "inherit",
                     overflow: "hidden",
                     paddingBottom: "10px"
                 }}>

                <div className="trip-builder-form-content-gap">
                    <span>
                        <span>
                            {
                                tripDetails.items.some(item => item.status == TripElementStatus.Booked || item.status == TripElementStatus.Confirmed)
                                ? (
                                    <LockIcon
                                        style={{
                                            path: {
                                                fill: "#777e90"
                                            }
                                        }}
                                    />
                                )
                                : (
                                    <MdButton
                                        type="submit"
                                        style={{
                                            "button": {
                                                alignItems: "center",
                                                justifyContent: "center",
                                                borderWidth: 0,
                                                backgroundColor: "#FFFFFF",
                                                color: "rgb(0 0 0)",
                                                borderRadius: "6px",
                                                boxShadow: "0px 3px 1px -2px rgba(0, 0, 0, 0.16), 0px 1px 2px 0px rgba(0, 0, 0, 0.1), 0px 1px 5px 0px rgba(0, 0, 0, 0.08)",
                                                marginRight: "0px",
                                                border: "1px solid rgb(175 175 175)",
                                                borderStyle: 'none',
                                                width: "24px",
                                                height: "24px",
                                                padding: 0
                                            },
                                            "ink": {
                                                backgroundColor: "rgba(255,255,255,.5)"
                                            }
                                        }}
                                        disabled={(canEdit == false)}
                                        onClick={() => {
                                            if (callbacks?.openPassengerSelectionModal != null) {
                                                callbacks.openPassengerSelectionModal()
                                            }
                                        }}
                                    >
                                        <MenuIcon
                                            width="12px"
                                            height="12px"
                                            style={{
                                                "svg": {
                                                    marginTop: "-2px"
                                                },
                                                "path": {
                                                    fill: "rgb(62 184 115)"
                                                }
                                            }}
                                        />
                                        {/*<PlusIcon*/}
                                        {/*    width="12px"*/}
                                        {/*    height="12px"*/}
                                        {/*    style={{*/}
                                        {/*        "svg": {*/}
                                        {/*            marginTop: "-2px"*/}
                                        {/*        },*/}
                                        {/*        "path": {*/}
                                        {/*            strokeWidth: "3px",*/}
                                        {/*            stroke: "rgb(62 184 115)"*/}
                                        {/*        }*/}
                                        {/*    }}*/}
                                        {/*/>*/}
                                    </MdButton>
                                )
                            }
                        </span>
                        <span>Пассажиры</span>
                    </span>
                    <span></span>
                    <span>
                        { passengers.length }
                    </span>
                </div>

                <div className="flight-info-section" style={{flexDirection: "column"}}>
                    {
                        passengers != null && passengers.length > 0 && (
                            <div className="passengers-list">
                                {
                                    passengers
                                        .sort((a, b) => (a.details.code > b.details.code) ? 1 : ((b.details.code > a.details.code) ? -1 : 0))
                                        .map((passengerInfo, passengerInfoIndex) => {
                                            return (
                                                <React.Fragment key={passengerInfoIndex}>
                                                    <div className="passengers-list-item">
                                                        <div className="passenger-item"
                                                             key={"passenger" + Guid.newGuid()}>
                                                            <div className="passenger-item-icon">
                                                                <UserIconFilled
                                                                    width="18px"
                                                                    height="18px"
                                                                    style={{
                                                                        "path": {
                                                                            fill: "#777E90"
                                                                        }
                                                                    }}
                                                                />
                                                            </div>
                                                            <div className="passenger-item-details">
                                                                <div className="passenger-item-details-row">
                                                                     <span className="passenger-fio">
                                                                         {
                                                                             (passengerInfo.details.firstName + " " + passengerInfo.details.lastName + " " + (passengerInfo.details.patronymic ?? ""))
                                                                                 .toLowerCase()
                                                                                 .split(' ')
                                                                                 .map(word => word.charAt(0).toUpperCase() + word.slice(1))
                                                                                 .join(' ')
                                                                         }
                                                                     </span>
                                                                    <div className="passenger-item-details-row">
                                                                        <CalendarIcon
                                                                            width="10px"
                                                                            height="10px"
                                                                            style={{
                                                                                "svg": {
                                                                                    marginTop: "2px"
                                                                                },
                                                                                "path": {
                                                                                    fill: "#8E8E90"
                                                                                }
                                                                            }}
                                                                        />
                                                                        <span className="passenger-dob">{passengerInfo.details.dateOfBurn.split('T')[0]}</span>
                                                                    </div>
                                                                </div>
                                                                <div className="passenger-item-details-row">
                                                                    {
                                                                        passengerInfo.passport != null
                                                                            ? (
                                                                                <MdSelect
                                                                                    displayMemberPath={"number"}
                                                                                    itemTemplate={(item: ITripPassportDTO) => {
                                                                                        const isExpired =
                                                                                            item.type !== PassportType.Russian
                                                                                            && (new Date(item.expired ?? "").getTime() - new Date().getTime() < 0);
                                                                                        return (
                                                                                            <div
                                                                                                className={("passport-item" + (isExpired ? " --expired" : ""))}>
                                                                                                <div className="passport-item-type">
                                                                                                    {
                                                                                                        (() => {
                                                                                                            if(item.type == PassportType.Russian) {
                                                                                                                return "Паспорт РФ"
                                                                                                            }
                                                                                                            else if(item.type == PassportType.Foreign) {
                                                                                                                return "Загран."
                                                                                                            }
                                                                                                            else if(item.type == PassportType.International) {
                                                                                                                return "Международ."
                                                                                                            }
                                                                                                        })()
                                                                                                    }
                                                                                                </div>
                                                                                                <div
                                                                                                    className="passport-item-number">
                                                                                                    {
                                                                                                        item.number
                                                                                                    }
                                                                                                </div>
                                                                                                {
                                                                                                    item.type !== PassportType.Russian && (
                                                                                                        <div className="passport-item-expiry">
                                                                                                            {
                                                                                                                new Date(item.expired ?? "").toLocaleDateString()
                                                                                                            }
                                                                                                        </div>
                                                                                                    )
                                                                                                }
                                                                                            </div>
                                                                                        )
                                                                                    }}
                                                                                    itemSource={passengerInfo.details.passports}
                                                                                    selectedItem={passengerInfo.passport ?? passengerInfo.details.passports[0]}
                                                                                    setSelectedItem={(passport: any) => {
                                                                                        if (callbacks?.changePassengerPassport != null) {
                                                                                            callbacks.changePassengerPassport(passengerInfo.details.code, passport)
                                                                                        }
                                                                                    }}
                                                                                    style={{
                                                                                        "root": {
                                                                                            margin: "5px 0",
                                                                                            minWidth: "290px"
                                                                                        }
                                                                                    }}
                                                                                    disabled={(canEdit == false)}
                                                                                />
                                                                            )
                                                                            : (
                                                                                <div className="trip-item-status-message error-status">
                                                                                    <span>
                                                                                        <NotFoundIcon
                                                                                            style={{
                                                                                                "svg": {
                                                                                                    margin: "-1px 5px 0 0",
                                                                                                    width: "12px",
                                                                                                    height: "12px"
                                                                                                },
                                                                                                "path": {
                                                                                                    fill: "#b12f00"
                                                                                                }
                                                                                            }}
                                                                                        />
                                                                                    </span>
                                                                                    <span>{t("bookingPage.noPassportDetails")}</span>
                                                                                </div>
                                                                            )
                                                                    }

                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="passengers-list-item-tools">
                                                            {
                                                                !tripDetails.items.some(item => item.status == TripElementStatus.Booked || item.status == TripElementStatus.Confirmed) && (
                                                                    <MdButton
                                                                        icon={
                                                                            <CrossIcon
                                                                                width="14"
                                                                                height="14"
                                                                                style={{
                                                                                    "path": {
                                                                                        fill: "rgb(114, 28, 36)"
                                                                                    }
                                                                                }}
                                                                            />
                                                                        }
                                                                        style={{
                                                                            "button": {
                                                                                alignItems: "center",
                                                                                justifyContent: "center",
                                                                                borderWidth: 0,
                                                                                backgroundColor: "#FFFFFF",
                                                                                color: "rgb(0 0 0)",
                                                                                borderRadius: "6px",
                                                                                boxShadow: "0px 3px 1px -2px rgba(0, 0, 0, 0.16), 0px 1px 2px 0px rgba(0, 0, 0, 0.1), 0px 1px 5px 0px rgba(0, 0, 0, 0.08)",
                                                                                marginRight: "0px",
                                                                                width: "24px",
                                                                                height: "24px",
                                                                                padding: 0
                                                                            },
                                                                            "ink": {
                                                                                backgroundColor: "rgba(230, 230, 230, 0.5)"
                                                                            },
                                                                            "icon": {
                                                                                marginLeft: 0,
                                                                                marginRight: 0,
                                                                            }
                                                                        }}
                                                                        disabled={(canEdit == false)}
                                                                        onClick={() => {
                                                                            if (callbacks?.removePassenger != null) {
                                                                                callbacks.removePassenger(passengerInfo.details.code)
                                                                            }
                                                                        }}
                                                                    />
                                                                )
                                                            }
                                                        </div>
                                                    </div>
                                                </React.Fragment>
                                            )
                                        })
                                }
                            </div>
                        )
                    }
                </div>

                <div className="trip-builder-form-content-gap">
                    <span>
                        <span>
                            <MdButton
                                type="submit"
                                style={{
                                    "button": {
                                        alignItems: "center",
                                        justifyContent: "center",
                                        borderWidth: 0,
                                        backgroundColor: "#FFFFFF",
                                        color: "rgb(0 0 0)",
                                        borderRadius: "6px",
                                        boxShadow: "0px 3px 1px -2px rgba(0, 0, 0, 0.16), 0px 1px 2px 0px rgba(0, 0, 0, 0.1), 0px 1px 5px 0px rgba(0, 0, 0, 0.08)",
                                        marginRight: "0px",
                                        border: "1px solid rgb(175 175 175)",
                                        borderStyle: 'none',
                                        width: "24px",
                                        height: "24px",
                                        padding: 0
                                    },
                                    "ink": {
                                        backgroundColor: "rgba(255,255,255,.5)"
                                    }
                                }}
                                disabled={(canEdit == false)}
                                onClick={() => {
                                    if (callbacks?.openTicketSearchModal != null) {
                                        callbacks.openTicketSearchModal()
                                    }
                                }}
                            >
                                <PlusIcon
                                    width="12px"
                                    height="12px"
                                    style={{
                                        "svg": {
                                            marginTop: "-2px"
                                        },
                                        "path": {
                                            strokeWidth: "3px",
                                            stroke: "rgb(62 184 115)"
                                        }
                                    }}
                                />
                            </MdButton>
                        </span>
                        <span>Маршрут</span>
                    </span>
                    <span></span>
                    <span>
                        {items.length}
                    </span>
                </div>

                {
                    items
                        .sort((a, b) => (a.timestamp > b.timestamp) ? 1 : ((b.timestamp > a.timestamp) ? -1 : 0))
                        .map((tripItem, tripItemIndex) => {
                            if (tripItem instanceof FlightTripItem) {
                                return (
                                    <React.Fragment key={"trip-item" + Guid.newGuid()}>
                                    <div className="ticket-list-item">
                                            <div>
                                                <MdCheckbox
                                                    type='filled'
                                                    checked={tripItem.isSelected}
                                                    style={{
                                                        "label": {
                                                            marginTop: "55px"
                                                        },
                                                        "input": {
                                                            width: "18px",
                                                            height: "18px"
                                                        }
                                                    }}
                                                    disabled={(canEdit == false)}
                                                    onChanged={(state) => {
                                                        if (callbacks?.toggleIsSelectedItem != null) {
                                                            callbacks.toggleIsSelectedItem(tripItem.id, state);
                                                        }
                                                    }}
                                                />
                                            </div>

                                            <div>
                                                <FlightComponent
                                                    flightInfo={tripItem}
                                                    canEdit={(
                                                        tripItem.status != TripElementStatus.Booked
                                                        && tripItem.status != TripElementStatus.Confirmed
                                                        && canEdit
                                                    )}
                                                    callbacks={{
                                                        toggle: (state: boolean) => {
                                                            if (callbacks?.toggleIsOpenItem != null) {
                                                                callbacks.toggleIsOpenItem(tripItem.id, state);
                                                            }
                                                        },
                                                        changeTariff: (tariffIndex: number) => {
                                                            if (callbacks?.changeItemTariff != null) {
                                                                callbacks.changeItemTariff(tripItem.id, TripElementType.Flight, tariffIndex);
                                                            }
                                                        }
                                                    }}
                                                />
                                                {
                                                    (tripItem.status == TripElementStatus.NotSet && passengers.length == 0) && (
                                                        <div className="trip-item-status-message error-status" style={{ top: "65px" }}>
                                                            <span>{t("bookingPage.noPassengers")}</span>
                                                        </div>
                                                    )
                                                }
                                            </div>

                                            <div>
                                                {
                                                    tripItem.status == TripElementStatus.NotSet && (
                                                        <MdButton
                                                            icon={
                                                                <CrossIcon
                                                                    width="14"
                                                                    height="14"
                                                                    style={{
                                                                        "path": {
                                                                            fill: "rgb(114, 28, 36)"
                                                                        }
                                                                    }}
                                                                />
                                                            }
                                                            style={{
                                                                "button": {
                                                                    marginTop: "50px",
                                                                    alignItems: "center",
                                                                    justifyContent: "center",
                                                                    borderWidth: 0,
                                                                    backgroundColor: "#FFFFFF",
                                                                    color: "rgb(0 0 0)",
                                                                    borderRadius: "6px",
                                                                    boxShadow: "0px 3px 1px -2px rgba(0, 0, 0, 0.16), 0px 1px 2px 0px rgba(0, 0, 0, 0.1), 0px 1px 5px 0px rgba(0, 0, 0, 0.08)",
                                                                    marginRight: "0px",
                                                                    width: "24px",
                                                                    height: "24px",
                                                                    padding: 0
                                                                },
                                                                "ink": {
                                                                    backgroundColor: "rgba(230, 230, 230, 0.5)"
                                                                },
                                                                "icon": {
                                                                    marginLeft: 0,
                                                                    marginRight: 0,
                                                                }
                                                            }}
                                                            disabled={(canEdit == false)}
                                                            onClick={() => {
                                                                if (callbacks?.removeItem != null) {
                                                                    callbacks.removeItem(tripItem.id, TripElementType.Flight);
                                                                }
                                                            }}
                                                        />
                                                    )
                                                }
                                            </div>
                                        </div>
                                    </React.Fragment>
                                )
                            }
                            else if (tripItem instanceof TrainTripItem) {
                                return (
                                    <React.Fragment key={"trip-item" + Guid.newGuid()}>
                                        <div className="ticket-list-item">

                                            <div>
                                                {
                                                    tripItem.status != TripElementStatus.Confirmed && (
                                                        <MdCheckbox
                                                            type='filled'
                                                            checked={tripItem.isSelected}
                                                            style={{
                                                                "label": {
                                                                    marginTop: "45px"
                                                                },
                                                                "input": {
                                                                    width: "18px",
                                                                    height: "18px"
                                                                }
                                                            }}
                                                            disabled={(canEdit == false)}
                                                            onChanged={(state) => {
                                                                if (callbacks?.toggleIsSelectedItem != null) {
                                                                    callbacks.toggleIsSelectedItem(tripItem.id, state);
                                                                }
                                                            }}
                                                        />
                                                    )
                                                }
                                            </div>

                                            <div>
                                                <TrainComponent
                                                    trainInfo={tripItem}
                                                    canEdit={(
                                                        tripItem.status != TripElementStatus.Booked
                                                        && tripItem.status != TripElementStatus.Confirmed
                                                        && canEdit
                                                    )}
                                                    callbacks={{
                                                        toggle: (state: boolean) => {
                                                            if (callbacks?.toggleIsOpenItem != null) {
                                                                callbacks.toggleIsOpenItem(tripItem.id, state);
                                                            }
                                                        },
                                                        changeTariff: (tariffIndex: number) => {
                                                            if (callbacks?.changeItemTariff != null) {
                                                                callbacks.changeItemTariff(tripItem.id, TripElementType.Train, tariffIndex);
                                                            }
                                                        }
                                                    }}
                                                />
                                                {
                                                    (tripItem.status == TripElementStatus.NotSet && passengers.length == 0) && (
                                                        <div className="trip-item-status-message error-status" style={{top: "55px"}}>
                                                            <span>{t("bookingPage.noPassengers")}</span>
                                                        </div>
                                                    )
                                                }
                                            </div>

                                            <div>
                                                {
                                                    tripItem.status == TripElementStatus.NotSet && (
                                                        <MdButton
                                                            icon={
                                                                <CrossIcon
                                                                    width="14"
                                                                    height="14"
                                                                    style={{
                                                                        "path": {
                                                                            fill: "rgb(114, 28, 36)"
                                                                        }
                                                                    }}
                                                                />
                                                            }
                                                            style={{
                                                                "button": {
                                                                    marginTop: "40px",
                                                                    alignItems: "center",
                                                                    justifyContent: "center",
                                                                    borderWidth: 0,
                                                                    backgroundColor: "#FFFFFF",
                                                                    color: "rgb(0 0 0)",
                                                                    borderRadius: "6px",
                                                                    boxShadow: "0px 3px 1px -2px rgba(0, 0, 0, 0.16), 0px 1px 2px 0px rgba(0, 0, 0, 0.1), 0px 1px 5px 0px rgba(0, 0, 0, 0.08)",
                                                                    marginRight: "0px",
                                                                    width: "24px",
                                                                    height: "24px",
                                                                    padding: 0
                                                                },
                                                                "ink": {
                                                                    backgroundColor: "rgba(230, 230, 230, 0.5)"
                                                                },
                                                                "icon": {
                                                                    marginLeft: 0,
                                                                    marginRight: 0,
                                                                }
                                                            }}
                                                            disabled={(canEdit == false)}
                                                            onClick={() => {
                                                                if (callbacks?.removeItem != null) {
                                                                    callbacks.removeItem(tripItem.id, TripElementType.Train);
                                                                }
                                                            }}
                                                        />
                                                    )
                                                }
                                            </div>
                                        </div>
                                    </React.Fragment>
                                )
                            } else {
                                return (
                                    <React.Fragment key={"trip-item" + Guid.newGuid()}>

                                    </React.Fragment>
                                )
                            }
                        })
                }

                <div className="order-summary-info">
                    {/*<div className="order-summary-info-item">*/}
                    {/*    <span>Стоимость билетов</span>*/}
                    {/*    <span></span>*/}
                    {/*    <span>*/}
                    {/*        {*/}
                    {/*            (*/}
                    {/*                items.reduce((a, b) => {*/}
                    {/*                    if (b instanceof FlightTripItem) {*/}
                    {/*                        return a + b.tariff.priceWithSborTts;*/}
                    {/*                    } else if (b instanceof TrainTripItem) {*/}
                    {/*                        return a + (b.tariff.price + b.tariff.sborTts);*/}
                    {/*                    } else {*/}
                    {/*                        return 0;*/}
                    {/*                    }*/}
                    {/*                }, 0)*/}
                    {/*            ).toLocaleString()*/}
                    {/*            + " ₽"*/}
                    {/*        }*/}
                    {/*    </span>*/}
                    {/*</div>*/}

                    <div className="order-summary-info-item">
                        <span style={{fontWeight: 500}}>Стоимость командировки</span>
                        <span></span>
                        <span>
                            {
                                passengers.length == 0
                                    ? (
                                        "Не определена"
                                    )
                                    : (
                                        (
                                            items.filter(x => x.isSelected).reduce((a, b) => {
                                                if (b instanceof FlightTripItem) {
                                                    const tariff = b.variant.rejsInfo[b.variant.rejsInfo.length - 1].tarifs[b.tariffIndex];
                                                    return a + tariff.priceWithSborTts * passengers.length;
                                                } else if (b instanceof TrainTripItem) {
                                                    const tariff = b.variant.categoryPrice[b.tariffIndex];
                                                    return a + (tariff.price + tariff.sborTts) * passengers.length;
                                                } else {
                                                    return 0;
                                                }
                                            }, 0)
                                        ).toLocaleString()
                                        + " ₽"
                                    )
                            }
                        </span>
                    </div>
                </div>

            </div>

        </div>
    );
}