import {
  AVIA_SERVICE_RESPONSE,
  HOTEL_SERVICE_RESPONSE,
  TRAIN_SERVICE_RESPONSE,
} from "../../../../constants/ServiceCostants";
import { IClientStructure } from "../../../../interfaces/companyInfoInterfaces/IClientStructure";
import { StatisticSimpleCardComponent } from "../../../commonComponents/commonCardComponents/statisticSimpleCardComponent";
import {useTranslation} from "react-i18next";
import {TrainIcon} from "../../../../icons/TrainIcon";
import {PlaneIcon} from "../../../../icons/PlaneIcon";
import {HotelIcon} from "../../../../icons/HotelIcon";

type ClientStructureProps = {
  clientSales?: IClientStructure[];
  clientExchnge?: IClientStructure[];
  clientRefound?: IClientStructure[];
};
export function ClientStructureComponent(props: ClientStructureProps) {
    const { t } = useTranslation();
  return (
    <>

      {" "}
      <div className=" d-flex flex-row  flex-wrap gap-2">
        <StatisticSimpleCardComponent
            icon = {<PlaneIcon/>}
            type = "lightBlue"

          title={"Авиа Деятельность"}
          footer={""}
          data={[
            [
                `${t("statisticPage.sales")}`,
              "" +
              (props.clientSales?.find((item) => item.serviceKind === AVIA_SERVICE_RESPONSE)?.totalSum ?? 0),
            ],
            [
                `${t("statisticPage.exchange")}`,
              "" +
              (props.clientExchnge?.find((item) => item.serviceKind === AVIA_SERVICE_RESPONSE)?.totalSum ?? 0),
            ],
            [
                `${t("statisticPage.refund")}`,
              "" +
              (props.clientRefound?.find((item) => item.serviceKind === AVIA_SERVICE_RESPONSE)?.totalSum ?? 0),
            ],
          ]}
        />
        <StatisticSimpleCardComponent
            icon={<TrainIcon/>}
            type = "lightGreen"
          title={"Жд деятельность"}
          footer={""}
          data={[
            [
                `${t("statisticPage.sales")}`,
              "" +
              (props.clientSales?.find((item) => item.serviceKind === TRAIN_SERVICE_RESPONSE)?.totalSum ?? 0),
            ],
            [
                `${t("statisticPage.exchange")}`,
              "" +
                (props.clientExchnge?.find((item) => item.serviceKind === TRAIN_SERVICE_RESPONSE)?.totalSum ?? 0),
            ],
            [
                `${t("statisticPage.refund")}`,
              "" +
              (props.clientRefound?.find((item) => item.serviceKind === TRAIN_SERVICE_RESPONSE)?.totalSum ?? 0),
            ],
          ]}
        />
        <StatisticSimpleCardComponent
            icon = {<HotelIcon iconSize="20px"/>}
            type = "green"
          title={"Отели"}
          footer={""}
          data={[
            [
                `${t("statisticPage.sales")}`,
              "" +
              (props.clientSales?.find((item) => item.serviceKind === HOTEL_SERVICE_RESPONSE)?.totalSum ?? 0),
            ],
            [
                `${t("statisticPage.exchange")}`,
              "" +
              (props.clientExchnge?.find((item) => item.serviceKind === HOTEL_SERVICE_RESPONSE)?.totalSum ?? 0),
            ],
            [
                `${t("statisticPage.refund")}`,
              "" +
              (props.clientRefound?.find((item) => item.serviceKind === HOTEL_SERVICE_RESPONSE)?.totalSum ?? 0),
            ],
          ]}
        />
      </div>
    </>
  );
}
