import {Navigate, Route, Routes} from "react-router-dom";
import {useAxiosInterceptors} from "./api/axiosInterceptors";
import {ProtectedRoute} from "./components/protectedRoute";
import MainLayout from "./layout/mainLayout";
import {HomeLayout} from "./layout/homeLayout";
import {CabinetLayout} from "./layout/cabinetLayout";
import {HomePage} from "./pages/landingPages/homePage";
import {SignInPage} from "./pages/landingPages/signInPage";
import {HomeCabinetPage} from "./pages/cabinetPages/homeCabinetPage";
import {MyProfilePage} from "./pages/cabinetPages/myProfilePage";
import {StatisticPage} from "./pages/cabinetPages/statisticPage";
import {AccountantDocumentsPage} from "./pages/cabinetPages/documentsPage";
import {UsersPage} from "./pages/cabinetPages/usersPage";
import {EmployeesPage} from "./pages/cabinetPages/employeesPage";
import {ServicesPage} from "./pages/landingPages/servicesPage";
import {TariffsPage} from "./pages/landingPages/tariffsPage";
import {ContactsPage} from "./pages/landingPages/contactsPage";
import {ChangePasswordPage} from "./pages/commonPages/changePasswordPage";
import {RecoveryPasswordPage} from "./pages/commonPages/recoveryPasswordPage";
import {NotFoundPage} from "./pages/errorPages/notFoundPage";
import {Environments} from "./enums/Environments";
import {TripPage} from "./pages/cabinetPages/tripPage";
import {SupportPage} from "./pages/cabinetPages/supportPage";
import {SurveyPage} from "./pages/commonPages/surveyPage";
import "./App.scss";


export function App() {

    useAxiosInterceptors();


    return (
        <>
            <Routes>
                <Route path="/" element={<MainLayout/>}>
                    <Route path="/" element={<HomeLayout/>}>
                        <Route index element={<HomePage/>}/>
                        <Route path="/services" element={<ServicesPage/>}/>
                        <Route path="/tariffs" element={<TariffsPage/>}/>
                        <Route path="/contacts" element={<ContactsPage/>}/>
                        <Route path="sign-in" element={<SignInPage/>}/>
                        {/*<Route path="sign-up" element={<SignUpPage/>}/>*/}
                        <Route path="changePassword" element={<ChangePasswordPage/>}/>
                        <Route path="recovery" element={<RecoveryPasswordPage/>}/>
                        <Route path="survey" element={<SurveyPage/>}/>
                    </Route>
                    <Route path="/cabinet/" element={<ProtectedRoute/>}>
                        <Route path="/cabinet/" element={<CabinetLayout/>}>

                            <Route path="/cabinet/" element={<Navigate to="/cabinet/orders"/>}/>
                            <Route path="/cabinet/" element={<ProtectedRoute env={Environments.Development}/>}>
                                <Route path="/cabinet/account" element={<HomeCabinetPage/>}/>
                            </Route>

                            <Route path="/cabinet/" element={<ProtectedRoute env={Environments.Development}/>}>
                                <Route path="/cabinet/profile" element={<MyProfilePage/>}/>
                            </Route>

                            <Route
                                path="/cabinet/"
                                element={
                                    <ProtectedRoute env={Environments.Development}/>
                                }
                            >
                                <Route path="/cabinet/reports" element={<StatisticPage/>}/>
                            </Route>

                            <Route
                                path="documents"
                                element={
                                    <AccountantDocumentsPage/>
                                }
                            />

                            <Route
                                path="orders"
                                element={
                                    <TripPage/>
                                }
                            />

                            <Route
                                path="/cabinet/"
                                element={
                                    <ProtectedRoute env={Environments.Development | Environments.Staging}/>
                                }
                            >
                                <Route path="/cabinet/users" element={<UsersPage/>}/>
                            </Route>

                            <Route
                                path="/cabinet/employees"
                                element={
                                    <EmployeesPage/>
                                }
                            />

                            <Route
                                path="/cabinet/"
                                element={
                                    <ProtectedRoute />
                                }
                            >
                                <Route path="/cabinet/support" element={<SupportPage/>}/>
                            </Route>

                        </Route>
                    </Route>
                    <Route path='*' element={<NotFoundPage/>}/>
                    {/*<Route path='/accessDenied' element={<AccessDeniedPage/>}/>*/}
                </Route>
            </Routes>
        </>
    );
}

export default App;