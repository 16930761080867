import React from "react";
import "./style.scss";


export function SurveyPage() {

    return (
        <div className="survey-form-wrapper">
            <div className="survey-form-container">

                <div className="survey-form">

                    <div className="survey-form-input-group">
                        <label htmlFor="name" className="survey-form-label">Имя</label>
                        <input type="text" name="name" placeholder="Введите ваше имя" className="survey-form-input"/>
                    </div>

                    <div className="survey-form-input-group">
                        <label htmlFor="email" className="survey-form-label">Email</label>
                        <input type="email" name="email" placeholder="Введите ваш email"
                               className="survey-form-input"/>
                    </div>

                    <div className="survey-form-input-group">
                        <label htmlFor="age" className="survey-form-label">Возраст</label>
                        <input type="text" name="age" placeholder="Введите ваш возраст"
                               className="survey-form-input"/>
                    </div>

                    <div className="survey-form-input-group">
                        <label className="survey-form-label">
                            Какой вариант лучше всего вас описывает?
                        </label>

                        <select className="survey-form-select" name="occupation" >
                            <option value="Student">Студент</option>
                            <option value="designer">UX/UI дизайнер</option>
                            <option value="fullstack">Full Stack разработчик</option>
                            <option value="frontend">Front End разработчик</option>
                            <option value="backend">Back End разработчик</option>
                        </select>
                    </div>

                    <div className="survey-form-input-radio-wrapper">
                        <label htmlFor="ans" className="survey-form-label">
                            Порекомендуете ли вы наш сайт другу?
                        </label>

                        <div className="survey-form-radio-flex">
                            <div className="survey-form-radio-group">
                                <label className="survey-form-radio-label">
                                    <input className="survey-form-input-radio" type="radio" name="ans"/>
                                    <span className="survey-form-radio-checkmark"></span>
                                    Yes
                                </label>
                            </div>

                            <div className="survey-form-radio-group">
                                <label className="survey-form-radio-label">
                                    <input className="survey-form-input-radio" type="radio" name="ans"/>
                                    <span className="survey-form-radio-checkmark"></span>
                                    Нет
                                </label>
                            </div>

                            <div className="survey-form-radio-group">
                                <label className="survey-form-radio-label">
                                    <input className="survey-form-input-radio" type="radio" name="ans"/>
                                    <span className="survey-form-radio-checkmark"></span>
                                    Возможно
                                </label>
                            </div>
                        </div>
                    </div>

                    <div className="survey-form-input-radio-wrapper">
                        <label className="survey-form-label">
                            Какие языки &amp; фреймворки вы знаете?
                        </label>

                        <div className="survey-form-radio-flex">
                            <div className="survey-form-radio-group">
                                <label className="survey-form-radio-label">
                                    <input className="survey-form-input-radio" type="checkbox" name="c"/>
                                    <span className="survey-form-radio-checkmark"></span>
                                    C
                                </label>
                            </div>

                            <div className="survey-form-radio-group">
                                <label className="survey-form-radio-label">
                                    <input className="survey-form-input-radio" type="checkbox" name="c++"/>
                                    <span className="survey-form-radio-checkmark"></span>
                                    C++
                                </label>
                            </div>

                            <div className="survey-form-radio-group">
                                <label className="survey-form-radio-label">
                                    <input className="survey-form-input-radio" type="checkbox" name="c++"/>
                                    <span className="survey-form-radio-checkmark"></span>
                                    C#
                                </label>
                            </div>

                            <div className="survey-form-radio-group">
                                <label className="survey-form-radio-label">
                                    <input className="survey-form-input-radio" type="checkbox" name="java"/>
                                    <span className="survey-form-radio-checkmark"></span>
                                    Java
                                </label>
                            </div>

                            <div className="survey-form-radio-group">
                                <label className="survey-form-radio-label">
                                    <input className="survey-form-input-radio" type="checkbox" name="python"/>
                                    <span className="survey-form-radio-checkmark"></span>
                                    Python
                                </label>
                            </div>

                            <div className="survey-form-radio-group">
                                <label className="survey-form-radio-label">
                                    <input className="survey-form-input-radio" type="checkbox" name="javaScript"/>
                                    <span className="survey-form-radio-checkmark"></span>
                                    JavaScript
                                </label>
                            </div>

                            <div className="survey-form-radio-group">
                                <label className="survey-form-radio-label">
                                    <input className="survey-form-input-radio" type="checkbox" name="react"/>
                                    <span className="survey-form-radio-checkmark"></span>
                                    React
                                </label>
                            </div>

                            <div className="survey-form-radio-group">
                                <label className="survey-form-radio-label">
                                    <input className="survey-form-input-radio" type="checkbox" name="angular"/>
                                    <span className="survey-form-radio-checkmark"></span>
                                    Angular
                                </label>
                            </div>

                            <div className="survey-form-radio-group">
                                <label className="survey-form-radio-label">
                                    <input className="survey-form-input-radio" type="checkbox" name="django"/>
                                    <span className="survey-form-radio-checkmark"></span>
                                    Django
                                </label>
                            </div>

                            <div className="survey-form-radio-group">
                                <label className="survey-form-radio-label">
                                    <input className="survey-form-input-radio" type="checkbox" name="tailwind"/>
                                    <span className="survey-form-radio-checkmark"></span>
                                    Tailwind CSS
                                </label>
                            </div>

                            <div className="survey-form-radio-group">
                                <label className="survey-form-radio-label">
                                    <input className="survey-form-input-radio" type="checkbox" name="bootstrap"/>
                                    <span className="survey-form-radio-checkmark"></span>
                                    Bootstrap
                                </label>
                            </div>
                        </div>
                    </div>

                    <div>
                        <label htmlFor="message" className="survey-form-form-label">
                            Любые комментарии или предложения
                        </label>
                        <textarea rows={6}
                            name="message"
                            placeholder="Введите текст..."
                            className="survey-form-input"></textarea>
                    </div>

                    <button className="survey-form-btn">Отправить</button>

                </div>

            </div>

        </div>
    );
}
