import React, {forwardRef, useContext, useImperativeHandle, useState} from "react";
import {useTranslation} from "react-i18next";
import {ModalContext} from "../../../commonComponents/modal/ModalContext";
import {IPassengerDTO} from "../../../../interfaces/IPassengerDTO";
import {ICreatePassengerRequest} from "../../../../api/request/ICreatePassengerRequest";
import {ICountryDTO} from "../../../../interfaces/ICountryDTO";
import {Gender} from "../../../../enums/Gender";
import {EmployeesService} from "../../../../services/EmployeesService";
import {RefsService} from "../../../../services/RefsService";
import {DateService} from "../../../../services/DateService";
import {MdModal} from "../../../../components/md-modal";
import {MdScrollViewer} from "../../../../components/md-scroll-viewer";
import {MdToggleButton} from "../../../../components/md-toggle-button";
import {MdButton} from "../../../../components/md-button";
import {MdSelect} from "../../../../components/md-select";
import {Guid} from "../../../../utils/utils";
import "./style.scss";
import {IPassengerDocumentDTO} from "../../../../interfaces/IPassengerDocumentDTO";
import {useAppSelector} from "../../../../redux/hooks";
import {PassengerDocumentType} from "../../../../enums/PassengerDocumentType";
import {CrossIcon} from "../../../../icons/CrossIcon";
import {ICreatePassengerDocumentRequest} from "../../../../api/request/ICreatePassengerDocumentRequest";


interface IEmployeeDetailsModal {
    onOpen?: () => void;
    onClose?: () => void;
    onSubmit: (result: boolean) => void;
}

export const EmployeeDetailsModal = forwardRef((props: IEmployeeDetailsModal, ref) => {
    // get the passed properties and assign default values
    const {
        onOpen,
        onClose,
        onSubmit
    } = Object.assign({
        onOpen: () => {
        },
        onClose: () => {
        }
    }, props);

    const {isOpen, open, close} = useContext(ModalContext);

    const {t} = useTranslation();

    const authState = useAppSelector((state) => state.auth);

    const refsService = RefsService();
    const employeesService = EmployeesService();

    const {dateToStringForDatePicker} = DateService();


    const [modalId, setModalId] = useState(Guid.newGuid());

    const [countries, setCountries] = useState<ICountryDTO[]>([]);

    const [needsUpdate, setNeedsUpdate]   = useState<boolean>(false);
    const [code, setCode]                 = useState<string>("");
    const [firstName, setFirstName]       = useState<string>("");
    const [lastName, setLastName]         = useState<string>("");
    const [patronymic, setPatronymic]     = useState<string>("");
    const [firstNameEng, setFirstNameEng]       = useState<string>("");
    const [lastNameEng, setLastNameEng]         = useState<string>("");
    const [patronymicEng, setPatronymicEng]     = useState<string>("");
    const [dateOfBurn, setDateOfBurn]     = useState<string>(dateToStringForDatePicker(new Date()));
    const [phone, setPhone]               = useState<string>("");
    const [email, setEmail]               = useState<string>("");
    const [country, setCountry]           = useState<ICountryDTO | undefined>(undefined);
    const [documents, setDocuments]       = useState<IPassengerDocumentDTO[]>([]);
    const [newDocuments, setNewDocuments] = useState<IPassengerDocumentDTO[]>([]);
    const [gender, setGender]             = useState<boolean>(true);

    const [createEmployeePending, setCreateEmployeePending] = useState(false);
    const [createEmployeeError, setCreateEmployeeError]     = useState(false);
    const [bindEmployeePending, setBindEmployeePending]     = useState(false);
    const [bindEmployeeError, setBindEmployeeError]         = useState(false);
    const [createEmployeeDocumentsPending, setCreateEmployeeDocumentsPending]     = useState(false);
    const [createEmployeeDocumentsError, setCreateEmployeeDocumentsError]         = useState(false);
    const [editEmployeePending, setEditEmployeePending]     = useState(false);
    const [editEmployeeError, setEditEmployeeError]         = useState(false);


    useImperativeHandle(ref, () => ({
        async open(employee?: IPassengerDTO) {
            if (!isOpen(modalId)) {

                const countriesResponse = await refsService.getAllCountries();
                setCountries(countriesResponse);

                if(employee != null) {
                    let documentsResponse = await employeesService.getPassengerDocuments(employee.paxCode);
                    setDocuments(documentsResponse)

                    setCode(employee.paxCode);
                    setFirstName(employee.first);
                    setLastName(employee.last);
                    setPatronymic(employee.middle);
                    setFirstNameEng(employee.firstLatinShot);
                    setLastNameEng(employee.lastLatin);
                    setPatronymicEng(employee.middleLatinShot);
                    setDateOfBurn(dateToStringForDatePicker(new Date(employee.birthday.substring(0, 10).split(".").reverse().join("-"))));
                    setCountry(countriesResponse.find(x => x.countryAlpha2 == employee.countryCode));
                    setPhone(employee.phoneNumber);
                    setEmail(employee.email);
                    setGender(employee.gender == "муж");
                    setNeedsUpdate(true);
                }
                open(modalId);
            }
        },
        close() {
            if (isOpen(modalId)) {
                close(modalId);
            }
        }
    }));


    const addDocument = () => {
        setNewDocuments(prev => [
            ...prev, {
                code: "",
                index: (documents.length + prev.length),
                isMark: false,
                hasCopy: false,
                docType: PassengerDocumentType.RussianPassport
            }
        ])
    }
    const changeDocumentType = (type: PassengerDocumentType, index: number) => {
        setNewDocuments(prev => prev.map((document, documentIndex) => {
            if(documentIndex == index) {
                document.docType = type;
            }
            return document;
        }))
    }

    const changeDocumentNumber = (number: string, index: number) => {
        setNewDocuments(prev => prev.map((document, documentIndex) => {
            if(documentIndex == index) {
                //document.docNumber = number;
            }
            return document;
        }))
    }

    const changeDocumentExpirationDate = (date: string, index: number) => {
        setNewDocuments(prev => prev.map((document, documentIndex) => {
            if(documentIndex == index) {
                document.expiredDate = date;
            }
            return document;
        }))
    }

    const removeDocument = (index: number) => {
        setNewDocuments(prev => prev.filter((x, i) => i !== index));
    }


    const submitHandler = async () => {
        if(needsUpdate) {
            // update employee
            setEditEmployeePending(true);
            setEditEmployeeError(false);
            const request = {
                code: code,
                firstName: firstName,
                lastName: lastName,
                patronymic: patronymic,
                engFirstName: firstNameEng,
                engLastName: lastNameEng,
                //engPatronymic: patronymicEng,
                dob: dateOfBurn,
                gender: gender ? Gender.Male : Gender.Female,
                email: email,
                phoneNumber: phone,
                countryId: country?.countryId,
                countryCode: country?.countryAlpha2
            } as ICreatePassengerRequest;
            const response = await employeesService.updatePassenger(request);
            if (response == null) {
                setEditEmployeeError(true);
            }
            setEditEmployeePending(false);
        }
        else {
            // creating an employee
            setCreateEmployeePending(true);
            setCreateEmployeeError(false);
            const createEmployeeRequest = {
                firstName: firstName,
                lastName: lastName,
                patronymic: patronymic,
                engFirstName: firstNameEng,
                engLastName: lastNameEng,
                //engPatronymic: patronymicEng,
                dob: dateOfBurn,
                gender: gender ? Gender.Male : Gender.Female,
                email: email,
                phoneNumber: phone,
                countryId: country?.countryId,
                countryCode: country?.countryAlpha2
            } as ICreatePassengerRequest;
            const createEmployeeResponse = await employeesService.createPassenger(createEmployeeRequest);
            if (createEmployeeResponse == null) {
                setCreateEmployeeError(true);
            }
            setCreateEmployeePending(false);
        }

        // TODO: check if employee missing company binding
        if(!createEmployeeError && !needsUpdate) {
            // binding employee to active company
            setBindEmployeePending(true);
            setBindEmployeeError(false);
            const bindEmployeeResponse = await employeesService.bindPassengerToCompany(authState.currentClientCode, code);
            if (bindEmployeeResponse == null) {
                setBindEmployeeError(true);
            }
            setBindEmployeePending(false);
        }

        if(!createEmployeeError && !editEmployeeError && newDocuments.length > 0) {
            // creating employee documents
            setCreateEmployeeDocumentsPending(true);
            setBindEmployeeError(false);
            for(let i = 0; i < newDocuments.length; i++) {
                const document = newDocuments[i];
                const createEmployeeDocumentRequest = {
                    paxCode: code,
                    index: document.index,
                    hasCopy: document.hasCopy,
                    paxDocType: document.docType,
                    expiredDate: document.expiredDate
                } as ICreatePassengerDocumentRequest;
                const createEmployeeDocumentResponse = await employeesService.createPassengerDocument(createEmployeeDocumentRequest);
                if (createEmployeeDocumentResponse == null) {
                    setCreateEmployeeDocumentsError(true);
                    break;
                }
            }
            setCreateEmployeeDocumentsPending(false);
        }

        const result = !createEmployeeError && !editEmployeeError;

        onSubmit(result);

        if(result) {
            close(modalId);
        }
    }


    const reset = () => {
        setCode("");
        setFirstName("");
        setLastName("");
        setPatronymic("");
        setFirstNameEng("");
        setLastNameEng("");
        setPatronymicEng("");
        setDateOfBurn(dateToStringForDatePicker(new Date()));
        setCountry(undefined);
        setPhone("");
        setEmail("");
        setDocuments([]);
        setNewDocuments([]);
        setGender(true);
        setNeedsUpdate(false);
    }


    const openHandler = () => {
        onOpen();
    }

    const closeHandler = () => {
        onClose();
        reset();
    }


    return (
        <>
            <MdModal
                id={modalId}
                title={(needsUpdate ? t("employeesPage.editEmployee") : t("employeesPage.newEmployee"))}
                openHandler={openHandler}
                closeHandler={closeHandler}
                closeOnClickOutside={false}
            >

                <div className="employee-details-form">

                    <MdScrollViewer
                        style={{
                            "content": {
                                maxWidth: "700px",
                                maxHeight: "600px"
                            }
                        }}
                    >
                        <div className="employee-details-form-sections">

                            <div className="employee-details-form-section" style={{marginTop: "0px"}}>
                                <span
                                    className="employee-details-form-section-title">{t("employeesPage.fullNameRus")}</span>
                                <div className="employee-details-form-section-content">
                                    <div className="employee-details-form-fields-group">
                                        <div className="employee-details-form-input-field">
                                            <input
                                                style={{
                                                    width: "170px",
                                                    fontSize: "12px",
                                                    outline: "none",
                                                    boxShadow: "none"
                                                }}
                                                type="text"
                                                placeholder={t("employeesPage.lastName")}
                                                value={lastName}
                                                onChange={(e) => {
                                                    setLastName(e.target.value);
                                                }}
                                            />
                                        </div>
                                        <div className="employee-details-form-input-field">
                                            <input
                                                style={{
                                                    width: "170px",
                                                    fontSize: "12px",
                                                    outline: "none",
                                                    boxShadow: "none"
                                                }}
                                                type="text"
                                                placeholder={t("employeesPage.firstName")}
                                                value={firstName}
                                                onChange={(e) => {
                                                    setFirstName(e.target.value);
                                                }}
                                            />
                                        </div>
                                        <div className="employee-details-form-input-field">
                                            <input
                                                style={{
                                                    width: "170px",
                                                    fontSize: "12px",
                                                    outline: "none",
                                                    boxShadow: "none"
                                                }}
                                                type="text"
                                                placeholder={t("employeesPage.patronymic")}
                                                value={patronymic}
                                                onChange={(e) => {
                                                    setPatronymic(e.target.value);
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="employee-details-form-section" style={{marginTop: "10px"}}>
                                <span
                                    className="employee-details-form-section-title">{t("employeesPage.fullNameEng")}</span>
                                <div className="employee-details-form-section-content">
                                    <div className="employee-details-form-fields-group">
                                        <div className="employee-details-form-input-field">
                                            <input
                                                style={{
                                                    width: "170px",
                                                    fontSize: "12px",
                                                    outline: "none",
                                                    boxShadow: "none"
                                                }}
                                                type="text"
                                                placeholder={t("employeesPage.lastName")}
                                                value={lastNameEng}
                                                onChange={(e) => {
                                                    setLastNameEng(e.target.value);
                                                }}
                                            />
                                        </div>
                                        <div className="employee-details-form-input-field">
                                            <input
                                                style={{
                                                    width: "170px",
                                                    fontSize: "12px",
                                                    outline: "none",
                                                    boxShadow: "none"
                                                }}
                                                type="text"
                                                placeholder={t("employeesPage.firstName")}
                                                value={firstNameEng}
                                                onChange={(e) => {
                                                    setFirstNameEng(e.target.value);
                                                }}
                                            />
                                        </div>
                                        <div className="employee-details-form-input-field">
                                            <input
                                                style={{
                                                    width: "170px",
                                                    fontSize: "12px",
                                                    outline: "none",
                                                    boxShadow: "none"
                                                }}
                                                type="text"
                                                placeholder={t("employeesPage.patronymic")}
                                                value={patronymicEng}
                                                onChange={(e) => {
                                                    setPatronymicEng(e.target.value);
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="employee-details-form-section" style={{position: "relative", marginTop: "10px", zIndex: 1000}}>
                                <div className="employee-details-form-section-content">
                                    <div className="employee-details-form-fields-group">
                                        <div>
                                            <span
                                                className="employee-details-form-section-title">{t("employeesPage.dateOfBirth")}</span>
                                            <div className="employee-details-form-section-content">
                                                <span className="input-group">
                                                    <div className="form-group">
                                                        <input
                                                            className="form-control"
                                                            style={{
                                                                width: "170px",
                                                                fontSize: "12px",
                                                                outline: "none",
                                                                boxShadow: "none"
                                                            }}
                                                            type="date"
                                                            value={dateOfBurn}
                                                            onChange={(e) => {
                                                                setDateOfBurn(e.target.value);
                                                            }}
                                                        />
                                                    </div>
                                                </span>
                                            </div>
                                        </div>
                                        <div>
                                            <span
                                                className="employee-details-form-section-title">{t("employeesPage.country")}</span>
                                            <div className="employee-details-form-section-content">
                                                <div className="input-group" style={{marginTop: "0px"}}>
                                                    <div className="form-group">
                                                        <MdSelect
                                                            displayMemberPath={"countryName"}
                                                            itemTemplate={(item: ICountryDTO) => {
                                                                return (
                                                                    <div className="country-item">
                                                                        <div className="country-code">
                                                                            {
                                                                                item.countryAlpha2
                                                                            }
                                                                        </div>
                                                                        <div
                                                                            className="country-name">
                                                                            {
                                                                                item.countryName
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                )
                                                            }}
                                                            itemSource={countries}
                                                            selectedItem={country}
                                                            setSelectedItem={(country: ICountryDTO) => {
                                                                setCountry(country);
                                                            }}
                                                            style={{
                                                                "root": {
                                                                    margin: 0,
                                                                    minWidth: "344px"
                                                                },
                                                                "toggle-button": {
                                                                    padding: "3px 6px 3px 14px"
                                                                },
                                                                "options": {
                                                                    maxHeight: "130px"
                                                                }
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="employee-details-form-section" style={{marginTop: "10px"}}>
                                <div className="employee-details-form-section-content">
                                    <div className="employee-details-form-fields-group">
                                        <div>
                                            <span
                                                className="employee-details-form-section-title">{t("employeesPage.phone")}</span>
                                            <div className="employee-details-form-section-content">
                                                <div className="input-group" style={{marginTop: "0px"}}>
                                                    <div className="form-group">
                                                        <input
                                                            className="form-control"
                                                            style={{
                                                                width: "170px",
                                                                fontSize: "12px",
                                                                outline: "none",
                                                                boxShadow: "none"
                                                            }}
                                                            type="text"
                                                            placeholder="00000000000"
                                                            value={phone}
                                                            onChange={(e) => {
                                                                setPhone(e.target.value);
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div>
                                            <span
                                                className="employee-details-form-section-title">{t("employeesPage.email")}</span>
                                            <div className="employee-details-form-section-content">
                                                <div className="input-group" style={{marginTop: "0px"}}>
                                                    <div className="form-group">
                                                        <input
                                                            className="form-control"
                                                            style={{
                                                                width: "170px",
                                                                fontSize: "12px",
                                                                outline: "none",
                                                                boxShadow: "none"
                                                            }}
                                                            type="text"
                                                            placeholder="example@domain.com"
                                                            value={email}
                                                            onChange={(e) => {
                                                                setEmail(e.target.value);
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="employee-details-form-section" style={{position: "relative", marginTop: "10px", zIndex: 1000}}>
                                <span className="employee-details-form-section-title">{t("employeesPage.documents")}</span>
                                <div className="employee-details-form-section-content">
                                    <div className="employee-document-list">
                                        {
                                            documents.map((document, documentIndex) => {
                                                return (
                                                    <div className="employee-document-list-item" key={documentIndex}>
                                                        {document.code}
                                                    </div>
                                                )
                                            })
                                        }
                                        {
                                            newDocuments.map((document, documentIndex) => {
                                                const options = [
                                                    PassengerDocumentType.RussianPassport,
                                                    PassengerDocumentType.ForeignPassport,
                                                    PassengerDocumentType.InternationalPassport,
                                                    PassengerDocumentType.BirthCertificate
                                                ];
                                                return (
                                                    <div className="employee-document-list-item" key={documentIndex}>
                                                        <span className="remove-employee-document">
                                                            <MdButton
                                                                style={{
                                                                    "button": {
                                                                        width: "16px",
                                                                        height: "16px",
                                                                        padding: "0",
                                                                        border: "none",
                                                                        borderRadius: "4px",
                                                                        backgroundColor: "transparent",
                                                                    },
                                                                    "ink": {
                                                                        backgroundColor: "rgba(0, 0, 0, 0.1)"
                                                                    },
                                                                    "icon": {
                                                                        margin: 0
                                                                    }
                                                                }}
                                                                onClick={() => removeDocument(documentIndex)}
                                                            >
                                                                <CrossIcon
                                                                    width="14px"
                                                                    height="14px"
                                                                    style={{
                                                                        "path": {
                                                                            fill: "#000000"
                                                                        }
                                                                    }}
                                                                />
                                                            </MdButton>
                                                        </span>
                                                        <div className="employee-document-type">
                                                            <MdSelect
                                                                itemTemplate={(item: PassengerDocumentType) => {
                                                                    return (
                                                                        <div>
                                                                            {
                                                                                (() => {
                                                                                    switch (item) {
                                                                                        case PassengerDocumentType.RussianPassport:
                                                                                            return t("employeesPage.russianPassport");
                                                                                        case PassengerDocumentType.ForeignPassport:
                                                                                            return t("employeesPage.foreignPassport");
                                                                                        case PassengerDocumentType.InternationalPassport:
                                                                                            return t("employeesPage.internationalPassport");
                                                                                        case PassengerDocumentType.BirthCertificate:
                                                                                            return t("employeesPage.birthCertificate");
                                                                                    }
                                                                                })()
                                                                            }
                                                                        </div>
                                                                    )
                                                                }}
                                                                itemSource={options}
                                                                selectedItem={document.docType}
                                                                setSelectedItem={(item: PassengerDocumentType) => {
                                                                    changeDocumentType(item, documentIndex);
                                                                }}
                                                                style={{
                                                                    "root": {
                                                                        margin: 0,
                                                                        width: "208px",
                                                                        height: "30px",
                                                                        fontSize: "12px"
                                                                    },
                                                                    "toggle-button": {
                                                                        padding: "2px 2px 2px 14px"
                                                                    }
                                                                }}
                                                            />
                                                        </div>
                                                        <div className="employee-document-number">
                                                            <input
                                                                className="form-control"
                                                                style={{
                                                                    width: "208px",
                                                                    fontSize: "12px",
                                                                    outline: "none",
                                                                    boxShadow: "none"
                                                                }}
                                                                type="text"
                                                                placeholder={t("employeesPage.number")}
                                                                //value={document.code}
                                                                onChange={(e) => {
                                                                    changeDocumentNumber(e.target.value, documentIndex);
                                                                }}
                                                            />
                                                        </div>
                                                        <div className="employee-document-expiration">
                                                            {
                                                                document.docType !== PassengerDocumentType.BirthCertificate && (
                                                                    <input
                                                                        className="form-control"
                                                                        style={{
                                                                            width: "208px",
                                                                            fontSize: "12px",
                                                                            outline: "none",
                                                                            boxShadow: "none"
                                                                        }}
                                                                        type="date"
                                                                        //value={dateOfBurn}
                                                                        onChange={(e) => {
                                                                            changeDocumentExpirationDate(e.target.value, documentIndex);
                                                                        }}
                                                                    />
                                                                )
                                                            }
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                    <MdButton
                                        type="submit"
                                        style={{
                                            "button": {
                                                borderWidth: 0,
                                                backgroundColor: "rgb(253 253 253)",
                                                color: "rgb(175 175 175)",
                                                border: "1px solid rgb(175 175 175)",
                                                borderStyle: "dashed",
                                                borderRadius: "6px",
                                                height: "30px",
                                                padding: "5px 15px"
                                            },
                                            "ink": {
                                                backgroundColor: "rgba(255,255,255,.5)"
                                            }
                                        }}
                                        onClick={() => addDocument()}
                                    >
                                        <span>{t("employeesPage.add")}</span>
                                    </MdButton>
                                </div>
                            </div>

                            <div className="employee-details-form-section" style={{marginTop: "10px"}}>
                                <span className="employee-details-form-section-title">{t("employeesPage.gender")}</span>
                                <div className="employee-details-form-section-content">
                                    <div className="employee-gender-list">
                                        <MdToggleButton
                                            buttonStyle={{
                                                display: "flex",
                                                alignItems: "center",
                                                padding: "1px 10px 0 10px",
                                                borderRadius: "12px",
                                                height: "24px",
                                                fontSize: "11px",
                                                color: (gender ? "#74B100" : "#C1C1C1"),
                                                border: (gender ? "1px solid #74B100" : "1px solid #CCCCCC"),
                                            }}
                                            inkStyle={{
                                                backgroundColor: (gender ? "#74B100" : "#C1C1C1"),
                                                opacity: 0.1
                                            }}
                                            checked={gender}
                                            onClick={(e) => {
                                                if (gender) {
                                                    e.preventDefault();
                                                    return;
                                                }
                                                setGender(true)
                                            }}
                                        >
                                            <div style={{display: "flex", gap: "5px"}}>
                                                <span>{t("employeesPage.male")}</span>
                                            </div>
                                        </MdToggleButton>

                                        <MdToggleButton
                                            buttonStyle={{
                                                display: "flex",
                                                alignItems: "center",
                                                padding: "1px 10px 0 10px",
                                                borderRadius: "12px",
                                                height: "24px",
                                                fontSize: "11px",
                                                color: (!gender ? "#74B100" : "#C1C1C1"),
                                                border: (!gender ? "1px solid #74B100" : "1px solid #CCCCCC"),
                                            }}
                                            inkStyle={{
                                                backgroundColor: (!gender ? "#74B100" : "#C1C1C1"),
                                                opacity: 0.1
                                            }}
                                            checked={!gender}
                                            onClick={(e) => {
                                                if (!gender) {
                                                    e.preventDefault();
                                                    return;
                                                }
                                                setGender(false)
                                            }}
                                        >
                                            <div style={{display: "flex", gap: "5px"}}>
                                                <span>{t("employeesPage.female")}</span>
                                            </div>
                                        </MdToggleButton>

                                    </div>
                                </div>
                            </div>

                        </div>
                    </MdScrollViewer>

                    {/*{(error) && (*/}
                    {/*    <div className="error-message" style={{width: "80%"}}>*/}
                    {/*        {t("employeesPage.employeeCreationError")}*/}
                    {/*    </div>*/}
                    {/*)}*/}

                    <MdButton
                        type="submit"
                        style={{
                            "button": {
                                margin: "20px auto",
                                width: "200px",
                                borderWidth: 0,
                                backgroundColor: "#3E5CB8",
                                color: "#FFFFFF",
                                borderRadius: "6px",
                                height: "35px",
                                padding: "5px 15px"
                            },
                            "ink": {
                                backgroundColor: "rgba(255,255,255,.5)"
                            }
                        }}
                        disabled={(
                            //companyRoles.length == 0 || companyRoles.some(x => x.roles.length == 0)
                            false
                        )}
                        onClick={submitHandler}
                    >
                        {(needsUpdate ? t("employeesPage.save") : t("employeesPage.create"))}
                    </MdButton>

                </div>

            </MdModal>

            {/*<CompanySelectionModal*/}
            {/*    ref={companySelectionModal}*/}
            {/*    submitHandler={addCompanyHandler}*/}
            {/*/>*/}
        </>
    );
})