import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {DateService} from "../../../../services/DateService";
import {CompanyService} from "../../../../services/CompanyService";
import {MdButton} from "../../../../components/md-button";
import {MdSlider} from "../../../../components/md-slider";
import {CircleSpinnerIcon} from "../../../../icons/CircleSpinnerIcon";
import "./style.scss"
import {ICompanyDTO} from "../../../../interfaces/ICompanyDTO";


interface ICompanyAddComponent {
    pending: boolean;
    error: boolean;
    addHandler: (company: ICompanyDTO, cb: () => void) => void;
}


export function CompanyAddComponent(props: ICompanyAddComponent) {

    const {
        pending,
        error,
        addHandler,
    } = props;

    const {t} = useTranslation();

    const {dateToStringForDatePicker} = DateService();

    const companyService = CompanyService();


    const [activeSlide, setActiveSlide] = useState(0);

    const [searchPending, setSearchPending] = useState(false);
    const [searchError, setSearchError] = useState(false);

    const [code, setCode] = useState("");
    const [selectedCompany, setSelectedCompany] = useState<ICompanyDTO | null>(null);

    const onSubmit = (company: ICompanyDTO) => {
        addHandler(company, () => {
            setActiveSlide(0);
            setCode("");
            setSelectedCompany(null);
        });
    }


    const searchCompany = async () => {
        setSearchError(false);
        setSearchPending(true);
        setSelectedCompany(null);

        const response = await companyService.getCompanyInfo(code);

        if(response != null) {
            setSelectedCompany(response);
            setActiveSlide(1);
        }
        else {
            setSearchError(true);
        }

        setSearchPending(false);
    }


    useEffect(() => {

    }, []);


    return (
        <MdSlider
            slides={[
                {
                    content: (
                        <div className="add-company-form">
                            <span className="add-company-form-title">Поиск по коду</span>

                            <div className="add-company-form-content">

                                {
                                    <input
                                        style={{width: "180px", fontSize: "12px", outline: "none", boxShadow: "none"}}
                                        type="text"
                                        placeholder="Код компании"
                                        value={code}
                                        onChange={(e) => {
                                            setCode(e.target.value);
                                        }}
                                    />
                                }

                            </div>

                            {(searchError) && (
                                <div className="error-message">
                                    Нет данных по компании с указанным кодом
                                </div>
                            )}

                        </div>
                    )
                },
                {
                    content: (
                        <div className="add-company-form">
                            <span className="add-company-form-title">Данные компании</span>

                            <div className="add-company-form-content">

                                {
                                    selectedCompany != null && (
                                        <>
                                            <div className="add-company-form-fields-group">
                                                <div className="add-company-form-input-field">
                                                    <span className="add-company-form-input-field-title">Код</span>
                                                    <input
                                                        readOnly={true}
                                                        style={{
                                                            width: "100px",
                                                            fontSize: "12px",
                                                            outline: "none",
                                                            boxShadow: "none"
                                                        }}
                                                        type="text"
                                                        value={selectedCompany.code}
                                                    />
                                                </div>
                                                <div className="add-company-form-input-field">
                                                    <span className="add-company-form-input-field-title">Наименование</span>
                                                    <input
                                                        readOnly={true}
                                                        style={{
                                                            width: "230px",
                                                            fontSize: "12px",
                                                            outline: "none",
                                                            boxShadow: "none"
                                                        }}
                                                        type="text"
                                                        value={selectedCompany.fullName}
                                                    />
                                                </div>
                                            </div>

                                            <div className="add-company-form-fields-group">
                                                <div className="add-company-form-input-field">
                                                    <span
                                                        className="add-company-form-input-field-title">Физический адрес</span>
                                                    <input
                                                        readOnly={true}
                                                        style={{
                                                            width: "340px",
                                                            fontSize: "12px",
                                                            outline: "none",
                                                            boxShadow: "none"
                                                        }}
                                                        type="text"
                                                        value={selectedCompany.address}
                                                    />
                                                </div>
                                            </div>

                                            <div className="add-company-form-fields-group">
                                                <div className="add-company-form-input-field">
                                                    <span
                                                        className="add-company-form-input-field-title">Почтовый адрес</span>
                                                    <input
                                                        readOnly={true}
                                                        style={{
                                                            width: "340px",
                                                            fontSize: "12px",
                                                            outline: "none",
                                                            boxShadow: "none"
                                                        }}
                                                        type="text"
                                                        value={selectedCompany.postalAddress}
                                                    />
                                                </div>
                                            </div>

                                            <div className="add-company-form-fields-group">
                                                <div className="add-company-form-input-field">
                                                    <span className="add-company-form-input-field-title">ИНН</span>
                                                    <input
                                                        readOnly={true}
                                                        style={{
                                                            width: "100px",
                                                            fontSize: "12px",
                                                            outline: "none",
                                                            boxShadow: "none"
                                                        }}
                                                        type="text"
                                                        value={selectedCompany.inn}
                                                    />
                                                </div>
                                                <div className="add-company-form-input-field">
                                                    <span className="add-company-form-input-field-title">КПП</span>
                                                    <input
                                                        readOnly={true}
                                                        style={{
                                                            width: "100px",
                                                            fontSize: "12px",
                                                            outline: "none",
                                                            boxShadow: "none"
                                                        }}
                                                        type="text"
                                                        value={selectedCompany.kpp}
                                                    />
                                                </div>
                                                <div className="add-company-form-input-field">
                                                    <span className="add-company-form-input-field-title">ОГРН</span>
                                                    <input
                                                        readOnly={true}
                                                        style={{
                                                            width: "120px",
                                                            fontSize: "12px",
                                                            outline: "none",
                                                            boxShadow: "none"
                                                        }}
                                                        type="text"
                                                        value={selectedCompany.ogrn}
                                                    />
                                                </div>
                                            </div>

                                            <div className="add-company-form-fields-group">
                                                <div className="add-company-form-input-field">
                                                    <span className="add-company-form-input-field-title">Договор</span>
                                                    <textarea
                                                        readOnly={true}
                                                        style={{
                                                            width: "340px",
                                                            height: "68px",
                                                            fontSize: "12px",
                                                            outline: "none",
                                                            boxShadow: "none"
                                                        }}
                                                        value={(

                                                            (() => {
                                                                if(selectedCompany.agreements != null && selectedCompany.agreements.length > 0) {
                                                                    return (
                                                                        selectedCompany.agreements[0].code ?? ""
                                                                        + ' от ' + selectedCompany.agreements[0].dateFrom.toString().split('T')[0]
                                                                        + ' до ' + selectedCompany.agreements[0].dateTill.toString().split('T')[0]
                                                                    )
                                                                }
                                                                else {
                                                                    return "";
                                                                }
                                                            })()
                                                        )}
                                                    />
                                                </div>
                                            </div>
                                        </>
                                    )
                                }

                            </div>

                        </div>
                    )
                },
            ]}
            footer={
                <div className="booking-form-footer">
                    <div>
                        {
                            activeSlide > 0 && (
                                <MdButton
                                    style={{
                                        "button": {
                                            borderWidth: 0,
                                            backgroundColor: "rgb(233 233 233)",
                                            color: "rgb(0 0 0)",
                                            borderRadius: "6px",
                                            height: "35px",
                                            padding: "5px 15px"
                                        },
                                        "icon": {
                                            marginRight: "5px",
                                        },
                                        "ink": {
                                            backgroundColor: "rgba(255,255,255,.5)"
                                        }
                                    }}
                                    onClick={() => setActiveSlide(prev => Math.max(0, prev - 1))}
                                >
                                    Назад
                                </MdButton>
                            )
                        }
                    </div>

                    <div>
                        {
                            activeSlide == 0 && (
                                !searchPending
                                    ? (
                                        <MdButton
                                            type="submit"
                                            style={{
                                                "button": {
                                                    borderWidth: 0,
                                                    backgroundColor: "#3E5CB8",
                                                    color: "#FFFFFF",
                                                    borderRadius: "6px",
                                                    height: "35px",
                                                    padding: "5px 15px"
                                                },
                                                "ink": {
                                                    backgroundColor: "rgba(255,255,255,.5)"
                                                }
                                            }}
                                            disabled={(code.trim().length == 0)}
                                            onClick={() => {
                                                searchCompany()
                                            }}
                                        >
                                            Далее
                                        </MdButton>
                                    )
                                    : (
                                        <CircleSpinnerIcon
                                            width="24px"
                                            height="24px"
                                            style={{
                                                "svg": {
                                                    margin: "0 15px"
                                                }
                                            }}
                                        />
                                    )
                            )
                        }
                        {
                            activeSlide == 1 && (
                                <MdButton
                                    type="submit"
                                    style={{
                                        "button": {
                                            width: "200px",
                                            borderWidth: 0,
                                            backgroundColor: "#3E5CB8",
                                            color: "#FFFFFF",
                                            borderRadius: "6px",
                                            height: "35px",
                                            padding: "5px 15px"
                                        },
                                        "ink": {
                                            backgroundColor: "rgba(255,255,255,.5)"
                                        }
                                    }}
                                    disabled={(selectedCompany == null)}
                                    onClick={() => {
                                        if(selectedCompany != null) {
                                            onSubmit(selectedCompany)
                                        }
                                    }}
                                >
                                    Добавить
                                </MdButton>
                            )
                        }
                    </div>
                </div>
            }
            activeSlide={activeSlide}
            style={{
                "root": {
                    width: "400px",
                    height: "500px",
                    padding: "10px 20px 20px 20px"
                }
            }}
        />
    );
}