import React from "react";
import {ISvgIcon} from "../interfaces/componentInterfaces/IIconProps";


export const LockIcon = ({width = "24px", height = "24px", style = {}}: ISvgIcon) => {
    return (
        <svg
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
            viewBox="0 0 1024 1024"
            style={style["svg"]}>
            <path
                d="M666 486v-76.4c0-84.8-69.2-153.6-154-153.6s-154 68.8-154 153.6V486h-51v282h410V486h-51zm-256-76.4c0-56.6 45.4-102.4 102-102.4S614 353 614 409.6V486H410v-76.4z"
                fillRule="evenodd"
                clipRule="evenodd"
                style={style["path"]}
            />
        </svg>
    )
}