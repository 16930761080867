import axios, {CancelToken} from "axios";
import {store} from "../redux/store";
import {SupportApi} from "../api/SupportApi";
import {ISupportUser} from "../interfaces/support/ISupportUser";
import {ISupportUserResponse} from "../interfaces/support/ISupportUserResponse";
import {Guid} from "guid-ts";
import {Utils} from "../utils/utils";


export function SupportService() {

    const supportApi = SupportApi();


    /**
     * Method for add support user
     * @param {ISupportUser} request - request data
     * @param {CancelToken} ct - cancellation token
     * @return {Promise<any>} support user data. in case of errors returns null
     */
    const addSupportUser = async (request: ISupportUser, ct: CancelToken = axios.CancelToken.source().token): Promise<any> => {
        try {
            const state = store.getState();
            const response = await supportApi.addSupportUser(request, state.auth.accessToken, ct);
            return !Utils.isEmpty(response.data) ? response.data : null;
        }
        catch (ex: any) {
            return null;
        }
    };

    /**
     * Method for obtaining a list of support users
     * @param {string} clientCode - company code
     * @param {CancelToken} ct - cancellation token
     * @return {Promise<ISupportUserResponse[]>} list of support users
     */
    const getAllSupportUsers = async (clientCode: string, ct: CancelToken = axios.CancelToken.source().token): Promise<ISupportUserResponse[]> => {
        try {
            const state = store.getState();
            const response = await supportApi.getAllSupportUsers(clientCode, state.auth.accessToken, ct);
            return Array.isArray(response.data) ? response.data : [];
        }
        catch (ex: any) {
            return [];
        }
    }

    /**
     * Method for obtaining support user data
     * @param {string} email - support user email
     * @param {string} clientCode - company code
     * @param {CancelToken} ct - cancellation token
     * @return {Promise<ISupportUserResponse | null>} support user data. in case of errors returns null
     */
    const getSupportUser = async (email: string, clientCode: string, ct: CancelToken = axios.CancelToken.source().token): Promise<ISupportUserResponse | null> => {
        try {
            const state = store.getState();
            const response = await supportApi.getSupportUser(email, clientCode, state.auth.accessToken, ct);
            return !Utils.isEmpty(response.data) ? response.data : null;
        }
        catch (ex: any) {
            return null;
        }
    };

    /**
     * Method for delete support user
     * @param {Guid} id - support user id
     * @param {CancelToken} ct - cancellation token
     * @return {Promise<boolean>} delete result
     */
    const deleteSupportUser = async (id: Guid, ct: CancelToken = axios.CancelToken.source().token): Promise<boolean> => {
        try {
            const state = store.getState();
            const response = await supportApi.deleteSupportUser(id, state.auth.accessToken, ct);
            return response.data == true;
        }
        catch (ex: any) {
            return false;
        }
    };


    return {
        addSupportUser,
        getAllSupportUsers,
        getSupportUser,
        deleteSupportUser
    }
}