import React, {useEffect, useRef, useState} from "react";
import {useAppSelector} from "../../redux/hooks";
import {
    BEARER,
    ROUTE_CHAT_HUB
} from "../../constants/routeConstants/ApiRouteConstants";
import {SignalRService} from "../../services/SignalRService";
import {ChatService} from "../../services/ChatService";
import {RoomListComponent} from "./roomList";
import {RoomComponent} from "./room";
import {CircleSpinnerIcon} from "../../icons/CircleSpinnerIcon";
import './style.scss'


enum LayoutType {
    List,
    Room,
}


export interface IChatUser {
    id: string;
    email: string;
    firstName: string;
    lastName: string;
    Device: string;
}

export interface IChatRoom {
    id: string;
    icon?: string;
    name: string;
    description: string;
    createdAt: string;
    users: IChatUser[];
    messages: IChatMessage[];
}

export interface IChatMessage {
    id: string,
    roomId: string;
    userId: string;
    content: string;
    createdAt: string;
    read: boolean;
}


interface IChatComponent{
    closeHandler?: (e: React.MouseEvent) => void;
}


export function ChatComponent(props: IChatComponent) {

    const {
        closeHandler
    } = props;

    const root = useRef(null);


    const authState = useAppSelector(state => state.auth);

    const chatService = ChatService();

    const [token, setToken] = useState<string>(authState.accessToken);

    const [signalRService] = useState<SignalRService>(new SignalRService());

    //const [connection, setConnection] = useState<HubConnection | null>(null);
    const [reconnection, setReconnection] = useState<boolean>(false);

    const [activeLayout, setActiveLayout] = useState<LayoutType>(LayoutType.List);

    const [rooms, setRooms] = useState<IChatRoom[]>([]);
    const [activeRoomId, setActiveRoomId] = useState<string>("");
    const [activeRoom, setActiveRoom] = useState<IChatRoom | undefined>(undefined);

    const [roomCreating, setRoomCreating] = useState<boolean>(false);

    const [supportConnecting, setSupportConnecting] = useState<boolean>(false);

    const [loadMessagesMap, setLoadMessagesMap] = useState<Map<string, boolean>>(new Map());
    const [messagesMap, setMessagesMap] = useState<Map<string, IChatMessage[]>>(new Map());
    const [typingUsersMap, setTypingUsersMap] = useState<Map<string, IChatUser[]>>(new Map());


    const createChatConnection = async () => {
        unbindSignalREvents();
        await signalRService.createConnection(
            {
                hubUrl: process.env.REACT_APP_BASE_ROUTE + ROUTE_CHAT_HUB,
                options: {
                    headers: {
                        Authorization: BEARER + authState.accessToken,
                    },
                    accessTokenFactory: () => authState.accessToken,
                    withCredentials: true,
                    //skipNegotiation: true,
                    //transport: HttpTransportType.WebSockets
                }
            }
        );
        bindSignalREvents();
    }

    // const destroyChatConnection = async () => {
    //     unbindSignalREvents();
    //     await signalRService.stopConnection();
    //     setConnection(null);
    // }

    // const startConnection = async () => {
    //     //signalRService.httpClient.accessToken = authState.accessToken;
    //     const connected = await signalRService.startConnection();
    //     if(connected) {
    //         bindSignalREvents();
    //     }
    // }
    //
    // const stopConnection = async () => {
    //     const result = await signalRService.stopConnection();
    //     if(result) {
    //         unbindSignalREvents();
    //     }
    // }


    const bindSignalREvents = () => {
        console.warn('[SignalR] bind events...');

        signalRService.addEventListener('userConnected', signalRUserConnected);

        signalRService.addEventListener('userDisconnected', signalRUserDisconnected);

        signalRService.addEventListener('messageSent', signalRMessageSent);

        signalRService.addEventListener('userJoined', signalRUserJoined);

        signalRService.addEventListener('userLeft', signalRUserLeft);

        signalRService.addEventListener('usersTyping', signalRUsersTyping);
    }

    const unbindSignalREvents = () => {
        console.warn('[SignalR] unbind events...');

        signalRService.removeEventListener('userConnected', signalRUserConnected);

        signalRService.removeEventListener('userDisconnected', signalRUserDisconnected);

        signalRService.removeEventListener('messageSent', signalRMessageSent);

        signalRService.removeEventListener('userJoined', signalRUserJoined);

        signalRService.removeEventListener('userLeft', signalRUserLeft);

        signalRService.removeEventListener('usersTyping', signalRUsersTyping);
    }


    // callbacks from signalR
    const signalRUserConnected = async (user: IChatUser) => {
        console.warn('[SignalR message] userConnected', user);
    };

    const signalRUserDisconnected = async (user: IChatUser) => {
        console.warn('[SignalR message] userDisconnected', user);
        setMessagesMap(map => new Map());
        setRooms([]);
    };

    const signalRUserJoined = async (roomId: string) => {
        console.warn('[SignalR message] userJoined', roomId);
        await updateRoomHandler(roomId);
    };

    const signalRUserLeft = async (roomId: string) => {
        console.warn('[SignalR message] userLeft', roomId);
        await updateRoomHandler(roomId);
    }

    const signalRMessageSent = async (roomId: string, message: IChatMessage) => {
        console.warn('[SignalR message] messageSent', roomId, message);
        if (message != null) {
            setMessagesMap(map => new Map(map.set(roomId, [...(map.get(roomId)?.filter(x => x.id != message.id) ?? []), message])));
        }
        await updateRoomHandler(roomId);
    };

    const signalRUsersTyping = async (roomId: string, users: IChatUser[]) => {
        console.warn('[SignalR message] usersTyping', roomId, users);
        if (users != null) {
            setTypingUsersMap(map => new Map(map.set(roomId, users)));
        }
    }


    // handlers
    const receiveRoomsHandler = async () => {
        // setRoomsReceiving(true);
        const rooms = await chatService.getRooms();
        setRooms(rooms);
        // setRoomsReceiving(false);
    }

    const createRoomHandler = async () => {
        setRoomCreating(true);
        const room = await chatService.createRoom({ name: "Новый чат", description: "Описание чата" });
        if (room != null) {
            setRooms(prev => [...prev.filter(x => x.id != room.id), room]);
            await selectRoomHandler(room.id);
        }
        setRoomCreating(false);
    }

    const updateRoomHandler = async (roomId: string) => {
        // setRoomUpdating(true);
        const room = await chatService.getRoomById(roomId);
        if(room != null) {
            setRooms(prev => [...(prev.length > 0 ? prev.map(r => r.id == room.id ? room : r) : [room])]);
        }
        // setRoomUpdating(false);
    }

    const selectRoomHandler = async (roomId: string) => {
        if (roomId) {
            setActiveRoomId(roomId);
            setActiveLayout(LayoutType.Room);

            setLoadMessagesMap(map => new Map(map.set(roomId, true)));
            setMessagesMap(map => new Map());

            const messages = await chatService.getRoomMessages(roomId);

            setMessagesMap(map => new Map(map.set(roomId, messages)));
            setLoadMessagesMap(map => new Map(map.set(roomId, false)));
        }
    }

    const connectSupportUserHandler = async (roomId: string) => {
        setSupportConnecting(true);
        await chatService.connectSupportUser(roomId);
        setSupportConnecting(false);
    }

    const disconnectSupportUserHandler = async (roomId: string) => {
        await chatService.disconnectSupportUser(roomId);
    }

    const sendMessageHandler = async (chatId: string, message: string) => {
        // TODO: add message to list and set pending state, then remove it in signalRMessageSent callback
        await chatService.sendMessage({ roomId: chatId, content: message.trimEnd() });
        await chatService.getBotMessage(chatId,  message.trimEnd());
    }

    const backToChatListHandler = () => {
        setActiveLayout(0);
    }

    const onUserTypingHandler = async () => {
        //await signalRService.sendData('IsTyping', true);
    }

    // const onUserStartPrintingHandler = (roomId: string) => {
    //     if (!activeRoom?.printingUsers.some(x => x.email == authState.email)) {
    //         try {
    //             console.warn(authState.email, connection?.connectionId);
    //             connection?.invoke("StartPrinting", chatId, authState.email, connection.connectionId);
    //         } catch (ex) {
    //             console.error(ex);
    //         }
    //     }
    // }

    // const onUserEndPrintingHandler = (roomId: string) => {
    //     if (activeRoom?.printingUsers.some(x => x.email == authState.email)) {
    //         try {
    //             connection?.invoke("EndPrinting", chatId, authState.email, connection.connectionId);
    //         } catch (ex) {
    //             console.error(ex);
    //         }
    //     }
    // }


    // useEffect(() => {
    //     createChatConnection();
    //     return () => {
    //        destroyChatConnection();
    //     };
    // }, []);

    // useEffect(() => {
    //     // const reconnecting = async () => {
    //     //     setReconnection(true);
    //     //     await stopConnection();
    //     //     setToken(authState.accessToken);
    //     //     await startConnection();
    //     //     setReconnection(false);
    //     // };
    //     // if(token != authState.accessToken) {
    //     //     console.warn(token, authState.accessToken)
    //     //     reconnecting();
    //     // }
    //     //signalRService.httpClient.accessToken = authState.accessToken;
    //     // if(signalRService.getConnectionId() != null) {//} && authState.accessToken.length > 0) {
    //     //     //signalRService.accessToken = authState.accessToken;
    //     //     //signalRService.reconnect();
    //     //     // signalRService.reconnect(authState.accessToken).then(() => {
    //     //     //     bindSignalREvents();
    //     //     // });
    //     // }
    //
    //     const reconnect = async () => {
    //         setReconnection(true);
    //         //await destroyChatConnection();
    //         await createChatConnection();
    //         setReconnection(false);
    //     }
    //     //
    //     // if(connection == null) {
    //     //     createChatConnection();
    //     // }
    //     // else {
    //     //     reconnect();
    //     // }
    //     receiveRoomsHandler();
    //     reconnect();
    //
    // }, [authState.accessToken]);


    const initialized = useRef(false)

    useEffect(() => {
         const connectToChat = async () => {
             setReconnection(true);
             await receiveRoomsHandler();
             await createChatConnection();
             setReconnection(false);
         }
        if (!initialized.current) {
            initialized.current = true;
            connectToChat();
        }
    }, []);


    useEffect(() => {
        setActiveRoom(rooms.find(x => x.id == activeRoomId));
    }, [rooms, activeRoomId]);


    return (
        <div ref={root}
             className="chat">

            <div className="chat-slider-container">
                <div className="chat-slider-slide" data-type={activeLayout == LayoutType.List ? "active" : "previous"}>
                    <RoomListComponent
                        rooms={rooms}
                        roomCreating={roomCreating}
                        createRoomHandler={createRoomHandler}
                        selectRoomHandler={selectRoomHandler}
                        closeHandler={closeHandler}
                    />
                </div>
                <div className="chat-slider-slide" data-type={activeLayout == LayoutType.Room ? "active" : ""}>
                    <RoomComponent
                        room={activeRoom}
                        messages={(messagesMap.get(activeRoomId) ?? [])}
                        typingUsers={(typingUsersMap.get(activeRoomId) ?? [])}
                        messagesLoading={(loadMessagesMap.get(activeRoomId) ?? false)}
                        supportConnecting={supportConnecting}
                        onUserTypingHandler={onUserTypingHandler}
                        //onUserStartPrintingHandler={onUserStartPrintingHandler}
                        //onUserEndPrintingHandler={onUserEndPrintingHandler}
                        backToChatListHandler={backToChatListHandler}
                        connectSupportUserHandler={connectSupportUserHandler}
                        disconnectSupportUserHandler={disconnectSupportUserHandler}
                        closeHandler={closeHandler}
                        sendMessageHandler={sendMessageHandler}
                    />
                </div>
            </div>

            {
                reconnection && (
                    <div className="reconnecting-layer">
                        <div className="reconnecting-layer-message">
                            <CircleSpinnerIcon
                                width="20px"
                                height="20px"
                                style={{
                                    "svg": {
                                        margin: "2px"
                                    },
                                    "circle": {
                                        stroke: "#999999"
                                    }
                                }}
                            />
                            <span>Подключение..</span>
                        </div>
                    </div>
                )
            }

        </div>
    )
}