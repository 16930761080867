import React, {forwardRef, useContext, useEffect, useImperativeHandle, useRef, useState} from "react";
import {useTranslation} from "react-i18next";
import {ModalContext} from "../../../commonComponents/modal/ModalContext";
import {ICompanyDTO} from "../../../../interfaces/ICompanyDTO";
import {IUserDTO} from "../../../../interfaces/IUserDTO";
import {ICompanyRole} from "../../../../interfaces/ICompanyRole";
import {ICreateUserRequest} from "../../../../api/request/ICreateUserRequest";
import {IUpdateUserRequest} from "../../../../api/request/IUpdateUserRequest";
import {CompanyService} from "../../../../services/CompanyService";
import {UsersService} from "../../../../services/UsersService";
import {DateService} from "../../../../services/DateService";
import {CompanySelectionModal} from "../../../commonComponents/companySelectionModal";
import {MdModal} from "../../../../components/md-modal";
import {MdScrollViewer} from "../../../../components/md-scroll-viewer";
import {MdButton} from "../../../../components/md-button";
import {MdToggleButton} from "../../../../components/md-toggle-button";
import {CompanyIcon} from "../../../../icons/CompanyIcon";
import {CrossIcon} from "../../../../icons/CrossIcon";
import {Guid} from "../../../../utils/utils";
import "./style.scss"


export const UserDetailsModal = forwardRef((
    props: {
        onOpen?: () => void;
        onClose?: () => void;
        onSubmit: (result: boolean) => void;
    }, ref) => {

    // get the passed properties and assign default values
    const {
        onOpen,
        onClose,
        onSubmit
    } = Object.assign({
        onOpen: () => { },
        onClose: () => { }
    }, props);

    const {isOpen, open, close} = useContext(ModalContext);

    const {t} = useTranslation();

    const usersService = UsersService();
    const companyService = CompanyService();

    const {dateToStringForDatePicker} = DateService();


    const [modalId, setModalId] = useState(Guid.newGuid());

    const [needsUpdate, setNeedsUpdate]   = useState<boolean>(false);
    const [id, setId]                     = useState<string>("");
    const [firstName, setFirstName]       = useState<string>("");
    const [lastName, setLastName]         = useState<string>("");
    const [patronymic, setPatronymic]     = useState<string>("");
    const [dateOfBurn, setDateOfBurn]     = useState<string>(dateToStringForDatePicker(new Date()));
    const [position, setPosition]         = useState<string>("");
    const [email, setEmail]               = useState<string>("");
    const [roles, setRoles]               = useState<{ clientCode: string; companyFullName: string; availableRoles: string[]; activeRoles: string[]; }[]>([]);
    const [gender, setGender]             = useState<boolean>(true);

    const [createUserPending, setCreateUserPending] = useState(false);
    const [createUserError, setCreateUserError]     = useState(false);
    const [editUserPending, setEditUserPending]     = useState(false);
    const [editUserError, setEditUserError]         = useState(false);

    const companySelectionModal = useRef<{ open: () => void, close: () => void }>();


    useImperativeHandle(ref, () => ({
        async open(user?: IUserDTO) {
            if (!isOpen(modalId)) {
                if(user != null) {
                    let userRoles = await usersService.getUserRoles(user.email);

                    let companyRoles = [];

                    for(let i = 0; i < userRoles.length; i++) {
                        let userCompanyRole = userRoles[i];
                        let availableRoles = (await companyService.getCompanyRoles(userCompanyRole.clientCode)).map(x => x.name);
                        companyRoles.push({
                            clientCode: userCompanyRole.clientCode,
                            companyFullName: userCompanyRole.companyFullName,
                            availableRoles: availableRoles,
                            activeRoles: userCompanyRole.roles
                        })
                    }

                    setId(user.id);
                    setFirstName(user.firstName);
                    setLastName(user.lastName);
                    setPatronymic(user.patronymic ?? "");
                    setDateOfBurn(dateToStringForDatePicker(new Date(user.dateOfBurn)));
                    setPosition(user.position);
                    setEmail(user.email);
                    setRoles(companyRoles);
                    setGender(user.gender);
                    setNeedsUpdate(true);
                }
                open(modalId);
            }
        },
        close() {
            if (isOpen(modalId)) {
                close(modalId);
            }
        }
    }));


    const addCompanyHandler = async (company: ICompanyDTO) => {
        let isCompanyExist = await companyService.isCompanyExists(company.code);
        if(!isCompanyExist) {
            await companyService.importCompany(company);
        }
        let availableRoles = (await companyService.getCompanyRoles(company.code)).map(x => x.name);
        if (!roles.some(x => x.clientCode == company.code || x.clientCode == company.code)) {
            setRoles(prev => [
                    ...prev.filter(x => (x.clientCode != company.code)),
                    {
                        clientCode: company.code ?? company.code,
                        companyFullName: company.fullName,
                        availableRoles: availableRoles,
                        activeRoles: []
                    }
                ]
            );
        }
    }

    const removeCompanyHandler = (companyCode: string) => {
        if (roles.some(x => x.clientCode == companyCode)) {
            setRoles(prev => prev.filter(x => x.clientCode != companyCode));
        }
    }


    const addCompanyRoleHandler = (companyCode: string, companyName: string, roleName: string) => {
        setRoles(prev => [
            ...prev.map(company => {
                return {
                    clientCode: company.clientCode,
                    companyFullName: company.companyFullName,
                    availableRoles: company.availableRoles,
                    activeRoles: (company.clientCode == companyCode ? [...company.activeRoles.filter(role => role != roleName), roleName] : company.activeRoles)
                }
            })
        ]);

    }

    const removeCompanyRoleHandler = (companyCode: string, companyName: string, roleName: string) => {
        setRoles(prev => [
            ...prev.map(company => {
                return {
                    clientCode: company.clientCode,
                    companyFullName: company.companyFullName,
                    availableRoles: company.availableRoles,
                    activeRoles: (company.clientCode == companyCode ? company.activeRoles.filter(role => role != roleName) : company.activeRoles)
                }
            })
        ]);
    }


    const submitHandler = async () => {

        const companyRoles = roles.map(company => {
            return company.activeRoles.map(role => {
                return {
                    clientCode: company.clientCode,
                    roleName: role
                } as ICompanyRole
            }).flat()
        }).flat();

        if(needsUpdate) {
            setEditUserPending(true);
            setEditUserError(false);
            const request = {
                id: id,
                firstName: firstName,
                lastName: lastName,
                patronymic: patronymic,
                position: position,
                dateOfBurn: dateOfBurn,
                email: email,
                gender: gender,
                companiesRoles: companyRoles,
            } as IUpdateUserRequest;
            const response = await usersService.updateUser(request);
            if (response == null) {
                setEditUserError(true);
            }
            setEditUserPending(false);
        }
        else {
            setCreateUserPending(true);
            setCreateUserError(false);
            const request = {
                firstName: firstName,
                lastName: lastName,
                patronymic: patronymic,
                position: position,
                dateOfBurn: dateOfBurn,
                email: email,
                gender: gender,
                companiesRoles: companyRoles,
            } as ICreateUserRequest;
            const response = await usersService.createUser(request);
            if (response == null) {
                setCreateUserError(true);
            }
            setCreateUserPending(false);
        }

        const result = !createUserError && !editUserError;

        onSubmit(result);

        if(result) {
            close(modalId);
        }
    }


    const reset = () => {
        setId("");
        setFirstName("");
        setLastName("");
        setPatronymic("");
        setDateOfBurn(dateToStringForDatePicker(new Date()));
        setPosition("");
        setEmail("");
        setRoles([]);
        setGender(true);
        setNeedsUpdate(false);
    }


    const openHandler = () => {
        onOpen();
    }

    const closeHandler = () => {
        onClose();
        reset();
    }


    return (
        <>
            <MdModal
                id={modalId}
                title={(needsUpdate ? t("usersPage.editUser") : t("usersPage.newUser"))}
                openHandler={openHandler}
                closeHandler={closeHandler}
                closeOnClickOutside={false}
            >

                <div className="user-details-form">

                    <MdScrollViewer
                        style={{
                            "content": {
                                maxWidth: "700px",
                                maxHeight: "500px"
                            }
                        }}
                    >
                        <div className="user-details-form-sections">

                            <div className="user-details-form-section" style={{marginTop: "0px"}}>
                                <span className="user-details-form-section-title">{t("usersPage.fullName")}</span>
                                <div className="user-details-form-section-content">
                                    <div className="user-details-form-fields-group">
                                        <div className="user-details-form-input-field">
                                            <input
                                                style={{
                                                    width: "170px",
                                                    fontSize: "12px",
                                                    outline: "none",
                                                    boxShadow: "none"
                                                }}
                                                type="text"
                                                placeholder={t("usersPage.lastName")}
                                                value={lastName}
                                                onChange={(e) => {
                                                    setLastName(e.target.value);
                                                }}
                                            />
                                        </div>
                                        <div className="user-details-form-input-field">
                                            <input
                                                style={{
                                                    width: "170px",
                                                    fontSize: "12px",
                                                    outline: "none",
                                                    boxShadow: "none"
                                                }}
                                                type="text"
                                                placeholder={t("usersPage.firstName")}
                                                value={firstName}
                                                onChange={(e) => {
                                                    setFirstName(e.target.value);
                                                }}
                                            />
                                        </div>
                                        <div className="user-details-form-input-field">
                                            <input
                                                style={{
                                                    width: "170px",
                                                    fontSize: "12px",
                                                    outline: "none",
                                                    boxShadow: "none"
                                                }}
                                                type="text"
                                                placeholder={t("usersPage.patronymic")}
                                                value={patronymic}
                                                onChange={(e) => {
                                                    setPatronymic(e.target.value);
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="user-details-form-section" style={{marginTop: "10px"}}>
                                <div className="user-details-form-section-content">
                                    <div className="user-details-form-fields-group">
                                        <div>
                                            <span className="user-details-form-section-title">{t("usersPage.dateOfBirth")}</span>
                                            <div className="user-details-form-section-content">
                                                <span className="input-group">
                                                    <div className="form-group">
                                                        <input
                                                            className="form-control"
                                                            style={{
                                                                width: "170px",
                                                                fontSize: "12px",
                                                                outline: "none",
                                                                boxShadow: "none"
                                                            }}
                                                            type="date"
                                                            value={dateOfBurn}
                                                            onChange={(e) => {
                                                                setDateOfBurn(e.target.value);
                                                            }}
                                                        />
                                                    </div>
                                                </span>
                                            </div>
                                        </div>
                                        <div>
                                            <span className="user-details-form-section-title">{t("usersPage.email")}</span>
                                            <div className="user-details-form-section-content">
                                                <div className="input-group" style={{marginTop: "0px"}}>
                                                    <div className="form-group">
                                                        <input
                                                            className="form-control"
                                                            style={{
                                                                width: "170px",
                                                                fontSize: "12px",
                                                                outline: "none",
                                                                boxShadow: "none"
                                                            }}
                                                            type="text"
                                                            placeholder="example@domain.com"
                                                            value={email}
                                                            onChange={(e) => {
                                                                setEmail(e.target.value);
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="user-details-form-section" style={{marginTop: "10px"}}>
                                <span className="user-details-form-section-title">{t("usersPage.position")}</span>
                                <div className="user-details-form-section-content">
                                    <div className="input-group" style={{marginTop: "0px"}}>
                                        <div className="form-group">
                                            <input
                                                className="form-control"
                                                style={{
                                                    width: "170px",
                                                    fontSize: "12px",
                                                    outline: "none",
                                                    boxShadow: "none"
                                                }}
                                                type="text"
                                                placeholder={t("usersPage.position")}
                                                value={position}
                                                onChange={(e) => {
                                                    setPosition(e.target.value);
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="user-details-form-section" style={{marginTop: "10px"}}>
                                <span className="user-details-form-section-title">{t("usersPage.company")}</span>
                                <div className="user-details-form-section-content">
                                    <div className="user-company-list">
                                        {
                                            roles.map((company, index) => {
                                                return (
                                                    <div className="company-list-item" key={index}>
                                                        <div className="company-list-item-info">
                                                            <span className="company-icon">
                                                                <CompanyIcon
                                                                    width={"12px"}
                                                                    height={"12px"}
                                                                    style={{
                                                                        "path": {
                                                                            fill: "#777E90"
                                                                        }
                                                                    }}
                                                                />
                                                            </span>
                                                            <span className="company-name">
                                                                {company.companyFullName}
                                                            </span>
                                                            <span className="remove-company">
                                                                <MdButton
                                                                    icon={
                                                                        <CrossIcon
                                                                            width="14px"
                                                                            height="14px"
                                                                            style={{
                                                                                "path": {
                                                                                    fill: "#000000"
                                                                                }
                                                                            }}
                                                                        />
                                                                    }
                                                                    style={{
                                                                        "button": {
                                                                            width: "16px",
                                                                            height: "16px",
                                                                            padding: "0",
                                                                            border: "none",
                                                                            borderRadius: "4px",
                                                                            backgroundColor: "transparent",
                                                                        },
                                                                        "ink": {
                                                                            backgroundColor: "rgba(0, 0, 0, 0.1)"
                                                                        },
                                                                        "icon": {
                                                                            margin: 0
                                                                        }
                                                                    }}
                                                                    onClick={() => removeCompanyHandler(company.clientCode)}
                                                                />
                                                            </span>
                                                        </div>
                                                        <div className="company-list-item-roles">
                                                            {
                                                                company.availableRoles.map((role, index) => {
                                                                    const hasRole = company.activeRoles.includes(role);
                                                                    return (
                                                                        <div key={index}>
                                                                            <MdToggleButton
                                                                                buttonStyle={{
                                                                                    display: "flex",
                                                                                    alignItems: "center",
                                                                                    padding: "1px 10px 0 10px",
                                                                                    borderRadius: "12px",
                                                                                    height: "24px",
                                                                                    fontSize: "10px",
                                                                                    color: (hasRole ? "#74B100" : "#C1C1C1"),
                                                                                    border: (hasRole ? "1px solid #74B100" : "1px solid #CCCCCC"),
                                                                                }}
                                                                                inkStyle={{
                                                                                    backgroundColor: (hasRole ? "#74B100" : "#C1C1C1"),
                                                                                    opacity: 0.1
                                                                                }}
                                                                                checked={hasRole}
                                                                                onChange={(state) => {
                                                                                    if (state) {
                                                                                        addCompanyRoleHandler(company.clientCode, company.companyFullName, role);
                                                                                    } else {
                                                                                        removeCompanyRoleHandler(company.clientCode, company.companyFullName, role);
                                                                                    }
                                                                                }}
                                                                            >
                                                                                <div style={{
                                                                                    display: "flex",
                                                                                    gap: "5px"
                                                                                }}>
                                                                                    <span>{role}</span>
                                                                                </div>
                                                                            </MdToggleButton>
                                                                        </div>
                                                                    )
                                                                })
                                                            }
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                    <MdButton
                                        type="submit"
                                        style={{
                                            "button": {
                                                borderWidth: 0,
                                                backgroundColor: "rgb(253 253 253)",
                                                color: "rgb(175 175 175)",
                                                border: "1px solid rgb(175 175 175)",
                                                borderStyle: "dashed",
                                                borderRadius: "6px",
                                                height: "30px",
                                                padding: "5px 15px"
                                            },
                                            "ink": {
                                                backgroundColor: "rgba(255,255,255,.5)"
                                            }
                                        }}
                                        onClick={() => companySelectionModal.current?.open()}
                                    >
                                        <span>{t("usersPage.add")}</span>
                                    </MdButton>
                                </div>
                            </div>

                            <div className="user-details-form-section" style={{marginTop: "10px"}}>
                                <span className="user-details-form-section-title">{t("usersPage.gender")}</span>
                                <div className="user-details-form-section-content">
                                    <div className="user-gender-list">
                                        <MdToggleButton
                                            buttonStyle={{
                                                display: "flex",
                                                alignItems: "center",
                                                padding: "1px 10px 0 10px",
                                                borderRadius: "12px",
                                                height: "24px",
                                                fontSize: "11px",
                                                color: (gender ? "#74B100" : "#C1C1C1"),
                                                border: (gender ? "1px solid #74B100" : "1px solid #CCCCCC"),
                                            }}
                                            inkStyle={{
                                                backgroundColor: (gender ? "#74B100" : "#C1C1C1"),
                                                opacity: 0.1
                                            }}
                                            checked={gender}
                                            onClick={(e) => {
                                                if (gender) {
                                                    e.preventDefault();
                                                    return;
                                                }
                                                setGender(true)
                                            }}
                                        >
                                            <div style={{display: "flex", gap: "5px"}}>
                                                <span>{t("usersPage.male")}</span>
                                            </div>
                                        </MdToggleButton>

                                        <MdToggleButton
                                            buttonStyle={{
                                                display: "flex",
                                                alignItems: "center",
                                                padding: "1px 10px 0 10px",
                                                borderRadius: "12px",
                                                height: "24px",
                                                fontSize: "11px",
                                                color: (!gender ? "#74B100" : "#C1C1C1"),
                                                border: (!gender ? "1px solid #74B100" : "1px solid #CCCCCC"),
                                            }}
                                            inkStyle={{
                                                backgroundColor: (!gender ? "#74B100" : "#C1C1C1"),
                                                opacity: 0.1
                                            }}
                                            checked={!gender}
                                            onClick={(e) => {
                                                if (!gender) {
                                                    e.preventDefault();
                                                    return;
                                                }
                                                setGender(false)
                                            }}
                                        >
                                            <div style={{display: "flex", gap: "5px"}}>
                                                <span>{t("usersPage.female")}</span>
                                            </div>
                                        </MdToggleButton>

                                    </div>
                                </div>
                            </div>

                        </div>
                    </MdScrollViewer>

                    {/*{(error) && (*/}
                    {/*    <div className="error-message" style={{width: "80%"}}>*/}
                    {/*        {t("usersPage.userCreationError")}*/}
                    {/*    </div>*/}
                    {/*)}*/}

                    <MdButton
                        type="submit"
                        style={{
                            "button": {
                                margin: "20px auto",
                                width: "200px",
                                borderWidth: 0,
                                backgroundColor: "#3E5CB8",
                                color: "#FFFFFF",
                                borderRadius: "6px",
                                height: "35px",
                                padding: "5px 15px"
                            },
                            "ink": {
                                backgroundColor: "rgba(255,255,255,.5)"
                            }
                        }}
                        disabled={(
                            roles.length == 0 || roles.some(x => x.activeRoles.length === 0 || x.activeRoles.length === 0)
                        )}
                        onClick={submitHandler}
                    >
                        {(needsUpdate ? t("employeesPage.save") : t("employeesPage.create"))}
                    </MdButton>

                </div>

            </MdModal>

            <CompanySelectionModal
                ref={companySelectionModal}
                submitHandler={addCompanyHandler}
            />
        </>
    );
})