import {AxiosResponse, CancelToken} from "axios";
import {axiosInstance} from "./AxiosInstance";
import {
    BEARER,
    ROUTE_BOT_GET_MESSAGE,
    ROUTE_BOT_START_SESSION,
    ROUTE_CONNECT_CHAT_SUPPORT_USER,
    ROUTE_CREATE_CHAT_ROOM, ROUTE_DISCONNECT_CHAT_SUPPORT_USER,
    ROUTE_GET_CHAT_ROOM_BY_ID,
    ROUTE_GET_CHAT_ROOM_MESSAGES,
    ROUTE_GET_CHAT_ROOMS,
    ROUTE_SEND_CHAT_MESSAGE,
} from "../constants/routeConstants/ApiRouteConstants";
import {IChatMessage, IChatRoom} from "../components/chat";
import {ICreateChatRoomRequest} from "./request/ICreateChatRoomRequest";
import {ISendChatMessageRequest} from "./request/ISendChatMessageRequest";



export function ChatApi() {

    /**
     * GET method for obtaining rooms
     * @param {string} accessToken - access token
     * @param {CancelToken} ct - cancellation token
     * @return {Promise<AxiosResponse<IChatRoom[], any>>} response with list of rooms
     */
    const getRooms = async (accessToken: string, ct: CancelToken): Promise<AxiosResponse<IChatRoom[], any>> => {
        return await axiosInstance.get<IChatRoom[]>(
            process.env.REACT_APP_BASE_ROUTE + ROUTE_GET_CHAT_ROOMS,
            {
                headers: {
                    Authorization: BEARER + accessToken
                },
                cancelToken: ct
            }
        );
    };

    /**
     * POST method for creating room
     * @param {ICreateChatRoomRequest} request - request data
     * @param {string} accessToken - access token
     * @param {CancelToken} ct - cancellation token
     * @return {Promise<AxiosResponse<IChatRoom, any>>} response with room data
     */
    const createRoom = async (request: ICreateChatRoomRequest, accessToken: string, ct: CancelToken): Promise<AxiosResponse<IChatRoom, any>> => {
        return await axiosInstance.post<IChatRoom>(
            process.env.REACT_APP_BASE_ROUTE + ROUTE_CREATE_CHAT_ROOM,
            request,
            {
                headers: {
                    Authorization: BEARER + accessToken
                },
                cancelToken: ct
            }
        );
    };

    /**
     * GET method for obtaining room by id
     * @param {string} roomId - room ID
     * @param {string} accessToken - access token
     * @param {CancelToken} ct - cancellation token
     * @return {Promise<AxiosResponse<IChatRoom, any>>} response with room data
     */
    const getRoomById = async (roomId: string, accessToken: string, ct: CancelToken): Promise<AxiosResponse<IChatRoom, any>> => {
        return await axiosInstance.get<IChatRoom>(
            process.env.REACT_APP_BASE_ROUTE + ROUTE_GET_CHAT_ROOM_BY_ID,
            {
                headers: {
                    Authorization: BEARER + accessToken
                },
                params: {
                    roomId: roomId,
                },
                cancelToken: ct
            }
        );
    };

    /**
     * GET method for obtaining room messages
     * @param {string} roomId - room ID
     * @param {string} accessToken - access token
     * @param {CancelToken} ct - cancellation token
     * @return {Promise<AxiosResponse<IChatMessage[], any>>} response with list of room messages
     */
    const getRoomMessages = async (roomId: string, accessToken: string, ct: CancelToken): Promise<AxiosResponse<IChatMessage[], any>> => {
        return await axiosInstance.get<IChatMessage[]>(
            process.env.REACT_APP_BASE_ROUTE + ROUTE_GET_CHAT_ROOM_MESSAGES,
            {
                headers: {
                    Authorization: BEARER + accessToken
                },
                params: {
                    roomId: roomId
                },
                cancelToken: ct
            }
        );
    };

    /**
     * POST method for send message to the room
     * @param {ISendChatMessageRequest} request - request data
     * @param {string} accessToken - access token
     * @param {CancelToken} ct - cancellation token
     * @return {Promise<AxiosResponse<any, any>>} response with send result
     */
    const sendMessage = async (request: ISendChatMessageRequest, accessToken: string, ct: CancelToken): Promise<AxiosResponse<any, any>> => {
        return await axiosInstance.post<any>(
            process.env.REACT_APP_BASE_ROUTE + ROUTE_SEND_CHAT_MESSAGE,
            request,
            {
                headers: {
                    Authorization: BEARER + accessToken
                },
                cancelToken: ct
            }
        );
    };

    /**
     * PUT method for connect support user to the room
     * @param {string} roomId - room ID
     * @param {string} accessToken - access token
     * @param {CancelToken} ct - cancellation token
     * @return {Promise<AxiosResponse<any, any>>} response with send result
     */
    const connectSupportUser = async (roomId: string, accessToken: string, ct: CancelToken): Promise<AxiosResponse<any, any>> => {
        return await axiosInstance.post<any>(
            process.env.REACT_APP_BASE_ROUTE + ROUTE_CONNECT_CHAT_SUPPORT_USER,
            roomId,
            {
                headers: {
                    Authorization: BEARER + accessToken
                },
                cancelToken: ct
            }
        );
    };

    /**
     * PUT method for disconnect support user from the room
     * @param {string} roomId - room ID
     * @param {string} accessToken - access token
     * @param {CancelToken} ct - cancellation token
     * @return {Promise<AxiosResponse<any, any>>} response with send result
     */
    const disconnectSupportUser = async (roomId: string, accessToken: string, ct: CancelToken): Promise<AxiosResponse<any, any>> => {
        return await axiosInstance.post<any>(
            process.env.REACT_APP_BASE_ROUTE + ROUTE_DISCONNECT_CHAT_SUPPORT_USER,
            roomId,
            {
                headers: {
                    Authorization: BEARER + accessToken
                },
                cancelToken: ct
            }
        );
    };


    /**
     * POST method for starting bot session
     * @param {string} accessToken - access token
     * @param {CancelToken} ct - cancellation token
     * @return {Promise<AxiosResponse<string, any>>} response with session id
     */
    const startBot = async (accessToken: string, ct: CancelToken): Promise<AxiosResponse<string, any>> => {
        return await axiosInstance.post<string>(
            process.env.REACT_APP_BASE_ROUTE + ROUTE_BOT_START_SESSION,
            null,
            {
                headers: {
                    Authorization: BEARER + accessToken
                },
                cancelToken: ct
            }
        );
    };

    /**
     * POST method for getting bot message
     * @param {string} roomId - room id
     * @param {string} message - message to bot
     * @param {string} accessToken - access token
     * @param {CancelToken} ct - cancellation token
     * @return {Promise<AxiosResponse<IChatMessage, any>>} response with session id
     */
    const getBotMessage = async (roomId: string, message: string, accessToken: string, ct: CancelToken): Promise<AxiosResponse<IChatMessage, any>> => {
        return await axiosInstance.post<IChatMessage>(
            process.env.REACT_APP_BASE_ROUTE + ROUTE_BOT_GET_MESSAGE,
            {
                roomId,
                message
            },
            {
                headers: {
                    Authorization: BEARER + accessToken
                },
                cancelToken: ct
            }
        );
    };



    return {
        getRooms,
        createRoom,
        getRoomById,
        getRoomMessages,
        sendMessage,
        connectSupportUser,
        disconnectSupportUser,
        startBot,
        getBotMessage
    };
}