import {AxiosResponse, CancelToken} from "axios";
import {axiosInstance} from "./AxiosInstance";
import {
    BEARER,
    ROUTE_GET_PASSENGERS,
    ROUTE_CREATE_PASSENGER,
    ROUTE_UPDATE_PASSENGER,
    ROUTE_CREATE_PASSENGER_DOCUMENT,
    ROUTE_GET_PASSENGER_DOCUMENTS,
    ROUTE_GET_PASSENGER_DOCUMENT, ROUTE_GET_PASSENGER, ROUTE_BIND_PASSENGER_TO_COMPANY,
} from "../constants/routeConstants/ApiRouteConstants";
import {IPassengerDTO} from "../interfaces/IPassengerDTO";
import {ICreatePassengerRequest} from "./request/ICreatePassengerRequest";
import {ICreatePassengerDocumentRequest} from "./request/ICreatePassengerDocumentRequest";
import {IPassengerDocumentDTO} from "../interfaces/IPassengerDocumentDTO";


export function EmployeesApi() {

    /**
     * GET method for obtaining list of passengers
     * @param {string} clientCode - active company code
     * @param {string} accessToken - access token
     * @param {CancelToken} ct - cancellation token
     * @return {Promise<AxiosResponse<IPassengerDTO[], any>>} response with list of passengers
     */
    const getPassengers = async (clientCode: string, accessToken: string, ct: CancelToken): Promise<AxiosResponse<IPassengerDTO[], any>> => {
        return await axiosInstance.get<Array<IPassengerDTO>>(
            process.env.REACT_APP_GATEWAY_ROUTE + ROUTE_GET_PASSENGERS,
            {
                headers: {
                    Authorization: BEARER + accessToken,
                    Auth: 123
                },
                params: {
                    clientCode: clientCode
                },
                cancelToken: ct
            }
        );
    };

    /**
     * GET method for obtaining passenger by code
     * @param {string} paxCode - passenger code
     * @param {string} accessToken - access token
     * @param {CancelToken} ct - cancellation token
     * @return {Promise<AxiosResponse<IPassengerDTO, any>>} response with passenger data
     */
    const getPassenger = async (paxCode: string, accessToken: string, ct: CancelToken): Promise<AxiosResponse<IPassengerDTO, any>> => {
        return await axiosInstance.get<IPassengerDTO>(
            process.env.REACT_APP_GATEWAY_ROUTE + ROUTE_GET_PASSENGER,
            {
                headers: {
                    Authorization: BEARER + accessToken,
                    Auth: 123
                },
                params: {
                    paxCode: paxCode
                },
                cancelToken: ct
            }
        );
    };

    /**
     * POST method to create a passenger
     * @param {ICreatePassengerRequest} request - request data
     * @param {string} accessToken - access token
     * @param {CancelToken} ct - cancellation token
     * @return {Promise<AxiosResponse<string>>} response with the passenger code
     */
    const createPassenger = async (request: ICreatePassengerRequest, accessToken: string, ct: CancelToken): Promise<AxiosResponse<string>> => {
        return await axiosInstance.post<string>(
            process.env.REACT_APP_GATEWAY_ROUTE + ROUTE_CREATE_PASSENGER,
            request,
            {
                headers: {
                    Authorization: BEARER + accessToken,
                    Auth: 123
                },
                cancelToken: ct
            }
        );
    };

    /**
     * PUT method to update passenger data
     * @param {ICreatePassengerRequest} request - request data
     * @param {string} accessToken - access token
     * @param {CancelToken} ct - cancellation token
     * @return {Promise<AxiosResponse<boolean>>} response with passenger update result
     */
    const updatePassenger = async (request: ICreatePassengerRequest, accessToken: string, ct: CancelToken): Promise<AxiosResponse<boolean>> => {
        return await axiosInstance.put<boolean>(
            process.env.REACT_APP_GATEWAY_ROUTE + ROUTE_UPDATE_PASSENGER,
            request,
            {
                headers: {
                    Authorization: BEARER + accessToken,
                    Auth: 123
                },
                cancelToken: ct
            }
        );
    };

    /**
     * PUT method to bind passenger to company
     * @param {string} clientCode - company code
     * @param {string} paxCode - passenger code
     * @param {string} accessToken - access token
     * @param {CancelToken} ct - cancellation token
     * @return {Promise<AxiosResponse<boolean>>} response with passenger binding result
     */
    const bindPassengerToCompany = async (clientCode: string, paxCode: string, accessToken: string, ct: CancelToken): Promise<AxiosResponse<boolean>> => {
        return await axiosInstance.put<boolean>(
            process.env.REACT_APP_GATEWAY_ROUTE + ROUTE_BIND_PASSENGER_TO_COMPANY,
            null,
            {
                headers: {
                    Authorization: BEARER + accessToken,
                    Auth: 123
                },
                params: {
                    clientCode: clientCode,
                    paxCode: paxCode
                },
                cancelToken: ct
            }
        );
    };

    /**
     * POST method to create a passenger document
     * @param {ICreatePassengerDocumentRequest} request - request data
     * @param {string} accessToken - access token
     * @param {CancelToken} ct - cancellation token
     * @return {Promise<AxiosResponse<string>>} response with the passenger code
     */
    const createPassengerDocument = async (request: ICreatePassengerDocumentRequest, accessToken: string, ct: CancelToken): Promise<AxiosResponse<string>> => {
        return await axiosInstance.post<string>(
            process.env.REACT_APP_GATEWAY_ROUTE + ROUTE_CREATE_PASSENGER_DOCUMENT,
            request,
            {
                headers: {
                    Authorization: BEARER + accessToken,
                    Auth: 123
                },
                cancelToken: ct
            }
        );
    };

    /**
     * GET method for obtaining list of passenger documents
     * @param {string} paxCode - passenger code
     * @param {string} accessToken - access token
     * @param {CancelToken} ct - cancellation token
     * @return {Promise<AxiosResponse<IPassengerDocumentDTO[], any>>} response with list of passenger documents
     */
    const getPassengerDocuments = async (paxCode: string, accessToken: string, ct: CancelToken): Promise<AxiosResponse<IPassengerDocumentDTO[], any>> => {
        return await axiosInstance.get<Array<IPassengerDocumentDTO>>(
            process.env.REACT_APP_GATEWAY_ROUTE + ROUTE_GET_PASSENGER_DOCUMENTS,
            {
                headers: {
                    Authorization: BEARER + accessToken,
                    Auth: 123
                },
                params: {
                    paxCode: paxCode
                },
                cancelToken: ct
            }
        );
    };

    /**
     * GET method for obtaining passenger document by code
     * @param {string} docCode - document code
     * @param {string} accessToken - access token
     * @param {CancelToken} ct - cancellation token
     * @return {Promise<AxiosResponse<IPassengerDocumentDTO, any>>} response with passenger document data
     */
    const getPassengerDocument = async (docCode: string, accessToken: string, ct: CancelToken): Promise<AxiosResponse<IPassengerDocumentDTO, any>> => {
        return await axiosInstance.get<IPassengerDocumentDTO>(
            process.env.REACT_APP_GATEWAY_ROUTE + ROUTE_GET_PASSENGER_DOCUMENT,
            {
                headers: {
                    Authorization: BEARER + accessToken,
                    Auth: 123
                },
                params: {
                    docCode: docCode
                },
                cancelToken: ct
            }
        );
    };


    return {
        getPassengers,
        getPassenger,
        createPassenger,
        updatePassenger,
        bindPassengerToCompany,
        createPassengerDocument,
        getPassengerDocuments,
        getPassengerDocument,
    };
}