import React, {useEffect, useState} from "react";
import {useAppSelector} from "../../../../redux/hooks";
import {useTranslation} from "react-i18next";
import {IAccountantDocument} from "../../../../interfaces/reportInterfaces/customerInterfaces";
import {MdButton} from "../../../../components/md-button";
import {MdEmailArea} from "../../../../components/md-email-area";
import {MdToggleButton} from "../../../../components/md-toggle-button";
import {CheckMarkIcon} from "../../../../icons/CheckMarkIcon";
import {MdTable} from "../../../../components/md-table";
import {CrossIcon} from "../../../../icons/CrossIcon";
import {CircleSpinnerIcon} from "../../../../icons/CircleSpinnerIcon";
import {IDocAttachments} from "../../../../interfaces/IDocAttachments";
import {AttachmentFormat} from "../../../../enums/AttachmentFormat";
import "./style.scss"
import {AttachmentType} from "../../../../enums/AttachmentType";


interface DocumentSendProps {
    documents: IAccountantDocument[];
    sendHandler: (attachments: IDocAttachments, recipients: string[], format: AttachmentFormat) => void;
    sending: boolean;
    error: boolean;
}

interface DocAttachment {
    docId: string;
    attachmentType: AttachmentType;
}


export function DocumentSendComponent(props: DocumentSendProps) {

    let {documents, sendHandler} = props;

    const {t} = useTranslation();

    const authState = useAppSelector((state) => state.auth);

    const [attachmentsState, setAttachmentsState] = useState<DocAttachment[]>([]);

    const [recipientAddresses, setRecipientAddresses] = useState<string[]>([
        authState.email,
    ]);

    const [isPdf, setIsPdf] = useState(true);
    const [isExel, setIsExel] = useState(false);


    const getDocAttachmentState = (doc: IAccountantDocument, attachmentType: AttachmentType) => {
        return attachmentsState.some(x => x.docId == doc.docNo.trim() && x.attachmentType == attachmentType)
    }

    const addAttachment = (doc: IAccountantDocument, attachmentType: AttachmentType) => {
        setAttachmentsState(prev => [...prev, {docId: doc.docNo.trim(), attachmentType}]);
    }

    const removeAttachment = (doc: IAccountantDocument, attachmentType: AttachmentType) => {
        setAttachmentsState(prev => prev.filter(x => !(x.docId == doc.docNo.trim() && x.attachmentType == attachmentType)));
    }

    const addAttachmentType = (attachmentType: AttachmentType) => {
        setAttachmentsState(prev => [...prev.filter(x => x.attachmentType != attachmentType), ...documents.map(x => { return { docId: x.docNo.trim(), attachmentType: attachmentType } }).flat()]);
    }

    const removeAttachmentType = (attachmentType: AttachmentType) => {
        setAttachmentsState(prev => [...prev.filter(x => x.attachmentType != attachmentType), ...[]]);
    }


    const onSubmit = () => {
        let attachments =
            documents
                //.filter(x => attachmentsState.some(y => y.docId == x.docNo.trim()))
                .map(x => {
                    let number = x.docNo.trim();
                    let attachments = attachmentsState.filter(x => x.docId == number);
                    return {
                        number: number,
                        attachmentType: AttachmentType.None + attachments.reduce((acc, x) => acc + x.attachmentType, 0)
                    }
            });
        let format =
            AttachmentFormat.None
            + (isPdf ? AttachmentFormat.Pdf : 0)
            + (isExel ? AttachmentFormat.Excel : 0);
        sendHandler(Object.fromEntries(attachments.map(({number, attachmentType}) => ([number, attachmentType]))), recipientAddresses, format);
    }


    useEffect(() => {
        setAttachmentsState(documents.map(x => {
            return {
                docId: x.docNo.trim(),
                attachmentType: AttachmentType.ClosingDocs
            }
        }));
    }, [documents]);


    useEffect(() => {

    }, []);


    return (
        <div className="send-documents-form">

            <div className="send-documents-form-sections">
                <div className="send-documents-form-section">
                    <span className="send-documents-form-section-title">Список документов ({documents.length})</span>
                    <div className="send-documents-form-section-content">
                        <MdTable
                            itemSource={documents}
                            columns={[
                                {
                                    width: 40,
                                    header: "№",
                                    cellTemplate(data: IAccountantDocument, rowIndex) {
                                        return <div>{rowIndex + 1}</div>
                                    }
                                },
                                {
                                    header: t("documentPage.document"),
                                    cellTemplate(data: IAccountantDocument) {
                                        return <div>{data.docNo}</div>
                                    }
                                },
                                {
                                    width: 80,
                                    header: t("documentPage.date"),
                                    cellTemplate(data: IAccountantDocument) {
                                        return <div>{data.docDate}</div>
                                    }
                                },
                                {
                                    header: t("documentPage.amount"),
                                    cellTemplate(data: IAccountantDocument) {
                                        return <div>{data.amount}</div>
                                    }
                                },
                                {
                                    width: 400,
                                    //header: t("documentPage.attachments"),
                                    headerTemplate() {
                                        const allBills = attachmentsState.filter(x => x.attachmentType == AttachmentType.Bill).length == documents.length;
                                        const allClosingDocs = attachmentsState.filter(x => x.attachmentType == AttachmentType.ClosingDocs).length == documents.length;
                                        const allAttachments = attachmentsState.filter(x => x.attachmentType == AttachmentType.Attachments).length == documents.length;

                                        return <div className="" style={{display: "flex", gap: "10px", padding: "0 10px", alignItems: "center", justifyContent: "center"}}>
                                            <MdToggleButton
                                                buttonStyle={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    padding: "0 10px 0 10px",
                                                    borderRadius: "10px",
                                                    height: "20px",
                                                    fontSize: "11px",
                                                    backgroundColor: (allBills ? "rgba(116, 177, 0, 0.1)" : "rgba(193, 193, 193, 0.15)"),
                                                    color: (allBills ? "#74B100" : "#95989a"),
                                                    border: "none"//(allBills ? "1px solid rgba(116, 177, 0, 0.15)" : "1px solid rgba(193, 193, 193, 0.15)"),
                                                }}
                                                inkStyle={{
                                                    backgroundColor: (allBills ? "#74B100" : "#C1C1C1"),
                                                    opacity: 0.1
                                                }}
                                                checked={allBills}
                                                onChange={(state) => {
                                                    if (state) {
                                                        addAttachmentType(AttachmentType.Bill);
                                                    } else {
                                                        removeAttachmentType(AttachmentType.Bill);
                                                    }
                                                }}
                                            >
                                                <div style={{display: "flex", gap: "5px"}}>
                                                        <span>
                                                            {
                                                                allBills
                                                                    ? (
                                                                        <CheckMarkIcon
                                                                            width="10px"
                                                                            height="10px"
                                                                            style={{
                                                                                "path": {
                                                                                    stroke: "#74B100"
                                                                                }
                                                                            }}
                                                                        />
                                                                    )
                                                                    : (
                                                                        <CrossIcon
                                                                            width="10px"
                                                                            height="10px"
                                                                            style={{
                                                                                "path": {
                                                                                    stroke: "#95989a"
                                                                                }
                                                                            }}
                                                                        />
                                                                    )
                                                            }
                                                        </span>
                                                    <span>{`${t("documentPage.invoice")}`}</span>
                                                </div>
                                            </MdToggleButton>
                                            <MdToggleButton
                                                buttonStyle={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    padding: "0 10px 0 10px",
                                                    borderRadius: "10px",
                                                    height: "20px",
                                                    fontSize: "11px",
                                                    backgroundColor: (allClosingDocs ? "rgba(116, 177, 0, 0.1)" : "rgba(193, 193, 193, 0.1)"),
                                                    color: (allClosingDocs ? "#74B100" : "#95989a"),
                                                    border: "none"//(allClosingDocs ? "1px solid rgba(116, 177, 0, 0.15)" : "1px solid rgba(193, 193, 193, 0.15)"),
                                                }}
                                                inkStyle={{
                                                    backgroundColor: (allClosingDocs ? "#74B100" : "#C1C1C1"),
                                                    opacity: 0.1
                                                }}
                                                checked={allClosingDocs}
                                                onChange={(state) => {
                                                    if (state) {
                                                        addAttachmentType(AttachmentType.ClosingDocs);
                                                    } else {
                                                        removeAttachmentType(AttachmentType.ClosingDocs);
                                                    }
                                                }}
                                            >
                                                <div style={{display: "flex", gap: "5px"}}>
                                                        <span>
                                                            {
                                                                allClosingDocs
                                                                    ? (
                                                                        <CheckMarkIcon
                                                                            width="10px"
                                                                            height="10px"
                                                                            style={{
                                                                                "path": {
                                                                                    stroke: "#74B100"
                                                                                }
                                                                            }}
                                                                        />
                                                                    )
                                                                    : (
                                                                        <CrossIcon
                                                                            width="10px"
                                                                            height="10px"
                                                                            style={{
                                                                                "path": {
                                                                                    stroke: "#95989a"
                                                                                }
                                                                            }}
                                                                        />
                                                                    )
                                                            }
                                                        </span>
                                                    <span>{`${t("documentPage.closingDocuments")}`}</span>
                                                </div>
                                            </MdToggleButton>
                                            <MdToggleButton
                                                buttonStyle={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    padding: "0 10px 0 10px",
                                                    borderRadius: "10px",
                                                    height: "20px",
                                                    fontSize: "11px",
                                                    backgroundColor: (allAttachments ? "rgba(116, 177, 0, 0.1)" : "rgba(193, 193, 193, 0.1)"),
                                                    color: (allAttachments ? "#74B100" : "#95989a"),
                                                    border: "none"//(allAttachments ? "1px solid rgba(116, 177, 0, 0.15)" : "1px solid rgba(193, 193, 193, 0.15)"),
                                                }}
                                                inkStyle={{
                                                    backgroundColor: (allAttachments ? "#74B100" : "#C1C1C1"),
                                                    opacity: 0.1
                                                }}
                                                checked={allAttachments}
                                                onChange={(state) => {
                                                    if (state) {
                                                        addAttachmentType(AttachmentType.Attachments);
                                                    } else {
                                                        removeAttachmentType(AttachmentType.Attachments);
                                                    }
                                                }}
                                            >
                                                <div style={{display: "flex", gap: "5px"}}>
                                                        <span>
                                                            {
                                                                allAttachments
                                                                    ? (
                                                                        <CheckMarkIcon
                                                                            width="10px"
                                                                            height="10px"
                                                                            style={{
                                                                                "path": {
                                                                                    stroke: "#74B100"
                                                                                }
                                                                            }}
                                                                        />
                                                                    )
                                                                    : (
                                                                        <CrossIcon
                                                                            width="10px"
                                                                            height="10px"
                                                                            style={{
                                                                                "path": {
                                                                                    stroke: "#95989a"
                                                                                }
                                                                            }}
                                                                        />
                                                                    )
                                                            }
                                                        </span>
                                                    <span>{`${t("documentPage.docAttachments")}`}</span>
                                                </div>
                                            </MdToggleButton>
                                        </div>
                                    },
                                    cellTemplate(data: IAccountantDocument) {
                                        const hasBill = getDocAttachmentState(data, AttachmentType.Bill);
                                        const hasClosingDocs = getDocAttachmentState(data, AttachmentType.ClosingDocs);
                                        const hasAttachments = getDocAttachmentState(data, AttachmentType.Attachments);

                                        return <div className="" style={{display: "flex", gap: "10px", padding: "0 10px"}}>
                                            <MdToggleButton
                                                buttonStyle={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    padding: "0 10px 0 10px",
                                                    borderRadius: "10px",
                                                    height: "20px",
                                                    fontSize: "11px",
                                                    color: (hasBill ? "#74B100" : "#C1C1C1"),
                                                    border: (hasBill ? "1px solid #74B100" : "1px solid #CCCCCC"),
                                                }}
                                                inkStyle={{
                                                    backgroundColor: (hasBill ? "#74B100" : "#C1C1C1"),
                                                    opacity: 0.1
                                                }}
                                                checked={hasBill}
                                                onChange={(state) => {
                                                    if (state) {
                                                        addAttachment(data, AttachmentType.Bill);
                                                    } else {
                                                        removeAttachment(data, AttachmentType.Bill);
                                                    }
                                                }}
                                            >
                                                <div style={{display: "flex", gap: "5px"}}>
                                                        <span>
                                                            {
                                                                hasBill
                                                                    ? (
                                                                        <CheckMarkIcon
                                                                            width="10px"
                                                                            height="10px"
                                                                            style={{
                                                                                "path": {
                                                                                    stroke: "#74B100"
                                                                                }
                                                                            }}
                                                                        />
                                                                    )
                                                                    : (
                                                                        <CrossIcon
                                                                            width="10px"
                                                                            height="10px"
                                                                            style={{
                                                                                "path": {
                                                                                    stroke: "#C1C1C1"
                                                                                }
                                                                            }}
                                                                        />
                                                                    )
                                                            }
                                                        </span>
                                                    <span>{`${t("documentPage.invoice")}`}</span>
                                                </div>
                                            </MdToggleButton>
                                            <MdToggleButton
                                                buttonStyle={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    padding: "0 10px 0 10px",
                                                    borderRadius: "10px",
                                                    height: "20px",
                                                    fontSize: "11px",
                                                    color: (hasClosingDocs ? "#74B100" : "#C1C1C1"),
                                                    border: (hasClosingDocs ? "1px solid #74B100" : "1px solid #CCCCCC"),
                                                }}
                                                inkStyle={{
                                                    backgroundColor: (hasClosingDocs ? "#74B100" : "#C1C1C1"),
                                                    opacity: 0.1
                                                }}
                                                checked={hasClosingDocs}
                                                onChange={(state) => {
                                                    if (state) {
                                                        addAttachment(data, AttachmentType.ClosingDocs);
                                                    } else {
                                                        removeAttachment(data, AttachmentType.ClosingDocs);
                                                    }
                                                }}
                                            >
                                                <div style={{display: "flex", gap: "5px"}}>
                                                        <span>
                                                            {
                                                                hasClosingDocs
                                                                    ? (
                                                                        <CheckMarkIcon
                                                                            width="10px"
                                                                            height="10px"
                                                                            style={{
                                                                                "path": {
                                                                                    stroke: "#74B100"
                                                                                }
                                                                            }}
                                                                        />
                                                                    )
                                                                    : (
                                                                        <CrossIcon
                                                                            width="10px"
                                                                            height="10px"
                                                                            style={{
                                                                                "path": {
                                                                                    stroke: "#C1C1C1"
                                                                                }
                                                                            }}
                                                                        />
                                                                    )
                                                            }
                                                        </span>
                                                    <span>{`${t("documentPage.closingDocuments")}`}</span>
                                                </div>
                                            </MdToggleButton>
                                            <MdToggleButton
                                                buttonStyle={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    padding: "0 10px 0 10px",
                                                    borderRadius: "10px",
                                                    height: "20px",
                                                    fontSize: "11px",
                                                    color: (hasAttachments ? "#74B100" : "#C1C1C1"),
                                                    border: (hasAttachments ? "1px solid #74B100" : "1px solid #CCCCCC"),
                                                }}
                                                inkStyle={{
                                                    backgroundColor: (hasAttachments ? "#74B100" : "#C1C1C1"),
                                                    opacity: 0.1
                                                }}
                                                checked={hasAttachments}
                                                onChange={(state) => {
                                                    if (state) {
                                                        addAttachment(data, AttachmentType.Attachments);
                                                    } else {
                                                        removeAttachment(data, AttachmentType.Attachments);
                                                    }
                                                }}
                                            >
                                                <div style={{display: "flex", gap: "5px"}}>
                                                        <span>
                                                            {
                                                                hasAttachments
                                                                    ? (
                                                                        <CheckMarkIcon
                                                                            width="10px"
                                                                            height="10px"
                                                                            style={{
                                                                                "path": {
                                                                                    stroke: "#74B100"
                                                                                }
                                                                            }}
                                                                        />
                                                                    )
                                                                    : (
                                                                        <CrossIcon
                                                                            width="10px"
                                                                            height="10px"
                                                                            style={{
                                                                                "path": {
                                                                                    stroke: "#C1C1C1"
                                                                                }
                                                                            }}
                                                                        />
                                                                    )
                                                            }
                                                        </span>
                                                    <span>{`${t("documentPage.docAttachments")}`}</span>
                                                </div>
                                            </MdToggleButton>
                                        </div>
                                    }
                                },
                            ]}
                            onPreviewRowSelect={() => false}
                            style={{
                                "table": {
                                  width: "100%"
                                },
                                "thead-tr": {
                                    backgroundImage: "none",
                                    backgroundColor: "rgb(249, 249, 249)",

                                },
                                "thead-tr-th": {
                                    borderLeftWidth: 0,
                                    borderRightWidth: 0,
                                },
                                "tbody": {
                                    maxHeight: "150px",
                                },
                                "tbody-tr": {

                                },
                                "tbody-tr-td": {
                                    borderLeftWidth: 0,
                                    borderRightWidth: 0,
                                }
                            }}
                        />
                    </div>
                </div>
                <div className="send-documents-form-section">
                    <span className="send-documents-form-section-title">Получатели ({recipientAddresses.length})</span>
                    <div className="send-documents-form-section-content">
                        <MdEmailArea
                            style={{
                                marginBottom: "10px",
                                border: "1px solid #f5f5f5",
                                borderRadius: "10px",
                                backgroundColor: "rgb(249, 249, 249)",
                                padding: "5px",
                                fontSize: "12px",
                                width: "700px"
                            }}
                            placeholder="example@domain.com"
                            valuesList={recipientAddresses}
                            setValuesList={setRecipientAddresses}
                        />
                    </div>
                </div>
                <div className="send-documents-form-section">
                    <span className="send-documents-form-section-title">Формат</span>
                    <div className="send-documents-form-section-content">
                        <div className="send-documents-form-format-list">
                            <MdToggleButton
                                buttonStyle={{
                                    display: "flex",
                                    alignItems: "center",
                                    padding: "1px 10px 0 10px",
                                    borderRadius: "12px",
                                    height: "24px",
                                    fontSize: "11px",
                                    color: (isPdf ? "#74B100" : "#C1C1C1"),
                                    border: (isPdf ? "1px solid #74B100" : "1px solid #CCCCCC"),
                                }}
                                inkStyle={{
                                    backgroundColor: (isPdf ? "#74B100" : "#C1C1C1"),
                                    opacity: 0.1
                                }}
                                checked={isPdf}
                                onChange={(state) => setIsPdf(state)}
                            >
                                <div style={{display: "flex", gap: "5px"}}>
                                    <span>
                                        {
                                            isPdf
                                                ? (
                                                    <CheckMarkIcon
                                                        width="10px"
                                                        height="10px"
                                                        style={{
                                                            "path": {
                                                                stroke: "#74B100"
                                                            }
                                                        }}
                                                    />
                                                )
                                                : (
                                                    <CrossIcon
                                                        width="10px"
                                                        height="10px"
                                                        style={{
                                                            "path": {
                                                                stroke: "#C1C1C1"
                                                            }
                                                        }}
                                                    />
                                                )
                                        }
                                    </span>
                                    <span>PDF</span>
                                </div>
                            </MdToggleButton>

                            <MdToggleButton
                                buttonStyle={{
                                    display: "flex",
                                    alignItems: "center",
                                    padding: "1px 10px 0 10px",
                                    borderRadius: "12px",
                                    height: "24px",
                                    fontSize: "11px",
                                    color: (isExel ? "#74B100" : "#C1C1C1"),
                                    border: (isExel ? "1px solid #74B100" : "1px solid #CCCCCC"),
                                }}
                                inkStyle={{
                                    backgroundColor: (isExel ? "#74B100" : "#C1C1C1"),
                                    opacity: 0.1
                                }}
                                checked={isExel}
                                onChange={(state) => setIsExel(state)}
                            >
                                <div style={{display: "flex", gap: "5px"}}>
                                    <span>
                                        {
                                            isExel
                                                ? (
                                                    <CheckMarkIcon
                                                        width="10px"
                                                        height="10px"
                                                        style={{
                                                            "path": {
                                                                stroke: "#74B100"
                                                            }
                                                        }}
                                                    />
                                                )
                                                : (
                                                    <CrossIcon
                                                        width="10px"
                                                        height="10px"
                                                        style={{
                                                            "path": {
                                                                stroke: "#C1C1C1"
                                                            }
                                                        }}
                                                    />
                                                )
                                        }
                                    </span>
                                    <span>Excel</span>
                                </div>
                            </MdToggleButton>

                        </div>
                    </div>
                </div>
            </div>

            {(props.error) && (
                <>
                    <div className="error-message">
                        Документы не отправлены, попробуйте повторить запрос позже.
                    </div>
                </>
            )}

            {
                !props.sending
                ? (
                        <MdButton
                            type="submit"
                            style={{
                                "button": {
                                    margin: "20px auto auto",
                                    width: "200px",
                                    borderWidth: 0,
                                    backgroundColor: "#3E5CB8",
                                    color: "#FFFFFF",
                                    borderRadius: "6px",
                                    height: "35px",
                                    padding: "5px 15px"
                                },
                                "ink": {
                                    backgroundColor: "rgba(255,255,255,.5)"
                                }
                            }}
                            disabled={attachmentsState.length == 0 || (!isPdf && !isExel)}
                            onClick={onSubmit}
                        >
                            {`${t("documentPage.send")}`}
                        </MdButton>
                )
                : (
                    <CircleSpinnerIcon
                        width="20px"
                        height="20px"
                        style={{
                            "svg": {
                                margin: "20px auto 15px auto",
                            }
                        }}
                    />
                )
            }


        </div>
    );
}
