import {useCookies} from "react-cookie";
import {JwtPayload, jwtDecode} from "jwt-decode";
import {ValidationErrors} from "fluentvalidation-ts";
import {useNavigate} from "react-router-dom";
import {
    setAccessToken,
    setCurrentClientCode,
    setEmail,
    setGkClientCodes,
    setGkCode,
    setIsUserValid,
    setFullName,
    setFirstName,
    setLastName,
    setPatronymic,
    setUserRoles,
    setUserRole,
    setExpiredAt,
    setGkCompanies,
    setUserId,
    setIsSupportUser,
    setSupportUserCode,
    setIsActivatedAcc,
    setCurrentCompany, authSlice,
} from "../redux/authSlice";
import {resetReports} from "../redux/reportsSlice";
import {ACCESS_TOKEN} from "../constants/FieldsConstants";
import {AuthApi} from "../api/AuthApi";
import {DataConvertService} from "./DataConvertService";
import {CompanyService} from "./CompanyService";
import {ISignupRequest} from "../api/request/ISignupRequest";
import {ITokenClaims} from "../interfaces/ITokenClaims";
import {ICompanyDTO} from "../interfaces/ICompanyDTO";
import {SignupRequestValidator} from "../validators/SignupRequestValidator";
import {clearCompanyInfo} from "../redux/CompanyInfoSlice";
import {store} from "../redux/store";
import {useAppDispatch} from "../redux/hooks";
import {Utils} from "../utils/utils";


export function AuthService() {

    const [cookies, setCookie, removeCookie] = useCookies([ACCESS_TOKEN]);

    const dispatch = useAppDispatch();

    const authApi = AuthApi();

    const companyService = CompanyService();

    const {objHasAnyPropertyValue} = DataConvertService();

    const signupValidator = new SignupRequestValidator();

    const navigate = useNavigate();


    /**
     * Method for signup
     * @param {ISignupRequest} request - request data
     * @return {Promise<boolean>} true if the user registered successfully, false otherwise.
     */
    const signup = async (request: ISignupRequest): Promise<boolean> => {
        try {
            const state = store.getState();
            const response = await authApi.signup(request, state.auth.accessToken);
            return response.status === 200;

        } catch (error) {
            return false;
        }
    };

    /**
     * Method for login
     * @param {string} email - user email
     * @param {string} password - user password
     * @return {Promise<number>} response status code
     */
    const login = async (email: string, password: string): Promise<number> => {
        try {
            const request = {
                email: email,
                password: password
            };

            const response = await authApi.login(request);

            if (!(response === undefined || response === null)) {

                const tokenClaims = getTokenClaims(response.data.jwtToken);

                const firstName = tokenClaims.firstName ?? "";
                const lastName = tokenClaims.lastName ?? "";
                const patronymic = tokenClaims.patronymic ?? "";
                const fullName = [firstName, lastName, patronymic].join(' ');
                const defaultClientCode = Array.isArray(tokenClaims.gkClientsCodes) ? tokenClaims.gkClientsCodes[0] : "";
                const userRole = tokenClaims.roles[defaultClientCode]?.find((x, i) => i === 0) ?? "";

                dispatch(setUserId(tokenClaims.id));
                dispatch(setEmail(tokenClaims.email ?? ""));
                dispatch(setFullName(fullName));
                dispatch(setFirstName(tokenClaims.firstName ?? ""));
                dispatch(setLastName(tokenClaims.lastName ?? ""));
                dispatch(setPatronymic(tokenClaims.patronymic ?? ""));
                dispatch(setIsSupportUser((tokenClaims.isSupportUser ?? "false").toLowerCase() === "true"));
                dispatch(setSupportUserCode(tokenClaims.supportUserCode ?? ""));
                dispatch(setAccessToken(response.data.jwtToken));
                dispatch(setExpiredAt(tokenClaims.exp ?? 0));
                dispatch(setIsActivatedAcc(tokenClaims.isActivatedAcc.toLowerCase() === "true"))
                dispatch(setUserRoles(tokenClaims.roles));
                dispatch(setUserRole(userRole));
                //TODO: need update Auth api; HardCode!!!
                dispatch(setIsUserValid(true));
                dispatch(setGkCode(tokenClaims.gkCode ?? ""))

                // TODO: check if necessary
                setCookie(ACCESS_TOKEN, response.data.jwtToken, {
                    // secure: true,
                    // sameSite: "strict",
                    // httpOnly: true,
                });

                // reset information for a previously selected company
                dispatch(clearCompanyInfo());
                dispatch(resetReports());
                //dispatch(setCurrentClientCode(null)); // TODO: reset company code
                //dispatch(setCurrentCompany(null)); // TODO: reset company info

                // prepare a list of company group codes
                const gkClientsCodes = tokenClaims.gkClientsCodes ?? [];
                // get information for each company in group
                const companyGroup: ICompanyDTO[] = [];
                for(let i = 0; i < gkClientsCodes.length; i++) {
                    const clientCode = gkClientsCodes[i];
                    const companyInfo = await companyService.getCompanyInfo(clientCode);
                    if(companyInfo != null) {
                        companyGroup.push(companyInfo);
                    }
                }
                // set company group codes
                dispatch(setGkClientCodes(gkClientsCodes));
                // set information for each company in group
                dispatch(setGkCompanies(companyGroup));

                // make default company as active
                await companyService.makeActiveCompany(defaultClientCode);

                // return response status
                return response.status;
            }

            return response === undefined ? 500 : 400;
        } catch (ex: any) {
            return 500;
        }
    };

    /**
     * Method for password recovery
     * @param {string} email - user email address
     * @return {Promise<boolean>} true if password recovery was successful, false otherwise.
     */
    const recoveryPassword = async (email: string): Promise<boolean> => {
        try {
            const response = await authApi.recoveryPassword(email);
            return response.data === true;
        } catch (ex: unknown) {
            return false;
        }
    };

    /**
     * Method for change password
     * @param {string} email - user email
     * @param {string} password - user password
     * @param {string} confirmPassword - user password confirmation
     * @param {string} token - change password token
     * @return {Promise<boolean>} true if password was changed successful, false otherwise.
     */
    const changePassword = async (email: string, password: string, confirmPassword: string, token: string): Promise<boolean> => {
        try {
            const request = {
                email: email,
                password: password,
                confirmPassword: confirmPassword,
                link: token
            }
            const response = await authApi.changePassword(request);
            return !Utils.isEmpty(response.data);
        } catch (ex: unknown) {
            return false;
        }
    };

    /**
     * Method for token refresh
     * @return {Promise<string>} new token in case of successful refresh, null otherwise.
     */
    const refreshToken = async (token: string): Promise<string | null> => {
        try {
            const response = await authApi.refreshToken(token);
            if (response.data != null) {
                const token = response.data.jwtToken;
                const claims = getTokenClaims(response.data.jwtToken);
                dispatch(setAccessToken(token));
                dispatch(setExpiredAt(claims.exp ?? 0));
                setCookie(ACCESS_TOKEN, token, {
                    //secure: true,
                    //sameSite: "strict",
                    //httpOnly: true,
                });
                return token;
            }
        } catch (ex: unknown) { }
        return null;
    }

    /**
     * Method for revoke token
     * @return {Promise<boolean>} true if token was revoked successful, false otherwise.
     */
    const revokeToken = async (): Promise<boolean> => {
        try {
            const state = store.getState();
            const response = await authApi.revokeToken(state.auth.accessToken);
            return response.data == true;
        } catch (ex: unknown) {
            return false;
        }
    }

    /**
     * Method for obtaining user activity status
     * @param {string} email - user email address
     * @param {string} token - change password token
     * @return {Promise<boolean>} true if user is active, false otherwise.
     */
    const isUserActive = async (email: string, token: string): Promise<boolean> => {
        try {
            const response = await authApi.isUserActive(email, token);
            return response.data == true;
        } catch (ex: unknown) {
            return false;
        }
    }

    /**
     * Method for logout
     */
    const logout = async (): Promise<void> => {
        try {
            const state = store.getState();
            await authApi.logout(state.auth.accessToken);
        }
        catch (ex: unknown) { }

        dispatch(setIsActivatedAcc(false));
        dispatch(setUserId(""));
        dispatch(setEmail(""));
        dispatch(setFullName(""));
        dispatch(setFirstName(""));
        dispatch(setLastName(""));
        dispatch(setPatronymic(""));
        dispatch(setIsSupportUser(false));
        dispatch(setSupportUserCode(""));
        dispatch(setAccessToken(""));
        dispatch(setExpiredAt(0));
        dispatch(setGkCompanies([]));
        dispatch(setGkClientCodes([]));
        dispatch(setCurrentClientCode(""));
        dispatch(setUserRoles({}));
        dispatch(setUserRole(""));
        dispatch(setIsUserValid(false));
        dispatch(setCurrentCompany({} as ICompanyDTO));

        removeCookie(ACCESS_TOKEN, {path: '/'});

        dispatch(resetReports());
        navigate("/sign-in");
    };


    /**
     * Method for obtaining claims from JWT token
     * @param {string} token - JWT token
     * @return {ITokenClaims} token claims
     */
    const getTokenClaims = (token: string): ITokenClaims => {
        const tokenPayload = jwtDecode<JwtPayload>(token);

        const tokenClaims =
            Object.fromEntries(Object.entries(tokenPayload).map(([k, v]) => {
                let cleanKey = k.substring(k.lastIndexOf("/") + 1);
                return [cleanKey, cleanKey === "role" ? JSON.parse(v) : v];
            }));

        const roleDictionary = tokenClaims.role;
        const clientCodes = roleDictionary != null ? Object.keys(roleDictionary) : [];

        return {
            id: (tokenPayload as any).id,
            iss: tokenPayload.iss,
            sub: tokenPayload.sub,
            aud: tokenPayload.aud,
            exp: tokenPayload.exp,
            nbf: tokenPayload.nbf,
            iat: (tokenPayload.iat ?? 0).toString(),
            jti: tokenPayload.jti,
            email: tokenClaims["email"],
            firstName: tokenClaims["User FirstName"],
            lastName: tokenClaims["User LastName"],
            isActivatedAcc: tokenClaims["Activate account"],
            patronymic: tokenClaims["User Patronymic"],
            isSupportUser: tokenClaims["IsSupportUser"],
            supportUserCode: tokenClaims["SupportUserCode"],
            roles: roleDictionary,
            gkClientsCodes: clientCodes,
            gkCode: tokenClaims.GkCode,
        }
    }


    const validateSignupRequest = (
        validateErrorHandler: (
            value: ValidationErrors<ISignupRequest> | undefined
        ) => void,
        passwordErrorHandler: (value: string) => void,
        request: ISignupRequest
    ): boolean => {
        clearSignupValidationError(validateErrorHandler);
        const validationResult = signupValidator.validate({
            email: request.email,
            password: request.password,
            passwordConfirm: request.passwordConfirm,
            firstName: request.firstName,
            lastName: request.lastName,
            patronymic: request.patronymic,
            position: request.position,
            shortCompanyName: request.shortCompanyName,
            fullCompanyName: request.fullCompanyName,
            companyOwner: request.companyOwner,
            legalAddress: request.legalAddress,
            mailingAddress: request.mailingAddress,
            deliveryAddress: request.deliveryAddress,
            mainContract: request.mainContract,
            inn: request.inn,
            hasInnForm: request.hasInnForm,
            ogrn: request.ogrn,
            hasOgrnForm: request.hasOgrnForm,
        });

        //Check fluentvalidator errors
        if (objHasAnyPropertyValue(validationResult)) {
            validateErrorHandler(validationResult);
            return false;
        }

        return true;
    };

    const clearSignupValidationError = (
        hanler: (value: ValidationErrors<ISignupRequest> | undefined) => void
    ) => {
        hanler({
            email: "",
            password: "",
            passwordConfirm: "",
            firstName: "",
            lastName: "",
            patronymic: "",
            position: "",
            shortCompanyName: "",
            fullCompanyName: "",
            companyOwner: "",
            legalAddress: "",
            mailingAddress: "",
            deliveryAddress: "",
            mainContract: "",
            inn: "",
            hasInnForm: "",
            ogrn: "",
            hasOgrnForm: "",
        });
    };


    return {
        login,
        recoveryPassword,
        changePassword,
        refreshToken,
        logout,
        isUserActive,
        signup,
        validateSignupRequest,
        getTokenClaims
    };
}