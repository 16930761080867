import React, {useContext, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useAppSelector} from "../../../../redux/hooks";
import {ModalContext, NEW_TRIP_MODAL_ID,} from "../../../commonComponents/modal/ModalContext";
import {ITrainVariant} from "../../../../interfaces/ITrainVariant";
import {IAviaVariant} from "../../../../interfaces/IAviaVariant";
import {IPassengerDTO, IPassportDTO} from "../../../../interfaces/IPassengerDTO";
import {TripElementType} from "../../../../enums/TripElementType";
import {IOrderDTO, ITripDTO, ITripPassengerDTO, ITripPassportDTO} from "../../../../interfaces/IOrderDTO";
import {TripElementStatus} from "../../../../enums/TripElementStatus";
import {ICreateFlightElementRequest, ICreateTrainElementRequest} from "../../../../api/request/ITripElementRequest";
import {IOrderBookRequest, IOrderIssueRequest, ProviderTypes} from "../../../../api/request/IOrderBookRequest";
import {ISearchFlightsResponse} from "../../../../api/response/ISearchFlightsResponse";
import {ISearchTrainsResponse} from "../../../../api/response/ISearchTrainsResponse";
import {PassportType} from "../../../../enums/PassportType";
import {CompanyService} from "../../../../services/CompanyService";
import {TripService} from "../../../../services/TripService";
import {EmployeesService} from "../../../../services/EmployeesService";
import {SearchTicketsModal} from "../searchTicketsModal";
import {TripComponent} from "../tripComponent";
import {PassengersSelectionModal} from "../passengersSelectionModal";
import {MdProgressBar} from "../../../../components/md-progress-bar";
import {MdButton} from "../../../../components/md-button";
import {MdModal} from "../../../../components/md-modal";
import {MdScrollViewer} from "../../../../components/md-scroll-viewer";
import {MdAccordion} from "../../../../components/md-accordion";
import {CircleSpinnerIcon} from "../../../../icons/CircleSpinnerIcon";
import {CompanyIcon} from "../../../../icons/CompanyIcon";
import {CrossIcon} from "../../../../icons/CrossIcon";
import {RefreshIcon} from "../../../../icons/RefreshIcon";
import {Utils} from "../../../../utils/utils";
import "./style.scss";


export class Trip {
    id: string;
    name: string;
    index: number;
    //isOpened: boolean;
    items: (FlightTripItem | TrainTripItem)[];
    passengers: IPassengerInfo[];

    constructor(
        id: string,
        name: string,
        index: number,
        /*isOpened: boolean,*/
        items: (FlightTripItem | TrainTripItem)[] = [],
        passengers: IPassengerInfo[] = []
    ) {
        this.id = id;
        this.name = name;
        this.index = index;
        //this.isOpened = isOpened;
        this.items = items;
        this.passengers = passengers;
    }
}

export interface IPassengerInfo {
    details: ITripPassengerDTO;
    passport?: ITripPassportDTO;
}

// export interface IReservation {
//     tripElementId: string,
//     items: Array<{
//         pnr: string,
//         limit: string
//     }>
// }

export class FlightTripItem {
    get hash(): number {
        return Utils.stringHash(
            this.variant.rejsInfo[0].airCodeDep
            + "-"
            + this.variant.rejsInfo[0].airCodeArr
            + "-"
            + this.variant.rejsInfo[0].flightNumber
            + "-"
            + this.variant.rejsInfo[0].dateDep
        );
    };
    id: string;
    timestamp: number;
    isOpened: boolean;
    isSelected: boolean;
    variant: IAviaVariant;
    tariffIndex: number;
    searchResponse: any;
    pnrs: string | undefined;
    status: TripElementStatus;

    constructor(id: string, timestamp: number, variant: IAviaVariant, tariffIndex: number, searchResponse: any, pnrs: string | undefined, status: TripElementStatus) {
        this.id = id;
        this.timestamp = timestamp;
        this.isOpened = false;
        this.isSelected = true;
        this.variant = variant;
        this.tariffIndex = tariffIndex;
        this.searchResponse = searchResponse;
        this.pnrs = pnrs;
        this.status = status;
    }
}
export class TrainTripItem {
    get hash(): number {
        return Utils.stringHash(
            this.variant.cityFrom
            + "-"
            + this.variant.cityTo
            + "-"
            + this.variant.trainNum
            + "-"
            + this.variant.departureAt
            + "-"
            + this.variant.departureShortOn
        );
    };
    id: string;
    timestamp: number;
    isOpened: boolean;
    isSelected: boolean;
    variant: ITrainVariant;
    tariffIndex: number;
    searchResponse: any;
    pnrs: string | undefined;
    status: TripElementStatus;

    constructor(id: string, timestamp: number, variant: ITrainVariant, tariffIndex: number, searchResponse: any, pnrs: string | undefined, status: TripElementStatus) {
        this.id = id;
        this.timestamp = timestamp;
        this.isOpened = false;
        this.isSelected = true;
        this.variant = variant;
        this.tariffIndex = tariffIndex;
        this.searchResponse = searchResponse;
        this.pnrs = pnrs;
        this.status = status;
    }
}

// TODO: improve it
export interface ISelectFlightTicketArgs {
    type: 'avia';
    variant: IAviaVariant;
    tariffIndex: number;
    searchResponse: ISearchFlightsResponse;
}
export interface ISelectTrainTicketArgs {
    type: 'train';
    variant: ITrainVariant;
    tariffIndex: number;
    searchResponse: ISearchTrainsResponse;
}
export interface ISelectTicketHandler {
    (ticket: ISelectFlightTicketArgs | ISelectTrainTicketArgs): Promise<void>;
}

// TODO: improve it
export interface ISelectPassengersHandler {
    (passengerDTO: IPassengerDTO[]): void;
}


export function OrderDetailsModal(
    props: {
        opened: boolean;
        setOpened: React.Dispatch<React.SetStateAction<boolean>>,
        onOpen?: () => void;
        onClose?: () => void;
        refreshOrderDetails?: () => Promise<void>;
        onOrderBooked?: (orderId: string) => Promise<void>;
        onOrderIssued?: (orderId: string) => Promise<void>;
        order: IOrderDTO;
    }) {

    const {
        opened,
        setOpened,
        onOpen,
        onClose,
        refreshOrderDetails,
        onOrderBooked,
        onOrderIssued,
        order
    } = props;

    const {isOpen, open, close} = useContext(ModalContext);

    const {t} = useTranslation();


    const authState = useAppSelector((state) => state.auth);
    const selectedCompany = useAppSelector((state) => state.companyInfo) as any;


    const companyService = CompanyService();
    const passengersService = EmployeesService();
    const tripService = TripService();

    // type ModalState = [ isOpen: boolean, update: React.Dispatch<React.SetStateAction<boolean>> ];
    // const passengersSelectionModalState: ModalState = useState<boolean>(false);
    // const state: ModalState = useState(false);
    // state[0] = true;

    const [loadOrderPending, setLoadOrderPending] = useState<boolean>(false);


    const [searchTicketsModalOpened, setSearchTicketsModalOpened] = useState<boolean>(false);
    const [selectTicketHandler, setSelectTicketHandler] = useState<ISelectTicketHandler>();

    const [passengersSelectionModalOpened, setPassengersSelectionModalOpened] = useState<boolean>(false);
    const [selectPassengersHandler, setSelectPassengersHandler] = useState<ISelectPassengersHandler>();
    const [selectedPassengers, setSelectedPassengers] = useState<string[]>([]);

    const [trips, setTrips] = useState<Trip[]>([]);

    const [removeTripPending, setRemoveTripPending] = useState<Set<string>>(() => new Set());

    const [removePaxPending, setRemovePaxPending] = useState<Set<string>>(() => new Set());

    const [orderAmount, setOrderAmount] = useState<number>(0);

    const [isLimitExceeded, setIsLimitExceeded] = useState<boolean>(false);

    const [bookingPending, setBookingPending] = useState(false);
    const [bookingComplete, setBookingComplete] = useState(false);
    const [bookingError, setBookingError] = useState(false);

    const [issuePending, setIssuePending] = useState(false);
    const [issueComplete, setIssueComplete] = useState(false);
    const [issueError, setIssueError] = useState(false);


    const onModalOpen = async () => {
        reset();
        if (!opened) {
            setOpened(true);
        }
        if(onOpen != null) {
            onOpen();
        }
        if(order != null) {
            await mapTrips();
        }
    }

    const onModalClose = async () => {
        if (opened) {
            setOpened(false);
        }
        if(onClose != null) {
            onClose();
        }
        reset();
    }


    const reset = () => {
        setTrips([]);
        setBookingComplete(false);
        setBookingPending(false);
        setBookingError(false);
    }


    const mapTrips = async () => {
        setTrips(order.trips.map((trip, tripIndex) => {
            return new Trip(
                trip.tripId,
                trip.tripName ?? "Командировка",
                tripIndex,
                mapTripElements(trip),
                trip.paxes.map(passenger => {
                    return {
                        details: passenger,
                        passport: passenger.passports[0]
                    }
                })
            )
        }));
    }

    const mapTripElements = (tripDetails: ITripDTO) => {
        let items: (FlightTripItem | TrainTripItem)[] = [];

        for(let ei = 0; ei < tripDetails.flights.length; ei++) {
            let tripElement = tripDetails.flights[ei];
            let flightVariant = (JSON.parse(tripElement.searchResponse) as ISearchFlightsResponse).variants[0];
            let flightTariffIndex = flightVariant.rejsInfo[flightVariant.rejsInfo.length - 1].tarifs.findIndex(t => t.isSelected) ?? 0;
            items.push(
                new FlightTripItem(
                    tripElement.id,
                    (tripElement.departure != null ? new Date(tripElement.departure).getTime() : 0),
                    flightVariant,
                    flightTariffIndex,
                    tripElement.searchResponse,
                    tripElement.pnrs,
                    tripElement.status
                )
            )
        }

        for(let ei = 0; ei < tripDetails.trains.length; ei++) {
            let tripElement = tripDetails.trains[ei];
            let trainVariant = (JSON.parse(tripElement.searchResponse) as ISearchTrainsResponse).variants[0];
            let trainTariffIndex = trainVariant.categoryPrice.findIndex(t => t.isSelected) ?? 0;
            items.push(
                new TrainTripItem(
                    tripElement.id,
                    (tripElement.departure != null ? new Date(tripElement.departure).getTime() : 0),
                    trainVariant,
                    trainTariffIndex,
                    tripElement.searchResponse,
                    tripElement.pnrs,
                    tripElement.status
                )
            )
        }

        // TODO: add hotels

        items.sort((a,b) =>  (a.timestamp > b.timestamp) ? 1 : ((b.timestamp > a.timestamp) ? -1 : 0))

        return items;
    }


    const createTrip = async () => {
        const trip = await tripService.createTrip({
            tripName: "Командировка" + ("-" + (trips.length + 1)),
            orderId: order.id
        });
        if(trip != null) {
            setTrips(prev => [...prev, new Trip(
                trip.tripId,
                trip.tripName ?? "Командировка",
                prev.length,
                mapTripElements(trip),
                trip.paxes.map(passenger => {
                    return {
                        details: passenger,
                        passport: passenger.passports[0]
                    }
                })
            )]);
        }
    }

    const removeTrip = async (tripId: string) => {
        setRemoveTripPending(prev => new Set(prev).add(tripId));

        const response = await tripService.removeTrip(tripId);

        // if(response != null && refreshOrderDetails != null) {
        //     await refreshOrderDetails();
        // }

        if(response != null) {
            setTrips(prev => prev.filter(x => x.id != tripId));
        }

        setRemoveTripPending(prev => {
            const next = new Set(prev);
            next.delete(tripId);
            return next;
        });
    }


    const openTicketSearchModalHandler = async (tripId: string) => {
        setSelectTicketHandler(() => {
            return (async (ticket: ISelectFlightTicketArgs | ISelectTrainTicketArgs) => {
                if (ticket.type == 'avia') {
                    const item = {
                        tripId: tripId,
                        elementType: TripElementType.Flight,
                        status: TripElementStatus.NotSet,
                        pnr: undefined,
                        flightNumber:  [ ...new Set( ticket.variant.rejsInfo.map(x => x.flightNumber)) ].join(','),
                        dateDep: ticket.variant.rejsInfo[0].dateDep,
                        dateArr: ticket.variant.rejsInfo[ticket.variant.rejsInfo.length - 1].dateArr,

                        createFlightSegmentRequests: ticket.variant.rejsInfo.map(segment => {
                            return {
                                flightNumber: segment.flightNumber,
                                carriers: segment.airCompanyName,

                                airCodeDep: segment.airCodeDep,
                                cityDep: segment.cityDep,
                                dateDep: segment.dateDep,

                                airCodeArr: segment.airCodeArr,
                                cityArr: segment.cityArr,
                                dateArr: segment.dateArr,

                                isBackwardTicketHead: segment.isBackwardTicketHead ?? false,
                            };
                        }),

                        searchResponse: JSON.stringify(
                            {
                                requested: ticket.searchResponse.requested,
                                variants: [ ticket.variant ]
                            }
                        )
                    } as ICreateFlightElementRequest;
                    await addFlightItemToTrip(tripId, item, ticket.tariffIndex);
                } else if (ticket.type == 'train') {
                    const item = {
                        tripId: tripId,
                        elementType: TripElementType.Train,
                        status: TripElementStatus.NotSet,
                        pnr: undefined,

                        trainNumber: ticket.variant.trainNum,

                        stationDep: ticket.variant.stationFrom,
                        stationCodeDep: ticket.variant.codeStationFrom,
                        dateDep: Utils.dateToLocalISO(Utils.parseTrainDateTime(ticket.variant.departureAt, ticket.variant.departureShortOn)), //.toISOString().replace('Z', ''),

                        stationArr: ticket.variant.stationTo,
                        stationCodeArr: ticket.variant.codeStationTo,
                        dateArr: Utils.dateToLocalISO(Utils.parseTrainDateTime(ticket.variant.arriveShortOn, ticket.variant.arriveAtn)), //.toISOString(), //.replace('Z', ''),

                        searchResponse: JSON.stringify(
                            {
                                requested: ticket.searchResponse.requested,
                                variants: [ ticket.variant ]
                            }
                        )
                    } as ICreateTrainElementRequest;
                    await addTrainItemToTrip(tripId, item, ticket.tariffIndex);
                }
                setSelectTicketHandler(undefined);
            })
        });
        setSearchTicketsModalOpened(true);
    }

    const addFlightItemToTrip = async (tripId: string, item: ICreateFlightElementRequest, tariffIndex: number) => {
        const patchedSearchResponse = patchSearchResponse(item.searchResponse, item.elementType, tariffIndex);
        item.searchResponse = patchedSearchResponse != null ? patchedSearchResponse : item.searchResponse;
        const response = await tripService.createFlightElement(item);
        if(response != null) {
            setTrips(prev => prev.map(trip => {
                if(trip.id == tripId) {
                    let flightVariant = (JSON.parse(response.searchResponse) as ISearchFlightsResponse).variants[0];
                    let flightTariffIndex = flightVariant.rejsInfo[flightVariant.rejsInfo.length - 1].tarifs.findIndex(t => t.isSelected) ?? 0;
                    trip.items = [
                        ...trip.items,
                        new FlightTripItem(
                            response.id,
                            (response.departure != null ? new Date(response.departure).getTime() : 0),
                            flightVariant,
                            flightTariffIndex,
                            response.searchResponse,
                            undefined,
                            TripElementStatus.NotSet
                        )
                    ]
                }
                return trip;
            }));
        }
    }

    const addTrainItemToTrip = async (tripId: string, item: ICreateTrainElementRequest, tariffIndex: number) => {
        const patchedSearchResponse = patchSearchResponse(item.searchResponse, item.elementType, tariffIndex);
        item.searchResponse = patchedSearchResponse != null ? patchedSearchResponse : item.searchResponse;
        const response = await tripService.createTrainElement(item);
        if(response != null) {
            setTrips(prev => prev.map(trip => {
                if(trip.id == tripId) {
                    let trainVariant = (JSON.parse(response.searchResponse) as ISearchTrainsResponse).variants[0];
                    let trainTariffIndex = trainVariant.categoryPrice.findIndex(t => t.isSelected) ?? 0;
                    trip.items = [
                        ...trip.items,
                        new TrainTripItem(
                            response.id,
                            (response.departure != null ? new Date(response.departure).getTime() : 0),
                            trainVariant,
                            trainTariffIndex,
                            response.searchResponse,
                            undefined,
                            TripElementStatus.NotSet
                        )
                    ]
                }
                return trip;
            }));
        }
    }

    const toggleIsOpenTripElement = (tripId: string, tripItemId: string, state: boolean) => {
        setTrips(prev => prev.map(trip => {
            if(trip.id == tripId) {
                trip.items = trip.items.map(tripItem => {
                    if(tripItem.id == tripItemId) {
                        tripItem.isOpened = state;
                    }
                    return tripItem;
                })
            }
            return trip;
        }))
    }

    const toggleIsSelectedTripElement = (tripId: string, tripItemId: string, state: boolean) => {
        setTrips(prev => prev.map(trip => {
            if(trip.id == tripId) {
                trip.items = trip.items.map(tripItem => {
                    if(tripItem.id == tripItemId) {
                        tripItem.isSelected = state;
                    }
                    return tripItem;
                })
            }
            return trip;
        }))
    }

    const patchSearchResponse = (searchResponse: string, elementType: TripElementType, tariffIndex: number) => {
        if(elementType == TripElementType.Flight) {
            let itemSearchResponse = JSON.parse(searchResponse) as ISearchFlightsResponse;

            let tariffs = itemSearchResponse.variants[0].rejsInfo[itemSearchResponse.variants[0].rejsInfo.length - 1].tarifs;

            tariffs = tariffs.map((t, ti) => {
                t.isSelected = ti == tariffIndex;
                return t;
            });

            itemSearchResponse.variants[0].rejsInfo[itemSearchResponse.variants[0].rejsInfo.length - 1].tarifs = tariffs;

            return JSON.stringify(itemSearchResponse);
        }
        else if(elementType == TripElementType.Train) {
            let itemSearchResponse = JSON.parse(searchResponse) as ISearchTrainsResponse;

            let tariffs = itemSearchResponse.variants[0].categoryPrice;

            tariffs = tariffs.map((t, ti) => {
                t.isSelected = ti == tariffIndex;
                return t;
            });

            itemSearchResponse.variants[0].categoryPrice = tariffs;

            return JSON.stringify(itemSearchResponse);
        }
        return null;
    }

    const changeTripElementTariff = async (tripId: string, tripItemId: string, tariffIndex: number) => {
        let item = trips.find(x => x.id == tripId)?.items.find(x => x.id == tripItemId);
        let elementType = null;
        if(item instanceof FlightTripItem) {
            elementType = TripElementType.Flight;
        }
        else if(item instanceof TrainTripItem) {
            elementType = TripElementType.Train;
        }
        if(item != null && elementType != null) {
            let patchedSearchResponse = patchSearchResponse(item.searchResponse, elementType, tariffIndex);
            if(patchedSearchResponse != null) {
                item.searchResponse = patchedSearchResponse;
                const response = await tripService.patchTripElement({
                    id: item.id,
                    type: elementType,
                    searchResponse: item.searchResponse
                });
                // if(response != null && refreshOrderDetails != null) {
                //     await refreshOrderDetails();
                // }
                if(response != null) {
                    setTrips(prev => prev.map(trip => {
                        if(trip.id == tripId) {
                            trip.items = trip.items.map(tripItem => {
                                if(tripItem.id == tripItemId) {
                                    tripItem.tariffIndex = tariffIndex;
                                }
                                return tripItem;
                            })
                        }
                        return trip;
                    }))
                }
            }
        }
    }

    const removeItemFromTrip = async (tripId: string, tripItemId: string, elementType: TripElementType) => {
        const response = await tripService.removeTripItem(tripItemId, elementType);
        // if(response != null && refreshOrderDetails != null) {
        //     await refreshOrderDetails();
        // }
        if(response != null) {
            setTrips(prev => prev.map(trip => {
                if(trip.id == tripId) {
                    trip.items = trip.items.filter(x => x.id != tripItemId)
                }
                return trip;
            }));
        }
    }



    const openPassengersSelectionModal = (tripId: string) => {
        const tripPassengers =
            trips
                .filter(trip => trip.id == tripId)
                .map(trip => trip.passengers)
                .flat();

        setSelectedPassengers(tripPassengers.map(x => x.details.code));

        setSelectPassengersHandler(() => {
            return (async (passengerDTOs: IPassengerDTO[]) => {

                const oldPaxCodes = tripPassengers.map(x => x.details.code);
                const newPaxCodes = passengerDTOs.map(x => x.paxCode);

                const passengersToAdd = newPaxCodes.filter(x => !oldPaxCodes.includes(x));
                const passengersToRemove = oldPaxCodes.filter(x => !newPaxCodes.includes(x));

                if(passengersToAdd.length > 0) {

                    let promises = [];

                    for(let i = 0; i < passengersToAdd.length; i++) {
                        promises.push(
                            new Promise(async (resolve, reject) => {
                                const paxCode = passengersToAdd[i];
                                const checkResponse = await tripService.isPaxExist(paxCode);
                                const passenger = passengerDTOs.find(x => x.paxCode == paxCode);
                                if(!checkResponse && passenger != null) {
                                    await tripService.createPax({
                                        code: passenger.paxCode,
                                        clientCodes: [selectedCompany.code],
                                        gender: undefined,
                                        firstName: passenger.first,
                                        lastName: passenger.middle,
                                        patronymic: passenger.last,
                                        dateOfBurn: passenger.birthday.substring(0, 10).split(".").reverse().join("-") + "T00:00:00",
                                        passports: passenger.passports.map(passport => {
                                            return {
                                                number: passport.number,
                                                expired: passport.expiry,
                                                type: (() => {
                                                    if(passport.isInter) {
                                                        return PassportType.International;
                                                    }
                                                    else if(passport.isForeign) {
                                                        return PassportType.Foreign;
                                                    }
                                                    return PassportType.Russian;
                                                })()
                                            }
                                        }),
                                        bonuses: passenger.bonusCards.map(bonusCard => {
                                            return {
                                                name: "",
                                                number: "",
                                                ak: "",
                                                code: "",
                                                fullPaxName: ""
                                            }
                                        })
                                    });
                                }
                                resolve(true);
                            })
                        )
                    }

                    Promise.all(promises).then(async () => {
                        const response = await tripService.addPaxesToTrip({
                            tripId: tripId,
                            paxCodes: passengersToAdd
                        });

                        if (response != null) {
                            // TODO: fix twice execution
                            setTrips(prev => prev.map(trip => {
                                if (trip.id == tripId) {
                                    const passengers = response;
                                    if(passengers.length > 0) {
                                        trip.passengers = [
                                            ...trip.passengers.filter(x => !passengers.some(y => x.details.code == y.code)),
                                            ...passengers.map(passenger => {
                                                return {
                                                    details: passenger,
                                                    passport: passenger.passports[0]
                                                }
                                            }).slice()
                                        ]
                                    }
                                }
                                return trip;
                            }));
                        }
                    })
                }

                if(passengersToRemove.length > 0) {
                    const response = await tripService.removePaxesFromTrip({
                        tripId: tripId,
                        paxCodes: passengersToRemove
                    });

                    if (response != null) {
                        setTrips(prev => prev.map(trip => {
                            if (trip.id == tripId) {
                                trip.passengers = trip.passengers.filter(x => !response.some(y => y == x.details.code))
                            }
                            return trip;
                        }));
                    }
                }
            })
        });
        setPassengersSelectionModalOpened(true);
    }

    const changePassengerPassport = (tripId: string, passengerInfo: IPassengerInfo) => {
        // TODO: update passport on backend..

        setTrips(prev => prev.map(trip => {
            if(trip.id == tripId) {
                trip.passengers = trip.passengers.map(pi => {
                    if(pi.details.code == passengerInfo.details.code) {
                        return passengerInfo;
                    }
                    return pi;
                });
            }
            return trip;
        }));
    }

    const removePassengerFromTrip = async (tripId: string, paxCode: string) => {

        setRemovePaxPending(prev => new Set(prev).add(paxCode));

        const response = await tripService.removePaxesFromTrip({
            tripId: tripId,
            paxCodes: [paxCode]
        });

        if (response != null) {
            setTrips(prev => prev.map(trip => {
                if(trip.id == tripId) {
                    trip.passengers = trip.passengers.filter(pi => pi.details.code !== paxCode);
                }
                return trip;
            }));
        }

        setRemovePaxPending(prev => {
            const next = new Set(prev);
            next.delete(tripId);
            return next;
        });
    }


    const loadPassportsFromTTS = async () => {
        const passengerDTOs = await passengersService.getPassengers(selectedCompany.code);
        setTrips(prev => prev.map(trip => {
            trip.passengers = trip.passengers.map(passenger => {
                if(passenger.details.passports.length === 0) {
                    let passports = passengerDTOs.find(x => x.paxCode == passenger.details.code)?.passports.map(passport => {
                        let type = PassportType.Russian;
                        if (passport.isInter) {
                            type = PassportType.International;
                        } else if (passport.isForeign) {
                            type = PassportType.Foreign;
                        }
                        return  {
                            number: passport.number,
                            expired: passport.expiry,
                            type: type
                        };
                    });
                    if(passports != null && passports.length > 0) {
                        passenger.details.passports = passports;
                        passenger.passport = passports[0];
                    }
                }
                return passenger;
            })
            return trip;
        }));
    }


    const updateOrderAmount = async () => {
        let amount = 0;
        trips.forEach(trip => {
            trip.items.filter(x => x.isSelected).forEach(item => {
                if (item instanceof FlightTripItem) {
                    const tariff = item.variant.rejsInfo[item.variant.rejsInfo.length - 1].tarifs[item.tariffIndex];
                    amount += (tariff.priceWithSborTts * trip.passengers.length);
                } else if (item instanceof TrainTripItem) {
                    const tariff = item.variant.categoryPrice[item.tariffIndex];
                    amount += ((tariff.price + tariff.sborTts) * trip.passengers.length);
                } else {
                }
            })
        });
        setOrderAmount(amount);
    }


    const checkOrderLimit = async () => {
        const result = await tripService.canOrderIssue( selectedCompany?.code ?? "", orderAmount);
        if(result != null) {
            setIsLimitExceeded(!result.canIssue);
        }
    }


    const sendToTts = async () => {
        let flightTrips = trips.map(trip => {
            return {
                id: trip.id,
                index: trip.index,
                items: trip.items.filter(item => item instanceof FlightTripItem),
                passengers: trip.passengers
            } as Trip;
        }).filter(x => x.items.length > 0);

        let trainTrips = trips.map(trip => {
            return {
                id: trip.id,
                index: trip.index,
                items: trip.items.filter(item => item instanceof TrainTripItem),
                passengers: trip.passengers
            } as Trip;
        }).filter(x => x.items.length > 0);

        const bookRequest: IOrderBookRequest = {
            orderId: order.id,
            orderNo: order.orderNo,
            clientCode: selectedCompany?.code ?? "",
            flightBookModel: {
                mode: ProviderTypes.Flight,
                trips: flightTrips.map(trip => {
                    return (
                        {
                            id: trip.id,
                            paxes: trip.passengers.map(p => {
                                return {
                                    paxCode: p.details.code,
                                    selectedPassportNo: p.passport?.number ?? ""
                                }
                            }),
                            tripElements: trip.items.map(dir => {
                                const flightItem = dir as FlightTripItem;
                                let fareBasises = flightItem.variant.rejsInfo[flightItem.variant.rejsInfo.length - 1].tarifs[flightItem.tariffIndex].fareBasis;
                                return(
                                    {
                                        tripElementId: dir.id,
                                        fareBasises: fareBasises,
                                        searchResponse: JSON.parse(dir.searchResponse),
                                    }
                                )
                            }),
                        }
                    )
                })
            },
            trainBookModel: {
                mode: ProviderTypes.Train,
                trips: trainTrips.map(trip => {
                    return (
                        {
                            id: trip.id,
                            paxes: trip.passengers.map(p => {
                                return {
                                    paxCode: p.details.code,
                                    selectedPassportNo: p.passport?.number ?? ""
                                }
                            }),
                            tripElements: trip.items.map(dir => {
                                const flightItem = dir as TrainTripItem;
                                let fareBasises = [flightItem.variant.categoryPrice[flightItem.tariffIndex].fareBasis];
                                return(
                                    {
                                        tripElementId: dir.id,
                                        fareBasises: fareBasises,
                                        searchResponse: JSON.parse(dir.searchResponse),
                                    }
                                )
                            }),

                        }
                    )
                })
            }
        }

        const response = await tripService.sendOrderToTTS(bookRequest);
    }


    const bookSelected  = async () => {
        //const companyCheckResult = await checkCompany();

        // if (!companyCheckResult) {
        //     return;
        // }

        // update issue status
        setIssueError(false);
        setIssuePending(false);
        setIssueComplete(false);

        // update booking status
        setBookingError(false)
        setBookingPending(true);
        setBookingComplete(false);

        // filter trips with flight elements that are selected and not booked yet
        let flightTrips = trips.map(trip => {
            return {
                ...trip,
                items: trip.items.filter(item =>
                    item instanceof FlightTripItem
                    && item.status == TripElementStatus.NotSet
                    && item.isSelected
                ),
            } as Trip;

        }).filter(x => x.items.length > 0);

        // filter trips with flight elements that are selected and not booked yet
        let trainTrips = trips.map(trip => {
            return {
                ...trip,
                items: trip.items.filter(item =>
                            item instanceof TrainTripItem
                            && item.status == TripElementStatus.NotSet
                            && item.isSelected
                ),
            } as Trip;
        }).filter(x => x.items.length > 0);

        // prepare booking model
        const bookRequest: IOrderBookRequest = {
            orderId: order.id,
            orderNo: order.orderNo,
            clientCode: selectedCompany?.code ?? "",
            flightBookModel: {
                mode: ProviderTypes.Flight,
                trips: flightTrips.map(trip => {
                        // trip data
                        return ({
                            id: trip.id,
                            // trip passengers data
                            paxes: trip.passengers.map(p => {
                                return {
                                    paxCode: p.details.code,
                                    selectedPassportNo: p.passport?.number ?? ""
                                }
                            }),
                            // trip elements data
                            tripElements: trip.items.map(dir => {
                                const item = dir as FlightTripItem;
                                const tariff = item.variant.rejsInfo[item.variant.rejsInfo.length - 1].tarifs[item.tariffIndex];
                                let fareBasises = tariff.fareBasis;
                                return({
                                    tripElementId: dir.id,
                                    fareBasises: fareBasises,
                                    searchResponse: JSON.parse(dir.searchResponse)
                                })
                            }),
                        })
                })
            },
            trainBookModel: {
                mode: ProviderTypes.Train,
                trips: trainTrips.map(trip => {
                        // trip data
                        return ({
                            id: trip.id,
                            // trip passengers data
                            paxes: trip.passengers.map(p => {
                                return {
                                    paxCode: p.details.code,
                                    selectedPassportNo: p.passport?.number ?? ""
                                }
                            }),
                            // trip elements data
                            tripElements: trip.items.map(dir => {
                                const item = dir as TrainTripItem;
                                const tariff = item.variant.categoryPrice[item.tariffIndex];
                                let fareBasises = [tariff.fareBasis];
                                return({
                                    tripElementId: dir.id,
                                    fareBasises: fareBasises,
                                    searchResponse: JSON.parse(dir.searchResponse)
                                })
                            }),
                        })
                })
            }
        }


        let bookingTrips = [...flightTrips, ...trainTrips];
        let timeout = 0;
        for(let i = 0; i < bookingTrips.length; i++) {
            let bookingTrip = bookingTrips[i];
            timeout += bookingTrip.passengers.length * (bookingTrip.items.length) * 30 * 1000;
        }
        timeout = Math.max(timeout, 100 * 1000);
        console.warn('Timeout for booking = ' + Math.round(timeout / 1000) + 'sec');


        // book order
        const response = await tripService.bookOrder(bookRequest, timeout);
        // check response
        const result = !Utils.isEmpty(response);

        // execute onOrderCreate callback if any
        if(onOrderBooked != null) {
            await onOrderBooked(order.id);
        }

        // update booking status
        setBookingPending(false);
        setBookingError(!result);
        setBookingComplete(result);
    }


    const issueSelected = async () => {
        //const companyCheckResult = await checkCompany();

        // if (!companyCheckResult) {
        //     return;
        // }

        // update booking status
        setBookingError(false);
        setBookingPending(false);
        setBookingComplete(false);

        // update issue status
        setIssueError(false)
        setIssuePending(true);
        setIssueComplete(false);


        // filter trips with flight elements that are selected and booked already
        let flightTrips = trips.map(trip => {
            return {
                ...trip,
                items: trip.items.filter(item =>
                    item instanceof FlightTripItem
                    && item.status == TripElementStatus.Booked
                    && item.isSelected
                ),
            } as Trip;

        }).filter(x => x.items.length > 0);

        // filter trips with flight elements that are selected and booked already
        let trainTrips = trips.map(trip => {
            return {
                ...trip,
                items: trip.items.filter(item =>
                            item instanceof TrainTripItem
                            && item.status == TripElementStatus.Booked
                            && item.isSelected
                ),
            } as Trip;
        }).filter(x => x.items.length > 0);

        // prepare issue model
        const issueRequest: IOrderIssueRequest = {
            orderId: order.id,
            orderNo: order.orderNo,
            clientCode: selectedCompany?.code ?? "",
            // who first Fights or Trains ???
            trips: [...flightTrips, ...trainTrips].map(trip => {
                 // trip data
                 return ({
                    tripId: trip.id,
                    toIssueElements: trip.items.map(item => {
                        let isFlight = (item instanceof FlightTripItem);
                        let isTrain  = (item instanceof TrainTripItem);
                        return {
                            tripElementId: item.id,
                            type: (isFlight ? TripElementType.Flight : TripElementType.Train),
                            flightPnrs: (isFlight ? (item.pnrs?.split(";") ?? []) : []),
                            issueFlights: isFlight,  // temporary
                            trainPnrs: (isTrain ? (item.pnrs?.split(";") ?? []) : []),
                            issueTrain: isTrain,    // temporary
                        };
                    })
                })
            })
        }


        let issuingTrips = [...flightTrips, ...trainTrips];
        let timeout = 0;
        for(let i = 0; i < issuingTrips.length; i++) {
            let issuingTrip = issuingTrips[i];
            timeout += issuingTrip.passengers.length * (issuingTrip.items.length) * 30 * 1000;
        }
        timeout = Math.max(timeout, 100 * 1000);
        console.warn('Timeout for issue = ' + Math.round(timeout / 1000) + 'sec');


        // book order
        const response = await tripService.issueOrder(issueRequest, timeout);
        // check response
        const result = !Utils.isEmpty(response);

        // execute onOrderIssue callback if any
        if(onOrderIssued != null) {
            await onOrderIssued(order.id);
        }

        // update issue status
        setIssuePending(false);
        setIssueError(!result);
        setIssueComplete(result);
    }


    const closeModal = () => {
        setOpened(false);
    }


    useEffect(() => {
        if (isOpen(NEW_TRIP_MODAL_ID) && !opened) {
            close(NEW_TRIP_MODAL_ID);
        } else if (!isOpen(NEW_TRIP_MODAL_ID) && opened) {
            open(NEW_TRIP_MODAL_ID);
        }
    }, [opened]);

    useEffect(() => {
        updateOrderAmount();
    }, [trips]);

    useEffect(() => {
        if(isOpen(NEW_TRIP_MODAL_ID)) {
            checkOrderLimit();
        }
    }, [orderAmount]);


    return (
        <>
            <MdModal
                id={NEW_TRIP_MODAL_ID}
                title={
                    <>
                        <span>
                            {
                                order?.orderNo != null
                                    ? t("bookingPage.order") + " №" + order.orderNo
                                    : t("bookingPage.newOrder")
                            }
                        </span>
                        <div className="trip-status">
                            {
                                (bookingPending) && (
                                    <span className="trip-status-text info-status">
                                        <div className="booking-pending-message">
                                            <span>
                                                {t("bookingPage.bookingTickets")}
                                            </span>
                                            <CircleSpinnerIcon
                                                width="12px"
                                                height="12px"
                                                style={{
                                                    "circle": {
                                                        stroke: "#cd7402"
                                                    }
                                                }}
                                            />
                                        </div>
                                    </span>
                                )
                            }

                            {
                                (issuePending) && (
                                    <span className="trip-status-text info-status">
                                        <div className="issue-pending-message">
                                            <span>
                                                {t("bookingPage.issueTickets")}
                                            </span>
                                            <CircleSpinnerIcon
                                                width="12px"
                                                height="12px"
                                                style={{
                                                    "circle": {
                                                        stroke: "#cd7402"
                                                    }
                                                }}
                                            />
                                        </div>
                                    </span>
                                )
                            }

                            {
                                isLimitExceeded && (
                                    <span className="trip-status-text error-status">
                                        {t("bookingPage.orderLimitExceeded")}
                                    </span>
                                )
                            }

                            {
                                (!bookingPending && !issuePending && !isLimitExceeded) && (
                                    <span className="trip-status-text">
                                         {t("bookingPage.created")}
                                    </span>
                                )
                            }
                        </div>
                    </>
                }
                openHandler={onModalOpen}
                closeHandler={onModalClose}
                closeOnClickOutside={false}
                style={{
                    "root": {
                        width: "580px",
                        height: "850px",
                        maxHeight: "95vh"
                    },
                    "content": {
                        overflow: "none"
                    }
                }}
            >

                <div className="trip-builder-form">

                    <div className="trip-builder-form-header">

                        <div className="selected-company-details">
                            <div className="company-item">
                                <div className="company-item-icon">
                                    <CompanyIcon
                                        width="18px"
                                        height="18px"
                                        style={{
                                            "path": {
                                                fill: "#777E90"
                                            }
                                        }}
                                    />
                                </div>
                                <div className="company-item-details">
                                    <div className="company-item-details-row">
                                        <div className="company-item-name">
                                            {selectedCompany?.fullName}
                                        </div>
                                    </div>
                                    <div className="company-item-details-row">
                                        <div className="company-item-inn">
                                            {selectedCompany?.inn}
                                        </div>
                                    </div>
                                </div>
                                <div className="company-item-tools">

                                </div>
                            </div>
                        </div>

                        {
                            trips.some(trip => trip.passengers.some(passenger => passenger.passport == null)) && (
                                <div className="passengers-error-message">
                                    <span>
                                        {t("bookingPage.noPassportDetailsError")}
                                    </span>
                                    <span>
                                        <MdButton
                                            tooltip={t("bookingPage.synchronizationWithTTS")}
                                            style={{
                                                "button": {
                                                    width: "18px",
                                                    height: "18px",
                                                    border: "none",
                                                    padding: 0,
                                                    justifyContent: "center",
                                                    backgroundColor: "transparent",
                                                },
                                                "ink": {
                                                    backgroundColor: "rgba(0, 0, 0, 0.1)"
                                                }
                                            }}
                                            onClick={() => loadPassportsFromTTS()}
                                        >
                                            <RefreshIcon
                                                width="12"
                                                height="12"
                                                style={{
                                                    "path": {
                                                        fill: "#856404"
                                                    }
                                                }}
                                            />
                                        </MdButton>
                                    </span>
                                </div>
                            )
                        }

                        {
                            (bookingError && !issueError) && (
                                <div className="booking-error-message">
                                    <span>{t("bookingPage.bookingError")}</span>
                                    <MdButton
                                        style={{
                                            "button": {
                                                width: "18px",
                                                height: "18px",
                                                border: "none",
                                                padding: 0,
                                                justifyContent: "center",
                                                backgroundColor: "transparent",
                                            },
                                            "ink": {
                                                backgroundColor: "rgba(0, 0, 0, 0.1)"
                                            }
                                        }}
                                        onClick={() => setBookingError(false)}
                                    >
                                        <CrossIcon
                                            width="16"
                                            height="16"
                                            style={{
                                                "path": {
                                                    fill: "#856404"
                                                }
                                            }}
                                        />
                                    </MdButton>
                                </div>
                            )
                        }

                        {
                            issueError && (
                                <div className="booking-error-message">
                                    <span>{t("bookingPage.issueError")}</span>
                                    <MdButton
                                        style={{
                                            "button": {
                                                width: "18px",
                                                height: "18px",
                                                border: "none",
                                                padding: 0,
                                                justifyContent: "center",
                                                backgroundColor: "transparent",
                                            },
                                            "ink": {
                                                backgroundColor: "rgba(0, 0, 0, 0.1)"
                                            }
                                        }}
                                        onClick={() => setIssueError(false)}
                                    >
                                        <CrossIcon
                                            width="16"
                                            height="16"
                                            style={{
                                                "path": {
                                                    fill: "#856404"
                                                }
                                            }}
                                        />
                                    </MdButton>
                                </div>
                            )
                        }

                        {
                            (bookingComplete && !issueComplete) && (
                                <div className="booking-complete-message">
                                    {t("bookingPage.bookingComplete")}
                                </div>
                            )
                        }

                        {
                            issueComplete && (
                                <div className="booking-complete-message">
                                    {t("bookingPage.issueComplete")}
                                </div>
                            )
                        }

                    </div>

                    <MdScrollViewer
                        style={{
                            "content": {
                                padding: "10px 20px 20px 20px",
                            }
                        }}
                    >

                        {
                            loadOrderPending && (
                                <div className="trip-builder-preloader">
                                    <MdProgressBar />
                                    <span>{t('bookingPage.loadingData')}..</span>
                                </div>
                            )
                        }

                        {
                            !loadOrderPending && (
                                <React.Fragment>

                                    <MdAccordion
                                        sections={
                                            (
                                                trips.map((trip, tripIndex) => {
                                                    return ({
                                                        header: (
                                                            <div className="trip-header">
                                                                <>
                                                                    <span>
                                                                        {trip.name}
                                                                    </span>
                                                                    <span>
                                                                    {
                                                                        !trip.items.some(item => item.status == TripElementStatus.Booked || item.status == TripElementStatus.Confirmed) && (
                                                                            removeTripPending.has(trip.id)
                                                                                ? (
                                                                                    <CircleSpinnerIcon
                                                                                        width="18px"
                                                                                        height="18px"
                                                                                    />
                                                                                )
                                                                                : (
                                                                                    <MdButton
                                                                                        icon={
                                                                                            <CrossIcon
                                                                                                width="14px"
                                                                                                height="14px"
                                                                                                style={{
                                                                                                    "path": {
                                                                                                        fill: "rgb(114, 28, 36)"
                                                                                                    }
                                                                                                }}
                                                                                            />
                                                                                        }
                                                                                        style={{
                                                                                            "button": {
                                                                                                alignItems: "center",
                                                                                                justifyContent: "center",
                                                                                                borderWidth: 0,
                                                                                                backgroundColor: "#FFFFFF",
                                                                                                color: "rgb(0 0 0)",
                                                                                                borderRadius: "6px",
                                                                                                boxShadow: "0px 3px 1px -2px rgba(0, 0, 0, 0.16), 0px 1px 2px 0px rgba(0, 0, 0, 0.1), 0px 1px 5px 0px rgba(0, 0, 0, 0.08)",
                                                                                                margin: 0,
                                                                                                width: "24px",
                                                                                                height: "24px",
                                                                                                padding: 0
                                                                                            },
                                                                                            "ink": {
                                                                                                backgroundColor: "rgba(230, 230, 230, 0.5)"
                                                                                            }
                                                                                        }}
                                                                                        disabled={(bookingPending || issuePending)}
                                                                                        onClick={(event) => {
                                                                                            event.preventDefault();
                                                                                            event.stopPropagation();
                                                                                            removeTrip(trip.id)
                                                                                        }}
                                                                                    />
                                                                                )
                                                                        )
                                                                    }
                                                                    </span>
                                                                </>
                                                            </div>
                                                        ),
                                                        content: (
                                                            <React.Fragment key={tripIndex}>
                                                                <TripComponent
                                                                    tripDetails={trip}
                                                                    canEdit={(!bookingPending && !issuePending)}
                                                                    // TODO: process trip changes inside component, except remove trip?
                                                                    callbacks={{
                                                                        removeTrip: () => {
                                                                            removeTrip(trip.id);
                                                                        },
                                                                        openPassengerSelectionModal: () => {
                                                                            openPassengersSelectionModal(trip.id);
                                                                        },
                                                                        openTicketSearchModal: () => {
                                                                            openTicketSearchModalHandler(trip.id)
                                                                        },
                                                                        changePassengerPassport: (paxCode: string, passportInfo: any) => {
                                                                            const passengerInfo = trip.passengers.find(x => x.details.code == paxCode);
                                                                            if (passengerInfo != null) {
                                                                                changePassengerPassport(
                                                                                    trip.id,
                                                                                    Object.assign(
                                                                                        passengerInfo,
                                                                                        {
                                                                                            passport: passportInfo
                                                                                        }
                                                                                    )
                                                                                )
                                                                            }
                                                                        },
                                                                        removePassenger: (paxCode: string) => {
                                                                            removePassengerFromTrip(trip.id, paxCode);
                                                                        },
                                                                        toggleIsOpenItem: (itemId: string, state: boolean) => {
                                                                            toggleIsOpenTripElement(trip.id, itemId, state);
                                                                        },
                                                                        toggleIsSelectedItem: (itemId: string, state: boolean) => {
                                                                            toggleIsSelectedTripElement(trip.id, itemId, state);
                                                                        },
                                                                        changeItemTariff: (itemId: string, elementType: TripElementType, tariffIndex: number) => {
                                                                            changeTripElementTariff(trip.id, itemId, tariffIndex);
                                                                        },
                                                                        removeItem: (itemId: string, elementType: TripElementType) => {
                                                                            removeItemFromTrip(trip.id, itemId, elementType);
                                                                        }
                                                                    }}
                                                                />
                                                            </React.Fragment>
                                                        ),
                                                        isOpen: true
                                                    })
                                                })
                                            )
                                        }
                                    />

                                    <div className="trip-add-button">
                                        <MdButton
                                            type="submit"
                                            style={{
                                                "button": {
                                                    borderWidth: 0,
                                                    backgroundColor: "rgb(253 253 253)",
                                                    color: "rgb(123 123 123)",
                                                    border: "1px solid rgb(123 123 123)",
                                                    borderStyle: "dashed",
                                                    borderRadius: "6px",
                                                    height: "30px",
                                                    padding: "5px 15px"
                                                },
                                                "ink": {
                                                    backgroundColor: "rgba(255,255,255,.5)"
                                                }
                                            }}
                                            disabled={bookingPending}
                                            onClick={() => createTrip()}
                                        >
                                            Добавить командировку
                                        </MdButton>
                                    </div>


                                    {
                                        <div className="order-summary-info">

                                            <div className="order-summary-info-item">
                                                <span>{t("bookingPage.numberOfTrips")}</span>
                                                <span></span>
                                                <span>
                                                {
                                                    trips.length
                                                }
                                                </span>
                                            </div>

                                            <div className="order-summary-info-item">
                                                <span style={{fontWeight: 500}}>{t("bookingPage.orderTotalAmount")}</span>
                                                <span></span>
                                                <span>
                                                    {
                                                        trips.some(trip => trip.passengers.length == 0)
                                                            ? (
                                                                "Не определена"
                                                            )
                                                            : (
                                                                (() => {
                                                                    let total = 0;
                                                                    trips.forEach(trip => {
                                                                        trip.items.filter(x => x.isSelected).forEach(item => {
                                                                            if (item instanceof FlightTripItem) {
                                                                                const tariff = item.variant.rejsInfo[item.variant.rejsInfo.length - 1].tarifs[item.tariffIndex];
                                                                                total += (tariff.priceWithSborTts * trip.passengers.length);
                                                                            } else if (item instanceof TrainTripItem) {
                                                                                const tariff = item.variant.categoryPrice[item.tariffIndex];
                                                                                total += ((tariff.price + tariff.sborTts) * trip.passengers.length);
                                                                            } else {
                                                                            }
                                                                        })
                                                                    });
                                                                    return total.toLocaleString() + " ₽";
                                                                })()
                                                            )
                                                    }
                                                </span>
                                            </div>
                                        </div>
                                    }

                                    {
                                        order.orderNo !== null && (
                                            <div className="order-tools">
                                                <MdButton
                                                    type="submit"
                                                    style={{
                                                        "button": {
                                                            alignSelf: "center",
                                                            borderWidth: 0,
                                                            backgroundColor: "#a9a9a9",
                                                            color: "#FFFFFF",
                                                            borderRadius: "6px",
                                                            height: "35px",
                                                            padding: "5px 15px"
                                                        },
                                                        "ink": {
                                                            backgroundColor: "rgba(255,255,255,.5)"
                                                        }
                                                    }}
                                                    onClick={() => sendToTts()}
                                                >
                                                    {t("bookingPage.save")}
                                                </MdButton>
                                            </div>
                                        )
                                    }

                                </React.Fragment>
                            )
                        }

                    </MdScrollViewer>

                    {
                        !loadOrderPending && (
                            <div className="trip-builder-form-footer">

                                <MdButton
                                    type="submit"
                                    style={{
                                        "button": {
                                            alignSelf: "center",
                                            width: "200px",
                                            borderWidth: 0,
                                            backgroundColor: "#3E5CB8",
                                            color: "#FFFFFF",
                                            borderRadius: "6px",
                                            height: "35px",
                                            padding: "5px 15px"
                                        },
                                        "ink": {
                                            backgroundColor: "rgba(255,255,255,.5)"
                                        }
                                    }}
                                    disabled={(
                                        isLimitExceeded
                                        || bookingPending
                                        || issuePending
                                        || trips.length == 0
                                        || trips.some(trip => trip.passengers.some(passenger => passenger.passport == null))
                                        || trips.some(trip => trip.passengers.length == 0 && trip.items.some(item => item.isSelected && item.status == TripElementStatus.NotSet))
                                        || !trips.map(trip => trip.items.filter(item => item.isSelected)).flat().some(item => item.status == TripElementStatus.NotSet)
                                    )}
                                    onClick={bookSelected}
                                >
                                    {(t("bookingPage.bookSelected") + " (" + trips.reduce(function (total, trip) {
                                        return total + trip.items.filter(item => item.status == TripElementStatus.NotSet && item.isSelected).length;
                                    }, 0) + ")")}
                                </MdButton>

                                <MdButton
                                    type="submit"
                                    style={{
                                        "button": {
                                            alignSelf: "center",
                                            width: "200px",
                                            borderWidth: 0,
                                            backgroundColor: "#3E5CB8",
                                            color: "#FFFFFF",
                                            borderRadius: "6px",
                                            height: "35px",
                                            padding: "5px 15px"
                                        },
                                        "ink": {
                                            backgroundColor: "rgba(255,255,255,.5)"
                                        }
                                    }}
                                    disabled={(
                                        isLimitExceeded
                                        || bookingPending
                                        || issuePending
                                        || trips.length == 0
                                        || trips.some(trip => trip.passengers.some(passenger => passenger.passport == null))
                                        || trips.some(trip => trip.passengers.length == 0 && trip.items.some(item => item.isSelected && item.status == TripElementStatus.Booked))
                                        || !trips.map(trip => trip.items.filter(item => item.isSelected)).flat().some(item => item.status == TripElementStatus.Booked)
                                    )}
                                    onClick={issueSelected}
                                >
                                    {(t("bookingPage.issueSelected") + " (" + trips.reduce(function (total, trip) {
                                        return total + trip.items.filter(item => item.status == TripElementStatus.Booked && item.isSelected).length;
                                    }, 0) + ")")}
                                </MdButton>

                            </div>
                        )
                    }

                </div>
            </MdModal>

            <SearchTicketsModal
                opened={searchTicketsModalOpened}
                setOpened={setSearchTicketsModalOpened}
                onClosed={() => {
                    setSelectTicketHandler(undefined);
                }}
                order={order}
                selectedCompany={selectedCompany}
                selectTicketHandler={selectTicketHandler}
            />

            <PassengersSelectionModal
                title={(t("bookingPage.choosingPax") + (order.orderNo != null ? " №" + order.orderNo : ""))}
                opened={passengersSelectionModalOpened}
                setOpened={setPassengersSelectionModalOpened}
                onClosed={() => {
                    setSelectPassengersHandler(undefined);
                }}
                company={selectedCompany}
                tripPassengers={selectedPassengers}
                selectPassengersHandler={selectPassengersHandler}
            />
        </>

    );
}