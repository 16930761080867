import axios, {CancelToken} from "axios";
import {store} from "../redux/store";
import {IChatMessage, IChatRoom} from "../components/chat";
import {ICreateChatRoomRequest} from "../api/request/ICreateChatRoomRequest";
import {ISendChatMessageRequest} from "../api/request/ISendChatMessageRequest";
import {ChatApi} from "../api/ChatApi";
import {Utils} from "../utils/utils";


export function ChatService() {

    const chatApi = ChatApi();


    /**
     * GET Method for obtaining rooms
     * @param {CancelToken} ct - cancellation token
     * @return {Promise<IChatRoom[]>} list of rooms
     */
    const getRooms = async (ct: CancelToken = axios.CancelToken.source().token): Promise<IChatRoom[]> => {
        try {
            const state = store.getState();
            const response = await chatApi.getRooms(state.auth.accessToken, ct);
            return !Utils.isEmpty(response.data) ? response.data : [];
        }
        catch (ex: any) {
            return [];
        }
    };

    /**
     * Method for creating room
     * @param {ICreateChatRoomRequest} request - request data
     * @param {CancelToken} ct - cancellation token
     * @return {Promise<IChatRoom | null>} room data
     */
    const createRoom = async (request: ICreateChatRoomRequest, ct: CancelToken = axios.CancelToken.source().token): Promise<IChatRoom | null> => {
        try {
            const state = store.getState();
            const response = await chatApi.createRoom(request, state.auth.accessToken, ct);
            return response.data;
        }
        catch (ex: any) {
            return null;
        }
    };

    /**
     * Method for obtaining room by id
     * @param {string} roomId - room ID
     * @param {CancelToken} ct - cancellation token
     * @return {Promise<IChatRoom | null>} room data
     */
    const getRoomById = async (roomId: string, ct: CancelToken = axios.CancelToken.source().token): Promise<IChatRoom | null> => {
        try {
            const state = store.getState();
            const response = await chatApi.getRoomById(roomId, state.auth.accessToken, ct);
            return response.data;
        }
        catch (ex: any) {
            return null;
        }
    };

    /**
     * Method for obtaining room messages
     * @param {string} roomId - room ID
     * @param {CancelToken} ct - cancellation token
     * @return {Promise<IChatMessage[]>} list of room messages
     */
    const getRoomMessages = async (roomId: string, ct: CancelToken = axios.CancelToken.source().token): Promise<IChatMessage[]> => {
        try {
            const state = store.getState();
            const response = await chatApi.getRoomMessages(roomId, state.auth.accessToken, ct);
            return !Utils.isEmpty(response.data) ? response.data : [];
        }
        catch (ex: any) {
            return [];
        }
    };

    /**
     * Method for send message to the room
     * @param {ISendChatMessageRequest} request - request data
     * @param {CancelToken} ct - cancellation token
     * @return {Promise<any>} send result
     */
    const sendMessage = async (request: ISendChatMessageRequest, ct: CancelToken = axios.CancelToken.source().token): Promise<any> => {
        try {
            const state = store.getState();
            const response = await chatApi.sendMessage(request, state.auth.accessToken, ct);
            return response.data;
        }
        catch (ex: any) {
            return null;
        }
    };

    /**
     * Method for connect support user to the room
     * @param {string} roomId - room ID
     * @param {CancelToken} ct - cancellation token
     * @return {Promise<any>} send result
     */
    const connectSupportUser = async (roomId: string, ct: CancelToken = axios.CancelToken.source().token): Promise<any> => {
        try {
            const state = store.getState();
            const response = await chatApi.connectSupportUser(roomId, state.auth.accessToken, ct);
            return response.data;
        }
        catch (ex: any) {
            return null;
        }
    };

    /**
     * Method for disconnect support user from the room
     * @param {string} roomId - room ID
     * @param {CancelToken} ct - cancellation token
     * @return {Promise<any>} response with send result
     */
    const disconnectSupportUser = async (roomId: string, ct: CancelToken = axios.CancelToken.source().token): Promise<any> => {
        try {
            const state = store.getState();
            const response = await chatApi.disconnectSupportUser(roomId, state.auth.accessToken, ct);
            return response.data;
        }
        catch (ex: any) {
            return null;
        }
    };



    /**
     * Method for starting bot session
     * @param {CancelToken} ct - cancellation token
     * @return {Promise<string | null>} session id
     */
    const startBot = async (ct: CancelToken = axios.CancelToken.source().token): Promise<string | null> => {
        try {
            const state = store.getState();
            const response = await chatApi.startBot(state.auth.accessToken, ct);
            return response.data;
        }
        catch (ex: any) {
            return null;
        }
    };

    /**
     * Method for getting bot message
     * @param {string} roomId - room id
     * @param {string} message - message to bot
     * @param {CancelToken} ct - cancellation token
     * @return {Promise<IChatMessage | null>} response with session id
     */
    const getBotMessage = async (roomId: string, message: string, ct: CancelToken = axios.CancelToken.source().token): Promise<IChatMessage | null> => {
        try {
            const state = store.getState();
            const response = await chatApi.getBotMessage(roomId, message, state.auth.accessToken, ct);
            return response.data;
        }
        catch (ex: any) {
            return null;
        }
    };


    return {
        getRooms,
        createRoom,
        getRoomById,
        getRoomMessages,
        sendMessage,
        connectSupportUser,
        disconnectSupportUser,
        startBot,
        getBotMessage
    };
}