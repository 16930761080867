import {AxiosResponse, CancelToken} from "axios";
import {axiosInstance} from "./AxiosInstance";
import {
    BEARER,
    ROUTE_GET_CLIENT_DEPT,
    ROUTE_GET_CLIENT_SALES,
    ROUTE_GET_CLIENT_SALES_STRUCTURE, ROUTE_GET_CUSTOMER_ADDITIONAL_SERVICES, ROUTE_GET_CUSTOMER_AIR_COMPANIES,
    ROUTE_GET_CUSTOMER_FLIGHTS,
    ROUTE_GET_CUSTOMER_TICKET_ORDERS,
    ROUTE_GET_STATEMENT_CLOSE_BALANCE,
    ROUTE_GET_STATEMENT_INCOMES,
    ROUTE_GET_STATEMENT_OPEN_BALANCE,
    ROUTE_GET_STATEMENT_SALES,
} from "../constants/routeConstants/ApiRouteConstants";
import {IStatementSale} from "../interfaces/reportInterfaces/IStatementSale";
import {IStatementIncome} from "../interfaces/reportInterfaces/IStatementIncome";
import {IClientStructureRequest, ICommonReportRequest} from "../interfaces/requestsInterfaces/reportRequests";
import {IStatementOpenBalance} from "../interfaces/reportInterfaces/IStatementOpenBalance";
import {IStatementCloseBalance} from "../interfaces/reportInterfaces/IStatementCloseBalance";
import {IClientSale} from "../interfaces/companyInfoInterfaces/IClientSale";
import {IClientDetail} from "../interfaces/companyInfoInterfaces/IClientDetail";
import {IClientStructure} from "../interfaces/companyInfoInterfaces/IClientStructure";
import {
    ICustomerAdditionalService,
    ICustomerAirCompany,
    ICustomerFlight,
    ICustomerTicketOrder
} from "../interfaces/reportInterfaces/customerInterfaces";


export function ReportsApi(accessToken?: string) {

    /**
     * GET method for obtaining company debt details
     * @param {string} code - company code
     * @param {string} dateStart - date start
     * @param {string} accessToken - access token
     * @param {CancelToken} ct - cancellation token
     * @return {Promise<AxiosResponse<IClientDetail>>} response with a company debt details
     */
    const getClientDept = async (code: string, dateStart: string, accessToken: string, ct: CancelToken): Promise<AxiosResponse<IClientDetail>> => {
        return await axiosInstance.get<IClientDetail>(
            process.env.REACT_APP_GATEWAY_ROUTE + ROUTE_GET_CLIENT_DEPT,
            {
                headers: {
                    Authorization: BEARER + accessToken,
                    Auth: 123
                },
                params: {
                    code: code,
                    dateStart: dateStart
                },
                cancelToken: ct
            }
        );
    };

    /**
     * GET method for obtaining company sales details
     * @param {string} code - company code
     * @param {string} accessToken - access token
     * @param {CancelToken} ct - cancellation token
     * @return {Promise<AxiosResponse<IClientSale[]>>} response with a company sales details
     */
    const getClientSales = async (code: string, accessToken: string, ct: CancelToken): Promise<AxiosResponse<IClientSale[]>> => {
        return await axiosInstance.get<IClientSale[]>(
            process.env.REACT_APP_GATEWAY_ROUTE + ROUTE_GET_CLIENT_SALES,
            {
                headers: {
                    Authorization: BEARER + accessToken,
                    Auth: 123
                },
                params: {
                    clientCode: code,
                },
                cancelToken: ct
            }
        );
    };

    /**
     * GET method for obtaining company sales structure details
     * @param {IClientStructureRequest} request - request data
     * @param {string} accessToken - access token
     * @param {CancelToken} ct - cancellation token
     * @return {Promise<AxiosResponse<IClientStructure[]>>} response with a company sales structure details
     */
    const getClientSalesStructure = async (request: IClientStructureRequest, accessToken: string, ct: CancelToken): Promise<AxiosResponse<IClientStructure[]>> => {
        return await axiosInstance.get<IClientStructure[]>(
            process.env.REACT_APP_GATEWAY_ROUTE + ROUTE_GET_CLIENT_SALES_STRUCTURE,
            {
                headers: {
                    Authorization: BEARER + accessToken,
                    Auth: 123
                },
                params: {
                    clientCode: request.clientCode,
                    status: request.status,
                    monthNum: request.monthNum,
                    quarter: request.quarter,
                    year: request.year,
                    dateStart: request.dateStart,
                    dateEnd: request.dateEnd
                },
                cancelToken: ct
            }
        )
    }

    /**
     * GET method for obtaining statement sales details
     * @param {string} request - request data
     * @param {string} accessToken - access token
     * @param {CancelToken} ct - cancellation token
     * @return {Promise<AxiosResponse<IStatementSale[]>>} response with a statement sales details
     */
    const getStatementSales = async (request: ICommonReportRequest, accessToken: string, ct: CancelToken): Promise<AxiosResponse<IStatementSale[]>> => {
        return await axiosInstance.get<IStatementSale[]>(
            process.env.REACT_APP_GATEWAY_ROUTE + ROUTE_GET_STATEMENT_SALES,
            {
                headers: {
                    Authorization: BEARER + accessToken,
                    Auth: 123
                },
                params: {
                    clientCode: request.clientCode,
                    dateStart: request.startDate,
                    dateEnd: request.endDate,
                    gkCode: request.gkCode,
                },
                cancelToken: ct
            }
        );
    };

    /**
     * GET method for obtaining statement incomes details
     * @param {string} request - request data
     * @param {string} accessToken - access token
     * @param {CancelToken} ct - cancellation token
     * @return {Promise<AxiosResponse<IStatementIncome[]>>} response with a statement incomes details
     */
    const getStatementIncomes = async (request: ICommonReportRequest, accessToken: string, ct: CancelToken): Promise<AxiosResponse<IStatementIncome[]>> => {
        return await axiosInstance.get<IStatementIncome[]>(
            process.env.REACT_APP_GATEWAY_ROUTE + ROUTE_GET_STATEMENT_INCOMES,
            {
                headers: {
                    Authorization: BEARER + accessToken,
                    Auth: 123
                },
                params: {
                    clientCode: request.clientCode,
                    dateStart: request.startDate,
                    dateEnd: request.endDate,
                    gkCode: request.gkCode,
                },
                cancelToken: ct
            }
        );
    };

    /**
     * GET method for obtaining statement open balance
     * @param {string} request - request data
     * @param {string} accessToken - access token
     * @param {CancelToken} ct - cancellation token
     * @return {Promise<AxiosResponse<IStatementOpenBalance>>} response with a statement open balance
     */
    const getStatementOpenBalance = async (request: ICommonReportRequest, accessToken: string, ct: CancelToken): Promise<AxiosResponse<IStatementOpenBalance>> => {
        return await axiosInstance.get<IStatementOpenBalance>(
            process.env.REACT_APP_GATEWAY_ROUTE + ROUTE_GET_STATEMENT_OPEN_BALANCE,
            {
                headers: {
                    Authorization: BEARER + accessToken,
                    Auth: 123
                },
                params: {
                    clientCode: request.clientCode,
                    dateStart: request.startDate,
                    dateEnd: request.endDate,
                    gkCode: request.gkCode,
                },
                cancelToken: ct
            }
        );
    };

    /**
     * GET method for obtaining statement close balance
     * @param {string} request - request data
     * @param {string} accessToken - access token
     * @param {CancelToken} ct - cancellation token
     * @return {Promise<AxiosResponse<IStatementCloseBalance>>} response with a statement close balance
     */
    const getStatementCloseBalance = async (request: ICommonReportRequest, accessToken: string, ct: CancelToken): Promise<AxiosResponse<IStatementCloseBalance>> => {
        return await axiosInstance.get<IStatementCloseBalance>(
            process.env.REACT_APP_GATEWAY_ROUTE + ROUTE_GET_STATEMENT_CLOSE_BALANCE,
            {
                headers: {
                    Authorization: BEARER + accessToken,
                    Auth: 123
                },
                params: {
                    clientCode: request.clientCode,
                    dateStart: request.startDate,
                    dateEnd: request.endDate,
                    gkCode: request.gkCode,
                },
                cancelToken: ct
            }
        );
    };

    /**
     * GET method for obtaining customer flights details
     * @param {string} request - request data
     * @param {string} accessToken - access token
     * @param {CancelToken} ct - cancellation token
     * @return {Promise<AxiosResponse<ICustomerFlight[]>>} response with customer flights details
     */
    const getCustomerFlights = async (request: ICommonReportRequest, accessToken: string, ct: CancelToken): Promise<AxiosResponse<ICustomerFlight[]>> => {
        return await axiosInstance.get<ICustomerFlight[]>(
            process.env.REACT_APP_GATEWAY_ROUTE + ROUTE_GET_CUSTOMER_FLIGHTS,
            {
                headers: {
                    Authorization: BEARER + accessToken,
                    Auth: 123
                },
                params: {
                    dateStart: request.startDate,
                    dateEnd: request.endDate,
                    gkCode: request.gkCode,
                    clientCode: request.clientCode,
                },
                cancelToken: ct
            }
        );
    };

    /**
     * GET method for obtaining customer ticket orders details
     * @param {string} request - request data
     * @param {string} accessToken - access token
     * @param {CancelToken} ct - cancellation token
     * @return {Promise<AxiosResponse<ICustomerTicketOrder[]>>} response with customer ticket orders details
     */
    const getCustomerTicketOrders = async (request: ICommonReportRequest, accessToken: string, ct: CancelToken): Promise<AxiosResponse<ICustomerTicketOrder[]>> => {
        return await axiosInstance.get<ICustomerTicketOrder[]>(
            process.env.REACT_APP_GATEWAY_ROUTE + ROUTE_GET_CUSTOMER_TICKET_ORDERS,
            {
                headers: {
                    Authorization: BEARER + accessToken,
                    Auth: 123
                },
                params: {
                    dateStart: request.startDate,
                    dateEnd: request.endDate,
                    gkCode: request.gkCode,
                    clientCode: request.clientCode,
                },
                cancelToken: ct
            }
        );
    };

    /**
     * GET method for obtaining customer air companies
     * @param {string} request - request data
     * @param {string} accessToken - access token
     * @param {CancelToken} ct - cancellation token
     * @return {Promise<AxiosResponse<ICustomerAirCompany[]>>} response with customer ticket orders details
     */
    const getCustomerAirCompanies = async (request: ICommonReportRequest, accessToken: string, ct: CancelToken): Promise<AxiosResponse<ICustomerAirCompany[]>> => {
        return await axiosInstance.get<ICustomerAirCompany[]>(
            process.env.REACT_APP_GATEWAY_ROUTE + ROUTE_GET_CUSTOMER_AIR_COMPANIES,
            {
                headers: {
                    Authorization: BEARER + accessToken,
                    Auth: 123
                },
                params: {
                    dateStart: request.startDate,
                    dateEnd: request.endDate,
                    gkCode: request.gkCode,
                    clientCode: request.clientCode,
                },
                cancelToken: ct
            }
        );
    };

    /**
     * GET method for obtaining customer additional services
     * @param {string} request - request data
     * @param {string} accessToken - access token
     * @param {CancelToken} ct - cancellation token
     * @return {Promise<AxiosResponse<ICustomerAdditionalService[]>>} response with customer additional services
     */
    const getCustomerAdditionalServices = async (request: ICommonReportRequest, accessToken: string, ct: CancelToken): Promise<AxiosResponse<ICustomerAdditionalService[]>> => {
        return await axiosInstance.get<ICustomerAdditionalService[]>(
            process.env.REACT_APP_GATEWAY_ROUTE + ROUTE_GET_CUSTOMER_ADDITIONAL_SERVICES,
            {
                headers: {
                    Authorization: BEARER + accessToken,
                    Auth: 123
                },
                params: {
                    dateStart: request.startDate,
                    dateEnd: request.endDate,
                    gkCode: request.gkCode,
                    clientCode: request.clientCode,
                },
                cancelToken: ct
            }
        );
    };


    return {
        getClientDept,
        getClientSales,
        getClientSalesStructure,
        getStatementSales,
        getStatementIncomes,
        getStatementOpenBalance,
        getStatementCloseBalance,
        getCustomerFlights,
        getCustomerTicketOrders,
        getCustomerAirCompanies,
        getCustomerAdditionalServices
    };
}