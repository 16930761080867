import React from "react";
import {useTranslation} from "react-i18next";
import {IUserDTO} from "../../../../interfaces/IUserDTO";
import {MdButton} from "../../../../components/md-button";
import {MdTable} from "../../../../components/md-table";
import {MdCheckbox} from "../../../../components/md-checkbox";
import {CheckMarkIcon} from "../../../../icons/CheckMarkIcon";
import {CrossIcon} from "../../../../icons/CrossIcon";
import {CircleSpinnerIcon} from "../../../../icons/CircleSpinnerIcon";
import {SearchIcon2} from "../../../../icons/SearchIcon2";
import "./style.scss";
import "../../../../scss/tables.scss";


interface IUserListComponent {
    users: IUserDTO[];
    reloadUsers?: () => void;
    usersPending: boolean,
    isUsersNotFound: boolean,
    selectedUsers?: IUserDTO[];
    setSelectedUsers?: React.Dispatch<React.SetStateAction<IUserDTO[]>>;
    onItemClick?: (user: IUserDTO) => void;
    openUserDetailsModal: (user?: IUserDTO) => void;
}


export function UserListComponent(props: IUserListComponent) {

    // get the passed properties and assign default values
    const {
        users,
        reloadUsers,
        usersPending,
        isUsersNotFound,
        selectedUsers,
        setSelectedUsers,
        onItemClick,
        openUserDetailsModal
    } = Object.assign({
        reloadUsers: () => {},
        selectedUsers: [],
        setSelectedUsers: () => {},
        onItemClick: () => {},
    }, props);


    const {t} = useTranslation();



    const selectSingle = (user: IUserDTO) => {
        setSelectedUsers([...selectedUsers, user]);
    };

    const unselectSingle = (user: IUserDTO) => {
        setSelectedUsers((prev) =>
            prev.filter((x) => x.id !== user.id)
        );
    };

    const selectAll = () => {
        setSelectedUsers([...users]);
    };

    const unselectAll = () => {
        setSelectedUsers([]);
    };


    return (
        <div className="users-results">
            <div className="users-results-header">
                <div className="users-results-caption">
                    {`${t("usersPage.users")}`}
                    <span style={{display: (usersPending ? "none" : "block")}}>{users.length}</span>
                    <span className="users-results-preloader"
                          style={{display: (usersPending ? "flex" : "none")}}>
                            <div className="spinner-border users-results-preloader-icon"></div>
                            <span className="users-results-preloader-text">
                                {`${t("usersPage.searching")}`}
                            </span>
                        </span>
                </div>
                <div className="users-results-tools">
                    <MdButton
                        style={{
                            "button": {
                                borderWidth: 0,
                                backgroundColor: "rgb(233 233 233)",
                                color: "rgb(0 0 0)",
                                borderRadius: "6px",
                                height: "35px",
                                padding: "5px 15px"
                            },
                            "icon": {
                                marginRight: "5px",
                            },
                            "ink": {
                                backgroundColor: "rgba(255,255,255,.5)"
                            }
                        }}
                        disabled={usersPending}
                        onClick={() => openUserDetailsModal()}
                    >
                        {`${t("usersPage.create")}`}
                    </MdButton>
                </div>
            </div>

            {
                (!usersPending && !isUsersNotFound && users.length > 0) && (
                    <MdTable
                        columns={[
                            {
                                width: 45,
                                headerTemplate() {
                                    return (
                                        <MdCheckbox
                                            type="filled"
                                            checked={(selectedUsers.length == users.length && users.length > 0)}
                                            style={{
                                                "input": {
                                                    width: "16px",
                                                    height: "16px",
                                                },
                                            }}
                                            onChecked={selectAll}
                                            onUnchecked={unselectAll}
                                        />
                                    )
                                },
                                cellTemplate(data: IUserDTO) {
                                    return (
                                        <MdCheckbox
                                            type="filled"
                                            checked={
                                                selectedUsers.some((x: IUserDTO) => x.id === data.id)
                                            }
                                            style={{
                                                "input": {
                                                    width: "16px",
                                                    height: "16px",
                                                },
                                            }}
                                            onChanged={(checked: boolean) => {
                                                if (checked) {
                                                    selectSingle(data);
                                                } else {
                                                    unselectSingle(data);
                                                }
                                            }}
                                        />
                                    )
                                }
                            },
                            {
                                header: t("usersPage.fullName"),
                                cellTemplate(data: IUserDTO) {
                                    return <div>{[data.lastName, data.firstName, data.patronymic].join(' ')}</div>
                                }
                            },
                            {
                                width: 150,
                                header: t("usersPage.gender"),
                                cellTemplate(data: IUserDTO) {
                                    return <div>{(data.gender ? "Мужчина" : "Женщина")}</div>
                                }
                            },
                            {
                                width: 100,
                                header: t("usersPage.dateOfBirth"),
                                cellTemplate(data: IUserDTO) {
                                    return <div>{data.dateOfBurn.substring(0, 10)}</div>
                                }
                            },
                            {
                                width: 240,
                                header: t("usersPage.email"),
                                cellTemplate(data: IUserDTO) {
                                    return <div>{data.email}</div>
                                }
                            },
                            {
                                width: 100,
                                header: t("usersPage.isActive"),
                                cellTemplate(data: IUserDTO) {
                                    return <div>
                                        {
                                            (data.isActive)
                                                ? (
                                                    <div className="doc-state-indicator --success">
                                                        <div style={{display: "flex", gap: "5px"}}>
                                                            <span>
                                                                <CheckMarkIcon
                                                                    width="10px"
                                                                    height="10px"
                                                                    style={{
                                                                        "path": {
                                                                            stroke: "#74B100"
                                                                        }
                                                                    }}
                                                                />
                                                            </span></div>
                                                    </div>
                                                )
                                                : (
                                                    <div className="doc-state-indicator">
                                                        <div style={{display: "flex", gap: "5px"}}>
                                                            <span>
                                                                <CrossIcon
                                                                    width="10px"
                                                                    height="10px"
                                                                    style={{
                                                                        "path": {
                                                                            stroke: "#999999"
                                                                        }
                                                                    }}
                                                                />
                                                            </span></div>
                                                    </div>
                                                )
                                        }
                                    </div>
                                }
                            }
                        ]}
                        itemSource={users}
                        onPreviewRowSelect={(user: IUserDTO) => {
                            onItemClick(user);
                            return false;
                        }}
                        style={{
                            "tbody": {
                                maxHeight: "calc(100vh - 400px)"
                            }
                        }}
                    />
                )
            }

            {(isUsersNotFound) && (
                <div className="no-orders-message" style={{margin: "40px 0"}}>
                    <div className="no-orders-message-icon">
                        <SearchIcon2
                            width="50px"
                            height="50px"
                            style={{
                                "path": {
                                    fill: "rgb(100 100 100)"
                                }
                            }}
                        />
                    </div>
                    <div className="no-orders-message-caption">
                        {t("usersPage.noUsersToDisplay")}
                    </div>
                    <div className="no-orders-message-content">
                        {t("usersPage.createUserOrSearchAgain")}
                    </div>
                    <div className="no-orders-message-button">
                        {
                            !usersPending
                                ? (
                                    <MdButton
                                        style={{
                                            "button": {
                                                borderWidth: 0,
                                                backgroundColor: "rgb(233 233 233)",
                                                color: "rgb(0 0 0)",
                                                borderRadius: "6px",
                                                minWidth: "92px",
                                                height: "35px",
                                                padding: "5px 15px"
                                            },
                                            "ink": {
                                                backgroundColor: "rgba(255,255,255,.5)"
                                            }
                                        }}
                                        onClick={() => reloadUsers()}
                                    >
                                        {t("usersPage.retry")}
                                    </MdButton>
                                )
                                : (
                                    <CircleSpinnerIcon
                                        width="18px"
                                        height="18px"
                                        style={{
                                            "circle": {
                                                stroke: "rgb(50 50 50)"
                                            }
                                        }}
                                    />
                                )
                        }
                    </div>
                </div>
            )}

        </div>
    );
}
