import {TripElementType} from "../../enums/TripElementType";


export interface IPaxBookParams {
    paxCode: string;
    selectedPassportNo: string;
}

export interface IBookParams {
    fareBasises: string[];
    paxes: IPaxBookParams[];
    searchResponse:any;
}



export enum ProviderTypes {
    Unknown = 0,
    Flight = 1,
    Train = 2,
    Hotel = 3
}


export interface IBookTripsModel {
    mode: ProviderTypes;
    trips: Array<{
        id: string;
        paxes: Array<{
            paxCode: string;
            selectedPassportNo: string;
        }>,
        tripElements: Array<{
            tripElementId: string;
            fareBasises: string[];
            searchResponse: any;
        }>
    }>
}

export interface IOrderBookRequest {
    orderId: string;
    orderNo?: number;
    clientCode: string;
    flightBookModel: IBookTripsModel;
    trainBookModel: IBookTripsModel;
}



export interface IOrderIssueRequest {
    orderId: string;
    orderNo?: number;
    clientCode: string;
    trips: Array<{
        tripId: string;
        toIssueElements: Array<{
            tripElementId: string;
            type: TripElementType;
            flightPnrs: string[];   // TODO: maybe use common property pnrs???
            issueFlights: boolean;  // temporary
            trainPnrs: string[];    // TODO: maybe use common property pnrs???
            issueTrain: boolean;    // temporary
        }>
    }>
}



export interface IBookFlightRequestNew {
    userId: string;
    supportUserCode: string;
    clientCode: string;
    orderId?: string;
    trips: Array<{
        parameters: Array<{
            fareBasises: string[];
            paxes: Array<{
                paxCode: string;
                selectedPassportNo: string;
            }>,
            searchResponse: any;
        }>
    }>
}