import React, {CSSProperties, useEffect, useState} from "react";
import './style.scss'

export interface IMdAccordionCallbacks {
    toggleItem?: (itemIndex: number, state: boolean) => void;
}

interface IMdAccordionProps {
    mode?: "multi-open" | "single-open";
    sections: Array<IMdAccordionSectionProps>;
    style?: { [selector: string]: CSSProperties }
    callbacks?: IMdAccordionCallbacks;
}

export interface IMdAccordionSectionCallbacks {
    toggleItem?: (state: boolean) => void;
}

interface IMdAccordionSectionProps {
    header?: string | React.ReactNode;
    content?: string | React.ReactNode;
    isOpen?: boolean;
    style?: React.CSSProperties;
    callbacks?: IMdAccordionSectionCallbacks;
}


export function MdAccordion({
    sections = [],
    style = {},
    callbacks = {}
}: IMdAccordionProps) {


    useEffect(() => { }, []);


    return (
        <div className={"md-accordion"}
             style={style["root"]}>
            {
                sections.map((section, sectionIndex) => {
                    return (
                        <React.Fragment key={sectionIndex}>
                            <MdAccordionSection
                                header={section.header}
                                isOpen={section.isOpen}
                                content={section.content}
                                callbacks={{
                                    toggleItem: (state) => {
                                        if(callbacks?.toggleItem != null) {
                                            callbacks?.toggleItem(sectionIndex, state);
                                        }
                                    }
                                }}
                            />
                        </React.Fragment>
                    )
                })
            }
        </div>
    );
}


export function MdAccordionSection({
    header = null,
    content = null,
    isOpen = false,
    style = {},
    callbacks = {}
}: IMdAccordionSectionProps) {

    const [opened, setOpened] = useState(isOpen);

    const toggle = () => {
        setOpened(prev => !prev);
        if(callbacks?.toggleItem != null) {
            callbacks.toggleItem(opened);
        }
    }


    useEffect(() => {
    }, []);


    return (
            <div className="md-accordion-section">
                <div className="md-accordion-section-header">
                    <button className="md-accordion-section-header-button"
                        onClick={() => toggle()}
                    >
                        {header}
                    </button>
                </div>
                <div className={("md-accordion-section-content" + (opened ? " --open" : ""))}>
                    {content}
                </div>
            </div>
        )
}