import React, { useEffect, useRef, useState} from "react";
import {useTranslation} from "react-i18next";
import {useAppSelector} from "../../../../../redux/hooks";
import axios, {CancelTokenSource} from "axios";
import {ICompanyDTO} from "../../../../../interfaces/ICompanyDTO";
import {IOrderDTO} from "../../../../../interfaces/IOrderDTO";
import {ITrainVariant} from "../../../../../interfaces/ITrainVariant";
import {TimeOfDay} from "../../../../../enums/TimeOfDay";
import {DateService} from "../../../../../services/DateService";
import {RefsService} from "../../../../../services/RefsService";
import {TripService} from "../../../../../services/TripService";
import {TrainListComponent} from "./trainListComponent";
import {MdMenu} from "../../../../../components/md-menu";
import {MdToggleButton} from "../../../../../components/md-toggle-button";
import {MdSelect} from "../../../../../components/md-select";
import {MdButton} from "../../../../../components/md-button";
import {SwitchIcon} from "../../../../../icons/SwitchIcon";
import {MinusIcon} from "../../../../../icons/MinusIcon";
import {PlusIcon} from "../../../../../icons/PlusIcon";
import {SunriseIcon} from "../../../../../icons/SunriseIcon";
import {SunIcon} from "../../../../../icons/SunIcon";
import {MoonIcon} from "../../../../../icons/MoonIcon";
import {Guid, Utils} from "../../../../../utils/utils";
import "./style.scss";
import {FlightClass} from "../searchFlightsComponent";
import {AeroExpressTariff} from "../searchAeroExpressComponent";


export enum TripType {
    OneWay,
    TwoWay
}

export enum TrainClass {
    None,
    Economy,
    Business,
    FirstClass,
    Bistro
}

export enum Railcar {
    None,
    Seat,
    Couchette,
    Coupe,
    Sleeping
}

// export enum Railcar {
//     None      = 0,
//     Seat      = 1 << 0, // 0001
//     Couchette = 1 << 1, // 0010
//     Coupe     = 1 << 2, // 0100
//     Sleeping  = 1 << 3, // 1000
//     All = Seat | Couchette | Coupe | Sleeping
// }

export enum CoupeType {
    None,
    Man,
    Woman,
    Mixed
}

// export enum CoupeType {
//     None    = 0,
//     Man     = 1 << 0, // 0001
//     Woman   = 1 << 1, // 0010
//     Family  = 1 << 2, // 0100
//     Mixed   = 1 << 3, // 1000
//     All = Man | Woman | Family | Mixed
// }

export enum SeatType {
    None,
    Bottom,
    Top
}

// export enum SeatType {
//     None         = 0,          // 0
//     Bottom       = 1 << 0,     // 0001
//     SideBottom   = 1 << 1,     // 0010
//     Top          = 1 << 2,     // 0100
//     SideTop      = 1 << 3,     // 1000
//     ByTheWindow  = 1 << 4, // 10000
//     All = Bottom | SideBottom | Top | SideTop | ByTheWindow
// }

// TODO: rename to TrainAutoCompleteItem
interface IAutoCompleteItem {
    name: string;
    country: string;
    type: string;
    esr: string;
    express?: string;
}

export interface ITrainRoute {
    id: string;
    tripType: TripType;
    passengers: number;
    class?: TrainClass;
    railcar?: Railcar;
    coupe?: CoupeType;
    seat?: SeatType;
    //railcar: Railcar;
    //coupe: CoupeType;
    //seat: SeatType;
    placeType: {
        atTheTable?: boolean;
        atTheWindow?: boolean;
        alongTheWay?: boolean;
        byThePassage?: boolean;
        isSolitary?: boolean;
        isEven?: boolean;
    };
    isSideSeat?: boolean;
    origin: IAutoCompleteItem;
    direction: IAutoCompleteItem;
    departDate?: string;
    departTimeOfDay?: TimeOfDay;
    departTime?: string;
    returnDate?: string;
    returnTimeOfDay?: TimeOfDay;
    returnTime?: string;
    notes: string;
}


interface ISearchTrainsComponent {
    order?: IOrderDTO;
    selectedCompany: ICompanyDTO | undefined;
    selectVariantHandler: (variant: ITrainVariant, tariffIndex: number, searchResponse: any) => Promise<void>;
}


export function SearchTrainsComponent(props: ISearchTrainsComponent) {

    const {
        order,
        selectedCompany,
        selectVariantHandler
    } = props;

    const {t} = useTranslation();

    const authState = useAppSelector((state) => state.auth);

    const refsService = RefsService();

    const tripService = TripService();

    const dateService = DateService();


    const minPassengersCount = 1;
    const maxPassengersCount = 99;

    const [searchRoute, setSearchRoute] =
        useState<ITrainRoute>(
            {
                id: Guid.newGuid(),
                tripType: TripType.OneWay,
                passengers: minPassengersCount,
                class: undefined,
                railcar: undefined,
                coupe: undefined,
                seat: undefined,
                //railcar: Railcar.None,
                //coupe: CoupeType.None,
                //seat: SeatType.None,
                placeType: { },
                isSideSeat: undefined,
                origin: {
                    name: "",
                    country: "",
                    esr: "",
                    type: "",
                },
                direction: {
                    name: "",
                    country: "",
                    esr: "",
                    type: "",
                },
                departTimeOfDay: TimeOfDay.None,
                returnTimeOfDay: TimeOfDay.None,
                notes: ""
            }
        );

    const [originFocused, setOriginFocused] = useState<boolean>(false);
    const [directionFocused, setDirectionFocused] = useState<boolean>(false);
    const [autoComplete, setAutoComplete] = useState<IAutoCompleteItem[]>([]);
    const [departDateFocused, setDepartDateFocused] = useState<boolean>(false);
    const [returnDateFocused, setReturnDateFocused] = useState<boolean>(false);

    const [providersPending, setProvidersPending] = useState<boolean>(false);
    const [isProvidersNotFound, setIsProvidersNotFound] = useState<boolean>(false);
    const [providers, setProviders] = useState<string[]>([]);
    const [selectedProviders, setSelectedProviders] = useState<string[]>([]);

    const [searchCT, setSearchCT] = useState<CancelTokenSource|undefined>(undefined);
    const [searchPending, setSearchPending] = useState(false);
    const [searchNotFound, setSearchNotFound] = useState(false);
    const [variants, setVariants] = useState<ITrainVariant[]>([]);

    const [searchResponse, setSearchResponse] = useState<any>(null);



    const days = [
        t("bookingPage.sundayShort"),
        t("bookingPage.mondayShort"),
        t("bookingPage.tuesdayShort"),
        t("bookingPage.wednesdayShort"),
        t("bookingPage.thursdayShort"),
        t("bookingPage.fridayShort"),
        t("bookingPage.saturdayShort"),
    ];

    const months = [
        t("bookingPage.januaryShort"),
        t("bookingPage.februaryShort"),
        t("bookingPage.marchShort"),
        t("bookingPage.aprilShort"),
        t("bookingPage.mayShort"),
        t("bookingPage.juneShort"),
        t("bookingPage.julyShort"),
        t("bookingPage.augustShort"),
        t("bookingPage.septemberShort"),
        t("bookingPage.octoberShort"),
        t("bookingPage.novemberShort"),
        t("bookingPage.decemberShort"),
    ];

    const [tripTypeOptions] = useState<Map<TripType, { name: string }>>(
        new Map([
            [TripType.OneWay,  { name: t("bookingPage.oneWayTrip") }],
            //[TripType.TwoWay,  { name: t("bookingPage.roundTrip") }]
        ])
    );

    const [trainClassOptions] = useState<Map<TrainClass, { name: string }>>(
        new Map([
            [TrainClass.Economy,     { name: t("bookingPage.economyClass") }],
            [TrainClass.Business,    { name: t("bookingPage.businessClass")  }],
            [TrainClass.FirstClass,  { name: t("bookingPage.firstClass")  }],
            [TrainClass.Bistro,      { name: t("bookingPage.bistroClass")  }]
        ])
    );

    const [railcarOptions] = useState<Map<Railcar, { name: string }>>(
        new Map([
            [Railcar.Seat,       { name: t("bookingPage.seatCarriage") }],
            [Railcar.Couchette,  { name: t("bookingPage.couchetteCarriage")  }],
            [Railcar.Coupe,      { name: t("bookingPage.coupeCarriage")  }],
            [Railcar.Sleeping,   { name: t("bookingPage.sleepingCarriage")  }]
        ])
    );

    const [coupeTypeOptions] = useState<Map<CoupeType, { name: string }>>(
        new Map([
            [CoupeType.Man,     { name: t("bookingPage.menCoupe") }],
            [CoupeType.Woman,   { name: t("bookingPage.womenCoupe")  }],
            //[CoupeType.Family,  { name: t("bookingPage.familyCoupe")  }],
            [CoupeType.Mixed,   { name: t("bookingPage.mixedCoupe")  }]
        ])
    );

    const [seatTypeOptions] = useState<Map<SeatType, { name: string }>>(
        new Map([
            //[SeatType.ByTheWindow,  { name: t("bookingPage.byTheWindowSeat") }],
            [SeatType.Bottom,       { name: t("bookingPage.bottomSeat")  }],
            [SeatType.Top,          { name: t("bookingPage.topSeat")  }],
            //[SeatType.SideBottom,   { name: t("bookingPage.sideBottomSeat")  }],
            //[SeatType.SideTop,      { name: t("bookingPage.sideTopSeat")  }]
        ])
    );

    const [timeOfDayOptions] = useState<Map<TimeOfDay, { name: string, interval: number[] }>>(
        new Map([
            [TimeOfDay.None,      { name: t("bookingPage.anyTime"),         interval: [] }],
            [TimeOfDay.Exact,     { name: t("bookingPage.approximateTime"), interval: [] }],
            [TimeOfDay.Morning,   { name: t("bookingPage.morning"),         interval: [4, 12] }],
            [TimeOfDay.Afternoon, { name: t("bookingPage.afternoon"),       interval: [10, 16] }],
            [TimeOfDay.Evening,   { name: t("bookingPage.evening"),         interval: [16, 23] }]
        ])
    );



    const setTripType = (tripType: TripType) => {
        setSearchRoute(route =>
            ({
                ...route,
                tripType: tripType,
                returnDate: (tripType == TripType.TwoWay ? route.returnDate: undefined),
                returnTime: (tripType == TripType.TwoWay ? route.returnTime: undefined),
            })
        );
    }

    const setPassengers = (passengers: number) => {
        setSearchRoute(route =>
            ({
                ...route,
                passengers: passengers
            })
        );
    }

    const setClass = (trainClass?: TrainClass) => {
        setSearchRoute(route =>
            ({
                ...route,
                class: trainClass
            })
        );
    }

    const setRailcar = (railcar?: Railcar) => {
        setSearchRoute(route =>
            ({
                ...route,
                railcar: railcar
            })
        );
        // if((searchRoute.railcar & Railcar.Seat) !== Railcar.Seat) {
        //     setSeat(searchRoute.seat &= ~SeatType.ByTheWindow);
        // }
        // if((searchRoute.railcar & Railcar.Couchette) !== Railcar.Couchette) {
        //     setSeat(searchRoute.seat &= ~SeatType.SideBottom);
        //     setSeat(searchRoute.seat &= ~SeatType.SideTop);
        // }
        // if((searchRoute.railcar & Railcar.Couchette) !== Railcar.Couchette && (searchRoute.railcar & Railcar.Coupe) !== Railcar.Coupe) {
        //     setSeat(searchRoute.seat &= ~SeatType.Bottom);
        //     setSeat(searchRoute.seat &= ~SeatType.Top);
        // }
        // if((searchRoute.railcar & Railcar.Coupe) !== Railcar.Coupe && (searchRoute.railcar & Railcar.Sleeping) !== Railcar.Sleeping) {
        //     setCoupe(CoupeType.None);
        // }
    }

    const setCoupe = (coupe?: CoupeType) => {
        setSearchRoute(route =>
            ({
                ...route,
                coupe: coupe
            })
        );
    }

    const setSeat = (seat?: SeatType) => {
        setSearchRoute(route =>
            ({
                ...route,
                seat: seat
            })
        );
    }

    const setAtTheTable = (atTheTable?: boolean) => {
        setSearchRoute(route =>
            ({
                ...route,
                placeType: {
                    ...route.placeType,
                    atTheTable: atTheTable
                }
            })
        );
    }

    const setAtTheWindow = (atTheWindow?: boolean) => {
        setSearchRoute(route =>
            ({
                ...route,
                placeType: {
                    ...route.placeType,
                    atTheWindow: atTheWindow
                }
            })
        );
    }

    const setAlongTheWay = (alongTheWay?: boolean) => {
        setSearchRoute(route =>
            ({
                ...route,
                placeType: {
                    ...route.placeType,
                    alongTheWay: alongTheWay
                }
            })
        );
    }

    const setByThePassage = (byThePassage?: boolean) => {
        setSearchRoute(route =>
            ({
                ...route,
                placeType: {
                    ...route.placeType,
                    byThePassage: byThePassage
                }
            })
        );
    }

    const setIsSolitary = (isSolitary?: boolean) => {
        setSearchRoute(route =>
            ({
                ...route,
                placeType: {
                    ...route.placeType,
                    isSolitary: isSolitary
                }
            })
        );
    }

    const setIsEven = (isEven?: boolean) => {
        setSearchRoute(route =>
            ({
                ...route,
                placeType: {
                    ...route.placeType,
                    isEven: isEven
                }
            })
        );
    }

    const setIsSideSeat = (isSideSeat?: boolean) => {
        setSearchRoute(route =>
            ({
                ...route,
                isSideSeat: isSideSeat
            })
        );
    }

    const setOrigin = (origin: IAutoCompleteItem) => {
        setSearchRoute(route =>
            ({
                ...route,
                origin: origin
            })
        );
    }

    const switchCities = () => {
        setSearchRoute(route =>
            ({
                ...route,
                origin: route.direction,
                direction: route.origin
            })
        );
    }

    const setDirection = (direction: IAutoCompleteItem) => {
        setSearchRoute(route =>
            ({
                ...route,
                direction: direction
            })
        );
    }

    const setDepartDate = (date: string | undefined) => {
        setSearchRoute(route =>
            ({
                ...route,
                departDate: date,
                returnDate: (route.returnDate != undefined && date != undefined && new Date(route.returnDate) < new Date(date) ? undefined : route.returnDate)
            })
        );
    }

    const setDepartTimeOfDay = (timeOfDay: TimeOfDay | undefined) => {
        setSearchRoute(route =>
            ({
                ...route,
                departTimeOfDay: timeOfDay,
                departTime: timeOfDay == TimeOfDay.Exact && route.departTime == undefined ? "00:00" : route.departTime
            })
        );
    }

    const setDepartTime = (time: string | undefined) => {
        setSearchRoute(route =>
            ({
                ...route,
                departTime: time
            })
        );
    }

    const setReturnDate = (date: string | undefined) => {
        setSearchRoute(route =>
            ({
                ...route,
                returnDate: date
            })
        );
    }

    const setReturnTimeOfDay = (timeOfDay: TimeOfDay | undefined) => {
        setSearchRoute(route =>
            ({
                ...route,
                returnTimeOfDay: timeOfDay,
                returnTime: timeOfDay == TimeOfDay.Exact && route.returnTime == undefined ? "00:00" : route.returnTime
            })
        );
    }

    const setReturnTime = (time: string | undefined) => {
        setSearchRoute(route =>
            ({
                ...route,
                returnTime: time
            })
        );
    }


    const originFocusHandler = async () => {
        setOriginFocused(true);
        setAutoComplete([]);
        const originText = searchRoute.origin.name;
        if(Utils.isEmpty(originText)) {
            return;
        }
        const autoCompleteItems = await refsService.getTrainCities(originText, "ru");
        setAutoComplete(autoCompleteItems);
    }
    const originBlurHandler = async () => {
        setOriginFocused(false);
    }
    const originTextChangeHandler = async (text: string) => {
        if(Utils.isEmpty(text)) {
            setAutoComplete([]);
            return;
        }
        const autoCompleteItems = await refsService.getTrainCities(text, "ru");
        setAutoComplete(autoCompleteItems);
    }

    const directionFocusHandler = async () => {
        setDirectionFocused(true);
        setAutoComplete([]);
        const directionText = searchRoute.direction.name;
        if(Utils.isEmpty(directionText)) {
            return;
        }
        const autoCompleteItems = await refsService.getTrainCities(directionText, "ru");
        setAutoComplete(autoCompleteItems);
    }
    const directionBlurHandler = async () => {
        setDirectionFocused(false);
    }
    const directionTextChangeHandler = async (text: string) => {
        if(Utils.isEmpty(text)) {
            setAutoComplete([]);
            return;
        }
        const autoCompleteItems = await refsService.getTrainCities(text, "ru");
        setAutoComplete(autoCompleteItems);
    }

    const departDateFocusHandler = async () => {
        setDepartDateFocused(true);
    }
    const departDateBlurHandler = async () => {
        setDepartDateFocused(false);
    }

    const returnDateFocusHandler = async () => {
        setReturnDateFocused(true);
    }
    const returnDateBlurHandler = async () => {
        setReturnDateFocused(false);
    }


    const getProviders = async () => {
        setProvidersPending(true);
        setIsProvidersNotFound(false);
        setProviders([]);
        const response = await tripService.getTrainProviders(selectedCompany?.code ?? "");
        const providers = response ?? [];
        if (providers.length === 0) {
            setIsProvidersNotFound(true);
        }
        setProviders(providers);
        setProvidersPending(false);
    };


    const startSearch = async () => {
        setSearchPending(true);
        setSearchNotFound(false);
        setVariants([]);

        if (searchCT) {
            searchCT.cancel();
        }

        let cts = axios.CancelToken.source();
        setSearchCT(cts);

        const response = await tripService.getTrains(
            (
                searchRoute.tripType == TripType.TwoWay
                    ?
                        [
                            Object.assign({}, ({
                                ...searchRoute,
                            })),
                            Object.assign({}, ({
                                ...searchRoute,
                                origin: searchRoute.direction,
                                direction: searchRoute.origin,
                                departDate: searchRoute.returnDate,
                                departTimeOfDay: searchRoute.returnTimeOfDay,
                                departTime: searchRoute.returnTime
                            }))
                        ]
                    :
                        [
                            Object.assign({}, ({
                                ...searchRoute,
                            }))
                        ]
            ),
            selectedProviders,
            selectedCompany?.code ?? "",
            cts.token
        );

        setSearchCT(undefined);

        setSearchResponse(response);

        const variants = response?.variants ?? [];

        if (variants.length === 0) {
            setSearchNotFound(true);
        }

        setVariants(variants);

        setSearchPending(false);
    };

    const cancelSearchTrains = () => {
        if (searchCT) {
            searchCT.cancel();
        }
    }


    // const onKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    //     if (event.repeat) return;
    //     if (event.key === "Enter") {
    //         startSearch();
    //     }
    // }


    const selectVariant = async (variant: ITrainVariant, tariffIndex: number) => {
        await selectVariantHandler(variant, tariffIndex, searchResponse);
    }


    const checkDates = () => {
        const today = new Date();
        today.setHours(0,0,0,0);

        let departDateRange: number[] | undefined = undefined;
        if(searchRoute.departDate != undefined) {
            switch (searchRoute.departTimeOfDay) {
                case TimeOfDay.None:
                    departDateRange = [
                        new Date(searchRoute.departDate + "T00:00:00").getTime(),
                        new Date(searchRoute.departDate + "T23:59:59").getTime()
                    ];
                    break;
                case TimeOfDay.Exact:
                    departDateRange = [new Date(searchRoute.departDate + "T" + searchRoute.departTime).getTime()];
                    break;
                case TimeOfDay.Morning:
                    departDateRange = [
                        new Date(searchRoute.departDate + "T04:00:00").getTime(),
                        new Date(searchRoute.departDate + "T12:00:00").getTime()
                    ];
                    break;
                case TimeOfDay.Afternoon:
                    departDateRange = [
                        new Date(searchRoute.departDate + "T10:00:00").getTime(),
                        new Date(searchRoute.departDate + "T16:00:00").getTime()
                    ];
                    break;
                case TimeOfDay.Evening:
                    departDateRange = [
                        new Date(searchRoute.departDate + "T16:00:00").getTime(),
                        new Date(searchRoute.departDate + "T23:00:00").getTime(),
                    ];
                    break;
            }
        }

        let returnDateRange: number[] | undefined = undefined;
        if(searchRoute.returnDate != undefined) {
            switch (searchRoute.returnTimeOfDay) {
                case TimeOfDay.None:
                    returnDateRange = [
                        new Date(searchRoute.returnDate + "T00:00:00").getTime(),
                        new Date(searchRoute.returnDate + "T23:59:59").getTime()
                    ];
                    break;
                case TimeOfDay.Exact:
                    returnDateRange = [new Date(searchRoute.returnDate + "T" + searchRoute.returnTime).getTime()];
                    break;
                case TimeOfDay.Morning:
                    returnDateRange = [
                        new Date(searchRoute.returnDate + "T04:00:00").getTime(),
                        new Date(searchRoute.returnDate + "T12:00:00").getTime()
                    ];
                    break;
                case TimeOfDay.Afternoon:
                    returnDateRange = [
                        new Date(searchRoute.returnDate + "T10:00:00").getTime(),
                        new Date(searchRoute.returnDate + "T16:00:00").getTime()
                    ];
                    break;
                case TimeOfDay.Evening:
                    returnDateRange = [
                        new Date(searchRoute.returnDate + "T16:00:00").getTime(),
                        new Date(searchRoute.returnDate + "T23:00:00").getTime(),
                    ];
                    break;
            }
        }

        let hasError = false;

        if(searchRoute.departDate != undefined) {
            if(new Date(searchRoute.departDate) < today) {
                hasError = true;
            }
            if(departDateRange != undefined && returnDateRange != undefined) {
                if(returnDateRange.every(r => departDateRange?.every(d => r <= d))) {
                    hasError = true;
                }
            }
        }

        return hasError;
    }


    useEffect(() => {
        getProviders();
    }, [selectedCompany]);


    return (
        <React.Fragment>
            <div className="trip-page-search-form">

                <div className="trip-page-search-form-header">

                    <MdMenu
                        behaviour={'none'}
                        header={(
                            <div className="filter-menu-header">
                                {
                                    tripTypeOptions.get(searchRoute.tripType)?.name
                                }
                            </div>
                        )}
                        disabled={searchPending}
                    >
                        <div className="filter-menu-content">
                            {
                                Array.from(tripTypeOptions.entries()).map((entry, entryIndex) => {
                                    const [ variant, variantDetails ] = entry;
                                    return (
                                        <React.Fragment key={entryIndex}>
                                            <MdToggleButton
                                                buttonStyle={{
                                                    flex: 1,
                                                    minInlineSize: "fit-content",
                                                    display: "flex",
                                                    alignItems: "center",
                                                    padding: "5px 10px",
                                                    borderRadius: "4px",
                                                    minWidth: "max-content",
                                                    minHeight: "24px",
                                                    maxHeight: "52px",
                                                    fontSize: "12px",
                                                    color: ((searchRoute.tripType === variant) ? "rgb(62, 92, 184)" : "#C1C1C1"),
                                                    border: ((searchRoute.tripType === variant) ? "1px solid rgb(62, 92, 184)" : "1px solid #CCCCCC"),
                                                }}
                                                inkStyle={{
                                                    backgroundColor: ((searchRoute.tripType === variant) ? "rgb(62, 92, 184)" : "#C1C1C1"),
                                                    opacity: 0.1
                                                }}
                                                checked={(searchRoute.tripType === variant)}
                                                onClick={() => {
                                                    setTripType(variant);
                                                }}
                                            >
                                                {
                                                    variantDetails.name
                                                }
                                            </MdToggleButton>
                                        </React.Fragment>
                                    )
                                })
                            }
                        </div>
                    </MdMenu>

                    <MdMenu
                        behaviour={'none'}
                        header={(
                            <div className="filter-menu-header">
                                {t("bookingPage.passengers")}: {searchRoute.passengers.toString().padStart(2, "0")}
                            </div>
                        )}
                        disabled={searchPending}
                    >
                        <div className="passengers-input-control">
                            <MdButton
                                icon={
                                    <MinusIcon
                                        width="12px"
                                        height="12px"
                                        style={{
                                            "path": {
                                                stroke: "#878686"
                                            }
                                        }}
                                    />
                                }
                                style={{
                                    "button": {
                                        alignItems: "center",
                                        justifyContent: "center",
                                        borderWidth: 0,
                                        backgroundColor: "#FFFFFF",
                                        color: "rgb(0 0 0)",
                                        borderRadius: "6px",
                                        boxShadow: "0px 3px 1px -2px rgba(0, 0, 0, 0.16), 0px 1px 2px 0px rgba(0, 0, 0, 0.1), 0px 1px 5px 0px rgba(0, 0, 0, 0.08)",
                                        marginRight: "0px",
                                        width: "22px",
                                        height: "22px",
                                        padding: 0
                                    },
                                    "ink": {
                                        backgroundColor: "rgba(230, 230, 230, 0.5)"
                                    },
                                }}
                                onClick={() => {
                                    if (searchRoute.passengers > minPassengersCount) {
                                        setPassengers(searchRoute.passengers - 1);
                                    }
                                }}
                            />

                            <input
                                type="number"
                                min={minPassengersCount}
                                max={maxPassengersCount}
                                value={searchRoute.passengers}
                                readOnly={true}
                            />

                            <MdButton
                                icon={
                                    <PlusIcon
                                        width="12px"
                                        height="12px"
                                        style={{
                                            "path": {
                                                stroke: "#878686"
                                            }
                                        }}
                                    />
                                }
                                style={{
                                    "button": {
                                        alignItems: "center",
                                        justifyContent: "center",
                                        borderWidth: 0,
                                        backgroundColor: "#FFFFFF",
                                        color: "rgb(0 0 0)",
                                        borderRadius: "6px",
                                        boxShadow: "0px 3px 1px -2px rgba(0, 0, 0, 0.16), 0px 1px 2px 0px rgba(0, 0, 0, 0.1), 0px 1px 5px 0px rgba(0, 0, 0, 0.08)",
                                        marginRight: "0px",
                                        width: "22px",
                                        height: "22px",
                                        padding: 0
                                    },
                                    "ink": {
                                        backgroundColor: "rgba(230, 230, 230, 0.5)"
                                    },
                                }}
                                onClick={() => {
                                    if (searchRoute.passengers < maxPassengersCount) {
                                        setPassengers(searchRoute.passengers + 1);
                                    }
                                }}
                            />
                        </div>
                    </MdMenu>

                    <MdMenu
                        behaviour={'none'}
                        header={(
                            <div className="filter-menu-header">
                                {
                                    railcarOptions.get(searchRoute.railcar ?? Railcar.None)?.name ?? t("bookingPage.anyCarriage")
                                }
                            </div>
                        )}
                        disabled={searchPending}
                    >
                        <div className="filter-menu-content">
                            {
                                Array.from(railcarOptions.entries()).map((entry, entryIndex) => {
                                    const [ variant, variantDetails ] = entry;
                                    return (
                                        <React.Fragment key={entryIndex}>

                                            <MdToggleButton
                                                buttonStyle={{
                                                    flex: 1,
                                                    minInlineSize: "fit-content",
                                                    display: "flex",
                                                    alignItems: "center",
                                                    padding: "5px 10px",
                                                    borderRadius: "4px",
                                                    minWidth: "max-content",
                                                    minHeight: "24px",
                                                    maxHeight: "52px",
                                                    fontSize: "12px",
                                                    color: ((searchRoute.railcar == variant) ? "rgb(62, 92, 184)" : "#C1C1C1"),
                                                    border: ((searchRoute.railcar == variant) ? "1px solid rgb(62, 92, 184)" : "1px solid #CCCCCC"),
                                                }}
                                                inkStyle={{
                                                    backgroundColor: ((searchRoute.railcar == variant) ? "rgb(62, 92, 184)" : "#C1C1C1"),
                                                    opacity: 0.1
                                                }}
                                                checked={(searchRoute.railcar == variant)}
                                                onClick={() => {
                                                    if ((searchRoute.railcar != variant)) {
                                                        setRailcar(variant);
                                                    }
                                                    else {
                                                        setRailcar(undefined);
                                                    }
                                                }}
                                            >
                                                {
                                                    variantDetails.name
                                                }
                                            </MdToggleButton>
                                        </React.Fragment>
                                    )
                                })
                            }
                        </div>
                    </MdMenu>

                    {
                        (searchRoute.railcar == undefined || searchRoute.railcar == Railcar.None || searchRoute.railcar == Railcar.Seat) && (
                            <MdMenu
                                behaviour={'none'}
                                header={(
                                    <div className="filter-menu-header">
                                        {
                                            trainClassOptions.get(searchRoute.class ?? TrainClass.None)?.name ?? t("bookingPage.anyClass")
                                        }
                                    </div>
                                )}
                                disabled={searchPending}
                            >
                                <div className="filter-menu-content">
                                    {
                                        Array.from(trainClassOptions.entries()).map((entry, entryIndex) => {
                                            const [ variant, variantDetails ] = entry;
                                            return (
                                                <React.Fragment key={entryIndex}>
                                                    <MdToggleButton
                                                        buttonStyle={{
                                                            flex: 1,
                                                            minInlineSize: "fit-content",
                                                            display: "flex",
                                                            alignItems: "center",
                                                            padding: "5px 10px",
                                                            borderRadius: "4px",
                                                            minWidth: "max-content",
                                                            minHeight: "24px",
                                                            maxHeight: "52px",
                                                            fontSize: "12px",
                                                            color: (searchRoute.class == variant ? "rgb(62, 92, 184)" : "#C1C1C1"),
                                                            border: (searchRoute.class == variant ? "1px solid rgb(62, 92, 184)" : "1px solid #CCCCCC"),
                                                        }}
                                                        inkStyle={{
                                                            backgroundColor: (searchRoute.class == variant ? "rgb(62, 92, 184)" : "#C1C1C1"),
                                                            opacity: 0.1
                                                        }}
                                                        checked={(searchRoute.class == variant)}
                                                        onClick={() => {
                                                            if ((searchRoute.class != variant)) {
                                                                setClass(variant);
                                                            }
                                                            else {
                                                                setClass(undefined);
                                                            }
                                                        }}
                                                    >
                                                        {
                                                            variantDetails.name
                                                        }
                                                    </MdToggleButton>
                                                </React.Fragment>
                                            )
                                        })
                                    }
                                </div>
                            </MdMenu>
                        )
                    }

                    {/*<MdMenu*/}
                    {/*    behaviour={'none'}*/}
                    {/*    header={(*/}
                    {/*        <div className="filter-menu-header">*/}
                    {/*            {*/}
                    {/*                railcarOptions.get(searchRoute.railcar)?.name ?? t("bookingPage.anyCarriage")*/}
                    {/*            }*/}
                    {/*        </div>*/}
                    {/*    )}*/}
                    {/*    disabled={searchPending}*/}
                    {/*>*/}
                    {/*    <div className="filter-menu-content">*/}
                    {/*        {*/}
                    {/*            Array.from(railcarOptions.entries()).map((entry, entryIndex) => {*/}
                    {/*                const [ variant, variantDetails ] = entry;*/}
                    {/*                return (*/}
                    {/*                    <React.Fragment key={entryIndex}>*/}
                    {/*                        <MdToggleButton*/}
                    {/*                            buttonStyle={{*/}
                    {/*                                flex: 1,*/}
                    {/*                                minInlineSize: "fit-content",*/}
                    {/*                                display: "flex",*/}
                    {/*                                alignItems: "center",*/}
                    {/*                                padding: "5px 10px",*/}
                    {/*                                borderRadius: "4px",*/}
                    {/*                                minWidth: "max-content",*/}
                    {/*                                minHeight: "24px",*/}
                    {/*                                maxHeight: "52px",*/}
                    {/*                                fontSize: "12px",*/}
                    {/*                                color: ((searchRoute.railcar & variant) == variant ? "rgb(62, 92, 184)" : "#C1C1C1"),*/}
                    {/*                                border: ((searchRoute.railcar & variant) == variant ? "1px solid rgb(62, 92, 184)" : "1px solid #CCCCCC"),*/}
                    {/*                            }}*/}
                    {/*                            inkStyle={{*/}
                    {/*                                backgroundColor: ((searchRoute.railcar & variant) == variant ? "rgb(62, 92, 184)" : "#C1C1C1"),*/}
                    {/*                                opacity: 0.1*/}
                    {/*                            }}*/}
                    {/*                            checked={(searchRoute.railcar & variant) == variant}*/}
                    {/*                            onClick={() => {*/}
                    {/*                                if ((searchRoute.railcar & variant) != variant) {*/}
                    {/*                                    setRailcar(searchRoute.railcar |= variant);*/}
                    {/*                                }*/}
                    {/*                                else {*/}
                    {/*                                    setRailcar(searchRoute.railcar &= ~variant);*/}
                    {/*                                }*/}
                    {/*                            }}*/}
                    {/*                        >*/}
                    {/*                            {*/}
                    {/*                                variantDetails.name*/}
                    {/*                            }*/}
                    {/*                        </MdToggleButton>*/}
                    {/*                    </React.Fragment>*/}
                    {/*                )*/}
                    {/*            })*/}
                    {/*        }*/}
                    {/*    </div>*/}
                    {/*</MdMenu>*/}

                    {
                        (searchRoute.railcar === undefined || searchRoute.railcar === Railcar.None || searchRoute.railcar === Railcar.Coupe) && (
                            <MdMenu
                                behaviour={'none'}
                                header={(
                                    <div className="filter-menu-header">
                                        {
                                            coupeTypeOptions.get(searchRoute.coupe ?? CoupeType.None)?.name ?? t('bookingPage.anyCoupe')
                                        }
                                    </div>
                                )}
                                disabled={searchPending}
                            >
                                <div className="filter-menu-content">
                                    {
                                        Array.from(coupeTypeOptions.entries()).map((entry, entryIndex) => {
                                            const [ variant, variantDetails ] = entry;
                                            return (
                                                <React.Fragment key={entryIndex}>
                                                    <MdToggleButton
                                                        buttonStyle={{
                                                            flex: 1,
                                                            minInlineSize: "fit-content",
                                                            display: "flex",
                                                            alignItems: "center",
                                                            padding: "5px 10px",
                                                            borderRadius: "4px",
                                                            minWidth: "max-content",
                                                            minHeight: "24px",
                                                            maxHeight: "52px",
                                                            fontSize: "12px",
                                                            color: ((searchRoute.coupe === variant) ? "rgb(62, 92, 184)" : "#C1C1C1"),
                                                            border: ((searchRoute.coupe === variant) ? "1px solid rgb(62, 92, 184)" : "1px solid #CCCCCC"),
                                                        }}
                                                        inkStyle={{
                                                            backgroundColor: ((searchRoute.coupe === variant) ? "rgb(62, 92, 184)" : "#C1C1C1"),
                                                            opacity: 0.1
                                                        }}
                                                        checked={(searchRoute.coupe === variant)}
                                                        onClick={() => {
                                                            if ((searchRoute.coupe !== variant)) {
                                                                setCoupe(variant);
                                                            }
                                                            else {
                                                                setCoupe(undefined);
                                                            }
                                                        }}
                                                    >
                                                        {
                                                            variantDetails.name
                                                        }
                                                    </MdToggleButton>
                                                </React.Fragment>
                                            )
                                        })
                                    }
                                </div>
                            </MdMenu>
                        )
                    }

                    {/*{*/}
                    {/*    (searchRoute.railcar === Railcar.None || (searchRoute.railcar & Railcar.Coupe) === Railcar.Coupe || (searchRoute.railcar & Railcar.Sleeping) == Railcar.Sleeping) && (*/}
                    {/*        <MdMenu*/}
                    {/*            behaviour={'none'}*/}
                    {/*            header={(*/}
                    {/*                <div className="filter-menu-header">*/}
                    {/*                    {*/}
                    {/*                        (() => {*/}
                    {/*                            const options = Array.from(coupeTypeOptions.entries());*/}

                    {/*                            const enabledOptions =*/}
                    {/*                                options.reduce((arr: { name: string }[], v) => {*/}
                    {/*                                    const [variant, variantDetails] = v;*/}
                    {/*                                    if(searchRoute.coupe === CoupeType.None || (searchRoute.coupe & variant) === variant) {*/}
                    {/*                                        arr.push(variantDetails)*/}
                    {/*                                    }*/}
                    {/*                                    return arr;*/}
                    {/*                                }, []);*/}

                    {/*                            if(enabledOptions.length == 0 || enabledOptions.length == options.length) {*/}
                    {/*                                return t('bookingPage.anyCoupe');*/}
                    {/*                            }*/}

                    {/*                            return enabledOptions[0].name+ (enabledOptions.length > 1 ? (" + " + (enabledOptions.length - 1)) : "")*/}
                    {/*                        })()*/}
                    {/*                    }*/}
                    {/*                </div>*/}
                    {/*            )}*/}
                    {/*            disabled={searchPending}*/}
                    {/*        >*/}
                    {/*            <div className="filter-menu-content">*/}
                    {/*                {*/}
                    {/*                    Array.from(coupeTypeOptions.entries()).map((entry, entryIndex) => {*/}
                    {/*                        const [ variant, variantDetails ] = entry;*/}
                    {/*                        return (*/}
                    {/*                            <React.Fragment key={entryIndex}>*/}
                    {/*                                <MdToggleButton*/}
                    {/*                                    buttonStyle={{*/}
                    {/*                                        flex: 1,*/}
                    {/*                                        minInlineSize: "fit-content",*/}
                    {/*                                        display: "flex",*/}
                    {/*                                        alignItems: "center",*/}
                    {/*                                        padding: "5px 10px",*/}
                    {/*                                        borderRadius: "4px",*/}
                    {/*                                        minWidth: "max-content",*/}
                    {/*                                        minHeight: "24px",*/}
                    {/*                                        maxHeight: "52px",*/}
                    {/*                                        fontSize: "12px",*/}
                    {/*                                        color: (((searchRoute.coupe & variant) === variant) ? "rgb(62, 92, 184)" : "#C1C1C1"),*/}
                    {/*                                        border: (((searchRoute.coupe & variant) === variant) ? "1px solid rgb(62, 92, 184)" : "1px solid #CCCCCC"),*/}
                    {/*                                    }}*/}
                    {/*                                    inkStyle={{*/}
                    {/*                                        backgroundColor: (((searchRoute.coupe & variant) === variant) ? "rgb(62, 92, 184)" : "#C1C1C1"),*/}
                    {/*                                        opacity: 0.1*/}
                    {/*                                    }}*/}
                    {/*                                    checked={((searchRoute.coupe & variant) === variant)}*/}
                    {/*                                    onClick={() => {*/}
                    {/*                                        if (((searchRoute.coupe & variant) !== variant)) {*/}
                    {/*                                            setCoupe(searchRoute.coupe |= variant);*/}
                    {/*                                        }*/}
                    {/*                                        else {*/}
                    {/*                                            setCoupe(searchRoute.coupe &= ~variant);*/}
                    {/*                                        }*/}
                    {/*                                    }}*/}
                    {/*                                >*/}
                    {/*                                    {*/}
                    {/*                                        variantDetails.name*/}
                    {/*                                    }*/}
                    {/*                                </MdToggleButton>*/}
                    {/*                            </React.Fragment>*/}
                    {/*                        )*/}
                    {/*                    })*/}
                    {/*                }*/}
                    {/*            </div>*/}
                    {/*        </MdMenu>*/}
                    {/*    )*/}
                    {/*}*/}

                    {
                        (searchRoute.railcar === undefined || searchRoute.railcar == Railcar.None || searchRoute.railcar == Railcar.Couchette || searchRoute.railcar == Railcar.Coupe) && (
                            <MdMenu
                                behaviour={'none'}
                                header={(
                                    <div className="filter-menu-header">
                                        {
                                            seatTypeOptions.get(searchRoute.seat ?? SeatType.None)?.name ?? t('bookingPage.anySeat')
                                        }
                                    </div>
                                )}
                                disabled={searchPending}
                            >
                                <div className="filter-menu-content">
                                    {
                                        <React.Fragment>
                                            {
                                                Array.from(seatTypeOptions.entries()).map((entry, entryIndex) => {
                                                    const [ variant, variantDetails ] = entry;
                                                    return (
                                                        <React.Fragment key={entryIndex}>
                                                            <MdToggleButton
                                                                buttonStyle={{
                                                                    flex: 1,
                                                                    minInlineSize: "fit-content",
                                                                    display: "flex",
                                                                    alignItems: "center",
                                                                    padding: "5px 10px",
                                                                    borderRadius: "4px",
                                                                    minWidth: "max-content",
                                                                    minHeight: "24px",
                                                                    maxHeight: "52px",
                                                                    fontSize: "12px",
                                                                    color: ((searchRoute.seat === variant) ? "rgb(62, 92, 184)" : "#C1C1C1"),
                                                                    border: ((searchRoute.seat === variant) ? "1px solid rgb(62, 92, 184)" : "1px solid #CCCCCC"),
                                                                }}
                                                                inkStyle={{
                                                                    backgroundColor: ((searchRoute.seat === variant) ? "rgb(62, 92, 184)" : "#C1C1C1"),
                                                                    opacity: 0.1
                                                                }}
                                                                checked={(searchRoute.seat === variant)}
                                                                onClick={() => {
                                                                    if ((searchRoute.seat !== variant)) {
                                                                        setSeat(variant);
                                                                    }
                                                                    else {
                                                                        setSeat(undefined);
                                                                    }
                                                                }}
                                                            >
                                                                {
                                                                    variantDetails.name
                                                                }
                                                            </MdToggleButton>
                                                        </React.Fragment>
                                                    )
                                                })
                                            }

                                            {
                                                searchRoute.railcar == Railcar.Couchette && (
                                                    <React.Fragment>
                                                        <div className="filter-menu-separator"></div>

                                                        <MdToggleButton
                                                            buttonStyle={{
                                                                flex: 1,
                                                                minInlineSize: "fit-content",
                                                                display: "flex",
                                                                alignItems: "center",
                                                                padding: "5px 10px",
                                                                borderRadius: "4px",
                                                                minWidth: "max-content",
                                                                minHeight: "24px",
                                                                maxHeight: "52px",
                                                                fontSize: "12px",
                                                                color: ((searchRoute.isSideSeat === true) ? "rgb(62, 92, 184)" : "#C1C1C1"),
                                                                border: ((searchRoute.isSideSeat === true) ? "1px solid rgb(62, 92, 184)" : "1px solid #CCCCCC"),
                                                            }}
                                                            inkStyle={{
                                                                backgroundColor: ((searchRoute.isSideSeat === true) ? "rgb(62, 92, 184)" : "#C1C1C1"),
                                                                opacity: 0.1
                                                            }}
                                                            checked={(searchRoute.isSideSeat === true)}
                                                            onClick={() => {
                                                                if ((searchRoute.isSideSeat !== true)) {
                                                                    setIsSideSeat(true);
                                                                } else {
                                                                    setIsSideSeat(undefined);
                                                                }
                                                            }}
                                                        >
                                                            {
                                                                t('bookingPage.sideSeat')
                                                            }
                                                        </MdToggleButton>
                                                    </React.Fragment>
                                                )
                                            }

                                        </React.Fragment>
                                    }
                                </div>
                            </MdMenu>
                        )
                    }

                    {/*{*/}
                    {/*    (searchRoute.railcar == Railcar.None || (searchRoute.railcar & Railcar.Couchette) == Railcar.Couchette || (searchRoute.railcar & Railcar.Coupe) == Railcar.Coupe || (searchRoute.railcar & Railcar.Seat) == Railcar.Seat) && (*/}
                    {/*        <MdMenu*/}
                    {/*            behaviour={'none'}*/}
                    {/*            header={(*/}
                    {/*                <div className="filter-menu-header">*/}
                    {/*                    {*/}
                    {/*                        (() => {*/}
                    {/*                            const options = Array.from(seatTypeOptions.entries()).filter((entry) => {*/}
                    {/*                                const [ variant, variantDetails ] = entry;*/}
                    {/*                                if(variant === SeatType.ByTheWindow && (searchRoute.railcar === Railcar.None || (searchRoute.railcar & Railcar.Seat) === Railcar.Seat)) {*/}
                    {/*                                    return true;*/}
                    {/*                                }*/}
                    {/*                                else if(variant === SeatType.Bottom && (searchRoute.railcar === Railcar.None || (searchRoute.railcar & Railcar.Couchette) === Railcar.Couchette || (searchRoute.railcar & Railcar.Coupe) === Railcar.Coupe)) {*/}
                    {/*                                    return true;*/}
                    {/*                                }*/}
                    {/*                                else if(variant === SeatType.Top && (searchRoute.railcar === Railcar.None || (searchRoute.railcar & Railcar.Couchette) === Railcar.Couchette || (searchRoute.railcar & Railcar.Coupe) === Railcar.Coupe)) {*/}
                    {/*                                    return true;*/}
                    {/*                                }*/}
                    {/*                                else if(variant === SeatType.SideBottom && (searchRoute.railcar === Railcar.None || (searchRoute.railcar & Railcar.Couchette) === Railcar.Couchette)) {*/}
                    {/*                                    return true;*/}
                    {/*                                }*/}
                    {/*                                else if(variant === SeatType.SideTop && (searchRoute.railcar === Railcar.None || (searchRoute.railcar & Railcar.Couchette) === Railcar.Couchette)) {*/}
                    {/*                                    return true;*/}
                    {/*                                }*/}
                    {/*                                return false;*/}
                    {/*                            });*/}

                    {/*                            const enabledOptions =*/}
                    {/*                                options.reduce((arr: { name: string }[], v) => {*/}
                    {/*                                    const [variant, variantDetails] = v;*/}
                    {/*                                    if(searchRoute.seat === SeatType.None || (searchRoute.seat & variant) === variant) {*/}
                    {/*                                        arr.push(variantDetails)*/}
                    {/*                                    }*/}
                    {/*                                    return arr;*/}
                    {/*                                }, []);*/}

                    {/*                            if(enabledOptions.length == 0 || enabledOptions.length == options.length) {*/}
                    {/*                                return t('bookingPage.anyTariff');*/}
                    {/*                            }*/}

                    {/*                            return enabledOptions[0].name+ (enabledOptions.length > 1 ? (" + " + (enabledOptions.length - 1)) : "")*/}
                    {/*                        })()*/}
                    {/*                    }*/}
                    {/*                </div>*/}
                    {/*            )}*/}
                    {/*            disabled={searchPending}*/}
                    {/*        >*/}
                    {/*            <div className="filter-menu-content">*/}
                    {/*                {*/}
                    {/*                    Array.from(seatTypeOptions.entries()).filter(entry => {*/}
                    {/*                        const [ variant, variantDetails ] = entry;*/}
                    {/*                        if(variant === SeatType.ByTheWindow*/}
                    {/*                            && (searchRoute.railcar === Railcar.None*/}
                    {/*                                || (searchRoute.railcar & Railcar.Seat) === Railcar.Seat)) {*/}
                    {/*                            return true;*/}
                    {/*                        }*/}
                    {/*                        else if(variant === SeatType.Bottom*/}
                    {/*                            && (searchRoute.railcar === Railcar.None*/}
                    {/*                                || (searchRoute.railcar & Railcar.Couchette) === Railcar.Couchette*/}
                    {/*                                || (searchRoute.railcar & Railcar.Coupe) === Railcar.Coupe)) {*/}
                    {/*                            return true;*/}
                    {/*                        }*/}
                    {/*                        else if(variant === SeatType.Top*/}
                    {/*                            && (searchRoute.railcar === Railcar.None*/}
                    {/*                                || (searchRoute.railcar & Railcar.Couchette) === Railcar.Couchette*/}
                    {/*                                || (searchRoute.railcar & Railcar.Coupe) === Railcar.Coupe)) {*/}
                    {/*                            return true;*/}
                    {/*                        }*/}
                    {/*                        else if(variant === SeatType.SideBottom*/}
                    {/*                            && (searchRoute.railcar === Railcar.None*/}
                    {/*                                || (searchRoute.railcar & Railcar.Couchette) === Railcar.Couchette)) {*/}
                    {/*                            return true;*/}
                    {/*                        }*/}
                    {/*                        else if(variant === SeatType.SideTop*/}
                    {/*                            && (searchRoute.railcar === Railcar.None*/}
                    {/*                                || (searchRoute.railcar & Railcar.Couchette) === Railcar.Couchette)) {*/}
                    {/*                            return true;*/}
                    {/*                        }*/}
                    {/*                        return false;*/}
                    {/*                    }).map((entry, entryIndex) => {*/}
                    {/*                        const [ variant, variantDetails ] = entry;*/}
                    {/*                        return (*/}
                    {/*                            <React.Fragment key={entryIndex}>*/}
                    {/*                                <MdToggleButton*/}
                    {/*                                    buttonStyle={{*/}
                    {/*                                        flex: 1,*/}
                    {/*                                        minInlineSize: "fit-content",*/}
                    {/*                                        display: "flex",*/}
                    {/*                                        alignItems: "center",*/}
                    {/*                                        padding: "5px 10px",*/}
                    {/*                                        borderRadius: "4px",*/}
                    {/*                                        minWidth: "max-content",*/}
                    {/*                                        minHeight: "24px",*/}
                    {/*                                        maxHeight: "52px",*/}
                    {/*                                        fontSize: "12px",*/}
                    {/*                                        color: (((searchRoute.seat & variant) === variant) ? "rgb(62, 92, 184)" : "#C1C1C1"),*/}
                    {/*                                        border: (((searchRoute.seat & variant) === variant) ? "1px solid rgb(62, 92, 184)" : "1px solid #CCCCCC"),*/}
                    {/*                                    }}*/}
                    {/*                                    inkStyle={{*/}
                    {/*                                        backgroundColor: (((searchRoute.seat & variant) === variant) ? "rgb(62, 92, 184)" : "#C1C1C1"),*/}
                    {/*                                        opacity: 0.1*/}
                    {/*                                    }}*/}
                    {/*                                    checked={((searchRoute.seat & variant) === variant)}*/}
                    {/*                                    onClick={() => {*/}
                    {/*                                        if (((searchRoute.seat & variant) !== variant)) {*/}
                    {/*                                            setSeat(searchRoute.seat |= variant);*/}
                    {/*                                        }*/}
                    {/*                                        else {*/}
                    {/*                                            setSeat(searchRoute.seat &= ~variant);*/}
                    {/*                                        }*/}
                    {/*                                    }}*/}
                    {/*                                >*/}
                    {/*                                    {*/}
                    {/*                                        variantDetails.name*/}
                    {/*                                    }*/}
                    {/*                                </MdToggleButton>*/}
                    {/*                            </React.Fragment>*/}
                    {/*                        )*/}
                    {/*                    })*/}
                    {/*                }*/}
                    {/*            </div>*/}
                    {/*        </MdMenu>*/}
                    {/*    )*/}
                    {/*}*/}

                    {
                        (searchRoute.railcar == undefined || searchRoute.railcar == Railcar.None || searchRoute.railcar == Railcar.Seat) && (
                            <MdMenu
                                behaviour={'none'}
                                header={(
                                    <div className="filter-menu-header">
                                        {
                                            t("bookingPage.additionally")
                                        }
                                    </div>
                                )}
                                disabled={searchPending}
                            >
                                <div className="filter-menu-content">
                                    {
                                        <React.Fragment>

                                            <MdToggleButton
                                                buttonStyle={{
                                                    flex: 1,
                                                    minInlineSize: "fit-content",
                                                    display: "flex",
                                                    alignItems: "center",
                                                    padding: "5px 10px",
                                                    borderRadius: "4px",
                                                    minWidth: "max-content",
                                                    minHeight: "24px",
                                                    maxHeight: "52px",
                                                    fontSize: "12px",
                                                    color: ((searchRoute.placeType.atTheTable == true) ? "rgb(62, 92, 184)" : "#C1C1C1"),
                                                    border: ((searchRoute.placeType.atTheTable == true) ? "1px solid rgb(62, 92, 184)" : "1px solid #CCCCCC"),
                                                }}
                                                inkStyle={{
                                                    backgroundColor: ((searchRoute.placeType.atTheTable == true) ? "rgb(62, 92, 184)" : "#C1C1C1"),
                                                    opacity: 0.1
                                                }}
                                                checked={(searchRoute.placeType.atTheTable == true)}
                                                onClick={() => {
                                                    if ((searchRoute.placeType.atTheTable !== true)) {
                                                        setAtTheTable(true);
                                                    }
                                                    else {
                                                        setAtTheTable(undefined);
                                                    }
                                                }}
                                            >
                                                {
                                                    t("bookingPage.atTheTable")
                                                }
                                            </MdToggleButton>

                                            <MdToggleButton
                                                buttonStyle={{
                                                    flex: 1,
                                                    minInlineSize: "fit-content",
                                                    display: "flex",
                                                    alignItems: "center",
                                                    padding: "5px 10px",
                                                    borderRadius: "4px",
                                                    minWidth: "max-content",
                                                    minHeight: "24px",
                                                    maxHeight: "52px",
                                                    fontSize: "12px",
                                                    color: ((searchRoute.placeType.atTheWindow == true) ? "rgb(62, 92, 184)" : "#C1C1C1"),
                                                    border: ((searchRoute.placeType.atTheWindow == true) ? "1px solid rgb(62, 92, 184)" : "1px solid #CCCCCC"),
                                                }}
                                                inkStyle={{
                                                    backgroundColor: ((searchRoute.placeType.atTheWindow == true) ? "rgb(62, 92, 184)" : "#C1C1C1"),
                                                    opacity: 0.1
                                                }}
                                                checked={(searchRoute.placeType.atTheWindow == true)}
                                                onClick={() => {
                                                    if ((searchRoute.placeType.atTheWindow !== true)) {
                                                        setAtTheWindow(true);
                                                    }
                                                    else {
                                                        setAtTheWindow(undefined);
                                                    }
                                                }}
                                            >
                                                {
                                                    t("bookingPage.atTheWindow")
                                                }
                                            </MdToggleButton>

                                            <MdToggleButton
                                                buttonStyle={{
                                                    flex: 1,
                                                    minInlineSize: "fit-content",
                                                    display: "flex",
                                                    alignItems: "center",
                                                    padding: "5px 10px",
                                                    borderRadius: "4px",
                                                    minWidth: "max-content",
                                                    minHeight: "24px",
                                                    maxHeight: "52px",
                                                    fontSize: "12px",
                                                    color: ((searchRoute.placeType.alongTheWay == true) ? "rgb(62, 92, 184)" : "#C1C1C1"),
                                                    border: ((searchRoute.placeType.alongTheWay == true) ? "1px solid rgb(62, 92, 184)" : "1px solid #CCCCCC"),
                                                }}
                                                inkStyle={{
                                                    backgroundColor: ((searchRoute.placeType.alongTheWay == true) ? "rgb(62, 92, 184)" : "#C1C1C1"),
                                                    opacity: 0.1
                                                }}
                                                checked={(searchRoute.placeType.alongTheWay == true)}
                                                onClick={() => {
                                                    if ((searchRoute.placeType.alongTheWay !== true)) {
                                                        setAlongTheWay(true);
                                                    }
                                                    else {
                                                        setAlongTheWay(undefined);
                                                    }
                                                }}
                                            >
                                                {
                                                    t("bookingPage.alongTheWay")
                                                }
                                            </MdToggleButton>

                                            <MdToggleButton
                                                buttonStyle={{
                                                    flex: 1,
                                                    minInlineSize: "fit-content",
                                                    display: "flex",
                                                    alignItems: "center",
                                                    padding: "5px 10px",
                                                    borderRadius: "4px",
                                                    minWidth: "max-content",
                                                    minHeight: "24px",
                                                    maxHeight: "52px",
                                                    fontSize: "12px",
                                                    color: ((searchRoute.placeType.byThePassage == true) ? "rgb(62, 92, 184)" : "#C1C1C1"),
                                                    border: ((searchRoute.placeType.byThePassage == true) ? "1px solid rgb(62, 92, 184)" : "1px solid #CCCCCC"),
                                                }}
                                                inkStyle={{
                                                    backgroundColor: ((searchRoute.placeType.byThePassage == true) ? "rgb(62, 92, 184)" : "#C1C1C1"),
                                                    opacity: 0.1
                                                }}
                                                checked={(searchRoute.placeType.byThePassage == true)}
                                                onClick={() => {
                                                    if ((searchRoute.placeType.byThePassage !== true)) {
                                                        setByThePassage(true);
                                                    }
                                                    else {
                                                        setByThePassage(undefined);
                                                    }
                                                }}
                                            >
                                                {
                                                    t("bookingPage.byThePassage")
                                                }
                                            </MdToggleButton>

                                            <MdToggleButton
                                                buttonStyle={{
                                                    flex: 1,
                                                    minInlineSize: "fit-content",
                                                    display: "flex",
                                                    alignItems: "center",
                                                    padding: "5px 10px",
                                                    borderRadius: "4px",
                                                    minWidth: "max-content",
                                                    minHeight: "24px",
                                                    maxHeight: "52px",
                                                    fontSize: "12px",
                                                    color: ((searchRoute.placeType.isSolitary == true) ? "rgb(62, 92, 184)" : "#C1C1C1"),
                                                    border: ((searchRoute.placeType.isSolitary == true) ? "1px solid rgb(62, 92, 184)" : "1px solid #CCCCCC"),
                                                }}
                                                inkStyle={{
                                                    backgroundColor: ((searchRoute.placeType.isSolitary == true) ? "rgb(62, 92, 184)" : "#C1C1C1"),
                                                    opacity: 0.1
                                                }}
                                                checked={(searchRoute.placeType.isSolitary == true)}
                                                onClick={() => {
                                                    if ((searchRoute.placeType.isSolitary !== true)) {
                                                        setIsSolitary(true);
                                                    }
                                                    else {
                                                        setIsSolitary(undefined);
                                                    }
                                                }}
                                            >
                                                {
                                                    t("bookingPage.segregate")
                                                }
                                            </MdToggleButton>

                                            <MdToggleButton
                                                buttonStyle={{
                                                    flex: 1,
                                                    minInlineSize: "fit-content",
                                                    display: "flex",
                                                    alignItems: "center",
                                                    padding: "5px 10px",
                                                    borderRadius: "4px",
                                                    minWidth: "max-content",
                                                    minHeight: "24px",
                                                    maxHeight: "52px",
                                                    fontSize: "12px",
                                                    color: ((searchRoute.placeType.isEven == true) ? "rgb(62, 92, 184)" : "#C1C1C1"),
                                                    border: ((searchRoute.placeType.isEven == true) ? "1px solid rgb(62, 92, 184)" : "1px solid #CCCCCC"),
                                                }}
                                                inkStyle={{
                                                    backgroundColor: ((searchRoute.placeType.isEven == true) ? "rgb(62, 92, 184)" : "#C1C1C1"),
                                                    opacity: 0.1
                                                }}
                                                checked={(searchRoute.placeType.isEven == true)}
                                                onClick={() => {
                                                    if ((searchRoute.placeType.isEven !== true)) {
                                                        setIsEven(true);
                                                    }
                                                    else {
                                                        setIsEven(undefined);
                                                    }
                                                }}
                                            >
                                                {
                                                    t("bookingPage.even")
                                                }
                                            </MdToggleButton>

                                        </React.Fragment>
                                    }
                                </div>
                            </MdMenu>
                        )
                    }

                </div>


                <div className="trip-page-search-form-content">

                    <div className={("trip-page-search-form-field" + (searchPending ? " --disabled" : ""))}>
                        <label
                            className={((originFocused || searchRoute.origin.name.length > 0 ? "--displaced" : ""))}>
                            {t("commonText.from")}
                        </label>
                        <MdSelect
                            isEditable={true}
                            text={searchRoute.origin.name}
                            setText={(value) => setOrigin(autoComplete.find(x => x.name == value) ?? {
                                name: value,
                                country: "",
                                type: "",
                                esr: ""
                            })}
                            selectedItem={searchRoute.origin}
                            setSelectedItem={(item) => setOrigin(item)}
                            onFocus={() => originFocusHandler()}
                            onBlur={() => originBlurHandler()}
                            itemSource={autoComplete}
                            displayMemberPath={"name"}
                            selectedItemTemplate={(item: IAutoCompleteItem) => {
                                const details = [(item.type ?? item.name), item.country].filter(x => x != null && x.length > 0);
                                return (
                                    <div className="trip-page-search-form-city-item"
                                         style={{padding: '26px 10px 6px 20px'}}>
                                        <div className="trip-page-search-form-city-item-header">
                                            <div className="trip-page-search-form-city-item-name">
                                                {item.name}
                                            </div>
                                            <div className="trip-page-search-form-city-item-code">
                                                {item.esr}
                                            </div>
                                        </div>
                                        <div className="trip-page-search-form-city-item-details">
                                            {/*{*/}
                                            {/*    item.country_code.length > 0 && (*/}
                                            {/*        <div className="trip-page-search-form-city-item-flag">*/}
                                            {/*            <span className={("flag-icon flag-icon-" + item.country_code.toLowerCase())}></span>*/}
                                            {/*        </div>*/}
                                            {/*    )*/}
                                            {/*}*/}
                                            <div className="trip-page-search-form-city-item-details-text">
                                                {
                                                    (details.length > 0 ? details.join(', ') : "")
                                                }
                                            </div>
                                        </div>
                                    </div>
                                )
                            }}
                            itemTemplate={(item: IAutoCompleteItem) => {
                                const details = [(item.type ?? item.name), item.country].filter(x => x != null && x.length > 0);
                                return (
                                    <div className="trip-page-search-form-city-item">
                                        <div className="trip-page-search-form-city-item-header">
                                            <div className="trip-page-search-form-city-item-name">
                                                {item.name}
                                            </div>
                                            <div className="trip-page-search-form-city-item-code">
                                                {item.esr}
                                            </div>
                                        </div>
                                        <div className="trip-page-search-form-city-item-details">
                                            {/*{*/}
                                            {/*    item.country_code.length > 0 && (*/}
                                            {/*        <div className="trip-page-search-form-city-item-flag">*/}
                                            {/*                <span className={("flag-icon flag-icon-" + item.country_code.toLowerCase())}></span>*/}
                                            {/*        </div>*/}
                                            {/*    )*/}
                                            {/*}*/}
                                            <div className="trip-page-search-form-city-item-details-text">
                                                {
                                                    (details.length > 0 ? details.join(', ') : "")
                                                }
                                            </div>
                                        </div>
                                    </div>
                                )
                            }}
                            onTextChange={(value) => originTextChangeHandler(value)}
                            style={{
                                "root": {
                                    width: "100%",
                                    height: "100%",
                                    margin: 0,
                                    border: "none"
                                },
                                "text-field": {
                                    padding: "7px 10px 6px 20px",
                                    height: "100%",
                                    fontWeight: 500,
                                    fontSize: "13px"
                                },
                                "toggle-button-icon": {
                                    display: "none"
                                }
                            }}
                        />
                    </div>


                    <div className="trip-page-search-form-switch-cities-control">
                        <MdButton
                            style={{
                                "button": {
                                    position: 'absolute',
                                    top: "50%",
                                    left: "50%",
                                    transform: "translate(-50%, -50%) rotate(-90deg)",
                                    borderWidth: 0,
                                    backgroundColor: "transparent",
                                    color: "rgb(0 0 0)",
                                    borderRadius: "6px",
                                    height: "30px",
                                    padding: "5px",
                                    zIndex: 1
                                },
                                "icon": {
                                    marginRight: "5px",
                                },
                                "ink": {
                                    backgroundColor: "rgba(255,255,255,.5)"
                                }
                            }}
                            tabIndex={-1}
                            disabled={searchPending}
                            onClick={() => switchCities()}
                        >
                            <SwitchIcon
                                width="20px"
                                height="20px"
                                style={{
                                    "path": {
                                        stroke: "rgb(100, 100, 100)",
                                        fill: "rgb(100, 100, 100)"
                                    }
                                }}
                            />
                        </MdButton>
                    </div>


                    <div className={("trip-page-search-form-field" + (searchPending ? " --disabled" : ""))}>
                        <label
                            className={((directionFocused || searchRoute.direction.name.length > 0 ? "--displaced" : ""))}>
                            {t("commonText.to")}
                        </label>
                        <MdSelect
                            isEditable={true}
                            text={searchRoute.direction.name}
                            setText={(value) => setDirection(autoComplete.find(x => x.name == value) ?? {
                                name: value,
                                country: "",
                                type: "",
                                esr: ""
                            })}
                            selectedItem={searchRoute.direction}
                            setSelectedItem={(item) => setDirection(item)}
                            onFocus={() => directionFocusHandler()}
                            onBlur={() => directionBlurHandler()}
                            itemSource={autoComplete}
                            displayMemberPath={"name"}
                            selectedItemTemplate={(item: IAutoCompleteItem) => {
                                const details = [(item.type ?? item.name), item.country].filter(x => x != null && x.length > 0);
                                return (
                                    <div className="trip-page-search-form-city-item"
                                         style={{padding: '26px 10px 6px 20px'}}>
                                        <div className="trip-page-search-form-city-item-header">
                                            <div className="trip-page-search-form-city-item-name">
                                                {item.name}
                                            </div>
                                            <div className="trip-page-search-form-city-item-code">
                                                {item.esr}
                                            </div>
                                        </div>
                                        <div className="trip-page-search-form-city-item-details">
                                            {/*{*/}
                                            {/*    item.country_code.length > 0 && (*/}
                                            {/*        <div className="trip-page-search-form-city-item-flag">*/}
                                            {/*                <span*/}
                                            {/*                    className={("flag-icon flag-icon-" + item.country_code.toLowerCase())}></span>*/}
                                            {/*        </div>*/}
                                            {/*    )*/}
                                            {/*}*/}
                                            <div className="trip-page-search-form-city-item-details-text">
                                                {
                                                    (details.length > 0 ? details.join(', ') : "")
                                                }
                                            </div>
                                        </div>
                                    </div>
                                )
                            }}
                            itemTemplate={(item: IAutoCompleteItem) => {
                                const details = [(item.type ?? item.name), item.country].filter(x => x != null && x.length > 0);
                                return (
                                    <div className="trip-page-search-form-city-item">
                                        <div className="trip-page-search-form-city-item-header">
                                            <div className="trip-page-search-form-city-item-name">
                                                {item.name}
                                            </div>
                                            <div className="trip-page-search-form-city-item-code">
                                                {item.esr}
                                            </div>
                                        </div>
                                        <div className="trip-page-search-form-city-item-details">
                                            {/*{*/}
                                            {/*    item.country_code.length > 0 && (*/}
                                            {/*        <div className="trip-page-search-form-city-item-flag">*/}
                                            {/*                <span*/}
                                            {/*                    className={("flag-icon flag-icon-" + item.country_code.toLowerCase())}></span>*/}
                                            {/*        </div>*/}
                                            {/*    )*/}
                                            {/*}*/}
                                            <div className="trip-page-search-form-city-item-details-text">
                                                {
                                                    details.length > 0 ? details.join(', ') : ""
                                                }
                                            </div>
                                        </div>
                                    </div>
                                )
                            }}
                            onTextChange={(value) => directionTextChangeHandler(value)}
                            style={{
                                "root": {
                                    width: "100%",
                                    height: "100%",
                                    margin: 0,
                                    border: "none"
                                },
                                "text-field": {
                                    padding: "7px 10px 6px 20px",
                                    height: "100%",
                                    fontWeight: 500,
                                    fontSize: "13px"
                                },
                                "toggle-button-icon": {
                                    display: "none"
                                }
                            }}
                        />
                    </div>


                    <div
                        className={("trip-page-search-form-field" + (searchPending ? " --disabled" : ""))}
                        style={{
                            marginLeft: "6px",
                            minWidth: "280px",
                            backgroundColor: (checkDates() ? "rgba(255, 0, 0, 0.2)" : "transparent")
                        }}
                    >
                        <label className={(departDateFocused || searchRoute.departDate != null ? "--displaced" : "")}>
                            {t("bookingPage.departDate")}
                        </label>
                        <input
                            className={(departDateFocused ? "--focused" : "")}
                            type="date"
                            min={dateService.dateToStringForDatePicker(new Date())}
                            value={searchRoute.departDate ?? ""}
                            onFocus={() => departDateFocusHandler()}
                            onBlur={() => departDateBlurHandler()}
                            onChange={(e) => setDepartDate(e.target.value)}
                            disabled={searchPending}
                        />
                        {
                            searchRoute.departTimeOfDay == TimeOfDay.Exact && (
                                <input
                                    className={(departDateFocused ? "--focused" : "")}
                                    type="time"
                                    value={searchRoute.departTime ?? ""}
                                    onFocus={() => departDateFocusHandler()}
                                    onBlur={() => departDateBlurHandler()}
                                    onChange={(e) => setDepartTime(e.target.value)}
                                    disabled={searchPending}
                                />
                            )
                        }
                        {
                            searchRoute.departDate != null && (
                                <div className={("trip-page-search-form-datetime-item" + (departDateFocused ? " --hidden" : ""))}>
                                    <div className="trip-page-search-form-datetime-item-date">
                                        {
                                            days[new Date(searchRoute.departDate).getDay()]
                                            + ", " + new Date(searchRoute.departDate).getDate().toString().padStart(2, "0") + " "
                                            + months[new Date(searchRoute.departDate).getMonth()]
                                            + " " + new Date(searchRoute.departDate).getFullYear()
                                        }
                                    </div>
                                    <div className="trip-page-search-form-datetime-item-time">
                                        {
                                            (() => {
                                                switch (searchRoute.departTimeOfDay) {
                                                    case TimeOfDay.Exact:
                                                        return (
                                                            <>
                                                                <span style={{ marginLeft: "16px" }}>
                                                                    {searchRoute.departTime?.toString() ?? "00:00"}
                                                                </span>
                                                            </>
                                                        )
                                                    case TimeOfDay.Morning:
                                                        return (
                                                            <>
                                                                <SunriseIcon
                                                                    width="16px"
                                                                    height="16px"
                                                                />
                                                                <span>
                                                                    { timeOfDayOptions.get(TimeOfDay.Morning)?.interval.map(x => x.toString().padStart(2, "0") + ":00").join(" - ") }
                                                                </span>
                                                            </>
                                                        )
                                                    case TimeOfDay.Afternoon:
                                                        return (
                                                            <>
                                                                <SunIcon
                                                                    width="16px"
                                                                    height="16px"
                                                                />
                                                                <span>
                                                                    { timeOfDayOptions.get(TimeOfDay.Afternoon)?.interval.map(x => x.toString().padStart(2, "0") + ":00").join(" - ") }
                                                                </span>
                                                            </>
                                                        )
                                                    case TimeOfDay.Evening:
                                                        return (
                                                            <>
                                                                <MoonIcon
                                                                    width="16px"
                                                                    height="16px"
                                                                />
                                                                <span>
                                                                    { timeOfDayOptions.get(TimeOfDay.Evening)?.interval.map(x => x.toString().padStart(2, "0") + ":00").join(" - ") }
                                                                </span>
                                                            </>
                                                        )
                                                    case TimeOfDay.None:
                                                    default:
                                                        return "--:--"
                                                }
                                            })()
                                        }
                                    </div>
                                </div>
                            )
                        }

                        <MdMenu
                            behaviour={'none'}
                            header={(
                                <div className="departure-time-type">
                                    {
                                        timeOfDayOptions.get(searchRoute.departTimeOfDay ?? TimeOfDay.None)?.name
                                    }
                                </div>
                            )}
                            style={{
                                "root": {
                                    position: 'absolute',
                                    bottom: "2px",
                                    right: "3px",
                                }
                            }}
                            disabled={searchPending}
                        >
                            <div className="departure-time-type-variants">
                                {
                                    Array.from(timeOfDayOptions.entries()).map((entry, entryIndex) => {
                                        const [ variant, variantDetails ] = entry;
                                        return (
                                            <React.Fragment key={entryIndex}>
                                                <MdToggleButton
                                                    buttonStyle={{
                                                        flex: 1,
                                                        minInlineSize: "fit-content",
                                                        display: "flex",
                                                        alignItems: "center",
                                                        padding: "5px 10px",
                                                        borderRadius: "4px",
                                                        minWidth: "max-content",
                                                        minHeight: "24px",
                                                        maxHeight: "52px",
                                                        fontSize: "12px",
                                                        color: ((searchRoute.departTimeOfDay === variant) ? "rgb(62, 92, 184)" : "#C1C1C1"),
                                                        border: ((searchRoute.departTimeOfDay === variant) ? "1px solid rgb(62, 92, 184)" : "1px solid #CCCCCC"),
                                                    }}
                                                    inkStyle={{
                                                        backgroundColor: ((searchRoute.departTimeOfDay === variant) ? "rgb(62, 92, 184)" : "#C1C1C1"),
                                                        opacity: 0.1
                                                    }}
                                                    checked={(searchRoute.departTimeOfDay === variant)}
                                                    onClick={() => {
                                                        setDepartTimeOfDay(variant);
                                                    }}
                                                >
                                                    {
                                                        variantDetails.name
                                                    }
                                                </MdToggleButton>
                                            </React.Fragment>
                                        )
                                    })
                                }
                            </div>
                        </MdMenu>
                    </div>


                    {
                        (searchRoute.tripType == TripType.TwoWay) && (
                            <div
                                className={("trip-page-search-form-field" + (searchPending ? " --disabled" : ""))}
                                style={{
                                    marginLeft: "6px",
                                    minWidth: "280px",
                                    backgroundColor: (checkDates() ? "rgba(255, 0, 0, 0.2)" : "transparent")
                                }}
                            >
                                <label className={(returnDateFocused || searchRoute.returnDate != null ? "--displaced" : "")}>
                                    {t("bookingPage.returnDate")}
                                </label>
                                <input
                                    className={(returnDateFocused ? "--focused" : "")}
                                    type="date"
                                    min={dateService.dateToStringForDatePicker(new Date())}
                                    value={searchRoute.returnDate ?? ""}
                                    onFocus={() => returnDateFocusHandler()}
                                    onBlur={() => returnDateBlurHandler()}
                                    onChange={(e) => setReturnDate(e.target.value)}
                                    disabled={searchPending}
                                />
                                {
                                    (searchRoute.returnDate != null && searchRoute.returnTimeOfDay == null) && (
                                        <input
                                            className={(returnDateFocused ? "--focused" : "")}
                                            type="time"
                                            value={searchRoute.returnTime ?? ""}
                                            onFocus={() => returnDateFocusHandler()}
                                            onBlur={() => returnDateBlurHandler()}
                                            onChange={(e) => setReturnTime(e.target.value)}
                                            disabled={searchPending}
                                        />
                                    )
                                }
                                {
                                    searchRoute.returnDate != null && (
                                        <div
                                            className={("trip-page-search-form-datetime-item" + (returnDateFocused ? " --hidden" : ""))}>
                                            <div className="trip-page-search-form-datetime-item-date">
                                                {
                                                    days[new Date(searchRoute.returnDate).getDay()]
                                                    + ", " + new Date(searchRoute.returnDate).getDate().toString().padStart(2, "0") + " "
                                                    + months[new Date(searchRoute.returnDate).getMonth()]
                                                    + " " + new Date(searchRoute.returnDate).getFullYear()
                                                }
                                            </div>
                                            <div className="trip-page-search-form-datetime-item-time">
                                                {
                                                    (() => {
                                                        switch (searchRoute.returnTimeOfDay) {
                                                            case TimeOfDay.Exact:
                                                                return (
                                                                    <>
                                                                        <span style={{ marginLeft: "16px" }}>
                                                                            {searchRoute.returnTime?.toString() ?? "00:00"}
                                                                        </span>
                                                                    </>
                                                                )
                                                            case TimeOfDay.Morning:
                                                                return (
                                                                    <>
                                                                        <SunriseIcon
                                                                            width="16px"
                                                                            height="16px"
                                                                        />
                                                                        <span>
                                                                            { timeOfDayOptions.get(TimeOfDay.Morning)?.interval.map(x => x.toString().padStart(2, "0") + ":00").join(" - ") }
                                                                        </span>
                                                                    </>
                                                                )
                                                            case TimeOfDay.Afternoon:
                                                                return (
                                                                    <>
                                                                        <SunIcon
                                                                            width="16px"
                                                                            height="16px"
                                                                        />
                                                                        <span>
                                                                            { timeOfDayOptions.get(TimeOfDay.Afternoon)?.interval.map(x => x.toString().padStart(2, "0") + ":00").join(" - ") }
                                                                        </span>
                                                                    </>
                                                                )
                                                            case TimeOfDay.Evening:
                                                                return (
                                                                    <>
                                                                        <MoonIcon
                                                                            width="16px"
                                                                            height="16px"
                                                                        />
                                                                        <span>
                                                                            { timeOfDayOptions.get(TimeOfDay.Evening)?.interval.map(x => x.toString().padStart(2, "0") + ":00").join(" - ") }
                                                                        </span>
                                                                    </>
                                                                )
                                                            case TimeOfDay.None:
                                                            default:
                                                                return "--:--"
                                                        }
                                                    })()
                                                }
                                            </div>
                                        </div>
                                    )
                                }
                                <MdMenu
                                    behaviour={'none'}
                                    header={(
                                        <div className="return-time-type">
                                            {
                                                timeOfDayOptions.get(searchRoute.returnTimeOfDay ?? TimeOfDay.None)?.name
                                            }
                                        </div>
                                    )}
                                    disabled={searchPending}
                                    style={{
                                        "root": {
                                            position: 'absolute',
                                            bottom: "2px",
                                            right: "3px",
                                        }
                                    }}
                                >
                                    <div className="return-time-type-variants">
                                        {
                                            Array.from(timeOfDayOptions.entries()).map((entry, entryIndex) => {
                                                const [ variant, variantDetails ] = entry;
                                                return (
                                                    <React.Fragment key={entryIndex}>
                                                        <MdToggleButton
                                                            buttonStyle={{
                                                                flex: 1,
                                                                minInlineSize: "fit-content",
                                                                display: "flex",
                                                                alignItems: "center",
                                                                padding: "5px 10px",
                                                                borderRadius: "4px",
                                                                minWidth: "max-content",
                                                                minHeight: "24px",
                                                                maxHeight: "52px",
                                                                fontSize: "12px",
                                                                color: ((searchRoute.returnTimeOfDay === variant) ? "rgb(62, 92, 184)" : "#C1C1C1"),
                                                                border: ((searchRoute.returnTimeOfDay === variant) ? "1px solid rgb(62, 92, 184)" : "1px solid #CCCCCC"),
                                                            }}
                                                            inkStyle={{
                                                                backgroundColor: ((searchRoute.returnTimeOfDay === variant) ? "rgb(62, 92, 184)" : "#C1C1C1"),
                                                                opacity: 0.1
                                                            }}
                                                            checked={(searchRoute.returnTimeOfDay === variant)}
                                                            onClick={() => {
                                                                setReturnTimeOfDay(variant);
                                                            }}
                                                        >
                                                            {
                                                                variantDetails.name
                                                            }
                                                        </MdToggleButton>
                                                    </React.Fragment>
                                                )
                                            })
                                        }
                                    </div>
                                </MdMenu>
                            </div>
                        )
                    }

                </div>


                <MdButton
                    className="search-button"
                    type="button"
                    style={{
                        "button": {
                            position: "absolute",
                            bottom: "-20px",
                            right: "30px",
                            borderWidth: 0,
                            backgroundColor: (searchPending ? "#B83E50" : "#3E5CB8"),
                            color: "#FFFFFF",
                            borderRadius: "8px",
                            minWidth: "160px",
                            height: "40px",
                            padding: "5px 15px",
                            fontWeight: 500,
                            fontSize: "12px",
                            textTransform: "capitalize",
                            alignItems: "center",
                            justifyContent: "center"
                        },
                        "text": {
                            width: "auto",
                            flex: 0,
                            color: "#FFFFFF"
                        },
                        "ink": {
                            backgroundColor: "rgba(255,255,255,.5)"
                        }
                    }}
                    disabled={(
                        searchRoute.origin.name?.length == 0
                        || searchRoute.direction.name?.length == 0
                        || searchRoute.departDate == undefined
                        || searchRoute.tripType == TripType.TwoWay && searchRoute.returnDate == undefined
                        || checkDates()
                    )}
                    onClick={() => {
                        if (!searchPending) {
                            startSearch()
                        } else {
                            cancelSearchTrains();
                        }
                    }}
                >
                    {
                        searchPending
                            ? (
                                t("bookingPage.stop")
                            )
                            : (
                                "Поиск поездов"
                            )
                    }
                </MdButton>

            </div>


            <div className="trip-page-search-tools">
                {
                    authState.isSupportUser && (
                        <div className="trip-page-search-providers">
                            <span className="provider-list-label">{t("bookingPage.providers")}:</span>
                            {
                                providers.map((provider, providerIndex) => {
                                    const isSelected = selectedProviders.includes(provider);
                                    return (
                                        <React.Fragment key={"provider" + Guid.newGuid()}>
                                            <MdToggleButton
                                                buttonStyle={{
                                                    minInlineSize: "fit-content",
                                                    display: "flex",
                                                    alignItems: "center",
                                                    padding: "2px 8px",
                                                    borderRadius: "4px",
                                                    minWidth: "28px",
                                                    minHeight: "24px",
                                                    maxHeight: "52px",
                                                    fontSize: "10px",
                                                    color: (isSelected ? "#74B100" : "#C1C1C1"),
                                                    border: (isSelected ? "1px solid #74B100" : "1px solid #CCCCCC"),
                                                }}
                                                inkStyle={{
                                                    backgroundColor: (isSelected ? "#74B100" : "#C1C1C1"),
                                                    opacity: 0.1
                                                }}
                                                checked={isSelected}
                                                onClick={() => {
                                                    if (isSelected) {
                                                        setSelectedProviders(prev => [...prev.filter(x => x != provider)]);
                                                    } else {
                                                        setSelectedProviders(prev => [...prev, provider]);
                                                    }
                                                }}
                                            >
                                                {
                                                    provider
                                                }
                                            </MdToggleButton>
                                        </React.Fragment>
                                    )
                                })
                            }
                        </div>
                    )
                }
            </div>

            <TrainListComponent
                searchPending={searchPending}
                searchNotFound={searchNotFound}
                variants={variants}
                selectVariantHandler={selectVariant}
            />

        </React.Fragment>
    );
}