import React, {useContext, useEffect, useRef, useState} from "react";
import {useAppDispatch, useAppSelector} from "../../../redux/hooks";
import {useCookies} from "react-cookie";
import {ACCESS_TOKEN} from "../../../constants/FieldsConstants";
import {IGeneratedDocument, IReadyToDownloadDoc} from "../../../interfaces/reportInterfaces/accountantPageInterfaces";
import {ModalContext} from "../../commonComponents/modal/ModalContext";
import {addAvailableEdmBuhDocsThunk, resetReports, setAvailableEdmBuhDocs,} from "../../../redux/reportsSlice";
import {ROUTE_REPORTS_HUB} from "../../../constants/routeConstants/ApiRouteConstants";
import {SignalRService} from "../../../services/SignalRService";
import {DocumentSendComponent} from "./documentSendComponent";
import {DocumentSearchComponent} from "./documentSearchComponent";
import {DocumentTableComponent} from "./documentTableComponent";
import {MdModal} from "../../../components/md-modal";
import "../../../scss/extends.scss";
import {Guid} from "../../../utils/utils";
import {IAccountantDocument} from "../../../interfaces/reportInterfaces/customerInterfaces";
import {CompanySelectionModal} from "../../commonComponents/companySelectionModal";
import {ICompanyDTO} from "../../../interfaces/ICompanyDTO";
import {DocumentsService} from "../../../services/DocumentsService";
import {IDocAttachments} from "../../../interfaces/IDocAttachments";
import {AttachmentFormat} from "../../../enums/AttachmentFormat";
import {AttachmentType} from "../../../enums/AttachmentType";


export function AccountantDocumentsPage() {
    const initialized = useRef(false);

    const reportState = useAppSelector((state) => state.reports);
    const authState = useAppSelector((state) => state.auth);
    const [cookies] = useCookies([ACCESS_TOKEN]);

    const dispatch = useAppDispatch();


    const documentsService = DocumentsService();


    const [signalRService] = useState<SignalRService>(new SignalRService());


    const [selectedDocs, setSelectedDocs] = useState<IAccountantDocument[]>([]);
    const [buchDocs, setBuchDocs] = useState<IAccountantDocument[]>(reportState.customerBuhDocs);
    const [availableDocs, setAvailableDocs] = useState<IReadyToDownloadDoc>();
    const [activeDoc, setActiveDoc] = useState<IAccountantDocument>();
    const [isLoading, setIsLoading] = useState(false);
    const [isSearchResultNotFound, setIsSearchResultNotFound] = useState(false);

    const [downloadingDocs, setDownloadingDocs] = useState<string[]>([]);

    const [sendingDocs, setSendingDocs] = useState(false);
    const [sendDocsError, setSendDocsError] = useState(false);

    const [connectionId, setConnectionId] = useState<string | null>(null);


    const [selectedCompany, setSelectedCompany] = useState<ICompanyDTO | undefined>(reportState.documentFilters?.company ?? undefined);
    const [companySelectionPending, setCompanySelectionPending] = useState(false);
    const [companySelectionError, setCompanySelectionError] = useState(false);

    const companySelectionModal = useRef<{ open:() => void, close:() => void }>();


    const {open, close} = useContext(ModalContext);
    const [sendDocumentsModalId] = useState(Guid.newGuid());


    //handlers
    const searchBuchDocsHandler = async (startDate: string, endDate: string, docNumber: string, direction: string, name: string, clientCode: string) => {
        setSelectedDocs([]);
        setActiveDoc(undefined);
        setIsLoading(true);
        dispatch(resetReports());

        setBuchDocs([]);
        setIsSearchResultNotFound(false);
        const request = {
            clientCode: clientCode,
            dateStart: startDate,
            dateEnd: endDate,
            gkCode: "",
            docNo: docNumber,
            route: direction,
            name: name
        }
        const response = await documentsService.getAccountantDocuments(request);
        if (response !== undefined && response.length > 0) {
            console.log(`AccDocPage ${response.length}`)
            setBuchDocs(response);

            await checkUploads(clientCode, response);
        } else {
            // alert("Docs not found. Try to change search parameters.");
            setIsSearchResultNotFound(true);
        }
        setIsLoading(false);
    };

    const checkUploads = async (clientCode: string, documents: IAccountantDocument[]) => {
        const availableDocs = await documentsService.getDocumentUploads(clientCode, documents.map(x => x.docNo));
        if (availableDocs.length === 0) {
            return (availableDocs);
        }
        setSendingDocs(availableDocs);
        dispatch(setAvailableEdmBuhDocs(availableDocs))
    }

    const addSelectedDocument = (doc: IAccountantDocument) => {
        setIsSearchResultNotFound(false);
        setSelectedDocs([...selectedDocs, doc]);
    };

    const removeSelectedDoc = (doc: IAccountantDocument) => {
        setIsSearchResultNotFound(false);
        setSelectedDocs((oldDocs) =>
            oldDocs.filter((item) => item.docNo !== doc.docNo)
        );
    };

    const selectAllDocuments = () => {
        setIsSearchResultNotFound(false);
        setSelectedDocs([...buchDocs]);
    };

    const unselectAllDocuments = () => {
        setIsSearchResultNotFound(false);
        setSelectedDocs([]);
    };

    const clearSelectedDocs = () => {
        setIsSearchResultNotFound(false);
        setSelectedDocs([]);
    };



    const openDocumentSendModal = () => open(sendDocumentsModalId);

    const closeDocumentSendModal = () => close(sendDocumentsModalId);

    const onSendDocsModalOpenedHandler = async () => {
        setSendDocsError(false);
    };

    const sendBuchDocsHandler = async (
        documents: IDocAttachments,
        recipients: string[],
        format: AttachmentFormat
    ) => {
        setSendDocsError(false);
        setSendingDocs(true);
        setIsSearchResultNotFound(false);
        const request = {
            documents: documents,
            emails: recipients,
            format: format,
            needEdmUpload: true,
            connectionId: "",
        };
        const result = await documentsService.generateDocuments(request);
        if (!result) {
            setSendDocsError(true);
        } else {
            closeDocumentSendModal();
            clearSelectedDocs();
        }
        setSendingDocs(false);
    };

    const onSendDocsModalClosedHandler = async () => {
        setSendDocsError(false);
    };


    /**
     * Function to mark a document as active.
     *
     * @param {ICustomerBuhDocs} doc - document that needs to be marked active
     */
    const setActiveDocumentHandler = (doc: IAccountantDocument) => {
        setActiveDoc(doc);
    };

    /**
     * PDF document generation function.
     *
     * @param {string} docNumber - number of the accounting document for which pdf generation is required
     */
    const generateDocumentsHandler = async (docNumber: string) => {
        // add a document to the list of generated ones (if it is missing)
        if (!downloadingDocs.includes(docNumber)) {
            setDownloadingDocs((prev) => [...prev, docNumber]);
        }
        // execute a request to generate documents
        // TODO: generate trow service
        await documentsService.generateDocuments({
            documents: {[docNumber]: AttachmentType.All},
            emails: [],
            needEdmUpload: true,
            connectionId: connectionId ?? "",
            format: AttachmentFormat.Excel
        });
    };

    /**
     * Function to download a pdf document from a link.
     *
     * @param {string} link - link to download the document
     */
    const downloadPDFDocumentHandler = async (link: string) => {
        // file request via link
        const response = await documentsService.getDocumentFile(link, isValidUrl(link), authState.currentClientCode);
        // create blob from response
        const file = new Blob([response], {type: "application/pdf"});
        // build a URL from the blob
        const fileURL = URL.createObjectURL(file);
        // open the URL in a new window
        const pdfWindow = window.open();
        // set file url as window location
        pdfWindow!.location.href = fileURL;
    };

    /**
     * Link validation function.
     *
     * @param {string} link - a string representing a link
     */
    const isValidUrl = (link: string) => {
        // create an input element
        let input = document.createElement('input');
        // set input type to url
        input.type = 'url';
        // set link string to input value
        input.value = link;
        // execute the built-in validation method and return the result
        return input.checkValidity();
    }


    const openCompanySelectionModal = () => {
        companySelectionModal.current?.open();
    }

    const selectCompanyHandler = (company: ICompanyDTO) => {
        setSelectedCompany(company);
    }


    useEffect(() => {

    }, [selectedDocs, buchDocs, reportState]);


    useEffect(() => {
        if (!initialized.current) {
            initialized.current = true;
            const initSignalR = async (hubUrl: string) => {
                let connection = await signalRService.createConnection({ hubUrl, options: { withCredentials: true } });
                setConnectionId(connection.connectionId);
            };
            initSignalR(process.env.REACT_APP_EDM_BASE_ROUTE + ROUTE_REPORTS_HUB).then(() => {
                signalRService.addEventListener('completeGeneration', function (docNumber: string, data: string) {
                    let docs = JSON.parse(data);
                    let docsArray: IReadyToDownloadDoc[] =
                        [docs].map((d: IGeneratedDocument) => {
                            return {
                                docNumber: d.Tag,
                                docLink: d.Link,
                                docName: d.FileName,
                            }
                        });
                    setDownloadingDocs((prev) => prev.filter(x => x != docNumber));
                    dispatch(addAvailableEdmBuhDocsThunk(docsArray));
                });
            });
        }
    }, []);

    return (
        <>
            <DocumentSearchComponent
                company={selectedCompany}
                setCompany={setSelectedCompany}
                openCompanySelectionModal={openCompanySelectionModal}
                searchHandler={searchBuchDocsHandler}
            />

            <DocumentTableComponent
                //docs={buchDocs}
                selectedDocs={selectedDocs}
                selectDocument={addSelectedDocument}
                unselectDocument={removeSelectedDoc}
                selectAllDocuments={selectAllDocuments}
                unselectAllDocuments={unselectAllDocuments}
                sentDocs={reportState.sentToEdmBuhDocs}
                generateDocumentsHandler={generateDocumentsHandler}
                downloadableDocs={reportState.availableEdmBuhDocs}
                //downloadingDocs={downloadingDocs}
                setActiveDocumentHandler={setActiveDocumentHandler}
                downloadPDFDocumentHandler={downloadPDFDocumentHandler}
                activeDoc={activeDoc}
                isLoading={isLoading}
                isSearchResultNotFound={isSearchResultNotFound}
                openDocumentSendModal={openDocumentSendModal}
            />

            <MdModal
                id={sendDocumentsModalId}
                title="Отправка документов"
                openHandler={onSendDocsModalOpenedHandler}
                closeHandler={onSendDocsModalClosedHandler}>
                <DocumentSendComponent
                    sendHandler={(attachments, recipients, format) => sendBuchDocsHandler(attachments, recipients, format)}
                    documents={selectedDocs}
                    sending={sendingDocs}
                    error={sendDocsError}
                />
            </MdModal>

            <CompanySelectionModal
                ref={companySelectionModal}
                submitHandler={selectCompanyHandler}
            />
        </>
    );
}
