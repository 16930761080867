import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {useAppSelector} from "../../../../../../redux/hooks";
import {IAviaRejsInfo, IAviaTariffInfo, IAviaVariant} from "../../../../../../interfaces/IAviaVariant";
import {FlightTicketComponent} from "./flightTicketComponent";
import {MdButton} from "../../../../../../components/md-button";
import {MdToggleButton} from "../../../../../../components/md-toggle-button";
import {MdSlider} from "../../../../../../components/md-slider";
import {WarningRhombusIcon} from "../../../../../../icons/WarningRhombusIcon";
import {AirplaneIcon} from "../../../../../../icons/AirplaneIcon";
import {Guid, Utils} from "../../../../../../utils/utils";
import "./style.scss";


export interface IFlightComponent {
    variantInfo: { id: string, variant: IAviaVariant, tariff?: IAviaTariffInfo }
    changeTariff?: (variantId: string, tariff: IAviaTariffInfo) => void;
    selectVariant?:  (ticket: IAviaVariant, tariffIndex: number) => Promise<void>;
}


export function FlightComponent({ variantInfo, changeTariff, selectVariant }: IFlightComponent) {
    const {t} = useTranslation();

    const authState = useAppSelector((state) => state.auth);

    const { variant, tariff } = variantInfo;

    const tickets = variant.rejsInfo;
    const tarifs = tickets.map(x => x.tarifs).flat();


    const RefundAndChangeVariants: { [selector: number]: string } = {
        0: "запрещен",
        1: "бесплатно",
        2: "за доп. плату",
        3: "неопределён"
    }

    const BaggageVariants: { [selector: number]: string } = {
        0: "присутствует",
        1: "без багажа",
        2: "неопределён"
    }


    const [isOpened, setIsOpened] = useState(false);
    const [selectedTariff, setSelectedTariff] = useState<IAviaTariffInfo>(variantInfo.tariff ?? tarifs[0]);
    const [selectedTariffIndex, setSelectedTariffIndex] = useState<number>(Math.max(0, tarifs.findIndex(x => x == selectedTariff)));


    const toggleHandler = (event: React.SyntheticEvent<HTMLDetailsElement, Event>) => {
        setIsOpened(prev => !prev);
    }

    const groupDirs = () => {
        const groups: IAviaRejsInfo[][] = [];
        const group: IAviaRejsInfo[] = [];

        tickets.forEach(ticket => {
            if(!ticket.isBackwardTicketHead) {
                group.push(ticket);
            }
            else {
                groups.push(group.slice());
                group.splice(0, group.length);
                group.push(ticket);
            }
        });

        if(group.length > 0) {
            groups.push(group.slice());
            group.splice(0, group.length);
        }

        return groups;
    }

    const groupsOfDirs = groupDirs();


    return (
        <details className="flight-component-2"
            open={isOpened}
            onToggle={toggleHandler}
        >
            <summary className="flight-component-2-header" style={{width: "100%"}}>

                <MdSlider
                    slides={
                        groupsOfDirs.map((segments, directionIndex) => {

                            const airCompanyNames = [...new Set(segments.map(x => x.airCompanyName))];

                            const routeStart = segments[0];
                            const routeStartDate = new Date(routeStart.dateDep.replace('Z', ''));

                            const routeEnd = segments[segments.length - 1];
                            const routeEndDate = new Date(routeEnd.dateArr.replace('Z', ''));

                            return {
                                content: (
                                    <React.Fragment>
                                        <div className="flight-component-2-header-first-line">
                                            <div>
                                                {
                                                    airCompanyNames.map((airCompany, airCompanyIndex) => {
                                                        return (
                                                            <span className="air-company-info" key={airCompanyIndex}>
                                                                <span> {airCompany} </span>
                                                            </span>
                                                        )
                                                    })
                                                }
                                            </div>
                                            <div>
                                                {
                                                    authState.isSupportUser && (
                                                        <div className="tariff-info">
                                                            {
                                                                <MdToggleButton
                                                                    buttonStyle={{
                                                                        minInlineSize: "fit-content",
                                                                        display: "flex",
                                                                        alignItems: "center",
                                                                        padding: "1px 10px 0 10px",
                                                                        borderRadius: "4px",
                                                                        minHeight: "24px",
                                                                        maxHeight: "52px",
                                                                        fontSize: "11px",
                                                                        color: "#777777",
                                                                        border: "1px solid #777777",
                                                                        pointerEvents: "none"
                                                                    }}
                                                                    inkStyle={{
                                                                        backgroundColor: "#777777",
                                                                        opacity: 0.1
                                                                    }}
                                                                    checked={true}
                                                                >
                                                                    {
                                                                        selectedTariff?.note.match("\\w\\:\\w+")
                                                                    }
                                                                </MdToggleButton>
                                                            }
                                                        </div>
                                                    )
                                                }
                                            </div>
                                            <div>
                                                <span className="price-info">
                                                    {
                                                        (selectedTariff.priceWithSborTts).toLocaleString() + " ₽"
                                                    }
                                                </span>
                                            </div>
                                        </div>
                                        <div className="flight-component-2-header-second-line">
                                            <div className="department-info">
                                                <div className="department-airport-code">{routeStart.airCodeDep}</div>
                                                <div
                                                    className="department-airport-city">{routeStart.cityDep.match(/([^()])+|(\(.*\))/g)?.shift()}</div>
                                                <div className="department-time">
                                                    {
                                                        routeStartDate.getHours().toString().padStart(2, "0") + ":" + routeStartDate.getMinutes().toString().padStart(2, "0")
                                                        + ", "
                                                        + routeStartDate.toLocaleString('default', {month: 'short'}).replace(".", "")
                                                        + " "
                                                        + routeStartDate.getDate().toString().padStart(2, "0")
                                                    }
                                                </div>
                                            </div>
                                            <div className="flight-path-info">
                                                <div className="flight-track">
                                                    <hr style={{marginTop: "16px "}}/>
                                                    <div className="flight-track-airplane">
                                                        <AirplaneIcon
                                                            width="16px"
                                                            height="16px"
                                                            style={{
                                                                "path": {
                                                                    fill: "#3E5CB8"
                                                                }
                                                            }}
                                                        />
                                                    </div>
                                                    <hr style={{marginTop: "16px "}}/>
                                                </div>
                                                {
                                                    segments.length > 1
                                                        ? (
                                                            <div className="flight-connections">
                                                                пересадки: {segments.length - 1}
                                                            </div>
                                                        )
                                                        : (
                                                            <div className="flight-time">
                                                                {routeStart.flightNumber}
                                                            </div>
                                                        )
                                                }
                                            </div>
                                            <div className="arrival-info">
                                                <div className="arrival-airport-code">{routeEnd.airCodeArr}</div>
                                                <div
                                                    className="arrival-airport-city">{routeEnd.cityArr.match(/([^()])+|(\(.*\))/g)?.shift()}</div>
                                                <div
                                                    className="arrival-time">
                                                    {
                                                        routeEndDate.getHours().toString().padStart(2, "0") + ":" + routeEndDate.getMinutes().toString().padStart(2, "0")
                                                        + ", "
                                                        + routeEndDate.toLocaleString('default', {month: 'short'}).replace(".", "")
                                                        + " "
                                                        + routeEndDate.getDate().toString().padStart(2, "0")
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </React.Fragment>
                                )
                            }
                        })
                    }
                    style={{
                        "root": {
                            flex: 1,
                            position: "relative",
                            display: "flex",
                            flexDirection: "column"
                        }
                    }}
                    paginationAlignment={'bottom'}
                />

            </summary>

            <div className="flight-component-2-details" style={{backgroundColor: "#fdfdfd"}}>

                <div className="flight-component-2-details-section">
                    <div className="flight-component-2-details-section-block">
                        <div className="flight-info-section">
                            <div className="flight-roadmap" style={{padding: 0}}>
                                {
                                    tickets.map((ticket, ticketIndex) => {
                                        const dateDepartment = new Date(ticket.dateDep.replace('Z', ''));
                                        const townDepartment = ticket.cityDep.replace(/ *\([^)]*\) */g, "");

                                        const dateArrival = new Date(ticket.dateArr.replace('Z', ''));
                                        const townArrival = ticket.cityArr.replace(/ *\([^)]*\) */g, "");

                                        let flightWarning = null;

                                        let messages = [];


                                        if (ticketIndex > 0 && !ticket.isBackwardTicketHead) {
                                            const prevDirection = tickets[ticketIndex - 1];
                                            const prevCity = prevDirection.cityArr.replace(/ *\([^)]*\) */g, "");
                                            const prevDateArrival = new Date(prevDirection.dateArr.replace('Z', ''));

                                            let waitTime = Utils.getTimeDetails(dateDepartment.getTime() - prevDateArrival.getTime());

                                            messages.push("пересадка в аэропорту города " + prevCity + " с ожиданием " + waitTime.hours + "ч " + waitTime.minutes + "м");

                                            // other checks...

                                            flightWarning = (
                                                <div className="flight-roadmap-item">
                                                    <div className="flight-roadmap-validation">
                                                        <div className="flight-roadmap-validation-node">
                                                            <div
                                                                className="flight-roadmap-validation-node-button">
                                                                <WarningRhombusIcon
                                                                    width="18px"
                                                                    height="18px"
                                                                    style={{
                                                                        "path": {
                                                                            fill: "#F66000" //"#838383"//"#F66000"
                                                                        }
                                                                    }}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="flight-roadmap-validation-message">
                                                            <div
                                                                className="flight-roadmap-validation-message-title">
                                                                Предупреждение
                                                            </div>
                                                            <ul
                                                                className="flight-roadmap-validation-message-content">
                                                                {
                                                                    messages.map(message => {
                                                                        return (
                                                                            <li key={"flight-warning-message" + Guid.newGuid()}>{message}</li>
                                                                        )
                                                                    })
                                                                }
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        }

                                        return (
                                            <div key={"flight-roadmap-item" + Guid.newGuid()}>

                                                {
                                                    ticket.isBackwardTicketHead && (
                                                        <div style={{height: "50px"}}>
                                                            {
                                                                // TODO: create gap indication
                                                            }
                                                        </div>
                                                    )
                                                }

                                                {
                                                    flightWarning != null && (
                                                        flightWarning
                                                    )
                                                }

                                                <div className="flight-roadmap-item">
                                                    <div className="flight-roadmap-item-start-node">
                                                        <button
                                                            className={"flight-roadmap-item-start-node-button" + (ticketIndex == 0 || ticket.isBackwardTicketHead ? " --flight-begin" : "")}>

                                                        </button>
                                                        <div className="flight-roadmap-item-start-node-location">
                                                            {townDepartment} ({ticket.airCodeDep})
                                                        </div>
                                                    </div>
                                                    <div className="flight-roadmap-item-content">

                                                        <FlightTicketComponent
                                                            ticket={ticket}
                                                        />

                                                    </div>
                                                    <div className="flight-roadmap-item-end-node">
                                                        <button
                                                            className={"flight-roadmap-item-end-node-button" + (ticketIndex == tickets.length - 1 || (tickets.length > ticketIndex + 1 && tickets[ticketIndex + 1].isBackwardTicketHead) ? " --flight-end" : "")}>

                                                        </button>
                                                        <div className="flight-roadmap-item-end-node-location">
                                                            {townArrival} ({ticket.airCodeArr})
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                    </div>
                    <div className="flight-component-2-details-section-block">
                        <div className="flight-tariff-switcher">
                            {
                                variant.rejsInfo[variant.rejsInfo.length - 1].tarifs.map((tariff, tariffIndex) => {
                                    return (
                                        <React.Fragment key={"tariff" + Guid.newGuid()}>
                                            <MdToggleButton
                                                buttonStyle={{
                                                    minInlineSize: "fit-content",
                                                    display: "flex",
                                                    alignItems: "center",
                                                    padding: "1px 10px 0 10px",
                                                    borderRadius: "4px",
                                                    minHeight: "24px",
                                                    maxHeight: "52px",
                                                    fontSize: "11px",
                                                    color: (selectedTariffIndex == tariffIndex ? "#74B100" : "#C1C1C1"),
                                                    border: (selectedTariffIndex == tariffIndex ? "1px solid #74B100" : "1px solid #CCCCCC"),
                                                }}
                                                inkStyle={{
                                                    backgroundColor: (selectedTariffIndex == tariffIndex ? "#74B100" : "#C1C1C1"),
                                                    opacity: 0.1
                                                }}
                                                checked={selectedTariffIndex == tariffIndex}
                                                onClick={() => {
                                                    setSelectedTariffIndex(tariffIndex);
                                                    setSelectedTariff(tariff);
                                                    if (changeTariff != null) {
                                                        changeTariff(variantInfo.id, tariff);
                                                    }
                                                }}
                                            >
                                                {tariff.name != null && tariff.name.length > 0 ? tariff.name : ("Тариф " + (tariffIndex + 1))}
                                            </MdToggleButton>
                                        </React.Fragment>
                                    )
                                })
                            }
                        </div>

                        <div className="flight-tariff-info">
                            <div className="flight-tariff-info-item">
                                <span>Обмен</span>
                                <span></span>
                                <span>
                                    {
                                        (RefundAndChangeVariants[Number(selectedTariff.obmen) ?? 3] ?? "неопределён")
                                    }
                                </span>
                            </div>
                            <div className="flight-tariff-info-item">
                                <span>Возврат</span>
                                <span></span>
                                <span>
                                    {
                                        (RefundAndChangeVariants[Number(selectedTariff.vozvrat) ?? 3] ?? "неопределён")
                                    }
                                </span>
                            </div>
                            <div className="flight-tariff-info-item">
                                <span>Багаж</span>
                                <span></span>
                                <span>
                                    {
                                        (BaggageVariants[Number(selectedTariff.bagaj) ?? 2] ?? "неопределён")
                                    }
                                            {" "}
                                            {
                                                selectedTariff.bagajInfo && (
                                                    "(" + selectedTariff.bagajInfo + ")"
                                                )
                                            }
                                </span>
                            </div>
                        </div>

                        {
                            selectVariant != null && (
                                <div className="flight-info-section" style={{width: "100%", justifyContent: "end"}}>
                                    <MdButton
                                        type="button"
                                        style={{
                                            "button": {
                                                marginTop: "5px",
                                                borderWidth: 0,
                                                backgroundColor: "#3E5CB8",
                                                color: "#FFFFFF",
                                                borderRadius: "6px",
                                                height: "30px",
                                                padding: "5px 15px"
                                            },
                                            "text": {
                                                width: "auto",
                                                flex: 0,
                                                color: "#FFFFFF"
                                            },
                                            "ink": {
                                                backgroundColor: "rgba(255,255,255,.5)"
                                            }
                                        }}
                                        onClick={async (e) => {
                                            e.preventDefault();
                                            e.stopPropagation();
                                            await selectVariant(variant, selectedTariffIndex);
                                        }}
                                    >
                                        Выбрать
                                    </MdButton>
                                </div>
                            )
                        }

                    </div>
                </div>

            </div>
        </details>
    );
}