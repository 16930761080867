import {store} from "../redux/store";
import {ReportsApi} from "../api/ReportsApi";
import {useAppDispatch} from "../redux/hooks";
import {IRequiredReports} from "../interfaces/componentInterfaces/IRequiredReports";
import {DateService} from "./DateService";
import {IError} from '../interfaces/dataInterfaces/IError';
import {TIME_SPAN_ERROR} from "../constants/ErrorConstants";
import {IClientStructureRequest, ICommonReportRequest} from "../interfaces/requestsInterfaces/reportRequests";
import {DATE_FORMAT_YYYYMMDD} from "../constants/ServiceCostants";
import {
    getCustomerAdditionalServicesThunk,
    getCustomerAirCompaniesThunk,
    getCustomerFlightsThunk,
    getCustomerTicketOrdersThunk,
    getReportClientStructureExchangeThunk,
    getReportClientStructureRefoundThunk,
    getReportClientStructureSalesThunk,
    getStatementCloseBalanceThunk,
    getStatementIncomesThunk,
    getStatementOpenBalanceThunk,
    getStatementSalesThunk
} from "../redux/reportsSlice";
import axios, {CancelToken} from "axios";
import {
    ICustomerAdditionalService,
    ICustomerAirCompany, ICustomerFlight,
    ICustomerTicketOrder
} from "../interfaces/reportInterfaces/customerInterfaces";
import {IStatementCloseBalance} from "../interfaces/reportInterfaces/IStatementCloseBalance";
import {IStatementOpenBalance} from "../interfaces/reportInterfaces/IStatementOpenBalance";
import {IStatementIncome} from "../interfaces/reportInterfaces/IStatementIncome";
import {IStatementSale} from "../interfaces/reportInterfaces/IStatementSale";
import {IClientStructure} from "../interfaces/companyInfoInterfaces/IClientStructure";
import {IClientSale} from "../interfaces/companyInfoInterfaces/IClientSale";
import {IClientDetail} from "../interfaces/companyInfoInterfaces/IClientDetail";
import {Utils} from "../utils/utils";


export function ReportService() {

    const reportsApi = ReportsApi();
    const dateService = DateService();

    const dispatch = useAppDispatch();


    /**
     * Method for obtaining company debt details
     * @param {string} code - company code
     * @param {string} dateStart - date start
     * @param {CancelToken} ct - cancellation token
     * @return {Promise<IClientDetail | null>} company debt details. in case of errors returns null
     */
    const getClientDept = async (code: string, dateStart: string, ct: CancelToken = axios.CancelToken.source().token): Promise<IClientDetail | null> => {
        try {
            const state = store.getState();
            const response = await reportsApi.getClientDept(code, dateStart, state.auth.accessToken, ct);
            return !Utils.isEmpty(response.data) ? response.data : null;
        }
        catch (ex: any) {
            return null;
        }
    };

    /**
     * Method for obtaining company sales details
     * @param {string} code - company code
     * @param {CancelToken} ct - cancellation token
     * @return {Promise<IClientSale[]>} company sales details
     */
    const getClientSales = async (code: string, ct: CancelToken = axios.CancelToken.source().token): Promise<IClientSale[]> => {
        try {
            const state = store.getState();
            const response = await reportsApi.getClientSales(code, state.auth.accessToken, ct);
            return Array.isArray(response.data) ? response.data : [];
        }
        catch (ex: any) {
            return [];
        }
    };

    /**
     * Method for obtaining company sales structure details
     * @param {IClientStructureRequest} request - request data
     * @param {CancelToken} ct - cancellation token
     * @return {Promise<IClientStructure[]>} company sales structure details
     */
    const getClientSalesStructure = async (request: IClientStructureRequest, ct: CancelToken = axios.CancelToken.source().token): Promise<IClientStructure[]> => {
        try {
            const state = store.getState();
            const response = await reportsApi.getClientSalesStructure(request, state.auth.accessToken, ct);
            return Array.isArray(response.data) ? response.data : [];
        }
        catch (ex: any) {
            return [];
        }
    }

    /**
     * Method for obtaining statement sales details
     * @param {string} request - request data
     * @param {CancelToken} ct - cancellation token
     * @return {Promise<IStatementSale[]>} statement sales details
     */
    const getStatementSales = async (request: ICommonReportRequest, ct: CancelToken = axios.CancelToken.source().token): Promise<IStatementSale[]> => {
        try {
            const state = store.getState();
            const response = await reportsApi.getStatementSales(request, state.auth.accessToken, ct);
            return Array.isArray(response.data) ? response.data : [];
        }
        catch (ex: any) {
            return [];
        }
    };

    /**
     * Method for obtaining statement incomes details
     * @param {string} request - request data
     * @param {CancelToken} ct - cancellation token
     * @return {Promise<IStatementIncome[]>} statement incomes details
     */
    const getStatementIncomes = async (request: ICommonReportRequest, ct: CancelToken = axios.CancelToken.source().token): Promise<IStatementIncome[]> => {
        try {
            const state = store.getState();
            const response = await reportsApi.getStatementIncomes(request, state.auth.accessToken, ct);
            return Array.isArray(response.data) ? response.data : [];
        }
        catch (ex: any) {
            return [];
        }
    };

    /**
     * Method for obtaining statement open balance
     * @param {string} request - request data
     * @param {CancelToken} ct - cancellation token
     * @return {Promise<IStatementOpenBalance | null>} statement open balance. in case of errors returns null
     */
    const getStatementOpenBalance = async (request: ICommonReportRequest, ct: CancelToken = axios.CancelToken.source().token): Promise<IStatementOpenBalance | null> => {
        try {
            const state = store.getState();
            const response = await reportsApi.getStatementOpenBalance(request, state.auth.accessToken, ct);
            return !Utils.isEmpty(response.data) ? response.data : null;
        }
        catch (ex: any) {
            return null;
        }
    };

    /**
     * GET method for obtaining statement close balance
     * @param {string} request - request data
     * @param {CancelToken} ct - cancellation token
     * @return {Promise<IStatementCloseBalance | null>} statement close balance. in case of errors returns null
     */
    const getStatementCloseBalance = async (request: ICommonReportRequest, ct: CancelToken = axios.CancelToken.source().token): Promise<IStatementCloseBalance | null> => {
        try {
            const state = store.getState();
            const response = await reportsApi.getStatementCloseBalance(request, state.auth.accessToken, ct);
            return !Utils.isEmpty(response.data) ? response.data : null;
        }
        catch (ex: any) {
            return null;
        }
    };

    /**
     * Method for obtaining customer flights details
     * @param {string} request - request data
     * @param {CancelToken} ct - cancellation token
     * @return {Promise<ICustomerFlight[]>} customer flights details
     */
    const getCustomerFlights = async (request: ICommonReportRequest, ct: CancelToken = axios.CancelToken.source().token): Promise<ICustomerFlight[]> => {
        try {
            const state = store.getState();
            const response = await reportsApi.getCustomerFlights(request, state.auth.accessToken, ct);
            return Array.isArray(response.data) ? response.data : [];
        }
        catch (ex: any) {
            return [];
        }
    };

    /**
     * Method for obtaining customer ticket orders details
     * @param {string} request - request data
     * @param {CancelToken} ct - cancellation token
     * @return {Promise<ICustomerTicketOrder[]>} customer ticket orders details
     */
    const getCustomerTicketOrders = async (request: ICommonReportRequest, ct: CancelToken = axios.CancelToken.source().token): Promise<ICustomerTicketOrder[]> => {
        try {
            const state = store.getState();
            const response = await reportsApi.getCustomerTicketOrders(request, state.auth.accessToken, ct);
            return Array.isArray(response.data) ? response.data : [];
        }
        catch (ex: any) {
            return [];
        }
    };

    /**
     * Method for obtaining customer air companies
     * @param {string} request - request data
     * @param {CancelToken} ct - cancellation token
     * @return {Promise<ICustomerAirCompany[]>} customer ticket orders details
     */
    const getCustomerAirCompanies = async (request: ICommonReportRequest, ct: CancelToken = axios.CancelToken.source().token): Promise<ICustomerAirCompany[]> => {
        try {
            const state = store.getState();
            const response = await reportsApi.getCustomerAirCompanies(request, state.auth.accessToken, ct);
            return Array.isArray(response.data) ? response.data : [];
        }
        catch (ex: any) {
            return [];
        }
    };

    /**
     * Method for obtaining customer additional services
     * @param {string} request - request data
     * @param {CancelToken} ct - cancellation token
     * @return {Promise<ICustomerAdditionalService[]>} customer additional services
     */
    const getCustomerAdditionalServices = async (request: ICommonReportRequest, ct: CancelToken = axios.CancelToken.source().token): Promise<ICustomerAdditionalService[]> => {
        try {
            const state = store.getState();
            const response = await reportsApi.getCustomerAdditionalServices(request, state.auth.accessToken, ct);
            return Array.isArray(response.data) ? response.data : [];
        }
        catch (ex: any) {
            return [];
        }
    };


    const getReportsForPeriod = async (requiredReports: IRequiredReports, startDate: string, endDate: string) => {
        const state = store.getState();
        let errors: IError[] = []
        // get request  parameters
        console.log(`GetReports forPeriod ${startDate}, ${endDate}`);
        const currentClientCode = state.auth.currentClientCode;
        if (!dateService.isTimeSpanCorrect(new Date(startDate), new Date(endDate))) errors.push({
            errorMessage: "Time span isn't correct",
            errorType: TIME_SPAN_ERROR
        });
        if (errors.length !== 0) return errors;
        const request: ICommonReportRequest = {
            clientCode: currentClientCode,
            startDate: dateService.dateToCustomFormat(new Date(startDate), DATE_FORMAT_YYYYMMDD),
            endDate: dateService.dateToCustomFormat(new Date(endDate), DATE_FORMAT_YYYYMMDD),
            gkCode: state.auth.currentGkCode ?? ""
        };

        //Open balance and closed balance must be looked for every time.

        dispatch(getStatementOpenBalanceThunk(request));

        dispatch(getStatementCloseBalanceThunk(request));

        // Client structure sales Exchange Refound
        let clientStructureRequest: IClientStructureRequest = {
            clientCode: currentClientCode,
            status: 0,
            monthNum: 0,
            quarter: 0,
            year: 0,
            dateStart: request.startDate,
            dateEnd: request.endDate
        }

        clientStructureRequest.status = 1;
        dispatch(getReportClientStructureSalesThunk(clientStructureRequest));

        clientStructureRequest.status = 2;
        dispatch(getReportClientStructureExchangeThunk(clientStructureRequest));

        clientStructureRequest.status = 3;
        dispatch(getReportClientStructureRefoundThunk(clientStructureRequest));

        //statementSales
        if (requiredReports.isStatementSalesRequired) {
            dispatch(getStatementSalesThunk(request))
        }

        //statement incomes
        if (requiredReports.isStatementIncomesRequired) {
            dispatch(getStatementIncomesThunk(request))
        }

        //ToDO
        if (requiredReports.isCustomerFlightsRequired) {
            dispatch(getCustomerFlightsThunk(request))
        }

        //ToDO
        if (requiredReports.isCustomerTicketOrdersRequired) {
            dispatch(getCustomerTicketOrdersThunk(request))
        }

        //ToDO
        if (requiredReports.isCustomerAirCompaniesRequired) {
            dispatch(getCustomerAirCompaniesThunk(request))
        }

        //ToDO
        if (requiredReports.isCustomerAdditionalService) {
            dispatch(getCustomerAdditionalServicesThunk(request))
        }

        return errors;
    }


    return {
        getClientDept,
        getClientSales,
        getClientSalesStructure,
        getStatementSales,
        getStatementIncomes,
        getStatementOpenBalance,
        getStatementCloseBalance,
        getCustomerFlights,
        getCustomerTicketOrders,
        getCustomerAirCompanies,
        getCustomerAdditionalServices,
        getReportsForPeriod
    };
}