import {createAsyncThunk, createSlice, PayloadAction} from "@reduxjs/toolkit";
import axios from "axios";
import {IRootState} from "./store";
import {PERIOD_LAST_MONTH} from '../constants/ServiceCostants';
import {CompanyApi} from "../api/CompanyApi";
import {
    IGetDeptInfoRequest,
    IClientStructureRequest,
} from "../interfaces/requestsInterfaces/reportRequests";
import {IAllowedPerson} from "../interfaces/companyInfoInterfaces/IAllowedPerson";
import {IClientStructure} from "../interfaces/companyInfoInterfaces/IClientStructure";
import {IClientSale} from '../interfaces/companyInfoInterfaces/IClientSale';
import {ICompanyDTO, IAgreementDTO} from "../interfaces/ICompanyDTO";
import {IClientDetail} from '../interfaces/companyInfoInterfaces/IClientDetail';
import {Utils} from "../utils/utils";
import {DocumentsApi} from "../api/DocumentsApi";
import {ReportsApi} from "../api/ReportsApi";


interface CompanyInfoState {
    isDataReceived:boolean;
    code: string;
    fullName: string;
    gkCode: string;
    gkName: string;
    inn: string;
    vatUse: boolean;
    agreements: IAgreementDTO[];
    agreementNo: string;
    agreementFrom: string;
    agreementTill: string;
    address: string;
    postalAddress: string;
    deptSum: number;
    deptType: string;
    allwedPersons: IAllowedPerson[]
    clientStructureSales: IClientStructure[]
    clientStructureExchange: IClientStructure[]
    clientStructureRefound: IClientStructure[],
    clientSales: IClientSale[],
    startCompanyInfoDate: string;
    endCompanyInfoDate: string;
    companyStatisticPeriod: string;
}


const initialState: CompanyInfoState = {
    isDataReceived:false,
    code: "",
    fullName: "",
    gkCode: "",
    gkName: "",
    inn: "",
    vatUse: false,
    agreements: [],
    agreementNo: "",
    agreementFrom: "",
    agreementTill: "",
    address: "",
    postalAddress: "",
    deptSum: 0,
    deptType: "",
    allwedPersons: [],
    clientStructureSales: [],
    clientStructureExchange: [],
    clientStructureRefound: [],
    clientSales: [],
    startCompanyInfoDate: "",
    endCompanyInfoDate: "",
    companyStatisticPeriod: PERIOD_LAST_MONTH
};

const companyApi = CompanyApi();
const customerApi = DocumentsApi();
const reportsApi = ReportsApi();


/**
 * Asynchronous request to API to receive client debt
 * @param {IGetDeptInfoRequest} request - request data
 * @return {Promise<IClientDetail>} company debt details
 */
export const getClientDeptThunk = createAsyncThunk("companyInfo/getCompanyInfo",
    async (request: IGetDeptInfoRequest, thunkAPI) => {
        const defaultData: IClientDetail = {
            fullname: "NotData",
            gkName: "NoGkCompany",
            deptSum: 0,
            deptType: "No data"
        }
        try {
            const state = thunkAPI.getState() as IRootState;
            const response = await reportsApi.getClientDept(request.clientCode, request.dateStart, state.auth.accessToken, axios.CancelToken.source().token);
            return !Utils.isEmpty(response.data) ? response.data : defaultData;
        }
        catch (ex: any) {
            return defaultData;
        }
    }
);

/**
 * Asynchronous request to API to receive information about a company
 * @param {string} clientCode - company code
 * @return {Promise<ICompanyDTO>} information about a company
 */
export const getClientDetailsThunk = createAsyncThunk("companyInfo/getClientDetail",
    async (clientCode: string, thunkAPI) => {
        try {
            const state = thunkAPI.getState() as IRootState;
            const response = await companyApi.getCompanyInfo(clientCode, state.auth.accessToken, axios.CancelToken.source().token);
            return !Utils.isEmpty(response.data) ? response.data : {} as ICompanyDTO;
        }
        catch (ex: any) {
            return {} as ICompanyDTO;
        }
    }
);

/**
 * Asynchronous request to API to receive allowed persons of a company
 * @param {string} clientCode - company code
 * @return {Promise<IAllowedPerson[]>} allowed persons of a company
 */
export const getAllowedPersonsThunk = createAsyncThunk("companyInfo/getAllowedPersons",
    async (clientCode: string, thunkAPI) => {
        try {
            const state = thunkAPI.getState() as IRootState;
            const response = await companyApi.getAllowedPersons(clientCode, state.auth.accessToken, axios.CancelToken.source().token);
            return Array.isArray(response.data) ? response.data : [];
        }
        catch (ex: any) {
            return [];
        }
    }
);

/**
 * Asynchronous request to API to receive company sales structure details
 * @param {IClientStructureRequest} request - request data
 * @return {Promise<IClientStructure[]>} company sales structure details
 */
export const getClientStructureSalesThunk = createAsyncThunk("companyInfo/getClientStructureSales",
    async (request: IClientStructureRequest, thunkAPI) => {
        try {
            const state = thunkAPI.getState() as IRootState;
            const response = await reportsApi.getClientSalesStructure(request, state.auth.accessToken, axios.CancelToken.source().token);
            return Array.isArray(response.data) ? response.data : [];
        }
        catch (ex: any) {
            return [];
        }
    }
);

/**
 * Asynchronous request to API to receive company exchange structure details
 * @param {IClientStructureRequest} request - request data
 * @return {Promise<IClientStructure[]>} company exchange structure details
 */
export const getClientStructureExchangeThunk = createAsyncThunk("companyInfo/getClientStructureExchange",
    async (request: IClientStructureRequest, thunkAPI) => {
        try {
            const state = thunkAPI.getState() as IRootState;
            const response = await reportsApi.getClientSalesStructure(request, state.auth.accessToken, axios.CancelToken.source().token);
            return Array.isArray(response.data) ? response.data : [];
        }
        catch (ex: any) {
            return [];
        }
    }
);

/**
 * Asynchronous request to API to receive company refund structure details
 * @param {IClientStructureRequest} request - request data
 * @return {Promise<IClientStructure[]>} company refund structure details
 */
export const getClientStructureRefundThunk = createAsyncThunk("companyInfo/getClientStructureRefound",
    async (request: IClientStructureRequest, thunkAPI) => {
        try {
            const state = thunkAPI.getState() as IRootState;
            const response = await reportsApi.getClientSalesStructure(request, state.auth.accessToken, axios.CancelToken.source().token);
            return Array.isArray(response.data) ? response.data : [];
        }
        catch (ex: any) {
            return [];
        }
    }
);

/**
 * Asynchronous request to API to receive company sales details
 * @param {string} clientCode - company code
 * @return {Promise<IClientStructure[]>} company sales details
 */
export const getClientSalesThunk = createAsyncThunk("companyInfo/getClientsSales",
    async (clientCode: string, thunkAPI) => {
        try {
            const state = thunkAPI.getState() as IRootState;
            const response = await reportsApi.getClientSales(clientCode, state.auth.accessToken, axios.CancelToken.source().token);
            return Array.isArray(response.data) ? response.data : [];
        }
        catch (ex: any) {
            return [];
        }
})


export const companyInfoSlice = createSlice({
    name: "companyInfo",
    initialState,
    reducers: {
        setCode: (state, action: PayloadAction<string>) => {
            state.code = action.payload;
        },
        setFullName: (state, action: PayloadAction<string>) => {
            state.fullName = action.payload;
        },
        setGkKode: (state, action: PayloadAction<string>) => {
            state.gkCode = action.payload;
        },
        setGkName: (state, action: PayloadAction<string>) => {
            state.gkName = action.payload;
        },
        setInn: (state, action: PayloadAction<string>) => {
            state.inn = action.payload;
        },
        setVatUse: (state, action: PayloadAction<boolean>) => {
            state.vatUse = action.payload;
        },
        setAgreements: (state, action: PayloadAction<IAgreementDTO[]>) => {
            state.agreements = action.payload;
        },
        setAgreementNo: (state, action: PayloadAction<string>) => {
            state.agreementNo = action.payload;
        },
        setAgreementFrom: (state, action: PayloadAction<string>) => {
            state.agreementFrom = action.payload;
        },
        setAgreementTill: (state, action: PayloadAction<string>) => {
            state.agreementTill = action.payload;
        },
        setAddress: (state, action: PayloadAction<string>) => {
            state.address = action.payload;
        },
        setPostalAddress: (state, action: PayloadAction<string>) => {
            state.postalAddress = action.payload;
        },
        setAllowedPerson: (state, action: PayloadAction<IAllowedPerson[]>) => {
            state.allwedPersons = action.payload;
        },
        setClientStructureSales: (state, action: PayloadAction<IClientStructure[]>) => {
            state.clientStructureSales = action.payload;
        },
        setClientStructureExchange: (state, action: PayloadAction<IClientStructure[]>) => {
            state.clientStructureExchange = action.payload;
        },
        setClientStructureRefound: (state, action: PayloadAction<IClientStructure[]>) => {
            state.clientStructureRefound = action.payload;
        },
        setClientSales: (state, action: PayloadAction<IClientSale[]>) => {
            state.clientSales = action.payload;
        },
        setStartCompanyInfoDate: (state, action: PayloadAction<string>) => {
            state.startCompanyInfoDate = action.payload;
        },
        setEndCompanyInfoDate: (state, action: PayloadAction<string>) => {
            state.endCompanyInfoDate = action.payload;
        },
        setCompanyStatisticPeriod: (state, action: PayloadAction<string>) => {
            state.companyStatisticPeriod = action.payload;
        },
        setIsDataReceived:(state,action:PayloadAction<boolean>)=>{
            state.isDataReceived = action.payload;
        },
        clearCompanyInfo: (state) => {
            state.isDataReceived =false;
            state.code = "";
            state.fullName = "";
            state.gkCode = "";
            state.gkName = "";
            state.inn = "";
            state.vatUse = false;
            state.agreements = [];
            state.agreementNo = "";
            state.agreementFrom = "";
            state.agreementTill = "";
            state.address = "";
            state.postalAddress = "";
            state.deptSum = 0;
            state.deptType = "";
            state.allwedPersons = [];
            state.clientStructureSales = [];
            state.clientStructureExchange = [];
            state.clientStructureRefound = [];
            state.clientSales = [];
            state.startCompanyInfoDate = "";
            state.endCompanyInfoDate = "";
            state.companyStatisticPeriod = PERIOD_LAST_MONTH;
        }

    },
    extraReducers: (builder) => {
        builder
            // Get client details
            .addCase(
                getClientDeptThunk.fulfilled,
                (state, action: PayloadAction<IClientDetail>) => {
                    state.deptSum = action.payload.deptSum;
                    state.deptType = action.payload.deptType;
                }
            )
            .addCase(
                getClientDetailsThunk.fulfilled,
                (state, action: PayloadAction<ICompanyDTO>) => {
                    state.code = action.payload !== undefined ? action.payload.code : "";
                    state.fullName = action.payload !== undefined ? action.payload.fullName : "";
                    state.gkCode = action.payload !== undefined ? action.payload.gkCode : "";
                    state.gkName = action.payload !== undefined ? action.payload.gkName : "";
                    state.inn = action.payload !== undefined ? action.payload.inn : "";
                    state.agreementNo = action.payload !== undefined ? (action.payload.agreements != null && action.payload.agreements.length > 0 ? action.payload.agreements[0].code : "") : "";
                    state.agreementFrom = action.payload !== undefined ? (action.payload.agreements != null && action.payload.agreements.length > 0 ? action.payload.agreements[0].dateFrom : "") : "";
                    state.agreementTill = action.payload !== undefined ? (action.payload.agreements != null && action.payload.agreements.length > 0 ? action.payload.agreements[0].dateTill : "") : "";
                    state.address = action.payload !== undefined ? action.payload.address : "";
                    state.postalAddress = action.payload !== undefined ? action.payload.postalAddress : "";
                }
            )
            .addCase(
                getAllowedPersonsThunk.fulfilled, (state, action: PayloadAction<IAllowedPerson[]>) => {
                    state.allwedPersons = action.payload
                })
            .addCase(
                getClientStructureSalesThunk.fulfilled, (state, action: PayloadAction<IClientStructure[]>) => {
                    state.clientStructureSales = action.payload
                })
            .addCase(getClientStructureExchangeThunk.fulfilled, (state, action: PayloadAction<IClientStructure[]>) => {
                state.clientStructureExchange = action.payload
            })
            .addCase(getClientStructureRefundThunk.fulfilled, (state, action: PayloadAction<IClientStructure[]>) => {
                state.clientStructureRefound = action.payload
            })
            .addCase(getClientSalesThunk.fulfilled, (state, action: PayloadAction<IClientSale[]>) => {
                state.clientSales = action.payload
            })
            .addCase(
                getClientStructureSalesThunk.pending, (state, action) => {
                }
            )
    },
});


export const {
    setCode,
    setFullName,
    setGkKode,
    setGkName,
    setInn,
    setVatUse,
    setAgreements,
    setAgreementNo,
    setAgreementFrom,
    setAgreementTill,
    setAddress,
    setPostalAddress,
    setClientStructureSales,
    setClientStructureExchange,
    setClientStructureRefound,
    setStartCompanyInfoDate,
    setEndCompanyInfoDate,
    setCompanyStatisticPeriod,
    clearCompanyInfo,
    setIsDataReceived
} = companyInfoSlice.actions;


export const companyInfoState = (state: IRootState): CompanyInfoState => {
    return {
        isDataReceived:state.companyInfo.isDataReceived,
        code: state.companyInfo.code,
        fullName: state.companyInfo.fullName,
        gkCode: state.companyInfo.gkCode,
        gkName: state.companyInfo.gkName,
        inn: state.companyInfo.inn,
        vatUse: state.companyInfo.vatUse,
        agreementNo: state.companyInfo.agreementNo,
        agreementFrom: state.companyInfo.agreementFrom,
        agreementTill: state.companyInfo.agreementTill,
        agreements: state.companyInfo.agreements,
        address: state.companyInfo.address,
        postalAddress: state.companyInfo.postalAddress,
        deptSum: state.companyInfo.deptSum,
        deptType: state.companyInfo.deptType,
        allwedPersons: state.companyInfo.allwedPersons,
        clientStructureSales: state.companyInfo.clientStructureSales,
        clientStructureExchange: state.companyInfo.clientStructureExchange,
        clientStructureRefound: state.companyInfo.clientStructureRefound,
        clientSales: state.companyInfo.clientSales,
        startCompanyInfoDate: state.companyInfo.startCompanyInfoDate,
        endCompanyInfoDate: state.companyInfo.endCompanyInfoDate,
        companyStatisticPeriod: state.companyInfo.companyStatisticPeriod
    };
};


export default companyInfoSlice.reducer;
