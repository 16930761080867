import { Validator } from "fluentvalidation-ts";
import {ILoginRequest} from "../api/request/ILoginRequest";

export class LoginRequestValidator extends Validator<ILoginRequest> {
  constructor(t: (key: string) => string) {
    super();
    this.ruleFor("email")
      .notEmpty()
      .withMessage(t("emailIsEmpty"))
      .emailAddress()
      .withMessage(t("incorrectEmail"));
    this.ruleFor("password")
        .notEmpty()
        //.minLength(8)
        .withMessage(t("passwordIsEmpty"));
  }
}
