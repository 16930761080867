import React, {useEffect, useRef, useState} from "react";
import {useAppSelector} from "../../../redux/hooks";
import {UserListComponent} from "./userListComponent";
import {UserDetailsModal} from "./userDetailsModal";
import {UsersService} from "../../../services/UsersService";
import {IUserDTO} from "../../../interfaces/IUserDTO";


export function UsersPage() {

    const authState = useAppSelector((state) => state.auth);

    const usersService = UsersService();

    const [users, setUsers] = useState<IUserDTO[]>([]);
    const [usersPending, setUsersPending] = useState(false);
    const [isUsersNotFound, setIsUsersNotFound] = useState(false);
    const [selectedUsers, setSelectedUsers] = useState<IUserDTO[]>([]);

    const userDetailsModal = useRef<{ open: (user?: IUserDTO) => void, close: () => void }>();


    const loadUsers = async () => {
        setUsersPending(true);
        setUsers([]);
        setSelectedUsers([]);
        setIsUsersNotFound(false);
        const response = await usersService.getUsers(authState.currentClientCode);
        if (response.length === 0) {
            setIsUsersNotFound(true);
        }
        setUsers(response);
        setUsersPending(false);
    }


    const openUserDetailsModal = (user: IUserDTO | undefined) => {
        userDetailsModal.current?.open(user);
    }

    const onUserDetailsModalSubmit = async (result: boolean) => {
        if(result) {
            await loadUsers();
        }
    }


    useEffect(() => {
        loadUsers();
    }, [authState.currentClientCode]);


    return (
        <>

            <UserListComponent
                users={users}
                usersPending={usersPending}
                isUsersNotFound={isUsersNotFound}
                selectedUsers={selectedUsers}
                setSelectedUsers={setSelectedUsers}
                onItemClick={(user: IUserDTO) => userDetailsModal.current?.open(user)}
                reloadUsers={loadUsers}
                openUserDetailsModal={openUserDetailsModal}
            />

            <UserDetailsModal
                ref={userDetailsModal}
                onSubmit={onUserDetailsModalSubmit}
            />

        </>
    );
}
