import {axiosInstance} from "./AxiosInstance";
import {AxiosResponse, CancelToken} from "axios";
import {
    BEARER,
    ROUTE_GET_USERS,
    ROUTE_ADD_USER,
    ROUTE_UPDATE_USER,
    ROUTE_GET_USER_ROLES,
    ROUTE_GET_USER_ROLES_IN_COMPANY
} from "../constants/routeConstants/ApiRouteConstants";
import {IUserDTO} from "../interfaces/IUserDTO";
import {ICreateUserRequest} from "./request/ICreateUserRequest";
import {ICompanyUserRoleDTO} from "../interfaces/ICompanyUserRoleDTO";


export function UsersApi() {

    /**
     * GET method to receive a list of users
     * @param {string} clientCode - company code
     * @param {string} accessToken - access token
     * @param {CancelToken} ct - cancellation token
     * @return {Promise<AxiosResponse<>ICustomerEmployee[]>} response with list of users
     */
    const getUsers = async (clientCode: string, accessToken: string, ct: CancelToken): Promise<AxiosResponse<IUserDTO[]>> => {
        return await axiosInstance.get<IUserDTO[]>(
            process.env.REACT_APP_GATEWAY_ROUTE + ROUTE_GET_USERS,
            {
                headers: {
                    Authorization: BEARER + accessToken,
                    Auth: 123
                },
                params: {
                    code: clientCode,
                },
                cancelToken: ct
            }
        );
    }

    /**
     * PUT method to create a user
     * @param {ICreateUserRequest} request - request data
     * @param {string} accessToken - access token
     * @param {CancelToken} ct - cancellation token
     * @return {Promise<AxiosResponse<IUserDTO>>} response with user data
     */
    const createUser = async (request: ICreateUserRequest, accessToken: string, ct: CancelToken): Promise<AxiosResponse<IUserDTO>> => {
        return await axiosInstance.put<IUserDTO>(
            process.env.REACT_APP_GATEWAY_ROUTE + ROUTE_ADD_USER,
            request,
            {
                headers: {
                    Authorization: BEARER + accessToken,
                    Auth: 123
                },
                cancelToken: ct
            }
        );
    };

    /**
     * PUT method to update user
     * @param {ICreateUserRequest} request - request data
     * @param {string} accessToken - access token
     * @param {CancelToken} ct - cancellation token
     * @return {Promise<AxiosResponse<IUserDTO>>} response with user data
     */
    const updateUser = async (request: ICreateUserRequest, accessToken: string, ct: CancelToken): Promise<AxiosResponse<IUserDTO>> => {
        return await axiosInstance.put<IUserDTO>(
            process.env.REACT_APP_GATEWAY_ROUTE + ROUTE_UPDATE_USER,
            request,
            {
                headers: {
                    Authorization: BEARER + accessToken,
                    Auth: 123
                },
                cancelToken: ct
            }
        );
    };

    /**
     * GET method to receive list of user roles
     * @param {string} email - user email
     * @param {string} accessToken - access token
     * @param {CancelToken} ct - cancellation token
     * @return {Promise<AxiosResponse<ICompanyUserRoleDTO[]>>} response with list of user roles
     */
    const getUserRoles = async (email: string, accessToken: string, ct: CancelToken): Promise<AxiosResponse<ICompanyUserRoleDTO[]>> => {
        return await axiosInstance.get<ICompanyUserRoleDTO[]>(
            process.env.REACT_APP_GATEWAY_ROUTE + ROUTE_GET_USER_ROLES,
            {
                headers: {
                    Authorization: BEARER + accessToken
                },
                params: {
                    email: email,
                },
                cancelToken: ct
            }
        );
    };

    /**
     * GET method to get a list of user role names in the company
     * @param {string} clientCode - company code
     * @param {string} email - user email
     * @param {string} accessToken - access token
     * @param {CancelToken} ct - cancellation token
     * @return {Promise<AxiosResponse<string[]>>} response with a list of user role names in the company
     */
    const getUserRolesInCompany = async (clientCode: string, email: string, accessToken: string, ct: CancelToken): Promise<AxiosResponse<string[]>> => {
        return await axiosInstance.get<string[]>(
            process.env.REACT_APP_GATEWAY_ROUTE + ROUTE_GET_USER_ROLES_IN_COMPANY,
            {
                headers: {
                    Authorization: BEARER + accessToken
                },
                params: {
                    code: clientCode,
                    email: email,
                },
                cancelToken: ct
            }
        );
    };


    return {
        getUsers,
        createUser,
        updateUser,
        getUserRoles,
        getUserRolesInCompany
    };
}