import React from "react";
import {useTranslation} from "react-i18next";
import {IAviaRejsInfo} from "../../../../../interfaces/IAviaVariant";
import {AirplaneIcon} from "../../../../../icons/AirplaneIcon";
import "./style.scss";


export interface FlightTicketComponent {
    ticket: IAviaRejsInfo,
}


export function FlightTicketComponent({ ticket }: FlightTicketComponent) {
    const {t} = useTranslation();

    const dateDepartment = new Date(ticket.dateDep.replace('Z', ''));
    const townDepartment = ticket.cityDep.replace(/ *\([^)]*\) */g, "");

    const dateArrival = new Date(ticket.dateArr.replace('Z', ''));
    const townArrival = ticket.cityArr.replace(/ *\([^)]*\) */g, "");

    return (
        <div className="flight-ticket-component">

            <div className="flight-ticket-component-header">
                <div className="depart-info">
                    <div className="city-code">
                        {ticket.airCodeDep}
                    </div>
                </div>
                <div className="flight-info">
                    <div className="air-company-name">
                        {ticket.airCompanyName}
                    </div>
                </div>
                <div className="arrive-info">
                    <div className="city-code">
                        {ticket.airCodeArr}
                    </div>
                </div>
            </div>

            <div className="flight-ticket-component-content">
                <div className="depart-info">
                    <div className="city-name">
                        {townDepartment}
                    </div>
                    <div className="datetime">
                        {
                            <>
                                <span>
                                    {dateDepartment.getHours().toString().padStart(2, "0") + ":" + dateDepartment.getMinutes().toString().padStart(2, "0") + ", "}
                                </span>
                                <span>
                                    {dateDepartment.toLocaleString('default', {month: 'short'}).replace(".", "")}
                                    {" "}
                                    {dateDepartment.getDate().toString().padStart(2, "0")}
                                </span>
                            </>
                        }
                    </div>
                </div>
                <div className="flight-info">
                    <div className="icon">
                        <span style={{opacity: 0.25}}></span>
                        <span style={{opacity: 0.5}}></span>
                        <span></span>
                        <div style={{margin: "0 2px"}}>
                            <AirplaneIcon
                                width="16px"
                                height="16px"
                                style={{
                                    "path": {
                                        fill: "#475AEF"
                                    }
                                }}
                            />
                        </div>
                        <span></span>
                        <span style={{opacity: 0.5}}></span>
                        <span style={{opacity: 0.25}}></span>
                    </div>
                    <div className="number">
                        {ticket.flightNumber}
                    </div>
                </div>
                <div className="arrive-info">
                    <div className="city-name">
                        {townArrival}
                    </div>
                    <div className="datetime">
                        {
                            <>
                                <span>
                                    {dateArrival.getHours().toString().padStart(2, "0") + ":" + dateArrival.getMinutes().toString().padStart(2, "0") + ", "}
                                </span>
                                <span>
                                    {dateArrival.toLocaleString('default', {month: 'short'}).replace(".", "")}
                                    {" "}
                                    {dateArrival.getDate().toString().padStart(2, "0")}
                                </span>
                            </>
                        }
                    </div>
                </div>
            </div>

        </div>
    );
}
