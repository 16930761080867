import {
    HubConnectionBuilder,
    HubConnection,
    IHttpConnectionOptions,
    DefaultHttpClient,
    HttpRequest,
    HttpResponse
} from '@microsoft/signalr';
import {BEARER} from "../constants/routeConstants/ApiRouteConstants";


interface SignalRHttpClientConfig {
    accessTokenFactory?(): (string | Promise<string>)
}

class SignalRHttpClient extends DefaultHttpClient {

    // private _accessToken = "";
    // get accessToken() {
    //     return this._accessToken;
    // }
    // set accessToken(value: string) {
    //     this._accessToken = value;
    //     console.warn('[SignalRHttpClient] token changed..')
    // }

    accessTokenFactory?(): string | Promise<string>


    constructor( config: SignalRHttpClientConfig) {
        super(console); // the base class wants a signalR.ILogger
        this.accessTokenFactory = config.accessTokenFactory;
    }


    public async send( request: HttpRequest ): Promise<HttpResponse> {
        //const authHeaders = getAuthHeaders();
        //request.headers = { ...request.headers, ...authHeaders };

        try {

            if(this.accessTokenFactory != null) {
                request.headers = {
                    ...request.headers,
                    Authorization: BEARER + this.accessTokenFactory()
                }
            }

            const response = await super.send(request);

            return response;
        } catch (er) {
            // if (er instanceof HttpError) {
            //     const error = er as HttpError;
            //     if (error.statusCode == 401) {
            //         //token expired - trying a refresh via refresh token
            //         await authService.refresh();
            //         const authHeaders = getAuthHeaders();
            //         request.headers = { ...request.headers, ...authHeaders };
            //     }
            // } else {
            //     throw er;
            // }
            throw er;
        }
        //re-try the request
        return super.send(request);
    }
}


interface SignalRServiceConfig {
    hubUrl: string;
    options?: IHttpConnectionOptions;
    httpClient?: SignalRHttpClient;
    //accessTokenFactory?(): string;
    //withAutomaticReconnect?(): IRetryPolicy | number[];
}

export class SignalRService {
    private httpClient: SignalRHttpClient | null = null;
    private connection: HubConnection | null = null;
    protected eventListeners: { [key: string]: Function[] } = {};

    // private _accessToken = "";
    // get accessToken() {
    //     return this._accessToken;
    // }
    // set accessToken(value: string) {
    //     if(this._accessToken != value) {
    //         this._accessToken = value;
    //         if(this.httpClient != null) {
    //             this.httpClient.accessToken = value;
    //         }
    //         console.warn('[SignalRService] token changed..');
    //         if(this.connection?.connectionId !== null) {
    //             this.reconnect();
    //         }
    //     }
    // }

    // private onConnectionStart = (cb: Function) => cb();
    // private onConnectionEnd = (cb: Function) => cb();

    //private _connectionPending = false;

    public onStartConnection: () => void = () => {};
    public onStopConnection: () => void = () => {};
    public onConnected: () => void = () => {};
    public onDisconnected: () => void = () => {};


    public async createConnection( config: SignalRServiceConfig ): Promise<HubConnection> {
        this.httpClient = config.httpClient ?? new SignalRHttpClient({ accessTokenFactory: config.options?.accessTokenFactory });

        //this.accessToken = config.token ?? "";

        config.options = {
            ...config.options ?? {},
            //httpClient: this.httpClient
        };

        if(this.connection != null) {
            await this.connection.stop();
        }

        this.connection =
            new HubConnectionBuilder()
                .withUrl(config.hubUrl, config.options)
                // .withAutomaticReconnect({
                //     nextRetryDelayInMilliseconds: retryContext => {
                //         if (retryContext.elapsedMilliseconds < 60000) {
                //             // If we've been reconnecting for less than 60 seconds so far,
                //             // wait between 0 and 10 seconds before the next reconnect attempt.
                //             return Math.random() * 10000;
                //         } else {
                //             // If we've been reconnecting for more than 60 seconds so far, stop reconnecting.
                //             return null;
                //         }
                //     }
                // })
                .build();

        // this.connection.onreconnecting((error) => {
        //     console.warn(error);
        // });
        // this.connection.onreconnected((result) => {
        //     console.warn(result);
        // });
        // this.connection.onclose((error) => {
        //     console.warn(error);
        // });

        await this.startConnection();

        return this.connection;
    }

    public async startConnection(): Promise<boolean> {
        try {
            await this.connection?.start()
                .then(() => {
                    console.log('[SignalR] connection started');
                })
                .catch(err => {
                    console.log('[SignalR] error while starting connection: ' + err);
                    return false;
                })
        } catch (err) {
            console.error('[SignalR] connection error: ', err);
            return false;
        }
        return true;
    }

    public async stopConnection(): Promise<boolean> {
        try {
            await this.connection?.stop()
                .then(() => {
                    console.log('[SignalR] connection stopped');
                })
                .catch(err => {
                    console.log('[SignalR] error while stopping connection: ' + err);
                    return false;
                })
        } catch (err) {
            console.error('[SignalR] connection error: ', err);
            return false;
        }
        return true;
    }


    // public async reconnect(): Promise<void> {
    //     console.warn('[SignalRService] reconnect..')
    //     await this.stopConnection();
    //     await this.startConnection();
    // }


    // public onreconnecting(callback: (error?: Error) => void): void {
    //     if (callback) {
    //         this._reconnectingCallbacks.push(callback);
    //     }
    // }


    public getConnectionId(): string | null {
        return this.connection ? this.connection.connectionId : null;
    }


    public async sendData( method: string, ...args: any[] ): Promise<void> {
        if (this.connection) {
            try {
                await this.connection.invoke(method, ...args);
            } catch (err) {
                console.error('[SignalR] invoke method ' + method +' error: ', err);
            }
        }
    }


    /**
     * Event subscribe function
     *
     * @param {String} event Event name
     * @param {Function} callback Function
     */
    addEventListener( event: string, callback: Function ) {
        this.eventListeners[event] = this.eventListeners[event] || [];
        this.eventListeners[event].push(callback);

        this.connection?.on(event, (...args: any[]) => {
            callback(...args);
        });

        return this;
    }

    /**
     * Event unsubscribe function
     *
     * @param {String} event Event name
     * @param {Function} callback Function
     */
    removeEventListener(event: string, callback: Function) {
        const functions = (this.eventListeners[event] || []);
        const index = functions.indexOf(callback);
        if (~index) {
            functions.splice(index, 1);
        }
        this.connection?.off(event);
        return this;
    }

    /**
     * Event emitter function
     *
     * @param {String} event Event name
     * @param {Any} args Argument list
     */
    private emit( event: string, ...args: any[] ) {
        this.eventListeners[event].forEach(cb => cb(...args, this));
    }
}

//export default new SignalRService();