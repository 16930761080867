import axios, {CancelToken} from "axios";
import {store} from "../redux/store";
import {EmployeesApi} from "../api/EmployeesApi";
import {IPassengerDTO} from "../interfaces/IPassengerDTO";
import {ICreatePassengerRequest} from "../api/request/ICreatePassengerRequest";
import {ICreatePassengerDocumentRequest} from "../api/request/ICreatePassengerDocumentRequest";
import {IPassengerDocumentDTO} from "../interfaces/IPassengerDocumentDTO";
import {Utils} from "../utils/utils";


export function EmployeesService() {

  const passengersApi = EmployeesApi();


  /**
   * Method for obtaining list of passengers
   * @param {string} clientCode - active company code
   * @param {CancelToken} ct - cancellation token
   * @return {Promise<IPassengerDTO[]>>} list of passengers. in case of errors returns empty array.
   */
  const getPassengers = async (clientCode: string, ct: CancelToken = axios.CancelToken.source().token): Promise<IPassengerDTO[]> => {
    try {
      const state = store.getState();
      const response = await passengersApi.getPassengers(clientCode, state.auth.accessToken, ct);
      return Array.isArray(response.data) ? response.data : [];
    } catch (error) {
      return [];
    }
  }

  /**
   * Method for obtaining passenger by code
   * @param {string} paxCode - passenger code
   * @param {CancelToken} ct - cancellation token
   * @return {Promise<IPassengerDTO | null>>} passenger data. in case of errors returns null.
   */
  const getPassenger = async (paxCode: string, ct: CancelToken = axios.CancelToken.source().token): Promise<IPassengerDTO | null> => {
    try {
      const state = store.getState();
      const response = await passengersApi.getPassenger(paxCode, state.auth.accessToken, ct);
      return Utils.isEmpty(response.data) ? response.data : null;
    } catch (error) {
      return null;
    }
  }

  /**
   * Method to create a passenger
   * @param {ICreatePassengerRequest} request - request data
   * @param {CancelToken} ct - cancellation token
   * @return {Promise<string | null>} passenger code. in case of errors returns null
   */
  const createPassenger = async (request: ICreatePassengerRequest, ct: CancelToken = axios.CancelToken.source().token): Promise<string | null> => {
    try {
      const state = store.getState();
      const response = await passengersApi.createPassenger(request, state.auth.accessToken, ct);
      return !Utils.isEmpty(response.data) ? response.data : null;
    } catch (ex) {
      return null;
    }
  }

  /**
   * Method to update passenger data
   * @param {ICreatePassengerRequest} request - request data
   * @param {CancelToken} ct - cancellation token
   * @return {Promise<boolean>} passenger update result
   */
  const updatePassenger = async (request: ICreatePassengerRequest, ct: CancelToken = axios.CancelToken.source().token): Promise<boolean> => {
    try {
      const state = store.getState();
      const response = await passengersApi.updatePassenger(request, state.auth.accessToken, ct);
      return response.data === true;
    } catch (ex) {
      return false;
    }
  }

  /**
   * Method to bind passenger to company
   * @param {string} clientCode - company code
   * @param {string} paxCode - passenger code
   * @param {CancelToken} ct - cancellation token
   * @return {Promise<boolean>} passenger binding result
   */
  const bindPassengerToCompany = async (clientCode: string, paxCode: string, ct: CancelToken = axios.CancelToken.source().token): Promise<boolean> => {
    try {
      const state = store.getState();
      const response = await passengersApi.bindPassengerToCompany(clientCode, paxCode, state.auth.accessToken, ct);
      return response.data === true;
    } catch (ex) {
      return false;
    }
  }

  /**
   * Method to create a passenger
   * @param {ICreatePassengerDocumentRequest} request - request data
   * @param {CancelToken} ct - cancellation token
   * @return {Promise<string | null>} passenger code. in case of errors returns null
   */
  const createPassengerDocument = async (request: ICreatePassengerDocumentRequest, ct: CancelToken = axios.CancelToken.source().token): Promise<string | null> => {
    try {
      const state = store.getState();
      const response = await passengersApi.createPassengerDocument(request, state.auth.accessToken, ct);
      return !Utils.isEmpty(response.data) ? response.data : null;
    } catch (ex) {
      return null;
    }
  }

  /**
   * Method for obtaining list of passenger documents
   * @param {string} paxCode - passenger code
   * @param {CancelToken} ct - cancellation token
   * @return {Promise<IPassengerDocumentDTO[]>>} list of passenger documents. in case of errors returns empty array.
   */
  const getPassengerDocuments = async (paxCode: string, ct: CancelToken = axios.CancelToken.source().token): Promise<IPassengerDocumentDTO[]> => {
    try {
      const state = store.getState();
      const response = await passengersApi.getPassengerDocuments(paxCode, state.auth.accessToken, ct);
      return Array.isArray(response.data) ? response.data : [];
    } catch (error) {
      return [];
    }
  }

  /**
   * Method for obtaining passenger document by code
   * @param {string} docCode - document code
   * @param {CancelToken} ct - cancellation token
   * @return {Promise<IPassengerDocumentDTO | null>>} passenger document data. in case of errors returns null.
   */
  const getPassengerDocument = async (docCode: string, ct: CancelToken = axios.CancelToken.source().token): Promise<IPassengerDocumentDTO | null> => {
    try {
      const state = store.getState();
      const response = await passengersApi.getPassengerDocument(docCode, state.auth.accessToken, ct);
      return Utils.isEmpty(response.data) ? response.data : null;
    } catch (error) {
      return null;
    }
  }


  return {
    getPassengers,
    getPassenger,
    createPassenger,
    updatePassenger,
    bindPassengerToCompany,
    createPassengerDocument,
    getPassengerDocuments,
    getPassengerDocument,
  };
}
