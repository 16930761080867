import {
    BEARER,
    ROUTE_LOGIN,
    ROUTE_REFRESH_TOKEN,
    ROUTE_LOGOUT,
} from "../constants/routeConstants/ApiRouteConstants";
import {axiosInstance} from "./AxiosInstance";
import {store} from "../redux/store";
import {AuthService} from "../services/AuthService";


class RefreshPending {
    promise: Promise<string>;
    resolve: ((value: (string | PromiseLike<string>)) => void) = () => {};
    reject: ((reason?: any) => void) = () => {};

    constructor() {
        this.promise = new Promise((resolve, reject)=> {
            this.reject = reject;
            this.resolve = resolve;
        })
    }
}

export const useAxiosInterceptors = () => {

    // init auth service
    const authService = AuthService();

    // prepare refresh token promise
    let refreshPending: RefreshPending | null = null;

    // clear request interceptor
    axiosInstance.interceptors.request.clear();
    // create request interceptor
    axiosInstance.interceptors.request.use(
        async (request) =>
            new Promise(async (resolve, reject) => {
                // skip token verification for login, logout, and token refresh routes
                if(!request.url?.includes(ROUTE_LOGIN) && !request.url?.includes(ROUTE_LOGOUT) && !request.url?.includes(ROUTE_REFRESH_TOKEN)) {
                    // skip token verification for routes without authorization
                    if(request.headers.hasOwnProperty('Authorization')) {
                        // get state
                        const state = store.getState();
                        // get access token
                        const accessToken = state.auth.accessToken;
                        // get token expiration date
                        const tokenExpirationDate = new Date(state.auth.expiredAt * 1000);
                        // calculate remaining token lifetime
                        const tokenLifetime = ((tokenExpirationDate.getTime() - new Date().getTime()));
                        // 30 seconds before the token expires, start the token refresh process
                        if (tokenLifetime - 30 * 1000 < 0) {
                            // if the token refresh process has not been initialized
                            if(refreshPending == null) {
                                // initialize the token refresh process
                                refreshPending = new RefreshPending();
                                // refresh token
                                const token = await authService.refreshToken(accessToken);
                                // update authorization header for request
                                request.headers.Authorization = BEARER + token;
                                // execute request
                                resolve(request);
                                // resolve refresh token promise
                                refreshPending.resolve(token ?? "");
                                // reset refresh token promise
                                refreshPending = null;
                            }
                            else {
                                // if the token refresh process has been initialized, wait for it to complete
                                refreshPending.promise.then(token => {
                                    // update authorization header for request
                                    request.headers.Authorization = BEARER + token;
                                    // execute request
                                    resolve(request);
                                })
                            }
                        }
                        else {
                            // update authorization header for next request
                            request.headers.Authorization = BEARER + accessToken;
                            // execute request
                            resolve(request);
                        }
                    }
                    else {
                        // execute request
                        resolve(request);
                    }
                }
                else {
                    // execute request
                    resolve(request);
                }
            })
        );

    // clear response interceptor
    axiosInstance.interceptors.response.clear();
    // create response interceptor
    axiosInstance.interceptors.response.use(
        async (response) => {
            return response;
        },
        async (error) => {
            // get request config
            const request = error.config;
            // handle unauthorized status (except for login request)
            if (error.response.status === 401 && !request.url?.includes(ROUTE_LOGOUT)) {
                // run the logout process
                await authService.logout();
                return Promise.reject(error);
            }
            return Promise.reject(error);
        }
    );
};