import React, {
    useEffect,
    useRef, useState,
} from "react";
import {useAppSelector} from "../../../redux/hooks";
import {IChatRoom, IChatMessage, IChatUser} from "../index";
import {MdScrollViewer} from "../../md-scroll-viewer";
import {MdButton} from "../../md-button";
import {CircleSpinnerIcon} from "../../../icons/CircleSpinnerIcon";
import {ArrowLeftIcon2} from "../../../icons/ArrowLeftIcon2";
import {AttachmentIcon} from "../../../icons/AttachmentIcon";
import {CrossIcon} from "../../../icons/CrossIcon";
import {ChatIcon2} from "../../../icons/ChatIcon2";
import {SmileIcon} from "../../../icons/SmileIcon";
import {PlusIcon} from "../../../icons/PlusIcon";
import {MinusIcon} from "../../../icons/MinusIcon";
import {RobotIcon} from "../../../icons/RobotIcon";
import {Utils} from "../../../utils/utils";
import './../style.scss';


interface IRoomComponent {
    //userData: IChatUser | null;
    room: IChatRoom | undefined,
    messages: IChatMessage[],
    typingUsers: IChatUser[],
    messagesLoading: boolean;
    supportConnecting: boolean;
    onUserTypingHandler: (roomId: string, userId: string) => void;
    //onUserStartPrintingHandler: (roomId: string, userId: string) => void;
    //onUserEndPrintingHandler: (roomId: string, userId: string) => void;
    backToChatListHandler?: () => void;
    connectSupportUserHandler: (roomId: string) => void;
    disconnectSupportUserHandler: (roomId: string) => void;
    sendMessageHandler: (roomId: string, message: string) => void;
    closeHandler?: (e: React.MouseEvent) => void;
}


export function RoomComponent(props: IRoomComponent) {

    const {
        //userData,
        room,
        messages,
        typingUsers,
        messagesLoading,
        supportConnecting,
        onUserTypingHandler = () => {},
        // onUserStartPrintingHandler = () => {
        // },
        // onUserEndPrintingHandler = () => {
        // },
        backToChatListHandler = () => {
        },
        connectSupportUserHandler = () => {

        },
        disconnectSupportUserHandler = () => {

        },
        closeHandler = () => {
        },
        sendMessageHandler = () => {
        }
    } = props

    const initialized = useRef(false);

    const authState = useAppSelector(state => state.auth);

    const timeZoneOffset = new Date().getTimezoneOffset() / 60;


    const root = useRef(null);
    const header = useRef(null);
    const textArea = useRef(null);
    const messagesList = useRef(null);
    //const printTimer = useRef(null);


    const [scrollToItem, setScrollToItem] = useState<string>("");

    let printTimer: NodeJS.Timeout;


    const onInput = () => {
        //textAreaElement.style.height = "30px";
        // if (printTimer != null) {
        //     clearTimeout(printTimer);
        // }
        //
        // onUserStartPrintingHandler(chat?.id ?? -1, authState.email);
        //
        // printTimer = setTimeout(() => {
        //     onUserEndPrintingHandler(chat?.id ?? -1, authState.email);
        // }, 1500);
    };


    const onChange = () => {
        if (textArea.current == null) {
            return;
        }

        const textAreaElement = textArea.current as HTMLTextAreaElement;

        textAreaElement.style.height = "30px";
        textAreaElement.style.height = Math.min(textAreaElement.scrollHeight, 100) + "px";
    }

    const sendMessage = () => {
        if (textArea.current != null && (textArea.current as HTMLTextAreaElement).value.length > 0) {
            const ta = (textArea.current as HTMLTextAreaElement);
            if (room != null) {
                sendMessageHandler(room.id, ta.value);
            }
            ta.value = "";
        }
    }


    const isSameDay = (a: Date, b: Date) => {
        return a.getFullYear() === b.getFullYear() &&
            a.getMonth() === b.getMonth() &&
            a.getDay() === b.getDay();
    }


    const formatDate = (timeStamp: number) => {
        let dt = new Date(timeStamp);
        let date = dt.getDate();
        let timeDiff = timeStamp - Date.now();
        let diffDays = new Date().getDate() - date;
        let diffMonths = new Date().getMonth() - dt.getMonth();
        let diffYears = new Date().getFullYear() - dt.getFullYear();

        if(diffYears === 0 && diffDays === 0 && diffMonths === 0) {
            return "сегодня"
        }
        else if(diffYears === 0 && diffDays === 1) {
            return "вчера";
        }
        else if(diffYears > 0) {
            return (
                + dt.getDate().toString().padStart(2, "0")
                + " "
                + dt.getMonth().toString().padStart(2, "0")
                + " "
                + dt.getFullYear().toString()
            );
        }
        else {
            return (
                dt.toLocaleString('default', {month: 'short'}).replace(".", "")
                + " "
                + dt.getDate().toString().padStart(2, "0")
            );
        }
    }



    useEffect(() => {
        if(room == undefined) {
            backToChatListHandler();
        }
    }, [room]);


    useEffect(() => {
        if(messages.length > 0) {
            setScrollToItem(
                ("message-" + (/*messages.find(x => !x.read)?.id ??*/ messages[messages.length - 1].id))
            )
        }
    }, [messages]);


    return (
        <div ref={root}
             className="chat-layout">

            <div
                ref={header}
                className="chat-layout-header">

                <div className="chat-layout-header-title-bar">

                    <MdButton
                        icon={
                            <ArrowLeftIcon2
                                width="26px"
                                height="26px"
                                style={{
                                    "path": {
                                        stroke: "#727272"
                                    }
                                }}
                            />
                        }
                        style={{
                            "button": {
                                justifyContent: "center",
                                borderWidth: 0,
                                backgroundColor: "transparent",
                                color: "#727272",
                                fontWeight: 700,
                                borderRadius: "6px",
                                width: "30px",
                                height: "30px",
                                padding: 0
                            },
                            "ink": {
                                backgroundColor: "rgba(230, 230, 230, 0.5)"
                            },
                        }}
                        onClick={(e) => {
                            e.preventDefault();
                            backToChatListHandler();
                        }}
                    />

                    <span className="chat-details">
                        <div className="chat-details-icon">
                            {
                                room?.icon != null
                                    ? (
                                        <img alt="" src={room.icon}/>
                                    )
                                    : (
                                        <div className="icon" style={{ backgroundColor: (Utils.stringToHslColor(room?.name ?? ""))}}>
                                            {
                                                room?.name.split(' ').map((w) => w[0].toUpperCase()).join("")
                                            }
                                        </div>
                                    )
                            }
                        </div>
                        <div className="chat-details-info">
                            <div className="chat-description-name">{room?.name}</div>
                            <div className="chat-description-description">{room?.description}</div>
                        </div>
                    </span>

                    <MdButton
                        style={{
                            "button": {
                                justifyContent: "center",
                                marginLeft: "5px",
                                borderWidth: 0,
                                backgroundColor: "transparent",
                                color: "rgb(0 0 0)",
                                borderRadius: "6px",
                                width: "30px",
                                height: "30px",
                                padding: 0
                            },
                            "ink": {
                                backgroundColor: "rgba(230, 230, 230, 0.5)"
                            }
                        }}
                        disabled={supportConnecting}
                        onClick={() => {
                            if (room != null) {
                                if(room?.users.length == 1) { // TODO: check for support user existing
                                    connectSupportUserHandler(room.id);
                                }
                                else {
                                    disconnectSupportUserHandler(room.id);
                                }
                            }
                        }}
                    >
                        {
                            supportConnecting
                            ? (
                                    <CircleSpinnerIcon
                                        width="14px"
                                        height="14px"
                                        style={{
                                            "circle": {
                                                stroke: "#000000"
                                            }
                                        }}
                                    />
                            )
                            : (
                                room?.users.length == 1 // TODO: check for support user existing
                                    ? (
                                        <PlusIcon
                                            width="18px"
                                            height="18px"
                                            style={{
                                                "path": {
                                                    stroke: "#727272"
                                                }
                                            }}
                                        />
                                    )
                                    : (
                                        <MinusIcon
                                            width="18px"
                                            height="18px"
                                            style={{
                                                "path": {
                                                    stroke: "#727272"
                                                }
                                            }}
                                        />
                                    )
                            )
                        }
                    </MdButton>

                    <MdButton
                        icon={
                            <CrossIcon
                                width="18px"
                                height="18px"
                                style={{
                                    "path": {
                                        fill: "#727272"
                                    }
                                }}
                            />
                        }
                        style={{
                            "button": {
                                justifyContent: "center",
                                marginLeft: "5px",
                                borderWidth: 0,
                                backgroundColor: "transparent",
                                color: "rgb(0 0 0)",
                                borderRadius: "6px",
                                width: "30px",
                                height: "30px",
                                padding: 0
                            },
                            "icon": {
                                marginLeft: 0,
                                marginRight: 0,
                            },
                            "ink": {
                                backgroundColor: "rgba(230, 230, 230, 0.5)"
                            },
                            "text": {
                                display: "none"
                            }
                        }}
                        onClick={closeHandler}
                    />

                </div>

            </div>

            <div className="chat-layout-messages messages">

                <MdScrollViewer
                    //needsScrollBottom={needsScrollBottom}
                    //setNeedsScrollBottom={setNeedsScrollBottom}
                    scrollToItem={scrollToItem}
                >

                    {
                        messagesLoading && (
                            <div className="loading-messages-block">
                                <CircleSpinnerIcon
                                    width="20px"
                                    height="20px"
                                    style={{
                                        "svg": {
                                            margin: "2px"
                                        },
                                        "circle": {
                                            stroke: "#F9952A"
                                        }
                                    }}
                                />
                                <span>Загрузка сообщений</span>
                            </div>
                        )
                    }

                    {
                        !messagesLoading && (
                            messages == null || messages.length == 0
                                ? (
                                    <div className="no-items-message">
                                        <div className="no-items-message-icon">
                                            <ChatIcon2 width="40px" height="40px" style={{
                                                "path": {
                                                    fill: "rgb(100 100 100)"
                                                }
                                            }}/>
                                        </div>
                                        <div className="no-items-message-caption">Нет сообщений для отображения</div>
                                        <div className="no-items-message-content">Начните общение, отправьте сообщение первым
                                        </div>
                                    </div>
                                )
                                : (
                                    messages.map((message, messageIndex) => {
                                        const fromUser = room?.users.find(x => x.id == message.userId);
                                        const prevMessage = messageIndex - 1 >=0 ? messages[messageIndex - 1] : null;
                                        const nextMessage = messages.length > messageIndex + 1 ? messages[messageIndex + 1] : null;
                                        return (
                                            <React.Fragment key={messageIndex}>
                                                {
                                                    (messages.some(x => new Date(x.createdAt).getDay() - new Date().getDay() != 0)) && (
                                                        ((prevMessage != null && !isSameDay(new Date(message.createdAt), new Date(prevMessage.createdAt))) || messageIndex == 0) && (
                                                            <li className="messages-gap">
                                                                {
                                                                    formatDate(new Date(message.createdAt).getTime() - new Date().getTimezoneOffset() * 60 * 1000)
                                                                }
                                                            </li>
                                                        )
                                                    )


                                                    // messages.some(x => new Date(x.createdAt).getDay() - new Date().getDay() != 0) && prevMessage?.createdAt) && (
                                                    //     <li>...</li>
                                                    // )
                                                }
                                                {
                                                    message.userId == authState.userId
                                                        ? (
                                                            <li className="self"
                                                                id={("message-" + message.id)}
                                                                style={{marginTop: ((prevMessage != null && prevMessage.userId == message.userId) ? "2px" : "15px")}}>
                                                                <div className="message-item">
                                                                    <div className="message-item-header"></div>
                                                                    <div className="message-item-content">
                                                                        <div className="message">
                                                                            {message.content}
                                                                            <span className="message-time">
                                                                         {
                                                                             (new Date(message.createdAt).getHours() - timeZoneOffset).toString().padStart(2, "0") + ":" + new Date(message.createdAt).getMinutes().toString().padStart(2, "0")
                                                                         }
                                                                                <div
                                                                                    className={"message-status" + (message.read ? " --read" : "")}>
                                                                             <div className="checkmark"></div>
                                                                             <div className="checkmark"></div>
                                                                         </div>
                                                                     </span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </li>
                                                        )
                                                        : (
                                                            <li className={"other" + (message.userId != authState.userId && !message.read ? " --unread-message" : "")}
                                                                id={("message-" + message.id)}
                                                                style={{marginTop: ((prevMessage != null && prevMessage.userId == message.userId) ? "2px" : "15px")}}>
                                                                <div className="message-item">
                                                                    {
                                                                        (prevMessage == null || prevMessage.userId != message.userId) && (
                                                                            <div className="message-item-header">
                                                                                {
                                                                                    fromUser?.firstName ?? "Ассистент"
                                                                                }
                                                                            </div>
                                                                        )
                                                                    }
                                                                    <div className="message-item-content">
                                                                        <div className="user-avatar-container">
                                                                            {
                                                                                (prevMessage == null || prevMessage.userId != message.userId) && (
                                                                                    <div className="user-avatar-wrapper">
                                                                                        <div className="icon"
                                                                                             style={{backgroundColor: (Utils.stringToHslColor(fromUser?.firstName ?? "Ассистент"))}}>
                                                                                            {
                                                                                                // TODO: change to fullname
                                                                                                fromUser != null
                                                                                                ? (
                                                                                                    (fromUser?.firstName[0].toUpperCase() ?? "")
                                                                                                    + (fromUser?.lastName[0].toUpperCase() ?? "")
                                                                                                )
                                                                                                : (
                                                                                                    <RobotIcon
                                                                                                        width="22px"
                                                                                                        height="22px"
                                                                                                        style={{
                                                                                                            "svg":{
                                                                                                                marginTop: "-2px"
                                                                                                            },
                                                                                                            "path":{
                                                                                                                fill: "#FFFFFF"
                                                                                                            }
                                                                                                        }}
                                                                                                    />
                                                                                                )
                                                                                            }
                                                                                        </div>
                                                                                    </div>
                                                                                )
                                                                            }
                                                                        </div>
                                                                        <div className="message">
                                                                            {message.content}
                                                                            <span className="message-time">
                                                                                 {
                                                                                     (new Date(message.createdAt).getHours() - timeZoneOffset).toString().padStart(2, "0") + ":" + new Date(message.createdAt).getMinutes().toString().padStart(2, "0")
                                                                                 }
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </li>
                                                        )
                                                }
                                            </React.Fragment>
                                        )
                                    })
                                )
                        )
                    }

                </MdScrollViewer>

                {
                    typingUsers.length > 0 && (
                        <div className="printing-status">
                            {
                                typingUsers.filter(x => x.email != authState.email).map((user, index) => {
                                    return (
                                        <React.Fragment key={index}>
                                            <span>{user.firstName}</span>
                                            {
                                                index > 0 && (
                                                    <span>, </span>
                                                )
                                            }
                                        </React.Fragment>

                                    )
                                })
                                + (typingUsers.length > 1 ? "печатают..." : "печатает...")
                            }
                        </div>
                    )
                }

                {/*{*/}
                {/*    chat != null && chat.printingUsers.length > 0 && (*/}
                {/*        <div className="printing-status">*/}
                {/*            {*/}
                {/*                chat.printingUsers.filter(x => x.email != authState.email).map((user, index) => {*/}
                {/*                    return (*/}
                {/*                        <span key={index}>{user.fullName} печатает...</span>*/}
                {/*                    )*/}
                {/*                })*/}
                {/*            }*/}
                {/*        </div>*/}
                {/*    )*/}
                {/*}*/}

            </div>

            <div className="chat-footer">

                <textarea
                    ref={textArea}
                    placeholder="Сообщение в чат"
                    rows={5}
                    style={{
                        height: "30px"
                    }}
                    onInput={onInput}
                    onChange={onChange}
                    onKeyDown={(e) => {
                        if (e.which === 13 && e.ctrlKey && textArea.current != null) {
                            e.preventDefault();
                            sendMessage();
                        }
                    }}
                />

                <div className="chat-footer-controls">

                    <div className="chat-footer-tools-block">

                        <MdButton
                            icon={
                                <PlusIcon
                                    width="14px"
                                    height="14px"
                                    style={{
                                        "path": {
                                            stroke: "#878686"
                                        }
                                    }}
                                />
                            }
                            style={{
                                "button": {
                                    alignItems: "center",
                                    justifyContent: "center",
                                    borderWidth: 0,
                                    backgroundColor: "#FFFFFF",
                                    color: "rgb(0 0 0)",
                                    borderRadius: "6px",
                                    boxShadow: "0px 3px 1px -2px rgba(0, 0, 0, 0.16), 0px 1px 2px 0px rgba(0, 0, 0, 0.1), 0px 1px 5px 0px rgba(0, 0, 0, 0.08)",
                                    marginRight: "0px",
                                    width: "28px",
                                    height: "28px",
                                    padding: 0
                                },
                                "ink": {
                                    backgroundColor: "rgba(230, 230, 230, 0.5)"
                                },
                            }}
                        />

                        <MdButton
                            icon={
                                <SmileIcon
                                    width="20px"
                                    height="20px"
                                    style={{
                                        "path": {
                                            fill: "#878686"
                                        }
                                    }}
                                />
                            }
                            style={{
                                "button": {
                                    marginLeft: "5px",
                                    borderWidth: 0,
                                    backgroundColor: "transparent",
                                    color: "rgb(0 0 0)",
                                    borderRadius: "6px",
                                    marginRight: "0px",
                                    height: "28px",
                                    padding: "2px"
                                },
                                "ink": {
                                    backgroundColor: "rgba(230, 230, 230, 0.5)"
                                },
                            }}
                        />

                        <MdButton
                            icon={
                                <AttachmentIcon
                                    width="16px"
                                    height="16px"
                                    style={{
                                        "path": {
                                            fill: "#878686"
                                        }
                                    }}
                                />
                            }
                            style={{
                                "button": {
                                    marginLeft: "5px",
                                    borderWidth: 0,
                                    backgroundColor: "transparent",
                                    color: "rgb(0 0 0)",
                                    borderRadius: "6px",
                                    marginRight: "0px",
                                    height: "28px",
                                    padding: "2px"
                                },
                                "ink": {
                                    backgroundColor: "rgba(230, 230, 230, 0.5)"
                                },
                                "icon": {
                                    marginLeft: 0,
                                    marginRight: 0,
                                },
                            }}
                        />

                    </div>

                    <div className="chat-footer-send-block">

                        <MdButton
                            style={{
                                "button": {
                                    borderWidth: 0,
                                    backgroundColor: "#000000",
                                    color: "rgb(0 0 0)",
                                    borderRadius: "6px",
                                    marginRight: "0px",
                                    height: "28px",
                                    padding: "0 8px",
                                    boxShadow: "0px 3px 1px -2px rgba(0, 0, 0, 0.16), 0px 1px 2px 0px rgba(0, 0, 0, 0.1), 0px 1px 5px 0px rgba(0, 0, 0, 0.08)",
                                },
                                "ink": {
                                    backgroundColor: "rgba(230, 230, 230, 0.5)"
                                },
                                "text": {
                                    color: "#FFFFFF",
                                    fontWeight: 500,
                                    fontSize: "12px"
                                }
                            }}
                            onClick={() => sendMessage()}
                        >
                            Отправить
                        </MdButton>

                    </div>

                </div>

            </div>

        </div>
    )
}