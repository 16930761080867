import React, { useEffect, useRef, useState} from "react";
import {useTranslation} from "react-i18next";
import {useAppSelector} from "../../../../../redux/hooks";
import axios, {CancelTokenSource} from "axios";
import {ICompanyDTO} from "../../../../../interfaces/ICompanyDTO";
import {IOrderDTO} from "../../../../../interfaces/IOrderDTO";
import {TimeOfDay} from "../../../../../enums/TimeOfDay";
import {DateService} from "../../../../../services/DateService";
import {TripService} from "../../../../../services/TripService";
import {HotelListComponent} from "./hotelListComponent";
import {MdMenu} from "../../../../../components/md-menu";
import {MdToggleButton} from "../../../../../components/md-toggle-button";
import {MdSelect} from "../../../../../components/md-select";
import {MdButton} from "../../../../../components/md-button";
import {RefsService} from "../../../../../services/RefsService";
import {MinusIcon} from "../../../../../icons/MinusIcon";
import {PlusIcon} from "../../../../../icons/PlusIcon";
import {SunriseIcon} from "../../../../../icons/SunriseIcon";
import {SunIcon} from "../../../../../icons/SunIcon";
import {MoonIcon} from "../../../../../icons/MoonIcon";
import {Guid, Utils} from "../../../../../utils/utils";
import "./style.scss";
import {TripType} from "../searchFlightsComponent";


export interface IHotelRoute {
    id: string;
    guests: number;
    rate: number;
    location: IAutoCompleteItem;
    checkInDate?: string;
    checkInTimeOfDay?: TimeOfDay;
    checkInTime?: string;
    checkOutDate?: string;
    checkOutTimeOfDay?: TimeOfDay;
    checkOutTime?: string;
    notes: string;
}

// TODO: rename to HotelAutoCompleteItem
interface IAutoCompleteItem {
    name: string;
    country_name: string;
    country_code: string;
    city_name: string;
    code: string;
}

interface ISearchHotelsComponent {
    order?: IOrderDTO;
    //selectHotelHandler: (hotel: IHotelVariant, tariff: IHotelTariffInfo, searchResponse: any) => void;
    selectedCompany: ICompanyDTO | undefined;
}


export function SearchHotelsComponent(props: ISearchHotelsComponent) {

    const {
        order,
        //selectHotelHandler,
        selectedCompany
    } = props;

    const {t} = useTranslation();

    const authState = useAppSelector((state) => state.auth);

    const refsService = RefsService();

    const tripService = TripService();

    const dateService = DateService();


    const minGuestsCount = 1;
    const maxGuestsCount = 6;

    const minRate = 3;

    const [searchRoute, setSearchRoute] =
        useState<IHotelRoute>(
            {
                id: Guid.newGuid(),
                guests: minGuestsCount,
                rate: minRate,
                location: {
                    name: "",
                    country_code: "",
                    country_name: "",
                    city_name: "",
                    code: "",
                },
                notes: ""
            }
        );

    const [locationFocused, setLocationFocused] = useState<boolean>(false);
    const [autoComplete, setAutoComplete] = useState<IAutoCompleteItem[]>([]);
    const [checkInDateFocused, setCheckInDateFocused] = useState<boolean>(false);
    const [checkOutDateFocused, setCheckOutDateFocused] = useState<boolean>(false);

    const [providersPending, setProvidersPending] = useState<boolean>(false);
    const [isProvidersNotFound, setIsProvidersNotFound] = useState<boolean>(false);
    const [providers, setProviders] = useState<string[]>([]);
    const [selectedProviders, setSelectedProviders] = useState<string[]>([]);

    const [searchCT, setSearchCT] = useState<CancelTokenSource|undefined>(undefined);
    const [searchPending, setSearchPending] = useState(false);
    const [searchNotFound, setSearchNotFound] = useState(false);
    const [variants, setVariants] = useState<any[]>([]); // TODO: change to structure

    const [searchResponse, setSearchResponse] = useState<any>(null);



    const days = [
        t("bookingPage.sundayShort"),
        t("bookingPage.mondayShort"),
        t("bookingPage.tuesdayShort"),
        t("bookingPage.wednesdayShort"),
        t("bookingPage.thursdayShort"),
        t("bookingPage.fridayShort"),
        t("bookingPage.saturdayShort"),
    ];

    const months = [
        t("bookingPage.januaryShort"),
        t("bookingPage.februaryShort"),
        t("bookingPage.marchShort"),
        t("bookingPage.aprilShort"),
        t("bookingPage.mayShort"),
        t("bookingPage.juneShort"),
        t("bookingPage.julyShort"),
        t("bookingPage.augustShort"),
        t("bookingPage.septemberShort"),
        t("bookingPage.octoberShort"),
        t("bookingPage.novemberShort"),
        t("bookingPage.decemberShort"),
    ];

    const [timeOfDayOptions] = useState<Map<TimeOfDay, { name: string, interval: number[] }>>(
        new Map([
            [TimeOfDay.None,      { name: t("bookingPage.anyTime"),         interval: [] }],
            [TimeOfDay.Exact,     { name: t("bookingPage.approximateTime"), interval: [] }],
            [TimeOfDay.Morning,   { name: t("bookingPage.morning"),         interval: [4, 12] }],
            [TimeOfDay.Afternoon, { name: t("bookingPage.afternoon"),       interval: [10, 16] }],
            [TimeOfDay.Evening,   { name: t("bookingPage.evening"),         interval: [16, 23] }]
        ])
    );



    const setGuests = (guests: number) => {
        setSearchRoute(route =>
            ({
                ...route,
                guests: guests
            })
        );
    }

    const setRate = (rate: number) => {
        setSearchRoute(route =>
            ({
                ...route,
                rate: rate
            })
        );
    }

    const setLocation = (location: IAutoCompleteItem) => {
        setSearchRoute(route =>
            ({
                ...route,
                location: location
            })
        );
    }

    const setCheckInDate = (date: string | undefined) => {
        setSearchRoute(route =>
            ({
                ...route,
                checkInDate: date,
                checkOutDate: (route.checkOutDate != undefined && date != undefined && new Date(route.checkOutDate) < new Date(date) ? undefined : route.checkOutDate)
            })
        );
    }

    const setCheckInTimeOfDay = (timeOfDay: TimeOfDay | undefined) => {
        setSearchRoute(route =>
            ({
                ...route,
                checkInTimeOfDay: timeOfDay
            })
        );
    }

    const setCheckInTime = (time: string | undefined) => {
        setSearchRoute(route =>
            ({
                ...route,
                checkInTime: time
            })
        );
    }

    const setCheckOutDate = (date: string | undefined) => {
        setSearchRoute(route =>
            ({
                ...route,
                checkOutDate: date
            })
        );
    }

    const setCheckOutTimeOfDay = (timeOfDay: TimeOfDay | undefined) => {
        setSearchRoute(route =>
            ({
                ...route,
                checkOutTimeOfDay: timeOfDay
            })
        );
    }

    const setCheckOutTime = (time: string | undefined) => {
        setSearchRoute(route =>
            ({
                ...route,
                checkOutTime: time
            })
        );
    }


    const locationFocusHandler = async () => {
        setLocationFocused(true);
        setAutoComplete([]);
        const directionText = searchRoute.location.name;
        if(Utils.isEmpty(directionText)) {
            return;
        }
        const autoCompleteItems = await refsService.getAviaCities(directionText, "ru");
        setAutoComplete(autoCompleteItems);
    }
    const locationBlurHandler = async () => {
        setLocationFocused(false);
    }
    const locationTextChangeHandler = async (text: string) => {

    }

    const checkInDateFocusHandler = async () => {
        setCheckInDateFocused(true);
    }
    const checkInDateBlurHandler = async () => {
        setCheckInDateFocused(false);
    }

    const checkOutDateFocusHandler = async () => {
        setCheckOutDateFocused(true);
    }
    const checkOutDateBlurHandler = async () => {
        setCheckOutDateFocused(false);
    }


    const getProviders = async () => {

    };


    const startSearch = async () => {

    };

    const cancelSearch = () => {
        if (searchCT) {
            searchCT.cancel();
        }
    }


    // const selectHotel = (variant: IAviaVariant, tariff: IAviaTariffInfo) => {
    //     selectHotelHandler(variant, tariff, searchResponse);
    // }


    const checkDates = () => {
        const today = new Date();
        today.setHours(0,0,0,0);

        let departDateRange: number[] | undefined = undefined;
        if(searchRoute.checkInDate != undefined) {
            switch (searchRoute.checkInTimeOfDay) {
                case TimeOfDay.None:
                    departDateRange = [
                        new Date(searchRoute.checkInDate + "T00:00:00").getTime(),
                        new Date(searchRoute.checkInDate + "T23:59:59").getTime()
                    ];
                    break;
                case TimeOfDay.Exact:
                    departDateRange = [new Date(searchRoute.checkInDate + "T" + searchRoute.checkInTime).getTime()];
                    break;
                case TimeOfDay.Morning:
                    departDateRange = [
                        new Date(searchRoute.checkInDate + "T04:00:00").getTime(),
                        new Date(searchRoute.checkInDate + "T12:00:00").getTime()
                    ];
                    break;
                case TimeOfDay.Afternoon:
                    departDateRange = [
                        new Date(searchRoute.checkInDate + "T10:00:00").getTime(),
                        new Date(searchRoute.checkInDate + "T16:00:00").getTime()
                    ];
                    break;
                case TimeOfDay.Evening:
                    departDateRange = [
                        new Date(searchRoute.checkInDate + "T16:00:00").getTime(),
                        new Date(searchRoute.checkInDate + "T23:00:00").getTime(),
                    ];
                    break;
            }
        }

        let returnDateRange: number[] | undefined = undefined;
        if(searchRoute.checkOutDate != undefined) {
            switch (searchRoute.checkOutTimeOfDay) {
                case TimeOfDay.None:
                    returnDateRange = [
                        new Date(searchRoute.checkOutDate + "T00:00:00").getTime(),
                        new Date(searchRoute.checkOutDate + "T23:59:59").getTime()
                    ];
                    break;
                case TimeOfDay.Exact:
                    returnDateRange = [new Date(searchRoute.checkOutDate + "T" + searchRoute.checkOutTime).getTime()];
                    break;
                case TimeOfDay.Morning:
                    returnDateRange = [
                        new Date(searchRoute.checkOutDate + "T04:00:00").getTime(),
                        new Date(searchRoute.checkOutDate + "T12:00:00").getTime()
                    ];
                    break;
                case TimeOfDay.Afternoon:
                    returnDateRange = [
                        new Date(searchRoute.checkOutDate + "T10:00:00").getTime(),
                        new Date(searchRoute.checkOutDate + "T16:00:00").getTime()
                    ];
                    break;
                case TimeOfDay.Evening:
                    returnDateRange = [
                        new Date(searchRoute.checkOutDate + "T16:00:00").getTime(),
                        new Date(searchRoute.checkOutDate + "T23:00:00").getTime(),
                    ];
                    break;
            }
        }

        let hasError = false;

        if(searchRoute.checkInDate != undefined) {
            if(new Date(searchRoute.checkInDate) < today) {
                hasError = true;
            }
            if(departDateRange != undefined && returnDateRange != undefined) {
                if(returnDateRange.every(r => departDateRange?.every(d => r <= d))) {
                    hasError = true;
                }
            }
        }

        return hasError;
    }


    useEffect(() => {
        getProviders();
    }, [selectedCompany]);


    return (
        <React.Fragment>
            <div className="trip-page-search-form">


                <div className="trip-page-search-form-header">

                    <MdMenu
                        behaviour={'none'}
                        header={(
                            <div className="filter-menu-header">
                                {t("bookingPage.guests")}: {searchRoute.guests.toString().padStart(2, "0")}
                            </div>
                        )}
                        disabled={searchPending}
                    >
                        <div className="passengers-input-control">
                            <MdButton
                                icon={
                                    <MinusIcon
                                        width="12px"
                                        height="12px"
                                        style={{
                                            "path": {
                                                stroke: "#878686"
                                            }
                                        }}
                                    />
                                }
                                style={{
                                    "button": {
                                        alignItems: "center",
                                        justifyContent: "center",
                                        borderWidth: 0,
                                        backgroundColor: "#FFFFFF",
                                        color: "rgb(0 0 0)",
                                        borderRadius: "6px",
                                        boxShadow: "0px 3px 1px -2px rgba(0, 0, 0, 0.16), 0px 1px 2px 0px rgba(0, 0, 0, 0.1), 0px 1px 5px 0px rgba(0, 0, 0, 0.08)",
                                        marginRight: "0px",
                                        width: "22px",
                                        height: "22px",
                                        padding: 0
                                    },
                                    "ink": {
                                        backgroundColor: "rgba(230, 230, 230, 0.5)"
                                    },
                                }}
                                onClick={() => {
                                    if (searchRoute.guests > minGuestsCount) {
                                        setGuests(searchRoute.guests - 1);
                                    }
                                }}
                            />

                            <input
                                type="number"
                                min={minGuestsCount}
                                max={maxGuestsCount}
                                value={searchRoute.guests}
                                readOnly={true}
                            />

                            <MdButton
                                icon={
                                    <PlusIcon
                                        width="12px"
                                        height="12px"
                                        style={{
                                            "path": {
                                                stroke: "#878686"
                                            }
                                        }}
                                    />
                                }
                                style={{
                                    "button": {
                                        alignItems: "center",
                                        justifyContent: "center",
                                        borderWidth: 0,
                                        backgroundColor: "#FFFFFF",
                                        color: "rgb(0 0 0)",
                                        borderRadius: "6px",
                                        boxShadow: "0px 3px 1px -2px rgba(0, 0, 0, 0.16), 0px 1px 2px 0px rgba(0, 0, 0, 0.1), 0px 1px 5px 0px rgba(0, 0, 0, 0.08)",
                                        marginRight: "0px",
                                        width: "22px",
                                        height: "22px",
                                        padding: 0
                                    },
                                    "ink": {
                                        backgroundColor: "rgba(230, 230, 230, 0.5)"
                                    },
                                }}
                                onClick={() => {
                                    if (searchRoute.guests < maxGuestsCount) {
                                        setGuests(searchRoute.guests + 1);
                                    }
                                }}
                            />
                        </div>
                    </MdMenu>

                </div>


                <div className="trip-page-search-form-content">

                    <div className={("trip-page-search-form-field" + (searchPending ? " --disabled" : ""))}>
                        <label
                            className={((locationFocused || (searchRoute.location.city_name ?? searchRoute.location.name).length > 0 ? "--displaced" : ""))}>
                            {t("bookingPage.location")}
                        </label>
                        <MdSelect
                            isEditable={true}
                            text={(searchRoute.location.city_name ?? searchRoute.location.name)}
                            setText={(value) => setLocation(autoComplete.find(x => x.city_name == value || x.name == value) ?? {
                                name: "",
                                country_code: "",
                                country_name: "",
                                city_name: value,
                                code: ""
                            })}
                            selectedItem={searchRoute.location}
                            setSelectedItem={(item) => setLocation(item)}
                            onFocus={() => locationFocusHandler()}
                            onBlur={() => locationBlurHandler()}
                            itemSource={autoComplete}
                            displayMemberPath={"name"}
                            selectedItemTemplate={(item: IAutoCompleteItem) => {
                                const details = [item.name, item.country_name].filter(x => x != null && x.length > 0);
                                return (
                                    <div className="trip-page-search-form-city-item"
                                         style={{padding: '26px 10px 6px 20px'}}>
                                        <div className="trip-page-search-form-city-item-header">
                                            <div className="trip-page-search-form-city-item-name">
                                                {item.city_name ?? item.name}
                                            </div>
                                            <div className="trip-page-search-form-city-item-code">
                                                {item.code}
                                            </div>
                                        </div>
                                        <div className="trip-page-search-form-city-item-details">
                                            {
                                                item.country_code.length > 0 && (
                                                    <span
                                                        className={("flag-icon flag-icon-" + item.country_code.toLowerCase())}></span>
                                                )
                                            }
                                            <div className="trip-page-search-form-city-item-details-text">
                                                {
                                                    (details.length > 0 ? details.join(', ') : "")
                                                }
                                            </div>
                                        </div>
                                    </div>
                                )
                            }}
                            itemTemplate={(item: IAutoCompleteItem) => {
                                const details = [item.name, item.country_name].filter(x => x != null && x.length > 0);
                                return (
                                    <div className="trip-page-search-form-city-item">
                                        <div className="trip-page-search-form-city-item-header">
                                            <div className="trip-page-search-form-city-item-name">
                                                {item.city_name ?? item.name}
                                            </div>
                                            <div className="trip-page-search-form-city-item-code">
                                                {item.code}
                                            </div>
                                        </div>
                                        <div className="trip-page-search-form-city-item-details">
                                            {
                                                item.country_code.length > 0 && (
                                                    <div className="trip-page-search-form-city-item-flag">
                                                        <span
                                                            className={("flag-icon flag-icon-" + item.country_code.toLowerCase())}></span>
                                                    </div>
                                                )
                                            }
                                            <div className="trip-page-search-form-city-item-details-text">
                                                {
                                                    (details.length > 0 ? details.join(', ') : "")
                                                }
                                            </div>
                                        </div>
                                    </div>
                                )
                            }}
                            onTextChange={(value) => locationTextChangeHandler(value)}
                            style={{
                                "root": {
                                    width: "100%",
                                    height: "100%",
                                    margin: 0,
                                    border: "none"
                                },
                                "text-field": {
                                    padding: "7px 10px 6px 20px",
                                    height: "100%",
                                    fontWeight: 500,
                                    fontSize: "13px"
                                },
                                "toggle-button-icon": {
                                    display: "none"
                                }
                            }}
                        />
                    </div>


                    <div className={("trip-page-search-form-field" + (searchPending ? " --disabled" : ""))}
                         style={{
                             marginLeft: "6px",
                             minWidth: "280px",
                             backgroundColor: ((searchRoute.checkOutDate != undefined && searchRoute.checkInDate != undefined && new Date(searchRoute.checkOutDate) < new Date(searchRoute.checkInDate)) ? "rgba(255, 0, 0, 0.2)" : "transparent")
                         }}>
                        <label className={(checkInDateFocused || searchRoute.checkInDate != null ? "--displaced" : "")}>
                            {t("bookingPage.checkInDate")}
                        </label>
                        <input
                            className={(checkInDateFocused ? "--focused" : "")}
                            type="date"
                            min={dateService.dateToStringForDatePicker(new Date()) + "T00:00:00"}
                            value={searchRoute.checkInDate ?? ""}
                            onFocus={() => checkInDateFocusHandler()}
                            onBlur={() => checkInDateBlurHandler()}
                            onChange={(e) => setCheckInDate(e.target.value)}
                            disabled={searchPending}
                        />
                        {
                            (searchRoute.checkInDate != null && searchRoute.checkInTimeOfDay == null) && (
                                <input
                                    className={(checkInDateFocused ? "--focused" : "")}
                                    type="time"
                                    value={searchRoute.checkInTime ?? ""}
                                    onFocus={() => checkInDateFocusHandler()}
                                    onBlur={() => checkInDateBlurHandler()}
                                    onChange={(e) => setCheckInTime(e.target.value)}
                                    disabled={searchPending}
                                />
                            )
                        }
                        {
                            searchRoute.checkInDate != null && (
                                <div
                                    className={("trip-page-search-form-datetime-item" + (checkInDateFocused ? " --hidden" : ""))}>
                                    <div className="trip-page-search-form-datetime-item-date">
                                        {
                                            days[new Date(searchRoute.checkInDate).getDay()]
                                            + ", " + new Date(searchRoute.checkInDate).getDate().toString().padStart(2, "0") + " "
                                            + months[new Date(searchRoute.checkInDate).getMonth()]
                                        }
                                    </div>
                                    <div className="trip-page-search-form-datetime-item-time">
                                        {
                                            (() => {
                                                switch (searchRoute.checkInTimeOfDay) {
                                                    case TimeOfDay.Exact:
                                                        return (
                                                            <>
                                                                <span style={{marginLeft: "16px"}}>
                                                                    {searchRoute.checkInTime?.toString() ?? "00:00"}
                                                                </span>
                                                            </>
                                                        )
                                                    case TimeOfDay.Morning:
                                                        return (
                                                            <>
                                                                <SunriseIcon
                                                                    width="16px"
                                                                    height="16px"
                                                                />
                                                                <span>
                                                                    {timeOfDayOptions.get(TimeOfDay.Morning)?.interval.map(x => x.toString().padStart(2, "0") + ":00").join(" - ")}
                                                                </span>
                                                            </>
                                                        )
                                                    case TimeOfDay.Afternoon:
                                                        return (
                                                            <>
                                                                <SunIcon
                                                                    width="16px"
                                                                    height="16px"
                                                                />
                                                                <span>
                                                                    {timeOfDayOptions.get(TimeOfDay.Afternoon)?.interval.map(x => x.toString().padStart(2, "0") + ":00").join(" - ")}
                                                                </span>
                                                            </>
                                                        )
                                                    case TimeOfDay.Evening:
                                                        return (
                                                            <>
                                                                <MoonIcon
                                                                    width="16px"
                                                                    height="16px"
                                                                />
                                                                <span>
                                                                    {timeOfDayOptions.get(TimeOfDay.Evening)?.interval.map(x => x.toString().padStart(2, "0") + ":00").join(" - ")}
                                                                </span>
                                                            </>
                                                        )
                                                    case TimeOfDay.None:
                                                    default:
                                                        return "--:--"
                                                }
                                            })()
                                        }
                                    </div>
                                </div>
                            )
                        }
                        <MdMenu
                            behaviour={'none'}
                            header={(
                                <div className="departure-time-type">
                                    {
                                        timeOfDayOptions.get(searchRoute.checkInTimeOfDay ?? TimeOfDay.None)?.name
                                    }
                                </div>
                            )}
                            style={{
                                "root": {
                                    position: 'absolute',
                                    bottom: "2px",
                                    right: "3px",
                                }
                            }}
                            disabled={searchPending}
                        >
                            <div className="departure-time-type-variants">
                                {
                                    Array.from(timeOfDayOptions.entries()).map((entry, entryIndex) => {
                                        const [ variant, variantDetails ] = entry;
                                        return (
                                            <React.Fragment key={entryIndex}>
                                                <MdToggleButton
                                                    buttonStyle={{
                                                        flex: 1,
                                                        minInlineSize: "fit-content",
                                                        display: "flex",
                                                        alignItems: "center",
                                                        padding: "5px 10px",
                                                        borderRadius: "4px",
                                                        minWidth: "max-content",
                                                        minHeight: "24px",
                                                        maxHeight: "52px",
                                                        fontSize: "12px",
                                                        color: ((searchRoute.checkInTimeOfDay === variant) ? "rgb(62, 92, 184)" : "#C1C1C1"),
                                                        border: ((searchRoute.checkInTimeOfDay === variant) ? "1px solid rgb(62, 92, 184)" : "1px solid #CCCCCC"),
                                                    }}
                                                    inkStyle={{
                                                        backgroundColor: ((searchRoute.checkInTimeOfDay === variant) ? "rgb(62, 92, 184)" : "#C1C1C1"),
                                                        opacity: 0.1
                                                    }}
                                                    checked={(searchRoute.checkInTimeOfDay === variant)}
                                                    onClick={() => {
                                                        setCheckOutTimeOfDay(variant);
                                                    }}
                                                >
                                                    {
                                                        variantDetails.name
                                                    }
                                                </MdToggleButton>
                                            </React.Fragment>
                                        )
                                    })
                                }
                            </div>
                        </MdMenu>
                    </div>


                    <div className={("trip-page-search-form-field" + (searchPending ? " --disabled" : ""))}
                         style={{
                             marginLeft: "6px",
                             minWidth: "280px",
                             backgroundColor: ((searchRoute.checkOutDate != undefined && searchRoute.checkInDate != undefined && new Date(searchRoute.checkOutDate) < new Date(searchRoute.checkInDate)) ? "rgba(255, 0, 0, 0.2)" : "transparent")
                         }}>
                        <label
                            className={(checkOutDateFocused || searchRoute.checkOutDate != null ? "--displaced" : "")}>
                            {t("bookingPage.checkOutDate")}
                        </label>
                        <input
                            className={(checkOutDateFocused ? "--focused" : "")}
                            type="date"
                            min={dateService.dateToStringForDatePicker(new Date()) + "T00:00:00"}
                            value={searchRoute.checkOutDate ?? ""}
                            onFocus={() => checkOutDateFocusHandler()}
                            onBlur={() => checkOutDateBlurHandler()}
                            onChange={(e) => setCheckOutDate(e.target.value)}
                            disabled={searchPending}
                        />
                        {
                            (searchRoute.checkOutDate != null && searchRoute.checkOutTimeOfDay == null) && (
                                <input
                                    className={(checkOutDateFocused ? "--focused" : "")}
                                    type="time"
                                    value={searchRoute.checkOutTime ?? ""}
                                    onFocus={() => checkOutDateFocusHandler()}
                                    onBlur={() => checkOutDateBlurHandler()}
                                    onChange={(e) => setCheckOutTime(e.target.value)}
                                    disabled={searchPending}
                                />
                            )
                        }
                        {
                            searchRoute.checkOutDate != null && (
                                <div
                                    className={("trip-page-search-form-datetime-item" + (checkOutDateFocused ? " --hidden" : ""))}>
                                    <div className="trip-page-search-form-datetime-item-date">
                                        {
                                            days[new Date(searchRoute.checkOutDate).getDay()]
                                            + ", " + new Date(searchRoute.checkOutDate).getDate().toString().padStart(2, "0") + " "
                                            + months[new Date(searchRoute.checkOutDate).getMonth()]
                                        }
                                    </div>
                                    <div className="trip-page-search-form-datetime-item-time">
                                        {
                                            (() => {
                                                switch (searchRoute.checkOutTimeOfDay) {
                                                    case TimeOfDay.Exact:
                                                        return (
                                                            <>
                                                                <span style={{marginLeft: "16px"}}>
                                                                    {searchRoute.checkOutTime?.toString() ?? "00:00"}
                                                                </span>
                                                            </>
                                                        )
                                                    case TimeOfDay.Morning:
                                                        return (
                                                            <>
                                                                <SunriseIcon
                                                                    width="16px"
                                                                    height="16px"
                                                                />
                                                                <span>
                                                                    {timeOfDayOptions.get(TimeOfDay.Morning)?.interval.map(x => x.toString().padStart(2, "0") + ":00").join(" - ")}
                                                                </span>
                                                            </>
                                                        )
                                                    case TimeOfDay.Afternoon:
                                                        return (
                                                            <>
                                                                <SunIcon
                                                                    width="16px"
                                                                    height="16px"
                                                                />
                                                                <span>
                                                                    {timeOfDayOptions.get(TimeOfDay.Afternoon)?.interval.map(x => x.toString().padStart(2, "0") + ":00").join(" - ")}
                                                                </span>
                                                            </>
                                                        )
                                                    case TimeOfDay.Evening:
                                                        return (
                                                            <>
                                                                <MoonIcon
                                                                    width="16px"
                                                                    height="16px"
                                                                />
                                                                <span>
                                                                    {timeOfDayOptions.get(TimeOfDay.Evening)?.interval.map(x => x.toString().padStart(2, "0") + ":00").join(" - ")}
                                                                </span>
                                                            </>
                                                        )
                                                    case TimeOfDay.None:
                                                    default:
                                                        return "--:--"
                                                }
                                            })()
                                        }
                                    </div>
                                </div>
                            )
                        }
                        <MdMenu
                            behaviour={'none'}
                            header={(
                                <div className="return-time-type">
                                    {
                                        timeOfDayOptions.get(searchRoute.checkOutTimeOfDay ?? TimeOfDay.None)?.name
                                    }
                                </div>
                            )}
                            disabled={searchPending}
                            style={{
                                "root": {
                                    position: 'absolute',
                                    bottom: "2px",
                                    right: "3px",
                                }
                            }}
                        >
                            <div className="return-time-type-variants">
                                {
                                    Array.from(timeOfDayOptions.entries()).map((entry, entryIndex) => {
                                        const [ variant, variantDetails ] = entry;
                                        return (
                                            <React.Fragment key={entryIndex}>
                                                <MdToggleButton
                                                    buttonStyle={{
                                                        flex: 1,
                                                        minInlineSize: "fit-content",
                                                        display: "flex",
                                                        alignItems: "center",
                                                        padding: "5px 10px",
                                                        borderRadius: "4px",
                                                        minWidth: "max-content",
                                                        minHeight: "24px",
                                                        maxHeight: "52px",
                                                        fontSize: "12px",
                                                        color: ((searchRoute.checkOutTimeOfDay === variant) ? "rgb(62, 92, 184)" : "#C1C1C1"),
                                                        border: ((searchRoute.checkOutTimeOfDay === variant) ? "1px solid rgb(62, 92, 184)" : "1px solid #CCCCCC"),
                                                    }}
                                                    inkStyle={{
                                                        backgroundColor: ((searchRoute.checkOutTimeOfDay === variant) ? "rgb(62, 92, 184)" : "#C1C1C1"),
                                                        opacity: 0.1
                                                    }}
                                                    checked={(searchRoute.checkOutTimeOfDay === variant)}
                                                    onClick={() => {
                                                        setCheckInTimeOfDay(variant);
                                                    }}
                                                >
                                                    {
                                                        variantDetails.name
                                                    }
                                                </MdToggleButton>
                                            </React.Fragment>
                                        )
                                    })
                                }
                            </div>
                        </MdMenu>
                    </div>

                </div>


                <MdButton
                    className="search-button"
                    type="button"
                    style={{
                        "button": {
                            position: "absolute",
                            bottom: "-20px",
                            right: "30px",
                            borderWidth: 0,
                            backgroundColor: (searchPending ? "#B83E50" : "#3E5CB8"),
                            color: "#FFFFFF",
                            borderRadius: "8px",
                            minWidth: "160px",
                            height: "40px",
                            padding: "5px 15px",
                            fontWeight: 500,
                            fontSize: "12px",
                            textTransform: "capitalize",
                            alignItems: "center",
                            justifyContent: "center"
                        },
                        "text": {
                            width: "auto",
                            flex: 0,
                            color: "#FFFFFF"
                        },
                        "ink": {
                            backgroundColor: "rgba(255,255,255,.5)"
                        }
                    }}
                    disabled={(
                        searchRoute.location.city_name?.length == 0
                        || searchRoute.checkInDate == undefined
                        || searchRoute.checkOutDate == undefined
                        || checkDates()
                    )}
                    onClick={() => {
                        if (!searchPending) {
                            startSearch()
                        } else {
                            cancelSearch();
                        }
                    }}
                >
                    {
                        searchPending
                            ? (
                                t("bookingPage.stop")
                            )
                            : (
                                t("bookingPage.searchHotels")
                            )
                    }
                </MdButton>

            </div>

            <div className="trip-page-search-tools">
                {
                    authState.isSupportUser && (
                        <>
                            <div className="trip-page-search-providers">
                                <span className="provider-list-label">{t("bookingPage.providers")}:</span>
                                {
                                    providers.map((provider, providerIndex) => {
                                        const isSelected = selectedProviders.includes(provider);
                                        return (
                                            <React.Fragment key={"provider" + Guid.newGuid()}>
                                                <MdToggleButton
                                                    buttonStyle={{
                                                        minInlineSize: "fit-content",
                                                        display: "flex",
                                                        alignItems: "center",
                                                        padding: "2px 8px",
                                                        borderRadius: "4px",
                                                        minWidth: "28px",
                                                        minHeight: "24px",
                                                        maxHeight: "52px",
                                                        fontSize: "10px",
                                                        color: (isSelected ? "#74B100" : "#C1C1C1"),
                                                        border: (isSelected ? "1px solid #74B100" : "1px solid #CCCCCC"),
                                                    }}
                                                    inkStyle={{
                                                        backgroundColor: (isSelected ? "#74B100" : "#C1C1C1"),
                                                        opacity: 0.1
                                                    }}
                                                    checked={isSelected}
                                                    onClick={() => {
                                                        if (isSelected) {
                                                            setSelectedProviders(prev => [...prev.filter(x => x != provider)]);
                                                        } else {
                                                            setSelectedProviders(prev => [...prev, provider]);
                                                        }
                                                    }}
                                                >
                                                    {
                                                        provider
                                                    }
                                                </MdToggleButton>
                                            </React.Fragment>
                                        )
                                    })
                                }
                            </div>
                        </>
                    )
                }
            </div>

            <HotelListComponent
                searchPending={searchPending}
                searchNotFound={searchNotFound}
                variants={variants}
                //selectFlightHandler={selectHotel}
            />

        </React.Fragment>
    );
}