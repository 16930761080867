import React, {useCallback, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useAppSelector} from "../../../../redux/hooks";
import {IAviaRejsInfo, IAviaVariant} from "../../../../interfaces/IAviaVariant";
import {TripElementStatus} from "../../../../enums/TripElementStatus";
import {FlightTripItem} from "../orderDetailsModal";
import {FlightTicketComponent} from "./flightTicketComponent";
import {MdToggleButton} from "../../../../components/md-toggle-button";
import {MdSlider} from "../../../../components/md-slider";
import {WarningRhombusIcon} from "../../../../icons/WarningRhombusIcon";
import {AirplaneIcon} from "../../../../icons/AirplaneIcon";
import {Guid, Utils} from "../../../../utils/utils";
import "./style.scss";


export interface IFlightComponentCallbacks {
    toggle?: (state: boolean) => void;
    changeTariff?: (index: number) => void;
}

export interface IFlightComponent {
    flightInfo: FlightTripItem;
    canEdit?: boolean;
    callbacks?: IFlightComponentCallbacks;
}


export function FlightComponent({
    flightInfo,
    canEdit,
    callbacks
}: IFlightComponent ) {

    const {t} = useTranslation();

    const authState = useAppSelector((state) => state.auth);

    const [time, setTime] = useState(new Date().getTime());

    const flight = flightInfo.variant;

    const tickets = flight.rejsInfo;
    const tarifs = tickets.map(x => x.tarifs).flat();


    const RefundAndChangeVariants: { [selector: number]: string } = {
        0: "запрещен",
        1: "бесплатно",
        2: "за доп. плату",
        3: "неопределён"
    }

    const BaggageVariants: { [selector: number]: string } = {
        0: "присутствует",
        1: "без багажа",
        2: "неопределён"
    }


    //const [selectedTariffIndex, setSelectedTariffIndex] = useState<number>(Math.max(0, tarifs.findIndex(x =>  x.isSelected)));

    const selectedTariffIndex = flightInfo.tariffIndex;
    const selectedTariff = tarifs[selectedTariffIndex];


    const toggle = (state: boolean) => {
        if(callbacks?.toggle != null) {
            callbacks.toggle(state);
        }
    }


    const groupDirs = () => {
        const groups: IAviaRejsInfo[][] = [];
        const group: IAviaRejsInfo[] = [];

        tickets.forEach(ticket => {
            if(!ticket.isBackwardTicketHead) {
                group.push(ticket);
            }
            else {
                groups.push(group.slice());
                group.splice(0, group.length);
                group.push(ticket);
            }
        });

        if(group.length > 0) {
            groups.push(group.slice());
            group.splice(0, group.length);
        }

        return groups;
    }

    const groupsOfDirs = groupDirs();


    const tick = useCallback(() => {
        setTime(new Date().getTime());
    }, []);

    useEffect(() => {
        const intervalID = setInterval(tick, 1000);
        return () => clearInterval(intervalID);
    }, [tick])


    return (
        <details className="flight-component"
                 open={flightInfo.isOpened ?? false}>
            <summary className="flight-component-header"
                     style={{width: "100%"}}
                     onClick={() => toggle(!flightInfo.isOpened)}>

                <MdSlider
                    slides={
                        groupsOfDirs.map((segments, directionIndex) => {

                            const airCompanyNames = [...new Set(segments.map(x => x.airCompanyName))];

                            const routeStart = segments[0];
                            const routeStartDate = new Date(routeStart.dateDep.replace('Z', ''));

                            const routeEnd = segments[segments.length - 1];
                            const routeEndDate = new Date(routeEnd.dateArr.replace('Z', ''));

                            return {
                                content: (
                                    <React.Fragment>
                                        <div className="flight-component-header-first-line">
                                            <div className="air-company">
                                                {
                                                    airCompanyNames.map((airCompany, airCompanyIndex) => {
                                                        return (
                                                            <React.Fragment key={airCompanyIndex}>
                                                                <span> { airCompany } </span>
                                                            </React.Fragment>
                                                        )
                                                    })
                                                }

                                            </div>
                                            <div>
                                                {
                                                    authState.isSupportUser && (
                                                        <div className="tariff-info">
                                                            {
                                                                <MdToggleButton
                                                                    buttonStyle={{
                                                                        minInlineSize: "fit-content",
                                                                        display: "flex",
                                                                        alignItems: "center",
                                                                        padding: "1px 10px 0 10px",
                                                                        borderRadius: "4px",
                                                                        minHeight: "24px",
                                                                        maxHeight: "52px",
                                                                        fontSize: "11px",
                                                                        color: "#777777",
                                                                        border: "1px solid #777777",
                                                                        pointerEvents: "none"
                                                                    }}
                                                                    inkStyle={{
                                                                        backgroundColor: "#777777",
                                                                        opacity: 0.1
                                                                    }}
                                                                    checked={true}
                                                                >
                                                                    {
                                                                        selectedTariff?.note.match("\\w\\:\\w+")
                                                                    }
                                                                </MdToggleButton>
                                                            }
                                                        </div>
                                                    )
                                                }
                                            </div>
                                            <div className="price">
                                                <span>
                                                    {
                                                        (selectedTariff.priceWithSborTts).toLocaleString() + " ₽"
                                                    }
                                                </span>
                                            </div>
                                        </div>
                                        <div className="flight-component-header-second-line">
                                            <div className="department-info">
                                                <div className="department-airport-code">{routeStart.airCodeDep}</div>
                                                <div
                                                    className="department-airport-city">{routeStart.cityDep.match(/([^()])+|(\(.*\))/g)?.shift()}</div>
                                                <div className="department-time">
                                                    {
                                                        routeStartDate.getHours().toString().padStart(2, "0") + ":" + routeStartDate.getMinutes().toString().padStart(2, "0")
                                                        + ", "
                                                        + routeStartDate.toLocaleString('default', {month: 'short'}).replace(".", "")
                                                        + " "
                                                        + routeStartDate.getDate().toString().padStart(2, "0")
                                                    }
                                                </div>
                                            </div>
                                            <div className="flight-path-info">
                                                <div className="flight-track">
                                                    <hr style={{marginTop: "16px "}}/>
                                                    <div className="flight-track-airplane">
                                                        <AirplaneIcon
                                                            width="16px"
                                                            height="16px"
                                                            style={{
                                                                "path": {
                                                                    fill: "#3E5CB8"
                                                                }
                                                            }}
                                                        />
                                                    </div>
                                                    <hr style={{marginTop: "16px "}}/>
                                                </div>
                                                {
                                                    segments.length > 1
                                                        ? (
                                                            <div className="flight-connections">
                                                                пересадки: {segments.length - 1}
                                                            </div>
                                                        )
                                                        : (
                                                            <div className="flight-time">
                                                                {routeStart.flightNumber}
                                                            </div>
                                                        )
                                                }
                                            </div>
                                            <div className="arrival-info">
                                                <div className="arrival-airport-code">{routeEnd.airCodeArr}</div>
                                                <div
                                                    className="arrival-airport-city">{routeEnd.cityArr.match(/([^()])+|(\(.*\))/g)?.shift()}</div>
                                                <div
                                                    className="arrival-time">
                                                    {
                                                        routeEndDate.getHours().toString().padStart(2, "0") + ":" + routeEndDate.getMinutes().toString().padStart(2, "0")
                                                        + ", "
                                                        + routeEndDate.toLocaleString('default', {month: 'short'}).replace(".", "")
                                                        + " "
                                                        + routeEndDate.getDate().toString().padStart(2, "0")
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </React.Fragment>
                                )
                            }
                        })
                    }
                    style={{
                        "root": {
                            flex: 1,
                            position: "relative",
                            display: "flex",
                            flexDirection: "column"
                        }
                    }}
                    paginationAlignment={'bottom'}
                />

                {
                    (() => {
                        switch (flightInfo.status) {
                            case TripElementStatus.Booked:
                                return(
                                    <div className="trip-item-status-message warn-status">
                                        <span>{t("bookingPage.booked")}</span>
                                        {/*<span className="trip-item-status-message-timer">*/}
                                        {/*    {*/}
                                        {/*        flightInfo.reservation?.items.map((x, xi) => {*/}
                                        {/*            let timeLimit = new Date(x.limit).getTime();*/}
                                        {/*            let timeLeft = timeLimit - time;*/}

                                        {/*            const {*/}
                                        {/*                days,*/}
                                        {/*                hours,*/}
                                        {/*                minutes,*/}
                                        {/*                seconds*/}
                                        {/*            } = Utils.getTimeDetails(timeLeft);*/}

                                        {/*            let daysLeft = Math.abs(days);*/}
                                        {/*            let hoursLeft = Math.abs(hours);*/}
                                        {/*            let minutesLeft = Math.abs(minutes);*/}
                                        {/*            let secondsLeft = Math.abs(seconds);*/}

                                        {/*            return (*/}
                                        {/*                <React.Fragment key={xi}>*/}
                                        {/*                    {*/}
                                        {/*                        timeLeft < 0 && (*/}
                                        {/*                            <span>срок истек</span>*/}
                                        {/*                        )*/}
                                        {/*                    }*/}
                                        {/*                    {*/}
                                        {/*                        timeLeft >= 0 && (*/}
                                        {/*                            <span>*/}
                                        {/*                                {*/}
                                        {/*                                    (daysLeft.toFixed(0).padStart(2, "0").substring(0, 2)) + ":" +*/}
                                        {/*                                    (hoursLeft.toFixed(0).padStart(2, "0").substring(0, 2)) + ":" +*/}
                                        {/*                                    (minutesLeft.toFixed(0).padStart(2, "0").substring(0, 2)) + ":" +*/}
                                        {/*                                    (secondsLeft.toFixed(0).padStart(2, "0").substring(0, 2))*/}
                                        {/*                                }*/}
                                        {/*                            </span>*/}
                                        {/*                        )*/}
                                        {/*                    }*/}
                                        {/*                </React.Fragment>*/}
                                        {/*            )*/}
                                        {/*        })*/}
                                        {/*    }*/}
                                        {/*</span>*/}
                                    </div>
                                );
                            case TripElementStatus.Confirmed:
                                return (
                                    <div className="trip-item-status-message success-status">
                                        <span>{t("bookingPage.issued")}</span>
                                    </div>
                                );
                        }
                    })()
                }

            </summary>

            <div className="flight-component-details" style={{backgroundColor: "#fdfdfd"}}>

                {
                    canEdit != false && (
                        <div className="flight-tariff-switcher">
                            {
                                flight.rejsInfo[flight.rejsInfo.length - 1].tarifs.map((tariff, tariffIndex) => {
                                    return (
                                        <React.Fragment key={"tariff" + Guid.newGuid()}>
                                            <MdToggleButton
                                                buttonStyle={{
                                                    minInlineSize: "fit-content",
                                                    display: "flex",
                                                    alignItems: "center",
                                                    padding: "1px 10px 0 10px",
                                                    borderRadius: "4px",
                                                    minHeight: "24px",
                                                    maxHeight: "52px",
                                                    fontSize: "11px",
                                                    color: (selectedTariffIndex == tariffIndex ? "#74B100" : "#C1C1C1"),
                                                    border: (selectedTariffIndex == tariffIndex ? "1px solid #74B100" : "1px solid #CCCCCC"),
                                                }}
                                                inkStyle={{
                                                    backgroundColor: (selectedTariffIndex == tariffIndex ? "#74B100" : "#C1C1C1"),
                                                    opacity: 0.1
                                                }}
                                                checked={selectedTariffIndex == tariffIndex}
                                                onClick={() => {
                                                    if (selectedTariffIndex == tariffIndex) {
                                                        return;
                                                    }
                                                    if (callbacks?.changeTariff != null) {
                                                        callbacks.changeTariff(tariffIndex);
                                                    }
                                                    //setSelectedTariffIndex(tariffIndex);
                                                }}
                                            >
                                                {tariff.name != null && tariff.name.length > 0 ? tariff.name : ("Тариф " + (tariffIndex + 1))}
                                            </MdToggleButton>
                                        </React.Fragment>
                                    )
                                })
                            }
                        </div>
                    )
                }

                <div className="flight-tariff-info">
                    <div className="flight-tariff-info-item">
                        <span>Обмен</span>
                        <span></span>
                        <span>
                            {
                                (RefundAndChangeVariants[Number(selectedTariff.obmen) ?? 3] ?? "неопределён")
                            }
                        </span>
                    </div>
                    <div className="flight-tariff-info-item">
                        <span>Возврат</span>
                        <span></span>
                        <span>
                            {
                                (RefundAndChangeVariants[Number(selectedTariff.vozvrat) ?? 3] ?? "неопределён")
                            }
                        </span>
                    </div>
                    <div className="flight-tariff-info-item">
                        <span>Багаж</span>
                        <span></span>
                        <span>
                            {
                                (BaggageVariants[Number(selectedTariff.bagaj) ?? 2] ?? "неопределён")
                            }
                            {" "}
                            {
                                selectedTariff.bagajInfo && (
                                    "(" + selectedTariff.bagajInfo + ")"
                                )
                            }
                        </span>
                    </div>
                </div>


                <div className="flight-info-section">
                    <div className="flight-roadmap" style={{padding: 0}}>
                        {
                            tickets.map((ticket, ticketIndex) => {
                                const dateDepartment = new Date(ticket.dateDep.replace('Z', ''));
                                const townDepartment = ticket.cityDep.replace(/ *\([^)]*\) */g, "");

                                const dateArrival = new Date(ticket.dateArr.replace('Z', ''));
                                const townArrival = ticket.cityArr.replace(/ *\([^)]*\) */g, "");

                                let flightWarning = null;

                                let messages = [];


                                if (ticketIndex > 0 && !ticket.isBackwardTicketHead) {
                                    const prevDirection = tickets[ticketIndex - 1];
                                    const prevCity = prevDirection.cityArr.replace(/ *\([^)]*\) */g, "");
                                    const prevDateArrival = new Date(prevDirection.dateArr.replace('Z', ''));

                                    let waitTime = Utils.getTimeDetails(dateDepartment.getTime() - prevDateArrival.getTime());

                                    messages.push("пересадка в аэропорту города " + prevCity + " с ожиданием " + waitTime.hours + "ч " + waitTime.minutes + "м");

                                    // other checks...

                                    flightWarning = (
                                        <div className="flight-roadmap-item">
                                            <div className="flight-roadmap-validation">
                                                <div className="flight-roadmap-validation-node">
                                                    <div
                                                        className="flight-roadmap-validation-node-button">
                                                        <WarningRhombusIcon
                                                            width="18px"
                                                            height="18px"
                                                            style={{
                                                                "path": {
                                                                    fill: "#F66000" //"#838383"//"#F66000"
                                                                }
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="flight-roadmap-validation-message">
                                                    <div
                                                        className="flight-roadmap-validation-message-title">
                                                        Предупреждение
                                                    </div>
                                                    <ul
                                                        className="flight-roadmap-validation-message-content">
                                                        {
                                                            messages.map(message => {
                                                                return (
                                                                    <li key={"flight-warning-message" + Guid.newGuid()}>{message}</li>
                                                                )
                                                            })
                                                        }
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                }

                                return (
                                    <div key={"flight-roadmap-item" + Guid.newGuid()}>

                                        {
                                            ticket.isBackwardTicketHead && (
                                                <div style={{height: "50px"}}>
                                                    {
                                                        // TODO: create gap indication
                                                    }
                                                </div>
                                            )
                                        }

                                        {
                                            flightWarning != null && (
                                                flightWarning
                                            )
                                        }

                                        <div className="flight-roadmap-item">
                                            <div className="flight-roadmap-item-start-node">
                                                <button
                                                    className={"flight-roadmap-item-start-node-button" + (ticketIndex == 0 || ticket.isBackwardTicketHead ? " --flight-begin" : "")}>

                                                </button>
                                                <div className="flight-roadmap-item-start-node-location">
                                                    {townDepartment} ({ticket.airCodeDep})
                                                </div>
                                            </div>
                                            <div className="flight-roadmap-item-content">

                                                <FlightTicketComponent
                                                    ticket={ticket}
                                                />

                                            </div>
                                            <div className="flight-roadmap-item-end-node">
                                                <button
                                                    className={"flight-roadmap-item-end-node-button" + (ticketIndex == tickets.length - 1 || (tickets.length > ticketIndex + 1 && tickets[ticketIndex + 1].isBackwardTicketHead) ? " --flight-end" : "")}>

                                                </button>
                                                <div className="flight-roadmap-item-end-node-location">
                                                    {townArrival} ({ticket.airCodeArr})
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                )
                            })
                        }
                    </div>
                </div>

            </div>
        </details>
    );
}