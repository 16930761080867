import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useAppSelector} from "../../../../redux/hooks";
import {IOrderDTO} from "../../../../interfaces/IOrderDTO";
import {MdButton} from "../../../../components/md-button";
import {MdTable} from "../../../../components/md-table";
import {ScheduleIcon} from "../../../../icons/ScheduleIcon";
import {CircleSpinnerIcon} from "../../../../icons/CircleSpinnerIcon";
import "./style.scss";
import "../../../../scss/tables.scss";


interface IOrdersTableComponent {
    orders: IOrderDTO[];
    isLoading: boolean,
    isSearchResultNotFound: boolean,
    onCreateOrderButtonClick: () => void;
    onViewOrderButtonClick: (order: IOrderDTO) => void;
    onRemoveOrderButtonClick: (order: IOrderDTO) => void;
    createOrderPending: boolean;
    onLoadOrdersButtonClick: () => void;
}


export function OrdersTableComponent({
        orders,
        isLoading,
        isSearchResultNotFound,
        onCreateOrderButtonClick,
        onViewOrderButtonClick,
        onRemoveOrderButtonClick,
        createOrderPending,
        onLoadOrdersButtonClick
    }: IOrdersTableComponent) {

    const {t} = useTranslation();

    const authState = useAppSelector((state) => state.auth);

    let tableData = orders;

    const itemsPerPage = 10;

    const isTableLoading = isLoading;
    const isEmptySearchResult = isSearchResultNotFound;

    const [orderList, setOrderList] = useState<IOrderDTO[]>([]);
    const [activePage, setActivePage] = useState(1);


    useEffect(() => {
        if (tableData != null) {
            //let skip = Math.max(0, (activePage - 1) * itemsPerPage);
            //setOrderList(tableData.slice(skip, skip + itemsPerPage));
            setOrderList(tableData);
        } else {
            setOrderList([]);
        }
    }, [orders, /*selectedOrders, */activePage]);


    return (
        <>

            <div className="orders-results">
                <div className="orders-results-header">
                    <div className="orders-results-caption">
                        {`${t("bookingPage.orders")}`}
                        <span style={{display: (isTableLoading ? "none" : "block")}}>{tableData?.length}</span>
                        <span className="orders-results-preloader"
                            style={{display: (isTableLoading ? "flex" : "none")}}>
                            <div className="spinner-border orders-results-preloader-icon"></div>
                            <span className="orders-results-preloader-text">
                                {`${t("bookingPage.searching")}`}
                            </span>
                        </span>
                    </div>
                    <div className="orders-results-tools">
                        {
                            createOrderPending && (
                                <CircleSpinnerIcon
                                    width="20px"
                                    height="20px"
                                    style={{
                                        "svg": {
                                            margin: "7px"
                                        }
                                    }}
                                />
                            )
                        }

                        {
                            !createOrderPending && (
                                <MdButton
                                    style={{
                                        "button": {
                                            borderWidth: 0,
                                            backgroundColor: "rgb(233 233 233)",
                                            color: "rgb(0 0 0)",
                                            borderRadius: "6px",
                                            height: "34px",
                                            padding: "5px 15px"
                                        },
                                        "icon": {
                                            marginRight: "5px",
                                        },
                                        "ink": {
                                            backgroundColor: "rgba(255,255,255,.5)"
                                        }
                                    }}
                                    disabled={isTableLoading}
                                    onClick={() => onCreateOrderButtonClick()}
                                >
                                    {`${t("bookingPage.create")}`}
                                </MdButton>
                            )
                        }
                    </div>
                </div>

                {
                    (!isTableLoading && !isEmptySearchResult && orders.length > 0) && (
                        <MdTable
                            columns={[
                                {
                                    width: 200,
                                    header: t("bookingPage.number"),
                                    cellTemplate(data: IOrderDTO) {
                                        return <div>{data.orderNo}</div>
                                    }
                                },
                                {
                                    width: 200,
                                    header: t("bookingPage.created"),
                                    cellTemplate(data: IOrderDTO) {
                                        return <div>{new Date(new Date(data.createdAt).getTime() - new Date().getTimezoneOffset() * 60000).toLocaleString()}</div>
                                    }
                                },
                                // {
                                //     width: 200,
                                //     header: t("bookingPage.status"),
                                //     cellTemplate(data: IOrderDetails) {
                                //         return <div>{data.status}</div>
                                //     }
                                // },
                                // {
                                //     width: 200,
                                //     header: t("bookingPage.comment"),
                                //     cellTemplate(data: IOrder) {
                                //         return <div>{data.comment}</div>
                                //     }
                                // },
                                // {
                                //     width: 200,
                                //     header: t("bookingPage.closed"),
                                //     cellTemplate(data) {
                                //         return <div>
                                //             {
                                //                 <div className="doc-state-indicator">
                                //                     <div style={{display: "flex", gap: "5px"}}>
                                //                                     <span>
                                //                                         <CrossIcon
                                //                                             width="10px"
                                //                                             height="10px"
                                //                                             style={{
                                //                                                 "path": {
                                //                                                     stroke: "#999999"
                                //                                                 }
                                //                                             }}
                                //                                         />
                                //                                     </span>
                                //                     </div>
                                //                 </div>
                                //             }
                                //         </div>
                                //     }
                                // },

                                {
                                    header:"",
                                    cellTemplate(data: IOrderDTO) {
                                        return (
                                            <></>
                                        )
                                    }
                                },

                                {
                                    width: 120,
                                    header:"",
                                    cellTemplate(data: IOrderDTO) {
                                        return (
                                            <div style={{ width: "100%", display: "flex", alignItems: "end", justifyContent: "end" }}>
                                                <MdButton
                                                    style={{
                                                        "button": {
                                                            borderWidth: 0,
                                                            backgroundColor: "rgb(233 233 233)",
                                                            color: "rgb(0 0 0)",
                                                            borderRadius: "6px",
                                                            height: "35px",
                                                            padding: "5px 15px"
                                                        },
                                                        "icon": {
                                                            marginRight: "5px",
                                                        },
                                                        "ink": {
                                                            backgroundColor: "rgba(255,255,255,.5)"
                                                        }
                                                    }}
                                                    onClick={() => onViewOrderButtonClick(data)}
                                                >
                                                    {t("bookingPage.details")}
                                                </MdButton>
                                            </div>
                                        )
                                    }
                                },

                                // {
                                //     width: 100,
                                //     header:"",
                                //     cellTemplate(data: IOrderDetails) {
                                //         return (
                                //             <div style={{ width: "100%", display: "flex", alignItems: "end", justifyContent: "end" }}>
                                //                 <MdButton
                                //                     style={{
                                //                         "button": {
                                //                             borderWidth: 0,
                                //                             backgroundColor: "#B83E50",
                                //                             color: "#FFFFFF",
                                //                             borderRadius: "6px",
                                //                             height: "35px",
                                //                             padding: "5px 15px"
                                //                         },
                                //                         "icon": {
                                //                             marginRight: "5px",
                                //                         },
                                //                         "ink": {
                                //                             backgroundColor: "rgba(255,255,255,.5)"
                                //                         }
                                //                     }}
                                //                     onClick={() => onRemoveOrderButtonClick(data)}
                                //                 >
                                //                     {t("bookingPage.delete")}
                                //                 </MdButton>
                                //             </div>
                                //         )
                                //     }
                                // }
                            ]}
                            itemSource={orderList}
                            onPreviewRowSelect={() => false}
                            //onRowSelect={(data: IOrder) => openOrderDetailsHandler(data)}
                            style={{
                                "tbody": {
                                    width: "100%",
                                    maxHeight: "calc(100vh - 400px)"
                                }
                            }}
                        />
                    )
                }

                {/*{*/}
                {/*    isTableLoading && (*/}
                {/*        <div className="table-skeleton-preloader">*/}
                {/*            {*/}
                {/*                Array.from(Array(itemsPerPage + 1).keys()).map((item, index) => {*/}
                {/*                    return (*/}
                {/*                        <div key={index} className="table-skeleton-item"></div>*/}
                {/*                    )*/}
                {/*                })*/}
                {/*            }*/}
                {/*        </div>*/}
                {/*    )*/}
                {/*}*/}

                {(isEmptySearchResult) && (
                    <div className="no-orders-message" style={{ margin: "40px 0"}}>
                        <div className="no-orders-message-icon">
                            <ScheduleIcon width="60px" height="60px" style={{
                                "path": {
                                    fill: "rgb(100 100 100)"
                                }
                            }}/>
                        </div>
                        <div className="no-orders-message-caption">{t("bookingPage.noOrders")}</div>
                        <div className="no-orders-message-content">{t("bookingPage.createOrSearch")}
                        </div>
                        <div className="no-orders-message-button">
                            {
                                !isTableLoading
                                ? (
                                        <MdButton
                                            style={{
                                                "button": {
                                                    borderWidth: 0,
                                                    backgroundColor: "rgb(233 233 233)",
                                                    color: "rgb(0 0 0)",
                                                    borderRadius: "6px",
                                                    minWidth: "92px",
                                                    height: "35px",
                                                    padding: "5px 15px"
                                                },
                                                "ink": {
                                                    backgroundColor: "rgba(255,255,255,.5)"
                                                }
                                            }}
                                            disabled={isTableLoading}
                                            onClick={() => onLoadOrdersButtonClick()}
                                        >
                                            {t("bookingPage.repeat")}
                                        </MdButton>
                                    )
                                    : (
                                        <CircleSpinnerIcon
                                            width="18px"
                                            height="18px"
                                            style={{
                                                "circle": {
                                                    stroke: "rgb(50 50 50)"
                                                }
                                            }}
                                        />
                                    )
                            }
                        </div>
                    </div>
                )}

            </div>

            {/*<div className="pagination-container" style={{float: "right"}}>*/}
            {/*    <MdPagination*/}
            {/*        activePage={activePage}*/}
            {/*        itemsCount={(tableData != null ? tableData.length : 0)}*/}
            {/*        itemsPerPage={itemsPerPage}*/}
            {/*        onChange={(page) => onPageChange(page)}*/}
            {/*    />*/}
            {/*</div>*/}

        </>
    );
}
