import axios, {CancelToken} from "axios";
import {TripApi} from "../api/TripApi";
import {
  IOrderBookRequest,
  IOrderIssueRequest
} from "../api/request/IOrderBookRequest";
import {
  IOrderDTO,
  ITripDTO,
  ITripPassengerDTO,
  ITripElementDTO,
  IFlightElementDTO,
  ITrainElementDTO,
  IOrderBookingDetails,
  IOrderIssueDetails,
  IOrderTicketPdf,
} from "../interfaces/IOrderDTO";
import {IPassengerDTO} from "../interfaces/IPassengerDTO";
import {ISearchFlightsResponse} from "../api/response/ISearchFlightsResponse";
import {IOrderBookResponse} from "../api/response/IBookFlightResponse";
import {ISearchTrainsResponse} from "../api/response/ISearchTrainsResponse";
import {Utils} from "../utils/utils";
import {IStorageKey} from "../interfaces/IStorageKey";
import {store} from "../redux/store";
import {IFlightRoute} from "../pages/cabinetPages/tripPage/searchTicketsModal/searchFlightsComponent";
import { ITrainRoute } from "../pages/cabinetPages/tripPage/searchTicketsModal/searchTrainsComponent";
import {
  ICreateFlightElementRequest,
  ICreateTrainElementRequest, IPatchSearchResponseRequest,
  ITripElementRequest
} from "../api/request/ITripElementRequest";
import {ITripPaxesRequest} from "../api/request/ITripPaxesRequest";
import {ICreateTripRequest} from "../api/request/ICreateTripRequest";
import {ICreateOrderRequest} from "../api/request/ICreateOrderRequest";
import {TripElementType} from "../enums/TripElementType";
import {ICreateTripPassengerRequest} from "../api/request/ICreateTripPassengerRequest";
import {ICanIssieCheck} from "../interfaces/support/ICanIssieCheck";
import {ISearchAeroExpressResponse} from "../api/response/ISearchAeroExpressResponse";


export function TripService() {

  const tripApi = TripApi();

  /**
   * Method for obtaining flight providers
   * @param {string} clientCode - active company code
   * @param {CancelToken} ct - cancellation token
   * @return {Promise<string[]>} list of provider names. in case of errors returns empty array.
   */
  const getFlightProviders = async (clientCode: string, ct: CancelToken = axios.CancelToken.source().token): Promise<string[]> => {
    try {
      const state = store.getState();
      const response = await tripApi.getFlightProviders(clientCode, state.auth.accessToken, ct);
      return Array.isArray(response.data) ? response.data : [];
    } catch (error) {
      return [];
    }
  }

  /**
   * Method for obtaining train providers
   * @param {string} clientCode - active company code
   * @param {CancelToken} ct - cancellation token
   * @return {Promise<string[]>} list of provider names. in case of errors returns empty array.
   */
  const getTrainProviders = async (clientCode: string, ct: CancelToken = axios.CancelToken.source().token): Promise<string[]> => {
    try {
      const state = store.getState();
      const response = await tripApi.getTrainProviders(clientCode, state.auth.accessToken, ct);
      return Array.isArray(response.data) ? response.data : [];
    } catch (error) {
      return [];
    }
  }

  /**
   * Method for obtaining flights by requesting parameters
   * @param {IFlightRoute[]} routes - list of flight routes data
   * @param {string[]} providers - list of provider names
   * @param {string} clientCode - active company code
   * @param {CancelToken} ct - cancellation token
   * @return {Promise<ISearchFlightsResponse|null>} list of flight variants. in case of errors returns null.
   */
  const getFlights = async (routes: IFlightRoute[], providers: string[], clientCode: string, ct: CancelToken = axios.CancelToken.source().token): Promise<ISearchFlightsResponse | null> => {
    try {
      const state = store.getState();
      const request = {
        routes: routes,
        providers: providers,
        clientCode: clientCode
      };
      const response = await tripApi.getFlights(request, state.auth.accessToken, ct);
      return !Utils.isEmpty(response.data) ? response.data : null;
    } catch (error) {
      return null;
    }
  }



  /**
   * Method for obtaining aero express by requesting parameters
   * @param {string} departDate - departure date
   * @param {string[]} providers - list of provider names
   * @param {string} clientCode - active company code
   * @param {CancelToken} ct - cancellation token
   * @return {Promise<ISearchAeroExpressResponse|null>} list of aero express variants. in case of errors returns null.
   */
  const getAeroExpress = async (departDate: string, providers: string[], clientCode: string, ct: CancelToken = axios.CancelToken.source().token): Promise<ISearchAeroExpressResponse|null> => {
    try {
      const state = store.getState();
      const request = {
        departDate: departDate,
        providers: providers,
        clientCode: clientCode
      };
      const response = await tripApi.getAeroExpress(request, state.auth.accessToken, ct);
      return !Utils.isEmpty(response.data) ? response.data : null;
    } catch (error) {
      return null;
    }
  };



  /**
   * Method for obtaining trains by requesting parameters
   * @param {IFlightRoute[]} routes - list of flight routes data
   * @param {string[]} providers - list of provider names
   * @param {string} clientCode - active company code
   * @param {CancelToken} ct - cancellation token
   * @return {Promise<ISearchTrainsResponse|null>} list of flight variants. in case of errors returns null.
   */
  const getTrains = async (routes: ITrainRoute[], providers: string[], clientCode: string, ct: CancelToken = axios.CancelToken.source().token): Promise<ISearchTrainsResponse | null> => {
    try {
      const state = store.getState();
      const request = {
        routes: routes,
        providers: providers,
        clientCode: clientCode
      };
      const response = await tripApi.getTrains(request, state.auth.accessToken, ct);
      return !Utils.isEmpty(response.data) ? response.data : null;
    } catch (error) {
      return null;
    }
  }

  /**
   * Method for creating order
   * @param {ICreateOrderRequest} request - request data
   * @param {CancelToken} ct - cancellation token
   * @return {Promise<IOrderDTO>} order details. in case of errors returns null
   */
  const createOrder = async (request: ICreateOrderRequest, ct: CancelToken = axios.CancelToken.source().token): Promise<IOrderDTO | null> => {
    try {
      const state = store.getState();
      const response = await tripApi.createOrder(request, state.auth.accessToken, ct);
      return !Utils.isEmpty(response.data) ? response.data : null;
    } catch (error) {
      return null;
    }
  };

  /**
   * Method for removing order
   * @param {string} id - order id
   * @param {CancelToken} ct - cancellation token
   * @return {Promise<any>} order details
   */
  const removeOrder = async (id: any, ct: CancelToken = axios.CancelToken.source().token): Promise<any> => {
    try {
      const state = store.getState();
      const response = await tripApi.removeOrder(id, state.auth.accessToken, ct);
      return !Utils.isEmpty(response.data) ? response.data : null;
    } catch (error) {
      return null;
    }
  };

  /**
   * Method for creating trip
   * @param {ICreateTripRequest} request - request data
   * @param {CancelToken} ct - cancellation token
   * @return {Promise<ITripDTO | null>} trip details
   */
  const createTrip = async (request: ICreateTripRequest, ct: CancelToken = axios.CancelToken.source().token): Promise<ITripDTO | null> => {
    try {
      const state = store.getState();
      const response = await tripApi.createTrip(request, state.auth.accessToken, ct);
      return !Utils.isEmpty(response.data) ? response.data : null;
    } catch (error) {
      return null;
    }
  };

  /**
   * Method for removing trip
   * @param {string} id - trip id
   * @param {CancelToken} ct - cancellation token
   * @return {Promise<any>} trip details
   */
  const removeTrip = async (id: string, ct: CancelToken = axios.CancelToken.source().token): Promise<any> => {
    try {
      const state = store.getState();
      const response = await tripApi.removeTrip(id, state.auth.accessToken, ct);
      return response.status === 200;
    } catch (error) {
      return false;
    }
  };



  /**
   * Method of checking existence of pax
   * @param {string} paxCode - passenger code
   * @param {CancelToken} ct - cancellation token
   * @return {Promise<boolean>} checking result
   */
  const isPaxExist = async (paxCode: string, ct: CancelToken = axios.CancelToken.source().token): Promise<boolean> => {
    try {
      const state = store.getState();
      const response = await tripApi.isPaxExist(paxCode, state.auth.accessToken, ct);
      return response.data === true;
    } catch (error) {
      return false;
    }
  };

  /**
   * Method for creating pax
   * @param {ICreateTripPassengerRequest} request - request data
   * @param {CancelToken} ct - cancellation token
   * @return {Promise<ITripPassengerDTO | null>} pax details
   */
  const createPax = async (request: ICreateTripPassengerRequest, ct: CancelToken = axios.CancelToken.source().token): Promise<ITripPassengerDTO | null> => {
    try {
      const state = store.getState();
      const response = await tripApi.createPax(request, state.auth.accessToken, ct);
      return !Utils.isEmpty(response.data) ? response.data : null;
    } catch (error) {
      return null;
    }
  };

  /**
   * Method for adding pax to trip item
   * @param {ITripPaxesRequest} request - request data
   * @param {CancelToken} ct - cancellation token
   * @return {Promise<ITripPassengerDTO[]>} added passenger details
   */
  const addPaxesToTrip = async (request: ITripPaxesRequest, ct: CancelToken = axios.CancelToken.source().token): Promise<ITripPassengerDTO[]> => {
    try {
      const state = store.getState();
      const response = await tripApi.addPaxesToTrip(request, state.auth.accessToken, ct);
      return Array.isArray(response.data) ? response.data : [];
    } catch (error) {
      return [];
    }
  };

  /**
   * Method for removing pax from trip item
   * @param {ITripPaxesRequest} request - request data
   * @param {CancelToken} ct - cancellation token
   * @return {Promise<string[]>} removed passenger ids
   */
  const removePaxesFromTrip = async (request: ITripPaxesRequest, ct: CancelToken = axios.CancelToken.source().token): Promise<string[]> => {
    try {
      const state = store.getState();
      const response = await tripApi.removePaxesFromTrip(request, state.auth.accessToken, ct);
      return Array.isArray(response.data) ? response.data : [];
    } catch (error) {
      return [];
    }
  };



  /**
   * Method for creating trip item
   * @param {ITripElementRequest} request - request data
   * @param {CancelToken} ct - cancellation token
   * @return {Promise<ITripElementDTO>} trip item details
   */
  const createTripItem = async (request: ITripElementRequest, ct: CancelToken = axios.CancelToken.source().token): Promise<ITripElementDTO | null> => {
    try {
      const state = store.getState();
      const response = await tripApi.createTripItem(request, state.auth.accessToken, ct);
      return !Utils.isEmpty(response.data) ? response.data : null;
    } catch (error) {
      return null;
    }
  };

  /**
   * Method for creating trip item
   * @param {ICreateFlightElementRequest} request - request data
   * @param {CancelToken} ct - cancellation token
   * @return {Promise<ITripElementDTO | null>} trip item details
   */
  const createFlightElement = async (request: ICreateFlightElementRequest, ct: CancelToken = axios.CancelToken.source().token): Promise<IFlightElementDTO | null> => {
    try {
      const state = store.getState();
      const response = await tripApi.createFlightElement(request, state.auth.accessToken, ct);
      return !Utils.isEmpty(response.data) ? response.data : null;
    } catch (error) {
      return null;
    }
  };

  /**
   * Method for creating train item
   * @param {ICreateFlightElementRequest} request - request data
   * @param {CancelToken} ct - cancellation token
   * @return {Promise<ITripElementDTO | null>} trip item details
   */
  const createTrainElement = async (request: ICreateTrainElementRequest, ct: CancelToken = axios.CancelToken.source().token): Promise<ITrainElementDTO | null> => {
    try {
      const state = store.getState();
      const response = await tripApi.createTrainElement(request, state.auth.accessToken, ct);
      return !Utils.isEmpty(response.data) ? response.data : null;
    } catch (error) {
      return null;
    }
  };

  /**
   * Method for patch trip element
   * @param {IPatchSearchResponseRequest} request - request data
   * @param {CancelToken} ct - cancellation token
   * @return {Promise<any>} response with trip item details
   */
  const patchTripElement = async (request: IPatchSearchResponseRequest, ct: CancelToken = axios.CancelToken.source().token): Promise<any> => {
    try {
      const state = store.getState();
      const response = await tripApi.patchTripElement(request, state.auth.accessToken, ct);
      return !Utils.isEmpty(response.data) ? response.data : null;
    } catch (error) {
      return null;
    }
  };

  /**
   * Method for removing trip item
   * @param {string} id - trip item id
   * @param {TripElementType} elementType - trip item type
   * @param {CancelToken} ct - cancellation token
   * @return {Promise<boolean>} deleting result
   */
  const removeTripItem = async (id: string, elementType: TripElementType, ct: CancelToken = axios.CancelToken.source().token): Promise<boolean> => {
    try {
      const state = store.getState();
      const response = await tripApi.removeTripItem(id, elementType, state.auth.accessToken, ct);
      return response.status === 200;
    } catch (error) {
      return false;
    }
  };



  // TODO: rename model
  /**
   * Method for sending order to TTS
   * @param {IOrderBookRequest} request - request data
   * @param {CancelToken} ct - cancellation token
   * @return {Promise<any|null>} action result. in case of errors returns null
   */
  const sendOrderToTTS = async (request: IOrderBookRequest, ct: CancelToken = axios.CancelToken.source().token): Promise<any | null> => {
    try {
      const state = store.getState();
      const response = await tripApi.sendOrderToTTS(request, state.auth.accessToken, ct);
      return !Utils.isEmpty(response.data) ? response.data : null;
    } catch (error) {
      return null;
    }
  }



  /**
   * Method for booking flights
   * @param {IOrderBookRequest} request - request data
   * @param {number} timeout - request timeout
   * @param {CancelToken} ct - cancellation token
   * @return {Promise<IOrderBookRequest|null>} booking details. in case of errors returns null
   */
  const bookOrder = async (request: IOrderBookRequest, timeout: number, ct: CancelToken = axios.CancelToken.source().token): Promise<IOrderBookResponse | null> => {
    try {
      const state = store.getState();
      const response = await tripApi.bookOrder(request, state.auth.accessToken, timeout, ct);
      return !Utils.isEmpty(response.data) ? response.data : null;
    } catch (error) {
      return null;
    }
  }

  /**
     * Method for issue order
     * @param {IOrderIssueRequest} request - request data
     * @param {number} timeout - request timeout
     * @param {CancelToken} ct - cancellation token
     * @return {Promise<any>} issue details. in case of errors returns null
     */
  const issueOrder = async (request: IOrderIssueRequest, timeout: number, ct: CancelToken = axios.CancelToken.source().token): Promise<any> => {
    try {
      const state = store.getState();
      const response = await tripApi.issueOrder(request, state.auth.accessToken, timeout, ct);
      return !Utils.isEmpty(response.data) ? response.data : null;
    } catch (error) {
      return null;
    }
};


  /**
   * Method for obtaining list of orders
   * @param {string} clientCode - active company code
   * @param {CancelToken} ct - cancellation token
   * @return {Promise<IOrderDTO[]>} list of orders. in case of errors returns empty array.
   */
  const getOrders = async (clientCode: string, ct: CancelToken = axios.CancelToken.source().token): Promise<IOrderDTO[]> => {
    try {
      const state = store.getState();
      const response = await tripApi.getOrders(clientCode, state.auth.accessToken, ct);
      return Array.isArray(response.data) ? response.data : [];
    } catch (error) {
      return [];
    }
  }

  /**
   * Method for obtaining order details
   * @param {string} id - order id
   * @param {CancelToken} ct - cancellation token
   * @return {Promise<IOrderDTO | null>} order details. in case of errors returns null
   */
  const getOrderById = async (id: string, ct: CancelToken = axios.CancelToken.source().token): Promise<IOrderDTO | null> => {
    try {
      const state = store.getState();
      const response = await tripApi.getOrderById(id, state.auth.accessToken, ct);
      return !Utils.isEmpty(response.data) ? response.data : null;
    } catch (error) {
      return null;
    }
  };

  /**
   * Method for obtaining state of issued order
   * @param {string} clientCode - active company code
   * @param {string} orderNo - order number
   * @param {CancelToken} ct - cancellation token
   * @return {Promise<boolean>} state of issued order
   */
  const isOrderIssued = async (clientCode: string, orderNo: string, ct: CancelToken = axios.CancelToken.source().token): Promise<boolean> => {
    try {
      const state = store.getState();
      const response = await tripApi.isOrderIssued(clientCode, orderNo, state.auth.accessToken, ct);
      return response.data == true;
    } catch (error) {
      return false;
    }
  }

  /**
   * Method for obtaining order booking details
   * @param {string} clientCode - active company code
   * @param {number | undefined} orderNo - order number
   * @param {CancelToken} ct - cancellation token
   * @return {Promise<IOrderBookingDetails[]>} list of order booking details. in case of errors returns empty array.
   */
  const getOrderBookingDetails = async (clientCode: string, orderNo: number | undefined, ct: CancelToken = axios.CancelToken.source().token): Promise<IOrderBookingDetails[]> => {
    try {
      const state = store.getState();
      const response = await tripApi.getOrderBookingDetails(clientCode, orderNo, state.auth.accessToken, ct);
      return Array.isArray(response.data) ? response.data : [];
    } catch (error) {
      return [];
    }
  }

  /**
   * Method for obtaining order issue details
   * @param {string} clientCode - active company code
   * @param {string} orderNo - order number
   * @param {CancelToken} ct - cancellation token
   * @return {Promise<IOrderIssueDetails | null>} order issue details. in case of errors returns null.
   */
  const getOrderIssueDetails = async (clientCode: string, orderNo: string, ct: CancelToken = axios.CancelToken.source().token): Promise<IOrderIssueDetails | null> => {
    try {
      const state = store.getState();
      const response = await tripApi.getOrderIssueDetails(clientCode, orderNo, state.auth.accessToken, ct);
      return !Utils.isEmpty(response.data) ? response.data : null;
    } catch (error) {
      return null;
    }
  }

  /**
   * Method for obtaining list of pdf tickets data
   * @param {string} clientCode - active company code
   * @param {string} bsoNo - bso number
   * @param {CancelToken} ct - cancellation token
   * @return {Promise<IOrderTicketPdf[]>} list of pdf tickets data. in case of errors returns empty array.
   */
  const getPdfTickets = async (clientCode: string, bsoNo: string, ct: CancelToken = axios.CancelToken.source().token): Promise<IOrderTicketPdf[]> => {
    try {
      const state = store.getState();
      const response = await tripApi.getPdfTickets(clientCode, bsoNo, state.auth.accessToken, ct);
      return !Utils.isEmpty(response.data) ? response.data : [];
    } catch (error) {
      return [];
    }
  }

  /**
   * Method for obtaining pdf ticket file
   * @param {string} clientCode - active company code
   * @param {IStorageKey} key - storage key
   * @param {CancelToken} ct - cancellation token
   * @return {Promise<Blob | null>} pdf ticket file. in case of errors returns null
   */
  const getPdfTicketFile = async (clientCode: string, key: IStorageKey, ct: CancelToken = axios.CancelToken.source().token): Promise<Blob | null> => {
    try {
      const state = store.getState();
      const response = await tripApi.getPdfTicketFile(clientCode, key, state.auth.accessToken, ct);
      return !Utils.isEmpty(response.data) ? response.data : null;
    } catch (error) {
      return null;
    }
  }


  /**
   * Method for checking order for issue
   * @param {string} clientCode - active company code
   * @param {number} sum - order total amount
   * @param {CancelToken} ct - cancellation token
   * @return {Promise<ICanIssieCheck | null>} checking result
   */
  const canOrderIssue = async (clientCode: string, sum: number, ct: CancelToken = axios.CancelToken.source().token): Promise<ICanIssieCheck | null> => {
    try {
      const state = store.getState();
      const response = await tripApi.canOrderIssue(clientCode, sum, state.auth.accessToken, ct);
      return !Utils.isEmpty(response.data) ? response.data : null;
    } catch (error) {
      return null;
    }
  }


  return {
    getFlightProviders,
    getFlights,

    getAeroExpress,

    getTrainProviders,
    getTrains,

    getOrders,
    getOrderById,
    isOrderIssued,
    getOrderBookingDetails,
    getOrderIssueDetails,
    getPdfTickets,
    getPdfTicketFile,

    createOrder,
    removeOrder,
    createTrip,
    removeTrip,
    isPaxExist,
    createPax,
    addPaxesToTrip,
    removePaxesFromTrip,
    createTripItem,
    createFlightElement,
    createTrainElement,
    patchTripElement,
    removeTripItem,
    sendOrderToTTS,
    bookOrder,
    issueOrder,

    canOrderIssue,
  };
}
