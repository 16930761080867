import './style.scss'
import React, {CSSProperties, useEffect} from "react";

interface IMdCheckbox {
    type?: "outline" | "filled";
    style?: { [selector: string]: CSSProperties | { [key: string]: string | number } };
    checked?: boolean;
    disabled?: boolean;
    onChecked?: () => void;
    onUnchecked?: () => void;
    onChanged?: (state: boolean) => void;
}

export function MdCheckbox({
                               type = "outline", style = {}, checked = false, disabled = false, onChecked = () => {
    }, onUnchecked = () => {
    }, onChanged = () => {
    },
                           }: IMdCheckbox) {


    const changeState = (e: React.ChangeEvent) => {
        let state = (e.target as HTMLInputElement).checked;

        if (state && onChecked != null) {
            onChecked();
        } else if (!state && onUnchecked != null) {
            onUnchecked();
        }

        if (onChanged != null) {
            onChanged(state);
        }
    };

    useEffect(() => {
    }, []);

    return (
        <label
            datatype={type}
            className="checkbox path md-checkbox"
            style={style["label"]}
            onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
            }}
        >
            <input
                type="checkbox"
                checked={checked}
                disabled={disabled}
                style={style["input"]}
                onChange={changeState}
            />
            {
                type === "outline"
                    ? (
                        <svg
                            viewBox="0 0 21 21"
                            style={style["input"]}
                        >
                            <path
                                d="M5,10.75 L8.5,14.25 L19.4,2.3 C18.8333333,1.43333333 18.0333333,1 17,1 L4,1 C2.35,1 1,2.35 1,4 L1,17 C1,18.65 2.35,20 4,20 L17,20 C18.65,20 20,18.65 20,17 L20,7.99769186"></path>
                        </svg>
                    )
                    : (
                        <svg
                            viewBox="0 0 21 21"
                            style={style["input"]}
                        >
                            <polyline points="5 10.75 8.5 14.25 16 6"></polyline>
                        </svg>
                    )
            }
        </label>
    );
}