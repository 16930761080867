import {IRootState} from "./store";
import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {ICompanyDTO} from '../interfaces/ICompanyDTO';

interface AuthState {
    userId: string;
    email: string;
    firstName: string;
    lastName: string;
    patronymic: string;
    isActivatedAcc:boolean
    isSupportUser: boolean;
    supportUserCode: string;
    fullName: string;
    currentClientCode: string;
    userRole: string;
    roles: { [company: string]: string[] },
    siteLanguage:string
    accessToken: string;
    expiredAt: number;
    isTokenBeingRefreshed: boolean;
    currentGkCode: string;
    gkClientCodes: string[];
    authError: string;
    isValidUser: boolean;
    gkCompanies: ICompanyDTO[],
    currentCompany: ICompanyDTO,
}

const initialState: AuthState = {
    userId: "",
    email: "",
    firstName: "",
    lastName: "",
    patronymic: "",
    isActivatedAcc:false,
    isSupportUser: false,
    supportUserCode: "",
    fullName: "",
    userRole: "",
    roles: {},
    //? need to remove arr add to export access and refresh tokens
    siteLanguage:"ru",
    accessToken: "",
    expiredAt: 0,
    isTokenBeingRefreshed: false,
    //! HardCode for Testing
    currentClientCode: "",
    authError: "",
    isValidUser: false,
    currentGkCode: "",
    gkClientCodes: [],
    gkCompanies: [],
    currentCompany: {} as ICompanyDTO
};

export const authSlice = createSlice({
    name: "auth",
    initialState,
    reducers: {
        setFullName: (state, action: PayloadAction<string>) => {
            state.fullName = action.payload;
        },
        setUserRoles: (state, action: PayloadAction<{ [company: string]: string[] }>) => {
            state.roles = action.payload;
        },
        setUserRole: (state, action: PayloadAction<string>) => {
            state.userRole = action.payload;
        },
        setEmail: (state, action: PayloadAction<string>) => {
            state.email = action.payload;
        },
        setAccessToken: (state, action: PayloadAction<string>) => {
            state.accessToken = action.payload;
        },
        setExpiredAt: (state, action: PayloadAction<number>) => {
            state.expiredAt = action.payload;
        },
        setIsTokenBeingRefreshed: (state, action: PayloadAction<boolean>) => {
            state.isTokenBeingRefreshed = action.payload;
        },
        setUserId: (state, action: PayloadAction<string>) => {
            state.userId = action.payload;
        },
        setFirstName: (state, action: PayloadAction<string>) => {
            state.firstName = action.payload;
        },
        setLastName: (state, action: PayloadAction<string>) => {
            state.lastName = action.payload;
        },
        setPatronymic: (state, action: PayloadAction<string>) => {
            state.patronymic = action.payload;
        },
        setIsSupportUser: (state, action: PayloadAction<boolean>) => {
            state.isSupportUser = action.payload;
        },
        setSupportUserCode: (state, action: PayloadAction<string>) => {
            state.supportUserCode = action.payload;
        },
        setCurrentClientCode: (state, action: PayloadAction<string>) => {
            state.currentClientCode = action.payload;
        },
        setCurrentCompany: (state, action: PayloadAction<ICompanyDTO>) => {
            state.currentCompany = action.payload;
        },
        setAuthError: (state, action: PayloadAction<string>) => {
            state.authError = action.payload;
        },
        setIsUserValid: (state, action: PayloadAction<boolean>) => {
            state.isValidUser = action.payload;
        },
        setGkCode: (state, action: PayloadAction<string>) => {
            state.currentGkCode = action.payload;
        },
        setGkClientCodes: (state, action: PayloadAction<string[]>) => {
            state.gkClientCodes = action.payload;
        },
        setGkCompanies: (state, action: PayloadAction<ICompanyDTO[]>) => {
            state.gkCompanies = action.payload
        },
        setIsActivatedAcc: (state, action: PayloadAction<boolean>) => {
            state.isActivatedAcc = action.payload
        },
        setSiteLanguage: (state, action: PayloadAction<string>) => {
            state.siteLanguage = action.payload
        },
    },
    extraReducers: (builder) => { }
});

export const {
    setEmail,
    setFirstName,
    setLastName,
    setPatronymic,
    setIsSupportUser,
    setSupportUserCode,
    setUserRole,
    setUserRoles,
    setFullName,
    setUserId,
    setAccessToken,
    setExpiredAt,
    setIsTokenBeingRefreshed,
    setIsUserValid,
    setCurrentClientCode,
    setGkCode,
    setGkClientCodes,
    setGkCompanies,
    setIsActivatedAcc,
    setCurrentCompany,
    setSiteLanguage,
} = authSlice.actions;
export const userValidationStatus = (state: IRootState) =>
    state.auth.isValidUser;
export default authSlice.reducer;

export const testToken = (state: IRootState) =>
    state.auth.accessToken;
