import React, {useCallback, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {TripElementStatus} from "../../../../enums/TripElementStatus";
import {TrainTripItem} from "../orderDetailsModal";
import {TrainTicketComponent} from "./trainTicketComponent";
import {MdToggleButton} from "../../../../components/md-toggle-button";
import {TrainIcon3} from "../../../../icons/TrainIcon3";
import {Guid, Utils} from "../../../../utils/utils";
import "./style.scss";


export interface ITrainComponentCallbacks {
    toggle?: (state: boolean) => void;
    changeTariff?: (index: number) => void;
}

export interface ITrainComponent {
    trainInfo: TrainTripItem;
    canEdit?: boolean;
    callbacks?: ITrainComponentCallbacks;
}


export function TrainComponent({
    trainInfo,
    canEdit,
    callbacks
}: ITrainComponent ) {

    const {t} = useTranslation();

    const train = trainInfo.variant;

    const [time, setTime] = useState(new Date().getTime());

    const tickets = [train];
    const tarifs = tickets.map(x => x.categoryPrice).flat();

    const minPrice = Math.min(...tickets.map(r => r.categoryPrice.map(t => t.price)).flat());

    const routeStart = tickets[0];
    const routeStartDate = Utils.parseTrainDateTime(routeStart.departureAt, routeStart.departureShortOn);

    const routeEnd = tickets[tickets.length - 1];
    const routeEndDate = Utils.parseTrainDateTime(routeStart.arriveShortOn, routeStart.arriveAtn);


    const selectedTariffIndex = trainInfo.tariffIndex;
    const selectedTariff = tarifs[selectedTariffIndex];


    const toggle = (state: boolean) => {
        if(callbacks?.toggle != null) {
            callbacks.toggle(state);
        }
    }


    const tick = useCallback(() => {
        setTime(new Date().getTime());
    }, []);

    useEffect(() => {
        const intervalID = setInterval(tick, 1000);
        return () => clearInterval(intervalID);
    }, [tick])


    return (
        <details className="train-component"
            open={trainInfo.isOpened ?? false}>
            <summary className="train-component-header"
                     style={{width: "100%"}}
                     onClick={() => toggle(!trainInfo.isOpened)}>

                <div className="train-component-header-first-line">
                    <div className="air-company">
                        <span>
                            {
                                routeStart.travelTime
                            }
                        </span>
                    </div>
                    <div className="price">
                        {

                        }
                        <span>
                            {
                               (selectedTariff.price + selectedTariff.sborTts).toLocaleString() + " ₽"
                            }
                        </span>
                    </div>
                </div>
                <div className="train-component-header-second-line">
                    <div className="department-info">
                        <div className="department-airport-code">
                            {
                                routeEnd.stationFrom
                            }
                        </div>
                        <div className="department-airport-city">
                            {
                                routeEnd.cityFrom
                                    .toLowerCase()
                                    .split(' ')
                                    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
                                    .join(' ')
                                    .split('-')
                                    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
                                    .join('-')
                            }
                        </div>
                        <div className="department-time">
                            {
                                routeStartDate.getHours().toString().padStart(2, "0") + ":" + routeStartDate.getMinutes().toString().padStart(2, "0")
                                + ", "
                                + routeStartDate.toLocaleString('default', {month: 'short'}).replace(".", "")
                                + " "
                                + routeStartDate.getDate().toString().padStart(2, "0")
                            }
                        </div>
                    </div>
                    <div className="train-path-info">
                        <div className="train-track">
                            <hr style={{marginTop: "16px "}}/>
                            <div className="train-track-icon">
                                <TrainIcon3
                                    width="18px"
                                    height="18px"
                                    style={{
                                        "path": {
                                            fill: "#3E5CB8"
                                        }
                                    }}
                                />
                            </div>
                            <hr style={{marginTop: "16px "}}/>
                        </div>
                        {
                            tickets.length > 1
                                ? (
                                    <div className="train-connections">
                                        пересадки: {tickets.length - 1}
                                    </div>
                                )
                                : (
                                    <div className="train-time">
                                        {routeStart.trainNum}
                                    </div>
                                )
                        }
                    </div>
                    <div className="arrival-info">
                        <div className="arrival-airport-code">
                            {
                                routeStart.stationTo
                            }
                        </div>
                        <div className="arrival-airport-city">
                            {
                                routeStart.cityTo
                                    .toLowerCase()
                                    .split(' ')
                                    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
                                    .join(' ')
                                    .split('-')
                                    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
                                    .join('-')
                            }
                        </div>
                        <div className="arrival-time">
                            {
                                routeEndDate.getHours().toString().padStart(2, "0") + ":" + routeEndDate.getMinutes().toString().padStart(2, "0")
                                + ", "
                                + routeEndDate.toLocaleString('default', {month: 'short'}).replace(".", "")
                                + " "
                                + routeEndDate.getDate().toString().padStart(2, "0")
                            }
                        </div>
                    </div>
                </div>

                {
                    (() => {
                        switch (trainInfo.status) {
                            case TripElementStatus.Booked:
                                return(
                                    <div className="trip-item-status-message warn-status">
                                        <span>{t("bookingPage.booked")}</span>
                                        {/*<span className="trip-item-status-message-timer">*/}
                                        {/*    {*/}
                                        {/*        trainInfo.reservation?.items.map((x, xi) => {*/}
                                        {/*            let timeLimit = new Date(x.limit).getTime();*/}
                                        {/*            let timeLeft = timeLimit - time;*/}

                                        {/*            const {*/}
                                        {/*                days,*/}
                                        {/*                hours,*/}
                                        {/*                minutes,*/}
                                        {/*                seconds*/}
                                        {/*            } = Utils.getTimeDetails(timeLeft);*/}

                                        {/*            let daysLeft = Math.abs(days);*/}
                                        {/*            let hoursLeft = Math.abs(hours);*/}
                                        {/*            let minutesLeft = Math.abs(minutes);*/}
                                        {/*            let secondsLeft = Math.abs(seconds);*/}

                                        {/*            return (*/}
                                        {/*                <React.Fragment key={xi}>*/}
                                        {/*                    {*/}
                                        {/*                        timeLeft < 0 && (*/}
                                        {/*                            <span>срок истек</span>*/}
                                        {/*                        )*/}
                                        {/*                    }*/}
                                        {/*                    {*/}
                                        {/*                        timeLeft >= 0 && (*/}
                                        {/*                            <span>*/}
                                        {/*                                {*/}
                                        {/*                                    (daysLeft.toFixed(0).padStart(2, "0").substring(0, 2)) + ":" +*/}
                                        {/*                                    (hoursLeft.toFixed(0).padStart(2, "0").substring(0, 2)) + ":" +*/}
                                        {/*                                    (minutesLeft.toFixed(0).padStart(2, "0").substring(0, 2)) + ":" +*/}
                                        {/*                                    (secondsLeft.toFixed(0).padStart(2, "0").substring(0, 2))*/}
                                        {/*                                }*/}
                                        {/*                            </span>*/}
                                        {/*                        )*/}
                                        {/*                    }*/}
                                        {/*                </React.Fragment>*/}
                                        {/*            )*/}
                                        {/*        })*/}
                                        {/*    }*/}
                                        {/*</span>*/}
                                    </div>
                                );
                            case TripElementStatus.Confirmed:
                                return (
                                    <div className="trip-item-status-message success-status">
                                        <span>{t("bookingPage.issued")}</span>
                                    </div>
                                );
                        }
                    })()
                }

            </summary>

            <div className="train-component-details" style={{backgroundColor: "#fdfdfd"}}>

                {
                    canEdit != false && (
                        <div className="train-tariff-switcher">
                            {
                                train.categoryPrice.map((tariff, tariffIndex) => {
                                    return (
                                        <React.Fragment key={"tariff" + Guid.newGuid()}>
                                            <MdToggleButton
                                                buttonStyle={{
                                                    minInlineSize: "fit-content",
                                                    display: "flex",
                                                    alignItems: "center",
                                                    padding: "1px 10px 0 10px",
                                                    borderRadius: "4px",
                                                    minHeight: "24px",
                                                    maxHeight: "52px",
                                                    fontSize: "11px",
                                                    color: (selectedTariffIndex == tariffIndex ? "#74B100" : "#C1C1C1"),
                                                    border: (selectedTariffIndex == tariffIndex ? "1px solid #74B100" : "1px solid #CCCCCC"),
                                                }}
                                                inkStyle={{
                                                    backgroundColor: (selectedTariffIndex == tariffIndex ? "#74B100" : "#C1C1C1"),
                                                    opacity: 0.1
                                                }}
                                                checked={selectedTariffIndex == tariffIndex}
                                                onClick={() => {
                                                    if (selectedTariffIndex == tariffIndex) {
                                                        return;
                                                    }
                                                    if (callbacks?.changeTariff != null) {
                                                        callbacks.changeTariff(tariffIndex);
                                                    }
                                                }}
                                            >
                                                {tariff.categoryName != null && tariff.categoryName.length > 0 ? tariff.categoryName : ("Тариф " + (tariffIndex + 1))}
                                            </MdToggleButton>
                                        </React.Fragment>
                                    )
                                })
                            }
                        </div>
                    )
                }

                <div className="train-tariff-info">
                    <div className="train-tariff-info-item">
                        <span>{t("bookingPage.Class/Car/Seat")}</span>
                        <span></span>
                        <span>
                            {
                                selectedTariff.foundInfo
                            }
                        </span>
                    </div>
                </div>

                <div className="train-info-section">
                    <div className="train-roadmap" style={{padding: 0}}>
                        {
                            tickets.map((ticket, ticketIndex) => {
                                //const dateDepartment = new Date(ticket.dateDep.replace('Z', ''));
                                const townDepartment = ticket.cityFrom;

                                //const dateArrival = new Date(ticket.dateArr.replace('Z', ''));
                                const townArrival = ticket.cityTo;

                                let trainWarning = null;

                                let messages = [];

                                // if (ticketIndex > 0) {
                                //     const prevDirection = tickets[ticketIndex - 1];
                                //     const prevCity = prevDirection.cityArr;
                                //     const prevDateArrival = new Date(prevDirection.dateArr.replace('Z', ''));
                                //
                                //     let waitTime = TimeUtils.getTimeDetails(dateDepartment.getTime() - prevDateArrival.getTime());
                                //
                                //     messages.push("пересадка в аэропорту города " + prevCity + " с ожиданием " + Math.floor(waitTime.minutes / 60) + "ч " + (waitTime.minutes % 60) + "м");
                                //
                                //     // other checks...
                                //
                                //     trainWarning = (
                                //         <div className="train-roadmap-item">
                                //             <div className="train-roadmap-validation">
                                //                 <div className="train-roadmap-validation-node">
                                //                     <div
                                //                         className="train-roadmap-validation-node-button">
                                //                         <WarningRhombusIcon
                                //                             width="18px"
                                //                             height="18px"
                                //                             style={{
                                //                                 "path": {
                                //                                     fill: "#F66000" //"#838383"//"#F66000"
                                //                                 }
                                //                             }}
                                //                         />
                                //                     </div>
                                //                 </div>
                                //                 <div className="train-roadmap-validation-message">
                                //                     <div
                                //                         className="train-roadmap-validation-message-title">
                                //                         Предупреждение
                                //                     </div>
                                //                     <ul
                                //                         className="train-roadmap-validation-message-content">
                                //                         {
                                //                             messages.map(message => {
                                //                                 return (
                                //                                     <li key={"train-warning-message" + Guid.newGuid()}>{message}</li>
                                //                                 )
                                //                             })
                                //                         }
                                //                     </ul>
                                //                 </div>
                                //             </div>
                                //         </div>
                                //     )
                                // }

                                return (
                                    <div key={"train-roadmap-item" + Guid.newGuid()}>

                                        {/*{*/}
                                        {/*    trainWarning != null && (*/}
                                        {/*        trainWarning*/}
                                        {/*    )*/}
                                        {/*}*/}

                                        <div className="train-roadmap-item">
                                            <div className="train-roadmap-item-start-node">
                                                <button
                                                    className={"train-roadmap-item-start-node-button" + (ticketIndex == 0 ? " --train-begin" : "")}>

                                                </button>
                                                <div className="train-roadmap-item-start-node-location">
                                                    {townDepartment} ({ticket.stationFrom})
                                                </div>
                                            </div>
                                            <div className="train-roadmap-item-content">

                                                <TrainTicketComponent
                                                    ticket={ticket}
                                                />

                                            </div>
                                            <div className="train-roadmap-item-end-node">
                                                <button
                                                    className={"train-roadmap-item-end-node-button" + (ticketIndex == tickets.length - 1 ? " --train-end" : "")}>

                                                </button>
                                                <div className="train-roadmap-item-end-node-location">
                                                    {townArrival} ({ticket.stationTo})
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                )
                            })
                        }
                    </div>
                </div>

            </div>

        </details>
    );
}