import React, {forwardRef, useContext, useEffect, useImperativeHandle, useState} from "react";
import {useTranslation} from "react-i18next";
import {ModalContext} from "../modal/ModalContext";
import {CompanyService} from "../../../services/CompanyService";
import {ICompanyDTO} from "../../../interfaces/ICompanyDTO";
import {MdButton} from "../../../components/md-button";
import {MdList} from "../../../components/md-list";
import {MdModal} from "../../../components/md-modal";
import {MdSelect} from "../../../components/md-select";
import {CircleSpinnerIcon} from "../../../icons/CircleSpinnerIcon";
import {MagnifierIcon} from "../../../icons/MagnifierIcon";
import {CompanyIcon} from "../../../icons/CompanyIcon";
import {Guid} from "../../../utils/utils";
import "./style.scss"


interface ICompanySelectionModal {
    onOpen?:        () => void;
    onClose?:       () => void;
    submitHandler: (company: ICompanyDTO) => void;
}

export const CompanySelectionModal = forwardRef((props: ICompanySelectionModal, ref) => {

    // get the passed properties and assign default values
    const {
        onOpen,
        onClose,
        submitHandler
    } = Object.assign({
        onOpen: () => {},
        onClose: () => {}
    }, props);

    const {isOpen, open, close} = useContext(ModalContext);

    const {t} = useTranslation();

    const companyService = CompanyService();


    const [modalId, setModalId] = useState(Guid.newGuid());

    const [searchType, setSearchType] = useState(t("bookingPage.name"));

    const [searchPending, setSearchPending] = useState(false);
    const [searchError, setSearchError] = useState(false);

    const [searchValue, setSearchValue] = useState("");
    const [companies, setCompanies] = useState<ICompanyDTO[]>([]);
    const [selectedCompany, setSelectedCompany] = useState<ICompanyDTO | null>(null);


    useImperativeHandle(ref, () => ({
        open() {
            if(!isOpen(modalId)) {
                open(modalId);
            }
        },
        close() {
            if(isOpen(modalId)) {
                close(modalId);
            }
        }
    }));


    const onKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.repeat) return;
        if (event.key === "Enter") {
            searchCompany();
        }
    }

    const onSubmit = (company: ICompanyDTO) => {
        submitHandler(company);
        close(modalId);
    }


    const searchCompany = async () => {
        setCompanies([]);
        setSearchError(false);
        setSearchPending(true);
        setSelectedCompany(null);

        console.log(searchType);

        if(searchType == t("bookingPage.name")) {
            const response = await companyService.searchCompaniesByName(searchValue);

            if (response != null && response.length > 0) {
                setCompanies(response);
            } else {
                setSearchError(true);
            }
        }
        else {
            const response = await companyService.getCompanyInfo(searchValue);

            if (response != null) {
                setCompanies([response]);
            } else {
                setSearchError(true);
            }
        }

        setSearchPending(false);
    }

    const reset = () => {
        setSearchValue("");
        setCompanies([]);
        setSelectedCompany(null);
    }

    const onModalOpen = () => {
        reset();
        onOpen();
    }

    const onModalClose = () => {
        onClose();
        reset();
    }



    return (
        <MdModal
            id={modalId}
            title={t("bookingPage.choosingCompany")}
            openHandler={onModalOpen}
            closeHandler={onModalClose}>

            <div className="company-selection-dialog" style={{ minWidth: "500px", maxHeight: (companies.length > 0 ? "500px": "300px")}}>

                <div className="company-selection-dialog__searchbar"
                     style={{ zIndex: 1000, transform: (searchPending || companies.length > 0 || searchError ? "translateY(0)" : "translateY(35px)")}}>

                    <div className="company-selection-dialog__searchbar-section">
                        <MdSelect
                            itemSource={[t("bookingPage.code"), t("bookingPage.name")]}
                            selectedItem={searchType}
                            setSelectedItem={setSearchType}
                            style={{
                                "root": {
                                    margin: 0,
                                    height: "30px",
                                    fontSize: "12px"
                                },
                                "toggle-button": {
                                    padding: "2px 2px 2px 14px"
                                }
                            }}
                        />

                        <input
                            className="search-input"
                            style={{fontSize: "12px", outline: "none", boxShadow: "none"}}
                            type="text"
                            placeholder={(searchType === t("bookingPage.code") ? t("bookingPage.companyCode") : t("bookingPage.companyName"))}
                            value={searchValue}
                            onChange={(e) => {
                                setSearchValue(e.target.value);
                            }}
                            onKeyDown={onKeyDown}
                        />

                        <MdButton
                            type="button"
                            disabled={searchPending}
                            icon={
                                <MagnifierIcon
                                    width="16px"
                                    height="16px"
                                    style={{
                                        "path": {
                                            fill: "#FFFFFF"
                                        }
                                    }}
                                />
                            }
                            style={{
                                "button": {
                                    borderWidth: 0,
                                    backgroundColor: "#3E5CB8",
                                    color: "#FFFFFF",
                                    borderRadius: "4px",
                                    width: "30px",
                                    height: "30px",
                                    padding: "5px",
                                    alignItems: "center",
                                    justifyContent: "center",
                                },
                                "icon": {},
                                "text": {},
                                "ink": {
                                    backgroundColor: "rgba(255,255,255,.5)"
                                }
                            }}
                            onClick={searchCompany}
                        />
                    </div>

                </div>

                {
                    companies.length > 0 && (
                        <>
                            <MdList
                                itemSource={companies}
                                activeItem={selectedCompany}
                                itemTemplate={(data: ICompanyDTO) => {
                                    return (
                                        <>
                                            <div className="company-details">
                                                <div className="company-details-row company-details-row-name">
                                                    <CompanyIcon
                                                        width="18px"
                                                        height="18px"
                                                        style={{
                                                            "svg": {
                                                                marginRight: "6px",
                                                                marginTop: "-4px"
                                                            },
                                                            "path": {
                                                                fill: "#7b7b7b"
                                                            }
                                                        }}
                                                    />
                                                    {data.fullName}
                                                </div>
                                                <div className="company-details-row">
                                                    <span>{t("employeePage.code")}: </span>
                                                    <span>{(data.code)}</span>
                                                </div>

                                                <div className="company-details-row">
                                                    <span>{t("employeePage.inn")}: </span>
                                                    <span>{data.inn}</span>
                                                </div>
                                                <div className="company-details-row">
                                                    <span>{t("myProfilePage.agreement")}: </span>
                                                    {
                                                        data.agreements != null && data.agreements.length > 0
                                                            ? (
                                                                <>
                                                                    <span>{(data.agreements[0].code)}</span>
                                                                    <span>
                                                                        {data.agreements[0] &&
                                                                            "("+t("myProfilePage.agreementFrom")+" "+
                                                                            + (new Date(data.agreements[0].dateFrom).toLocaleDateString() ?? "-")
                                                                            + " "+t("myProfilePage.agreementTill") +" "+
                                                                            + (new Date(data.agreements[0].dateTill).toLocaleDateString() ?? "-")
                                                                            + ")"
                                                                        }
                                                                    </span>
                                                                </>
                                                            )
                                                            : (
                                                                <span
                                                                    style={{color: "rgb(143 12 12)"}}>Отсутствует</span>
                                                            )
                                                    }
                                                </div>
                                            </div>
                                        </>
                                    )
                                }}
                                style={{
                                    "root": {
                                        flex: 1,
                                        width: "500px",
                                        borderTop: "1px solid #efefef"
                                    },
                                    "list": {
                                        padding: "10px 20px"
                                    }
                                }}
                                onItemSelect={setSelectedCompany}
                            />

                            <div className="company-selection-dialog__footer">
                                <MdButton
                                    type="submit"
                                    style={{
                                        "button": {
                                            width: "200px",
                                            borderWidth: 0,
                                            backgroundColor: "#3E5CB8",
                                            color: "#FFFFFF",
                                            borderRadius: "6px",
                                            height: "35px",
                                            padding: "5px 15px"
                                        },
                                        "ink": {
                                            backgroundColor: "rgba(255,255,255,.5)"
                                        }
                                    }}
                                    disabled={(selectedCompany == null)}
                                    onClick={() => {
                                        if(selectedCompany != null) {
                                            onSubmit(selectedCompany)
                                        }
                                    }}
                                >
                                    {t("commonText.select")}
                                </MdButton>
                            </div>

                        </>

                    )
                }

                {
                    searchPending && (
                        <div className="loading-results-message">
                            <CircleSpinnerIcon
                                width="20px"
                                height="20px"
                                style={{
                                    "circle": {
                                        stroke: "#A0A1A6"
                                    }
                                }}
                            />
                            {t("employeePage.loadCompanies")}
                        </div>
                    )
                }

                {
                    searchError && (
                        <div className="no-results-message">
                            {t("employeePage.companiesNotLoaded")}
                        </div>
                    )
                }

            </div>

        </MdModal>
    );
})