import {AxiosResponse, CancelToken} from "axios";
import {axiosInstance} from "./AxiosInstance";
import {
    BEARER, ROUTE_GET_ALL_COUNTRIES,
    ROUTE_GET_REFS_AVIA_CITIES,
    ROUTE_GET_REFS_TRAIN_STATION,
} from "../constants/routeConstants/ApiRouteConstants";
import {ISearchAviaCityResponse} from "./response/ISearchAviaCityResponse";
import {ISearchTrainCityResponse} from "./response/ISearchTrainCityResponse";
import {ICountryDTO} from "../interfaces/ICountryDTO";


export function RefsApi() {

    /**
     * GET method for obtaining all countries
     * @param {string} accessToken - access token
     * @param {CancelToken} ct - cancellation token
     * @return {Promise<AxiosResponse<ICountryDTO[], any>>} response with list of countries
     */
    const getAllCountries = async (accessToken: string, ct: CancelToken): Promise<AxiosResponse<ICountryDTO[], any>> => {
        return await axiosInstance.get<ICountryDTO[]>(
            process.env.REACT_APP_GATEWAY_ROUTE + ROUTE_GET_ALL_COUNTRIES,
            {
                headers: {
                    Authorization: BEARER + accessToken
                },
                cancelToken: ct
            }
        );
    };

    /**
     * GET method for obtaining cities with airports by requested name
     * @param {string} nameLike - city name
     * @param {string} local - localization name
     * @param {string} accessToken - access token
     * @param {CancelToken} ct - cancellation token
     * @return {Promise<AxiosResponse<ISearchAviaCityResponse[], any>>} response with list of cities
     */
    const getAviaCities = async (nameLike: string, local: string, accessToken: string, ct: CancelToken): Promise<AxiosResponse<ISearchAviaCityResponse[], any>> => {
        return await axiosInstance.get<ISearchAviaCityResponse[]>(
            process.env.REACT_APP_GATEWAY_ROUTE + ROUTE_GET_REFS_AVIA_CITIES,
            {
                headers: {
                    Authorization: BEARER + accessToken
                },
                params: {
                    local: local,
                    nameLike: nameLike,
                },
                cancelToken: ct
            }
        );
    };

    /**
     * GET method for obtaining cities with rail stations by requested name
     * @param {string} nameLike - city name
     * @param {string} local - localization name
     * @param {string} accessToken - access token
     * @param {CancelToken} ct - cancellation token
     * @return {Promise<AxiosResponse<ISearchTrainCityResponse[], any>>} response with list of cities
     */
    const getTrainCities = async (nameLike: string, local: string, accessToken: string, ct: CancelToken): Promise<AxiosResponse<ISearchTrainCityResponse[], any>> => {
        return await axiosInstance.get<ISearchTrainCityResponse[]>(
            process.env.REACT_APP_GATEWAY_ROUTE + ROUTE_GET_REFS_TRAIN_STATION,
            {
                headers: {
                    Authorization: BEARER + accessToken
                },
                params: {
                    local: local,
                    nameLike: nameLike,
                },
                cancelToken: ct
            }
        );
    };


    return {
        getAllCountries,
        getAviaCities,
        getTrainCities
    };
}