import axios, {CancelToken} from "axios";
import {store} from "../redux/store";
import {UsersApi} from "../api/UsersApi";
import {IUserDTO} from "../interfaces/IUserDTO";
import {ICreateUserRequest} from "../api/request/ICreateUserRequest";
import {ICompanyUserRoleDTO} from "../interfaces/ICompanyUserRoleDTO";
import {Utils} from "../utils/utils";


export function UsersService() {

    const usersApi = UsersApi();


    /**
     * Method to receive a list of users
     * @param {string} clientCode - company code
     * @param {CancelToken} ct - cancellation token
     * @return {Promise<IUserDTO[]>} list of users. in case of errors returns empty array
     */
    const getUsers = async (clientCode: string, ct: CancelToken = axios.CancelToken.source().token): Promise<IUserDTO[]> => {
        try {
            const state = store.getState();
            const response = await usersApi.getUsers(clientCode, state.auth.accessToken, ct);
            return Array.isArray(response.data) ? response.data : [];
        }
        catch (ex: any) {
            return [];
        }
    }

    /**
     * Method to create a user
     * @param {ICreateUserRequest} request - request data
     * @param {CancelToken} ct - cancellation token
     * @return {Promise<IUserDTO | null>} user data. in case of errors returns null
     */
    const createUser = async (request: ICreateUserRequest, ct: CancelToken = axios.CancelToken.source().token): Promise<IUserDTO | null> => {
        try {
            const state = store.getState();
            const response = await usersApi.createUser(request, state.auth.accessToken, ct);
            return !Utils.isEmpty(response.data) ? response.data : null;
        }
        catch (ex: any) {
            return null;
        }
    };

    /**
     * Method to update user
     * @param {ICreateUserRequest} request - request data
     * @param {CancelToken} ct - cancellation token
     * @return {Promise<IUserDTO | null>} user data. in case of errors returns null
     */
    const updateUser = async (request: ICreateUserRequest, ct: CancelToken = axios.CancelToken.source().token): Promise<IUserDTO | null> => {
        try {
            const state = store.getState();
            const response = await usersApi.updateUser(request, state.auth.accessToken, ct);
            return !Utils.isEmpty(response.data) ? response.data : null;
        }
        catch (ex: any) {
            return null;
        }
    };

    /**
     * Method to receive list of user roles
     * @param {string} email - user email
     * @param {CancelToken} ct - cancellation token
     * @return {Promise<ICompanyUserRoleDTO[]>} list of user roles. in case of errors returns empty array
     */
    const getUserRoles = async (email : string, ct: CancelToken = axios.CancelToken.source().token): Promise<ICompanyUserRoleDTO[]> => {
        try {
            const state = store.getState();
            const response = await usersApi.getUserRoles(email, state.auth.accessToken, ct);
            return Array.isArray(response.data) ? response.data : [];
        }
        catch (ex: any) {
            return [];
        }
    }

    /**
     * Method to get a list of user role names in the company
     * @param {string} clientCode - company code
     * @param {string} email - user email
     * @param {CancelToken} ct - cancellation token
     * @return {Promise<string[]>} list of user role names in the company. in case of errors returns empty array
     */
    const getUserRolesInCompany = async (clientCode: string, email : string, ct: CancelToken = axios.CancelToken.source().token): Promise<string[]> => {
        try {
            const state = store.getState();
            const response = await usersApi.getUserRolesInCompany(clientCode, email, state.auth.accessToken, ct);
            return Array.isArray(response.data) ? response.data : [];
        }
        catch (ex: any) {
            return [];
        }
    }


    return {
        getUsers,
        createUser,
        updateUser,
        getUserRoles,
        getUserRolesInCompany
    };
}