import {axiosInstance} from "./AxiosInstance";
import {ISupportUser} from "../interfaces/support/ISupportUser";
import {
    BEARER,
    ROUTE_ADD_SUPPORT_USER, ROUTE_DELETE_SUPPORT_USER,
    ROUTE_GET_SUPPORT_USER,
    ROUTE_GET_ALL_SUPPORT_USERS
} from "../constants/routeConstants/ApiRouteConstants";
import {ISupportUserResponse} from "../interfaces/support/ISupportUserResponse";
import {Guid} from "guid-ts";
import {AxiosResponse, CancelToken} from "axios";

export function SupportApi() {

    /**
     * PUT method for add support user
     * @param {ISupportUser} request - request data
     * @param {string} accessToken - access token
     * @param {CancelToken} ct - cancellation token
     * @return {Promise<AxiosResponse<any>>} response with the support user data
     */
    const addSupportUser = async (request: ISupportUser, accessToken: string, ct: CancelToken): Promise<AxiosResponse<any>> => {
        return await axiosInstance.put<any>( // TODO: specify SupportUserResponse struct
            process.env.REACT_APP_GATEWAY_ROUTE + ROUTE_ADD_SUPPORT_USER,
            request,
            {
                headers: {
                    Authorization: BEARER + accessToken,
                    Auth: 123
                },
                cancelToken: ct
            }
        );
    };

    /**
     * GET method for obtaining a list of support users
     * @param {string} clientCode - company code
     * @param {string} accessToken - access token
     * @param {CancelToken} ct - cancellation token
     * @return {Promise<AxiosResponse<ISupportUserResponse[]>>} response with a list of support users
     */
    const getAllSupportUsers = async (clientCode: string, accessToken: string, ct: CancelToken): Promise<AxiosResponse<ISupportUserResponse[]>> => {
        return await axiosInstance.get<ISupportUserResponse[]>(
            process.env.REACT_APP_GATEWAY_ROUTE + ROUTE_GET_ALL_SUPPORT_USERS,
            {
                headers: {
                    Authorization: BEARER + accessToken,
                    Auth: 123
                },
                params: {
                    code: clientCode // "Ф0021013"
                },
                cancelToken: ct
            }
        );
    }

    /**
     * GET method for obtaining support user data
     * @param {string} email - support user email
     * @param {string} clientCode - company code
     * @param {string} accessToken - access token
     * @param {CancelToken} ct - cancellation token
     * @return {Promise<AxiosResponse<ISupportUserResponse>>} response with support user data
     */
    const getSupportUser = async (email: string, clientCode: string, accessToken: string, ct: CancelToken): Promise<AxiosResponse<ISupportUserResponse>> => {
        return  await axiosInstance.get<ISupportUserResponse>(
            process.env.REACT_APP_GATEWAY_ROUTE + ROUTE_GET_SUPPORT_USER,
            {
                headers: {
                    Authorization: BEARER + accessToken,
                    Auth: 123
                },
                params: {
                    email: email,
                    code: clientCode
                },
                cancelToken: ct
            }
        );
    };

    /**
     * DELETE method for delete support user
     * @param {Guid} id - support user id
     * @param {string} accessToken - access token
     * @param {CancelToken} ct - cancellation token
     * @return {Promise<AxiosResponse<boolean>>} response with delete result
     */
    const deleteSupportUser = async (id: Guid, accessToken: string, ct: CancelToken): Promise<AxiosResponse<boolean>> => {
        return  await axiosInstance.delete<boolean>(
            process.env.REACT_APP_GATEWAY_ROUTE + ROUTE_DELETE_SUPPORT_USER,
            {
                headers: {
                    Authorization: BEARER + accessToken,
                    Auth: 123
                },
                params: {
                    id: id
                },
                cancelToken: ct
            }
        );
    };


    return {
        addSupportUser,
        getAllSupportUsers,
        getSupportUser,
        deleteSupportUser
    }
}




