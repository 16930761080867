import React, {CSSProperties, useEffect} from "react";
import './style.scss'


interface IMdProgressBar {
    style?: { [selector: string]: CSSProperties | {[key: string]: string | number} };
    disabled?: boolean;
}


export function MdProgressBar({ disabled = false, style = {} }: IMdProgressBar) {

    useEffect(() => { }, []);

    return (
        <div
            className="md-progress-bar"
            style={style["root"]}
        >
            <div className="md-progress-bar-value"></div>
        </div>
    );
}