import React, {CSSProperties, useContext, useEffect, useRef, useState} from "react";
import {MdButton} from "../md-button";
import {Guid} from "../../utils/utils";
import {ModalContext} from "../../pages/commonComponents/modal/ModalContext";
import './style.scss'
import {CrossIcon} from "../../icons/CrossIcon";


interface IMdModalProps {
    id?: string
    title?: string | React.ReactNode;
    children?: any;
    openHandler?: () => void;
    closeHandler?: () => void;
    closeOnClickOutside?: boolean;
    style?: { [selector: string]: CSSProperties }
}

export function MdModal({
    id = Guid.newGuid(),
    title = "",
    children = null,
    openHandler = () => {},
    closeHandler   = () => {},
    closeOnClickOutside = true,
    style = {}}: IMdModalProps
) {

    const {isOpen, open, close} = useContext(ModalContext);
    const [opened, setOpened] = useState(isOpen(id));


    const onOverlayMouseDown = (event: React.MouseEvent) => {
        if(!closeOnClickOutside) {
            return;
        }
        if ((event.target as HTMLElement).className == 'md-modal' && event.button == 0) {
            event.preventDefault();
            event.stopPropagation();
            close(id);
        }
    }


    useEffect(() => {
        const currentState = isOpen(id);
        if(opened != currentState) {
            if(currentState) {
                openHandler();
            }
            else {
                closeHandler();
            }
            setOpened(currentState);
        }
    }, [isOpen(id)]);


    return (
        <div className="md-modal"
             data-opened={isOpen(id)}
             onMouseDown={(e) => {
                 onOverlayMouseDown(e)
             }}>
            <div className="md-modal--window" style={style["root"]}>
                <div className="md-modal--header">
                    <div className="md-modal--header-title">
                        {title}
                    </div>
                    <div className="md-modal--header-buttons">
                        <MdButton
                            icon={
                                <CrossIcon
                                    width="16"
                                    height="16"
                                    style={{
                                        "path": {
                                            fill: "#000000"
                                        }
                                    }}
                                />
                            }
                            onClick={() => {
                                close(id);
                            }}
                            style={{
                                "button": {
                                    width: "30px",
                                    height: "30px",
                                    border: "none",
                                    justifyContent: "center",
                                    backgroundColor: "transparent",
                                },
                                "ink": {
                                    backgroundColor: "rgba(0, 0, 0, 0.1)"
                                }
                            }}
                        />
                    </div>
                </div>
                <div className="md-modal--content" style={style["content"]}>
                    {
                        children
                    }
                </div>
                {/*<div className="md-modal--footer">*/}

                {/*</div>*/}
            </div>
        </div>
    );
}