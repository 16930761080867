import React, {useEffect, useState} from "react";
import {useAppSelector} from "../../../redux/hooks";
import {useSearchParams} from "react-router-dom";
import {CompanyService} from "../../../services/CompanyService";
import {TripService} from "../../../services/TripService";
import {IOrderDTO} from "../../../interfaces/IOrderDTO";
import {ICreateOrderRequest} from "../../../api/request/ICreateOrderRequest";
import {OrderDetailsModal} from "./orderDetailsModal";
import {OrdersTableComponent} from "./ordersTableComponent";
import "./style.scss";


export function TripPage() {

    const [searchParams] = useSearchParams();
    const params = Object.fromEntries(searchParams.entries());


    const authState = useAppSelector((state) => state.auth);

    const tripService = TripService();
    const companyService = CompanyService();

    const [tripBuilderRequested, setTripBuilderRequested] = useState<boolean>(false);
    const [tripBuilderModalOpened, setTripBuilderModalOpened] = useState<boolean>(false);
    const [createOrderPending, setCreateOrderPending] = useState<boolean>(false);

    const [selectedOrder, setSelectedOrder] = useState<IOrderDTO | undefined>(undefined);

    const [orders, setOrders] = useState<IOrderDTO[]>([]);
    const [ordersPending, setOrdersPending] = useState(false);
    const [isOrdersNotFound, setIsOrdersNotFound] = useState(false);


    // TODO: maybe do it after company change?
    const checkCompany = async () => {
        let isCompanyExists = await companyService.isCompanyExists(authState.currentClientCode);
        if (!isCompanyExists) {
            await companyService.importCompany(authState.currentCompany);
        }
    }


    const onCreateOrderButtonClick = async () => {
        setCreateOrderPending(true);

        await checkCompany();

        const request = {
            code: authState.currentClientCode,
            orderNo: undefined,
            userId: authState.userId,
            initiator: 1, // LK
        } as ICreateOrderRequest;

        const orderDetails = await tripService.createOrder(request);

        if(orderDetails != null) {
            await loadOrders();
            setSelectedOrder(orderDetails);
            setTripBuilderRequested(true);
            setTripBuilderModalOpened(true);
        }

        setCreateOrderPending(false);
    }
    const onViewOrderButtonClick = async (orderInfo: IOrderDTO) => {
        await checkCompany();
        // TODO: improve it (separate OrderInfo and OrderDetails)
        //setSelectedOrder(orderInfo);
        const orderDetails = await tripService.getOrderById(orderInfo.id);
        if (orderDetails != null) {
            setSelectedOrder(orderDetails);
            setTripBuilderRequested(true);
            setTripBuilderModalOpened(true);
        }
    }
    const onRemoveOrderButtonClick = async (orderInfo: IOrderDTO) => {
        const response = await tripService.removeOrder(orderInfo.id);
        if(response != null) {
            await loadOrders();
        }
    }
    const onCreateOrderModalOpen = async () => {

    }
    const onCreateOrderModalClose = async () => {
        setTripBuilderRequested(false);
        setSelectedOrder(undefined);
    }
    const refreshOrderDetails = async () => {
        if(selectedOrder != null) {
            const orderDetails = await tripService.getOrderById(selectedOrder.id);
            if (orderDetails != null) {
                setSelectedOrder(orderDetails);
            }
        }
    }
    const onOrderBooked = async (orderId: string) => {
        const orderDetails = await tripService.getOrderById(orderId);
        if (orderDetails != null) {
            setSelectedOrder(orderDetails);
        }
    }
    const onOrderIssued = async (orderId: string) => {
        const orderDetails = await tripService.getOrderById(orderId);
        if (orderDetails != null) {
            setSelectedOrder(orderDetails);
        }
    }


    const loadOrders = async () => {
        setOrdersPending(true);
        setOrders([]);
        const response = await tripService.getOrders(authState.currentClientCode);
        if (response.length > 0) {
            setOrders(response);
            setIsOrdersNotFound(false);
        } else {
            setIsOrdersNotFound(true);
        }
        setOrdersPending(false);
    }


    useEffect(() => {
        if(params.clientCode != undefined) {
            companyService.makeActiveCompany(params.clientCode);
        }
    }, [params.clientCode]);

    useEffect(() => {
        loadOrders();
    }, [authState.currentClientCode]);


    return (
        <>
            {
                (params.clientCode == undefined || params.clientCode == authState.currentClientCode) && (
                    <OrdersTableComponent
                        orders={orders}
                        isLoading={ordersPending}
                        isSearchResultNotFound={isOrdersNotFound}
                        onCreateOrderButtonClick={onCreateOrderButtonClick}
                        onViewOrderButtonClick={onViewOrderButtonClick}
                        onRemoveOrderButtonClick={onRemoveOrderButtonClick}
                        createOrderPending={createOrderPending}
                        onLoadOrdersButtonClick={loadOrders}
                    />
                )
            }

            {
                (tripBuilderRequested && selectedOrder != undefined) && (
                    <OrderDetailsModal
                        opened={tripBuilderModalOpened}
                        setOpened={setTripBuilderModalOpened}
                        order={selectedOrder}
                        onOpen={onCreateOrderModalOpen}
                        onClose={onCreateOrderModalClose}
                        onOrderBooked={onOrderBooked}
                        onOrderIssued={onOrderIssued}
                    />
                )
            }

        </>
    );
}
